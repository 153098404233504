import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import qs from "query-string";
import PulseLoader from "react-spinners/PulseLoader";
import SpotTheBallGameContainer from "../../../containers/spot-the-ball/game/SpotTheBallGame.container";
import SpotTheBallForm from "../../../containers/spot-the-ball/form/SpotTheBallForm.container";
import SpotTheBallEnded from "../../../containers/spot-the-ball/ended/SpotTheBallEnded.container";
import SpotTheBallThankYou from "../../../containers/spot-the-ball/thank-you/SpotTheBallThankYou.container";

import { initiateStandardPayment } from "../../../utils/payment";
import { toast } from "react-toastify";
import { apiClient } from "../../../api/ApiClient";

const VIEW_STATE = {
    GAME: "GAME",
    FORM: "FORM",
    THANK_YOU: "THANK_YOU",
    ENDED: "ENDED",
};

class SpotTheBallPage extends Component {
    state = {
        isGameCompleted: false,
        isFormSubmitted: false,
    };

    constructor(props) {
        super(props);

        const { location } = this.props;
        const {
            quantity,
            contribution,
            orderId,
            anonymous,
            source,
            profileId,
        } = qs.parse(location.search);

        this.state = {
            quantity,
            contribution,
            orderId,
            anonymous,
            source,
            profileId,
        };
    }

    componentDidMount() {
        this.fetchSpotTheBall();
    }

    fetchSpotTheBall = (tryCount, resolve, reject) => {
        const { orderId, key } = this.state;
        const { seller } = this.props;

        if (!orderId) {
            return;
        } else if (!tryCount) {
            tryCount = 1;
        } else if (tryCount > 3) {
            console.error(
                `Giving up trying to retrieve spot-the-ball for order ${orderId}`
            );
            reject();
            return;
        }

        return new Promise((resolve, reject) => {
            apiClient
                .orderStatusCheck(seller.shortId, orderId)
                .then((res) => {
                    const { status, data } = res;

                    // Retry if the quiz is not yet ready
                    if (status === 202) {
                        setTimeout(() => {
                            this.fetchSpotTheBall(tryCount + 1);
                        }, 1500);

                        return;
                    }

                    this.setState({ data }, () => {
                        resolve();
                    });
                })
                .catch((err) => {});
        });
    };

    onSpotCompleted = (coords) => {
        const { orderId, key } = this.state;
        const { seller } = this.props;
        apiClient
            .makeSpotTheBallAttempts(seller.shortId, orderId, coords, key)
            .then(() => {
                this.onGameCompleted();
            })
            .catch((err) => {
                console.error(err);
                toast(
                    "An error occurred while submitting. Please try again in a moment",
                    { type: "error" }
                );
            });
    };

    onGameCompleted = () => {
        this.fetchSpotTheBall().then(() => {
            this.setState({ isGameCompleted: true });
        });
    };

    onFormSubmitted = () => {
        const {
            seller,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { contribution, quantity, source, profileId } = this.state;
        initiateStandardPayment({
            cart: {
                ...(contribution || {}),
                ...(quantity || {}),
            },
            seller,
            type: "CARD",
            source,
            sellerProfileId: profileId,
            salesLinkCodeOrShortId,
        });
    };

    render() {
        const { seller, t } = this.props;
        const {
            orderId,
            key,
            data,
            anonymous,
            isGameCompleted,
            isFormSubmitted,
            quantity,
            contribution,
            source,
            profileId,
        } = this.state;

        if (orderId && !data) {
            return (
                <div
                    style={{
                        marginTop: "80px",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <PulseLoader
                        sizeUnit={"px"}
                        size={20}
                        color={"#333"}
                        loading={true}
                        to
                    />
                </div>
            );
        }

        const { attempts, purchases, status, gameImage } = data || {};
        let purchasedCount = 0;

        if (purchases) {
            purchases.forEach((p) => {
                purchasedCount += p.quantity;
            });
        }

        const remainingAttempts =
            purchasedCount - (attempts ? attempts.length : 0);
        const hasMoreAttempts = remainingAttempts > 0;

        let viewState;
        if (gameImage?.isRevealed) {
            viewState = VIEW_STATE.ENDED;
        } else if (hasMoreAttempts && !isGameCompleted) {
            viewState = VIEW_STATE.GAME;
        } else if (!!!status && !isFormSubmitted) {
            viewState = VIEW_STATE.FORM;
        } else {
            viewState = VIEW_STATE.THANK_YOU;
        }

        switch (viewState) {
            case VIEW_STATE.GAME:
                return (
                    <SpotTheBallGameContainer
                        anonymous={anonymous}
                        orderId={orderId}
                        remainingAttempts={remainingAttempts}
                        onGameCompleted={() => this.onGameCompleted()}
                        onSpotCompleted={this.onSpotCompleted}
                        contest={data}
                    />
                );

            case VIEW_STATE.FORM:
                return (
                    <SpotTheBallForm
                        source={source}
                        profileId={profileId}
                        orderId={orderId}
                        updateKey={key}
                        quantity={quantity}
                        contribution={contribution}
                        seller={seller}
                        onFormSubmitted={() => this.onFormSubmitted()}
                    />
                );

            case VIEW_STATE.ENDED:
                return <SpotTheBallEnded contest={data} />;

            case VIEW_STATE.THANK_YOU:
                return <SpotTheBallThankYou contest={data} />;
        }
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(SpotTheBallPage))
);
