import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import qs from "query-string";

import ScratchcardPrizeContainer from "../../../containers/scratchcards/prize/ScratchcardPrize.container";

class ScratchcardPrizeFormPage extends Component {
    constructor(props) {
        super(props);

        const {
            match: {
                params: { orderId, scratchcardId },
            },
            location,
        } = this.props;
        const { key, selectedPrizeId, scratched } = qs.parse(location.search);
        this.state = {
            orderId,
            scratchcardId,
            key,
            selectedPrizeId,
            scratched,
        };
    }

    render() {
        const { orderId, scratchcardId, key, selectedPrizeId, scratched } =
            this.state;

        if (!orderId) {
            return null;
        }

        return (
            <ScratchcardPrizeContainer
                orderId={orderId}
                scratchcardId={scratchcardId}
                updateKey={key}
                scratched={scratched}
                selectedPrizeId={selectedPrizeId}
            />
        );
    }
}

export default withRouter(ScratchcardPrizeFormPage);
