import React from "react";
import Button from "../button/Button";
import { SimpleModal } from "../simple-modal/SimpleModal";
import ReactConfetti from "react-confetti";
import useWindowSize from "../../../hooks/useWindowSize";
import * as styles from "./GameEndModal.module.scss";

const Victory = ({ t, theme, nextPage }) => {
    return (
        <div>
            <h2>{t("raffle.draw_end_winner_heading")}</h2>
            <Button theme={theme} onClick={() => nextPage("delivery")}>
                {t("raffle.draw_end_winner_text")}
            </Button>
        </div>
    );
};
const Loss = ({ t, theme, nextPage }) => {
    return (
        <div>
            <h2>{t("raffle.draw_end_loser_heading")}</h2>
            <Button theme={theme} onClick={() => nextPage("summary")}>
                {t("raffle.draw_end_loser_text")}
            </Button>
        </div>
    );
};

const Confetti = () => {
    const { width, height } = useWindowSize();
    return (
        <div className={styles.confetti}>
            <ReactConfetti
                width={width}
                height={height}
                recycle={false}
                numberOfPieces={1000}
                initialVelocityY={20}
                initialVelocityX={10}
                confettiSource={{ x: width / 2, y: height / 2 }}
            />
        </div>
    );
};

export const GameEndModal = ({ t, isVictory, open, nextPage, theme }) => {
    const props = { t, theme, nextPage };
    return (
        <div className={styles.gameEndModal}>
            {open && isVictory && <Confetti />}
            <SimpleModal open={open}>
                {isVictory ? <Victory {...props} /> : <Loss {...props} />}
            </SimpleModal>
        </div>
    );
};
