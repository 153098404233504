import parsePhoneNumber from "libphonenumber-js";
import { getAlpha2CountryCodeByAlpha3Code } from "./localeHelpers";
import { toast } from "react-toastify";

export const isValidShoppingFrom = (
    countryCode,
    phoneNumber,
    email,
    postCode,
    t
) => {
    if (!/\S+@\S+\.\S+/.test(email)) {
        toast(t("error.error_invalid_email"), { type: "error" });
        return false;
    } else if (!isValidPhoneNumber(countryCode, phoneNumber)) {
        toast(t("error.error_invalid_phone_number"), { type: "error" });
        return false;
    } else if (countryCode === "NOR" && postCode.length !== 4) {
        toast(t("error.error_invalid_post_code"), { type: "error" });
        return false;
    }
    return true;
};

export const isValidPhoneNumber = (countryCode, phoneNumber) => {
    const formattedPhoneNumber = parsePhoneNumber(
        phoneNumber,
        getAlpha2CountryCodeByAlpha3Code(countryCode)
    );
    return !!formattedPhoneNumber && formattedPhoneNumber.isValid();
};
