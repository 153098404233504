import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";

const getUniqueCartId = () => window.location.pathname.split("/")[1];

export default function configureStore() {
    const middleware = [
        applyMiddleware(thunk),
        ...(window.__REDUX_DEVTOOLS_EXTENSION__
            ? [window.__REDUX_DEVTOOLS_EXTENSION__()]
            : []),
    ];

    const cartId = `cart_${getUniqueCartId()}`;
    const cart = sessionStorage.getItem(cartId)
        ? JSON.parse(sessionStorage.getItem(cartId))
        : [];

    const store = createStore(
        rootReducer,
        { sellerReducer: { cart, seller: {} } },
        compose(...middleware)
    );

    store.subscribe(() => {
        const {
            sellerReducer: { cart },
        } = store.getState();

        if (cart) {
            sessionStorage.setItem(cartId, JSON.stringify(cart));
        }
    });

    return store;
}
