import React, { useReducer } from "react";

const CakeTicketsContext = React.createContext();

const initialState = {
    onboardingStep: 0,
    selectedTickets: [],
    showButton: false,
    ticketCount: 50,
};
function reducer(state, { type, payload }) {
    switch (type) {
        case "selectTicket":
            return {
                ...state,
                onboardingStep:
                    state.onboardingStep < 2 ? state.onboardingStep + 1 : 2,
                selectedTickets: [...state.selectedTickets, payload],
            };
        case "removeTicket":
            return {
                ...state,
                selectedTickets: [...state.selectedTickets].filter(
                    (n) => n !== payload
                ),
            };
        case "updateShowButton":
            return { ...state, showButton: true };
        case "removeAllTickets":
            return {
                ...state,
                selectedTickets: [],
            };
        default:
            throw new Error();
    }
}

export const CakeTicketsContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <CakeTicketsContext.Provider
            value={{
                ...state,
                selectTicket: (number) =>
                    dispatch({ type: "selectTicket", payload: number }),
                removeTicket: (number) =>
                    dispatch({ type: "removeTicket", payload: number }),
                removeAllTickets: () => dispatch({ type: "removeAllTickets" }),
            }}
        >
            {children}
        </CakeTicketsContext.Provider>
    );
};

export default CakeTicketsContext;
