import SpondImage from "../../../components/spond-image/SpondImage";
import {
    PurchaseButton,
    PurchaseButtonThin,
} from "../../../components/button/Buttons";
import { Link, useParams } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";
import { Product, Seller, Theme } from "../../../api/types";
import styled from "styled-components";
import "./CheckoutOrKeepShopping.scss";
import { TinyColor } from "@ctrl/tinycolor";
import { CurrencyFormatter } from "../../../utils/Currency";
import { routes } from "../../../app/routes";

export type CheckoutOrKeepShoppingContentProps = {
    variationName: string;
    source: string;
    profileId: string;
    keepShopping: () => void;
    seller: Seller;
    product: Product;
    freeShippingCountdown: number | null;
};

const KeepShoppingButton = ({
    seller,
    onClick,
}: {
    seller: Seller;
    onClick: () => void;
}) => {
    const { t } = useTranslation();
    return (
        <PurchaseButton
            style={{ marginTop: "25px", marginBottom: "0" }}
            theme={seller.theme}
            onClick={onClick}
        >
            {t("actions.keep_shopping_label")}
        </PurchaseButton>
    );
};

const CheckoutButton = ({
    seller,
    source,
    profileId,
    salesLinkCodeOrShortId,
}: {
    seller: Seller;
    source: string;
    profileId: string;
    onClick?: () => void;
    salesLinkCodeOrShortId: string;
}) => {
    const { t } = useTranslation();
    const linkToBasket =
        routes.seller(salesLinkCodeOrShortId).cart +
        "?" +
        [
            { param: "source", value: source },
            { param: "profileId", value: profileId },
        ]
            .filter((it) => it.value)
            .map((it) => `${it.param}=${it.value}`)
            .join("&");
    return (
        <Link className={"link-content"} to={linkToBasket}>
            {/*@ts-ignore*/}
            <PurchaseButtonThin
                style={{ margin: "1rem 0 0 0" }}
                theme={seller.theme}
            >
                {t("actions.go_to_checkout")}
            </PurchaseButtonThin>
        </Link>
    );
};

const lighten = (baseColorHex: string, amount: number) =>
    "#" + new TinyColor(baseColorHex).lighten(amount).toHex();

const FreeShippingCountdownElement = styled.div`
    margin: 1.25rem 0 0 0;
    max-width: 360px;
    padding: 0.9rem 2rem;
    left: 41px;
    top: 482px;
    text-align: center;
    font-size: 1rem;
    background-color: ${({ primaryButtonColour }: Theme) =>
        lighten(primaryButtonColour || "#0000ff", 46)};
    border-radius: 18px;
`;

const ProductName = styled.h4`
    margin: 1rem 0 0 0;
    text-align: center;
`;

export const CheckoutOrKeepShopping = ({
    variationName,
    source,
    profileId,
    seller,
    keepShopping,
    product,
    freeShippingCountdown,
}: CheckoutOrKeepShoppingContentProps) => {
    const t = useTranslation().t;
    const productName = [
        product.name,
        variationName ? `(${variationName})` : null,
    ].join(" ");
    const { salesLinkCodeOrShortId }: { salesLinkCodeOrShortId: string } =
        useParams();
    return (
        <div id="checkout-or-keep-shopping" data-testid={"action-modal"}>
            <SpondImage
                imageUrl={product.preview}
                transformation="w=270&h=270"
                alt="product"
                style={{ width: "135px", height: "135px" }}
            />
            <ProductName>
                <strong>{productName}</strong>
                <br />
                {t("shopping_cart.is_added_to_cart")}
            </ProductName>
            {freeShippingCountdown !== null && freeShippingCountdown > 0 && (
                <FreeShippingCountdownElement
                    {...seller?.theme}
                    data-testid={"free-shipping-info"}
                >
                    {t(
                        "checkout_or_keep_shopping.remaining_until_free_shipping",
                        {
                            freeShippingCountdown: CurrencyFormatter(
                                t,
                                seller,
                                freeShippingCountdown
                            ),
                        }
                    )}
                </FreeShippingCountdownElement>
            )}
            <KeepShoppingButton seller={seller} onClick={keepShopping} />
            <CheckoutButton
                salesLinkCodeOrShortId={salesLinkCodeOrShortId}
                seller={seller}
                source={source}
                profileId={profileId}
            />
        </div>
    );
};
