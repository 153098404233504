import styled from "styled-components";

type Heading1Props = { name?: string };

export const Heading1 = styled.h1<Heading1Props>`
    color: ${({ theme }) =>
        theme && theme.primaryColour ? theme.primaryColour : "#E51D1F"};
    font-weight: 700;
    margin: 0;
    letter-spacing: 2px;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
        1px 1px 0 #fff;
`;

export const Terms = styled.p`
    color: #6e6e6e;
    text-align: center;
    font-size: 13px;
    line-height: 150%;

    a:link,
    a:visited,
    a:hover,
    a:active {
        color: #6e6e6e;
        font-weight: 600;
    }

    a:hover {
        text-decoration: underline;
    }
`;

export const Heading3 = styled.h3`
    color: ${({ theme }) =>
        theme && theme.primaryColour ? theme.primaryColour : "#E51D1F"};
    font-weight: 700;
    margin: 0;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
        1px 1px 0 #fff;
`;
