import React from "react";
import * as styles from "../CakeStart.module.scss";
import Modal from "react-responsive-modal";
import { useTranslation } from "react-i18next";

const modalRoot = document.createElement("div");
modalRoot.setAttribute("id", "modal-root");
document.body.appendChild(modalRoot);

export const RulesModalContent = ({ products, groupName, campaignURL }) => {
    const t = useTranslation().t;
    return (
        <>
            <div
                dangerouslySetInnerHTML={{
                    __html: t("landing.raffle_read_more_content"),
                }}
            />
            <div className={styles.prizeContainer}>
                {products.map(({ id, preview, name, description }) => (
                    <div key={id}>
                        <img src={preview} alt={name} />
                        <h1>{name}</h1>
                        <p>{description}</p>
                    </div>
                ))}
            </div>
            <div
                dangerouslySetInnerHTML={{
                    __html: t("landing.raffle_contact_content", {
                        groupName,
                        campaignURL,
                    }),
                }}
            />
        </>
    );
};

const RulesModal = ({ open, closeModal, ...rest }) => {
    return (
        <Modal
            onClose={closeModal}
            open={open}
            closeOnOverlayClick={true}
            classNames={{ overlay: styles.scrim, modal: styles.cakeRulesModal }}
            showCloseIcon
        >
            <RulesModalContent {...rest} />
        </Modal>
    );
};

export default RulesModal;
