import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { trackPageView } from "../../../EventTracker";
import { routes } from "../../../app/routes";

const Table = styled.table`
    table-layout: fixed;
    width: 100%;
    text-align: left;

    tr td:first-child {
        vertical-align: top;
    }

    th,
    td {
        padding: 5px 0;
    }
`;

class CookiesPage extends Component {
    componentDidMount() {
        const { seller } = this.props;
        trackPageView("Cookies", {
            shortId: seller.shortId,
            orgDisplayName: seller.displayName,
            groupId: seller.groupId,
        });
    }

    render() {
        const {
            t,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;

        return (
            <div>
                <Link to={routes.seller(salesLinkCodeOrShortId).pathname}>
                    <h4>&larr; {t("terms.back")}</h4>
                </Link>
                {}
                <span
                    dangerouslySetInnerHTML={{ __html: t("cookies.content") }}
                />
                <Table>
                    <thead>
                        <tr>
                            <th>{t("cookies.table_head_name")}</th>
                            <th>{t("cookies.table_head_content")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{t("cookies.table_name1")}</td>
                            <td>{t("cookies.table_content1")}</td>
                        </tr>
                        <tr>
                            <td>{t("cookies.table_name2")}</td>
                            <td>{t("cookies.table_content2")}</td>
                        </tr>
                        <tr>
                            <td>{t("cookies.table_name3")}</td>
                            <td>{t("cookies.table_content3")}</td>
                        </tr>
                        <tr>
                            <td>{t("cookies.table_name4")}</td>
                            <td>{t("cookies.table_content4")}</td>
                        </tr>
                    </tbody>
                </Table>
                <Link to={routes.seller(salesLinkCodeOrShortId).pathname}>
                    <h4>&larr; {t("terms.back")}</h4>
                </Link>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(CookiesPage))
);
