import React from "react";
import dots from "./dots";
import * as styles from "./Confetti.module.scss";

const Confetti = ({ position, margin }) => {
    return (
        <div className={styles.dots} style={{ [position]: `${margin || 0}px` }}>
            {dots.map((Dot, idx) => (
                <Dot key={idx} />
            ))}
        </div>
    );
};

export default Confetti;
