import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Header, HeaderInner } from "./BallGame.styled";
import SpotTheBallSelectorContainer from "../selector/SpotTheBallSelector.container";
import { withTranslation } from "react-i18next";

class SpotTheBallGameContainer extends SpotTheBallSelectorContainer {
    render() {
        const { previousAttempts } = this.state;
        const { remainingAttempts, t } = this.props;

        const sessionRemainingAttempts =
            remainingAttempts - previousAttempts.length;

        return (
            <>
                <Header>
                    <HeaderInner>
                        <h3
                            style={{
                                margin: 0,
                                userSelect: "none",
                                outline: "none",
                                WebkitTouchCallout: "none",
                                WebkitUserSelect: "none",
                            }}
                        >
                            {t("landing.spot_the_ball")}
                        </h3>
                        <p
                            style={{
                                userSelect: "none",
                                outline: "none",
                                WebkitTouchCallout: "none",
                                WebkitUserSelect: "none",
                            }}
                        >
                            {t("landing.spot_judges_winner")}
                        </p>
                        <p
                            style={{
                                marginTop: "0",
                                marginBottom: "10px",
                                color: "white",
                                paddingBottom: "20px",
                                fontWeight: "700",
                                userSelect: "none",
                                outline: "none",
                                WebkitTouchCallout: "none",
                                WebkitUserSelect: "none",
                            }}
                        >
                            {t("landing.spot_the_ball_attempts", {
                                count1: Math.min(
                                    remainingAttempts -
                                        sessionRemainingAttempts +
                                        1,
                                    remainingAttempts
                                ),
                                count2: remainingAttempts,
                            })}
                        </p>
                    </HeaderInner>
                </Header>

                {super.render()}
            </>
        );
    }
}

export default withTranslation()(SpotTheBallGameContainer);
