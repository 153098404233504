import React from "react";
import * as imgVippsLogo from "../../../assets/vipps_logo.png";
import styled from "styled-components";

const StyledButton = styled.button`
  user-select: none;
  padding: 0.5rem 2rem;
  color: var(--color-gray-white);
  font-size: 1.25rem;
  border-radius: var(--rounded);
  white-space: nowrap;
  border: none;
  cursor: pointer;
  font-weight: 700;
  transition: background-color 200ms ease-in-out;
	background-color: ${({ theme }) =>
        theme?.primaryButtonColour || "var(--color-accent)"};
	color: ${({ theme }) => theme?.primaryButtonTextColour || "white"}
	&:hover:enabled {
		filter: brightness(85%);
	}
  &:disabled {
    cursor: not-allowed;
  	filter: brightness(130%);
	}
`;

export const Button = ({ submit, ...rest }) => {
    return (
        <StyledButton
            data-testid={"styled-button"}
            type={submit ? "submit" : "button"}
            {...rest}
        />
    );
};

export const VippsButton = ({ children, submit, ...rest }) => {
    const vippsTheme = {
        primaryButtonColour: "#ff5b24",
        primaryButtonTextColour: "white",
    };
    return (
        <StyledButton
            theme={vippsTheme}
            type={submit ? "submit" : "button"}
            {...rest}
        >
            {children}{" "}
            <img
                alt={"Vipps Logo"}
                src={imgVippsLogo}
                style={{
                    height: "13px",
                    width: "50px",
                    position: "relative",
                    bottom: "-3px",
                    left: "0",
                    marginRight: "7px",
                }}
            />
        </StyledButton>
    );
};

export default Button;
