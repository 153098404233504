import React, { ReactNode } from "react";
import "./ScratchcardLayout.scss";

import styled from "styled-components";

export const ScratchcardBg = styled.div<{ bgImage?: string }>`
    background-image: url("${(props) => props.bgImage}");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 100vh;
`;

export default (props: { children: ReactNode }) => (
    <div className="container">{props.children}</div>
);
