import React, { useEffect, useState } from "react";
import Ticket from "../ticket/Ticket";
import GlassContainer from "../glass-container/GlassContainer";
import * as styles from "./TicketContainer.module.scss";

const TicketContainer = ({
    ticketCount,
    selectTicket,
    removeTicket,
    selectedTickets,
    theme,
}) => {
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setLoaded(() => true);
        setTimeout(() => {}, 500);
    }, []);
    return (
        <GlassContainer className={styles.wrapper}>
            {[...Array(ticketCount).keys()].map((n) => (
                <Ticket
                    key={"ticket-" + n}
                    className={[
                        styles.ticket,
                        loaded ? styles.loaded : "",
                    ].join(" ")}
                    style={{ transitionDelay: `${20 * n}ms` }}
                    number={n + 1}
                    selectTicket={selectTicket}
                    removeTicket={removeTicket}
                    selected={selectedTickets.includes(n + 1)}
                    theme={theme}
                />
            ))}
        </GlassContainer>
    );
};

export default TicketContainer;
