import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Confetti from "react-confetti";
import imgMedal from "../../../assets/feature-3@2x.png";

import { Header, HeaderInner } from "../game/BallGame.styled";
import { ContentHolder } from "../form/SpotTheBallForm.container.styled";
import Avatar from "../../../components/avatar/Avatar";
import SpotTheBallResultCanvas from "../../../components/spot-the-ball/SpotTheBallResultCanvas.component";

import DOMPurify from "dompurify";
import { getSellerDisplayName } from "../../../utils/strings/strings";
import {
    HighscoreListHolder,
    HeaderTableCell,
    TableRow,
    TableCell,
} from "./SpotTheBallEnded.styled";

const HighscoreList = ({ highscore, t }) => (
    <div>
        <HighscoreListHolder>
            <div
                style={{
                    display: "table",
                    tableLayout: "fixed",
                    width: "100%",
                }}
            >
                <TableRow>
                    <HeaderTableCell>{t("end.title_rank")}</HeaderTableCell>
                    <HeaderTableCell>{t("general.name")}</HeaderTableCell>
                    <HeaderTableCell>{t("end.title_distance")}</HeaderTableCell>
                </TableRow>
                {highscore.map(
                    (
                        { name, rank, distance, isCurrentUser, anonymous },
                        idx
                    ) => (
                        <TableRow
                            key={`${name}_${idx}`}
                            style={{
                                display: "table-row",
                                backgroundColor: isCurrentUser
                                    ? "#f2fbf6"
                                    : "none",
                            }}
                        >
                            <TableCell style={{ fontWeight: 500 }}>
                                {rank}
                            </TableCell>
                            <TableCell
                                style={{
                                    fontWeight: isCurrentUser ? 500 : 400,
                                }}
                            >
                                {idx === 0 && (
                                    <img
                                        src={imgMedal}
                                        style={{
                                            width: "18px",
                                            height: "18px",
                                            verticalAlign: "-3px",
                                        }}
                                    />
                                )}{" "}
                                {!anonymous ? name : `(${t("end.anonymous")})`}
                            </TableCell>
                            <TableCell
                                style={{
                                    fontWeight: isCurrentUser ? 500 : 400,
                                }}
                            >
                                {distance}
                            </TableCell>
                        </TableRow>
                    )
                )}
            </div>
        </HighscoreListHolder>
    </div>
);

class SpotTheBallEnded extends Component {
    render() {
        const {
            t,
            seller,
            seller: { shortId, sellerImage, groupName, groupSeller, prize },
            contest,
        } = this.props;

        const {
            attempts,
            gameImage: { ballX, ballY, url, height, width, isWinner },
        } = contest;

        const judgedPosition = {
            xCoord: ballX,
            yCoord: ballY,
            resX: width,
            resY: height,
        };
        const otherAttempts = attempts.filter((a) => !a.isCurrentUser);
        const myAttempts = attempts.filter((a) => a.isCurrentUser);
        const sellerDisplayName = getSellerDisplayName(seller);

        let prizeDescription;
        if (prize.type === "PROVIDED") {
            prizeDescription = prize.description;
        } else {
            prizeDescription = t("landing.spot_prize_description_gift_card", {
                count: prize.percentage,
            });
        }

        let placement;
        for (let i = 0; i < attempts.length; i++) {
            let hs = attempts[i];
            if (hs.isCurrentUser) {
                placement = hs.rank;
                break;
            }
        }
        const highScores = attempts;

        return (
            <>
                {isWinner && (
                    <Confetti
                        width={window.width}
                        height={window.height}
                        recycle={false}
                        numberOfPieces={1000}
                        initialVelocityX={6}
                        initialVelocityY={20}
                    />
                )}
                <Header>
                    <HeaderInner>
                        <div
                            style={{
                                position: "absolute",
                                left: "50%",
                                marginLeft: "-50px",
                                bottom: "-40px",
                            }}
                        >
                            <Avatar
                                width="100px"
                                imageUrl={sellerImage}
                                shortId={`${shortId.substring(0, 6)}B`}
                                style={{ border: "1px solid white" }}
                            />
                        </div>
                    </HeaderInner>
                </Header>
                <ContentHolder
                    style={{ padding: "0 20px", boxSizing: "border-box" }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            margin: "60px auto 30px auto",
                            maxWidth: "100%",
                        }}
                    >
                        <h2 style={{ textAlign: "center" }}>
                            {isWinner && <>{t("end.title_congratulations")}</>}
                            {!isWinner && <>{t("no_tickets.title")}</>}
                        </h2>

                        <p
                            style={{
                                textAlign: "center",
                                marginLeft: "auto",
                                marginRight: "auto",
                                maxWidth: "540px",
                            }}
                        >
                            {isWinner && (
                                <>
                                    {t("end.winner_description", {
                                        groupName,
                                        prizeDescription,
                                    })}
                                </>
                            )}
                            {!isWinner && placement === 1 && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            "end.winner_other_order_description",
                                            {
                                                displayName: DOMPurify.sanitize(
                                                    groupSeller
                                                        ? `${groupName}`
                                                        : `${sellerDisplayName}`,
                                                    {
                                                        USE_PROFILES: {
                                                            html: false,
                                                        },
                                                    }
                                                ),
                                            }
                                        ),
                                    }}
                                ></div>
                            )}
                            {!isWinner && placement !== 1 && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t("end.lose_description", {
                                            displayName: DOMPurify.sanitize(
                                                groupSeller
                                                    ? `${groupName}`
                                                    : `${sellerDisplayName}`,
                                                {
                                                    USE_PROFILES: {
                                                        html: false,
                                                    },
                                                }
                                            ),
                                        }),
                                    }}
                                ></div>
                            )}
                        </p>
                        <div style={{ margin: "30px auto", width: "100%" }}>
                            <SpotTheBallResultCanvas
                                imageUrl={url}
                                width={width}
                                height={height}
                                attempts={myAttempts}
                                judgedPosition={judgedPosition}
                                otherAttempts={otherAttempts}
                                highlightClosest={true}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: "100%",
                                    margin: "15px auto 0 auto",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        margin: "0 auto",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "8px",
                                            height: "8px",
                                            backgroundColor:
                                                "rgba(148, 195, 40, 1)",
                                            border: "1px solid #666",
                                        }}
                                    ></div>
                                    <div
                                        style={{
                                            paddingLeft: "8px",
                                            fontSize: "13px",
                                        }}
                                    >
                                        {isWinner && (
                                            <>{t("end.your_win_position")}</>
                                        )}

                                        {!isWinner && (
                                            <>{t("end.your_closest_attempt")}</>
                                        )}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        margin: "0 auto",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "8px",
                                            height: "8px",
                                            backgroundColor:
                                                "rgba(203, 23, 229, 1)",
                                            border: "1px solid #666",
                                        }}
                                    ></div>
                                    <div
                                        style={{
                                            paddingLeft: "8px",
                                            fontSize: "13px",
                                        }}
                                    >
                                        {t("end.judge_position")}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        margin: "0 auto",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "8px",
                                            height: "8px",
                                            backgroundColor: "white",
                                            border: "1px solid #666",
                                        }}
                                    ></div>
                                    <div
                                        style={{
                                            paddingLeft: "8px",
                                            fontSize: "13px",
                                        }}
                                    >
                                        {t("end.others_attempts")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!!highScores && (
                            <>
                                <div
                                    style={{
                                        backgroundColor: "#f0f0f0",
                                        height: "1px",
                                        width: "100%",
                                        maxWidth: "280px",
                                        margin: "0 auto 30px auto",
                                    }}
                                ></div>
                                <div>
                                    <h2 style={{ textAlign: "center" }}>
                                        {t("end.scoreboard")}
                                    </h2>
                                    <HighscoreList
                                        highscore={highScores}
                                        t={t}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </ContentHolder>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(SpotTheBallEnded))
);
