export const CurrencyFormatter = function (t, seller, amount) {
    if (seller && seller.countryCode == "NOR") {
        return amount * 0.01 + t("general.number_suffix");
    } else {
        return t("general.number_prefix") + amount * 0.01;
    }
};

export const CurrencyWithDecimal = function (seller, amount) {
    return amount * 100;
};
