import React, { useState, useEffect, useCallback } from "react";
import CakeTicketDrawPage from "../cake-ticket-draw-page/CakeTicketDraw.page";
import CakeSummaryPage from "../cake-summary-page/CakeSummary.page";
import CakeShippingPage from "../cake-shipping-page/CakeShippingPage";
import { apiClient } from "../../../api/ApiClient";
import { useParams } from "react-router-dom";
import { routes } from "../../../app/routes";

const CakeLotteryGameWrapper = ({ t, seller, orderId }) => {
    const [raffleTickets, setRaffleTickets] = useState();
    const [navState, setNavState] = useState();
    const { salesLinkCodeOrShortId } = useParams();
    const nextPage = (page) => {
        setNavState(page);
    };
    const missingTicketsRedirect = useCallback(() => {
        window.location.href = routes.seller(salesLinkCodeOrShortId).pathname;
    }, []);

    useEffect(() => {
        const { shortId } = seller;
        apiClient.orderStatusCheck(shortId, orderId).then((res) => {
            const { raffleTickets } = res.data;

            // Go to landing page
            if (!raffleTickets.length) {
                missingTicketsRedirect();
                return;
            }

            setRaffleTickets(
                raffleTickets.sort((a, b) => {
                    let labelA = parseInt(a.label);
                    let labelB = parseInt(b.label);
                    if (labelA > labelB) {
                        return 1;
                    } else if (labelA < labelB) {
                        return -1;
                    } else {
                        return 0;
                    }
                })
            );

            // Determine correct nav state
            if (!raffleTickets[0].revealed) {
                setNavState("draw");
            } else {
                // Determine if order has unclaimed prize
                for (let i = 0; i < raffleTickets.length; i++) {
                    const { hasPrize, prizeClaimed } = raffleTickets[i];
                    if (hasPrize && !prizeClaimed) {
                        setNavState("delivery");
                        return;
                    }
                }

                setNavState("summary");
            }
        });
    }, [seller]);

    return (
        <>
            {/* TODO == vs === */}
            {navState === "draw" && (
                <CakeTicketDrawPage
                    t={t}
                    orderId={orderId}
                    seller={seller}
                    nextPage={nextPage}
                    missingTicketsRedirect={missingTicketsRedirect}
                    raffleTickets={raffleTickets}
                />
            )}
            {/* TODO == vs === */}
            {navState === "delivery" && (
                <CakeShippingPage
                    t={t}
                    orderId={orderId}
                    missingTicketsRedirect={missingTicketsRedirect}
                    nextPage={() => nextPage("summary")}
                />
            )}
            {/* TODO == vs === */}
            {navState === "summary" && <CakeSummaryPage t={t} />}
        </>
    );
};

export default CakeLotteryGameWrapper;
