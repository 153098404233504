import React from "react";
import CakeBackground from "../cake-background/CakeBackground";
import * as styles from "./Layout.module.scss";

const Layout = ({ className, hasCake, children, theme, isPreview }) => {
    return (
        <div
            className={[
                isPreview ? styles.layoutPreview : styles.layout,
                className,
            ].join(" ")}
            style={{
                background: theme?.primaryColour || "var(--color-gray-50)",
            }}
        >
            <div className={styles.innerLayout}>{children}</div>
            {hasCake && (
                <CakeBackground
                    background={theme?.background}
                    mobileBackground={theme?.mobileBackground}
                />
            )}
        </div>
    );
};

export default Layout;
