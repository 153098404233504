import React from "react";

const Arrow = ({ className }) => {
    return (
        <svg
            width="19"
            height="17"
            viewBox="0 0 19 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M11.2321 16C10.4622 17.3333 8.53775 17.3333 7.76795 16L0.406734 3.25C-0.363066 1.91667 0.599184 0.25 2.13878 0.25L16.8612 0.250001C18.4008 0.250002 19.3631 1.91667 18.5933 3.25L11.2321 16Z"
                fill="#374151"
            />
        </svg>
    );
};

export default Arrow;
