import React from "react";
import { withTranslation } from "react-i18next";
import Avatar from "../avatar/Avatar";
import DOMPurify from "dompurify";
import {
    UkHeaderInner,
    UkHeaderTitle,
    UkHeaderDescription,
} from "./UkHeader.styled";
import { getSellerDisplayName } from "../../utils/strings/strings";
import { withRouter } from "react-router-dom";

const UkHeader = (props) => {
    const { previewMode, seller, t } = props;
    const {
        shortId,
        sellerImage,
        description,
        groupName,
        groupSeller,
        sellerFirstName,
        countryCode,
    } = seller;

    const representHimself = shortId?.endsWith("0");
    const sellerDisplayName = getSellerDisplayName(seller) || groupName;

    return (
        <UkHeaderInner>
            <Avatar
                width="100px"
                imageUrl={sellerImage}
                shortId={shortId}
                style={{ border: "1px solid white" }}
            />
            <>
                <UkHeaderTitle
                    dangerouslySetInnerHTML={{
                        __html: t(
                            groupSeller ||
                                previewMode ||
                                (representHimself && countryCode === "GBR")
                                ? "landing.subtitle_organization"
                                : "landing.title",
                            {
                                sellerDisplayName:
                                    '<span name="group-name" style=display:inline-block>' +
                                    DOMPurify.sanitize(sellerDisplayName, {
                                        USE_PROFILES: { html: false },
                                    }) +
                                    "</span>",
                                groupName:
                                    '<span name="group-name" style=display:inline-block>' +
                                    DOMPurify.sanitize(groupName, {
                                        USE_PROFILES: { html: false },
                                    }) +
                                    "</span>",
                                firstName: DOMPurify.sanitize(sellerFirstName, {
                                    USE_PROFILES: { html: false },
                                }),
                            }
                        ),
                    }}
                />
                <UkHeaderDescription
                    isSubTitle={true}
                    name="fundraiser-purpose"
                >
                    {description}
                </UkHeaderDescription>
            </>
        </UkHeaderInner>
    );
};

export default withTranslation()(UkHeader);
