import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { trackPageView } from "../../../EventTracker";
import { FUNDRAISER_TYPE } from "../../../data/consts";
import moment from "moment";
import { routes } from "../../../app/routes";

const getNorwegianTerms = ({ type, displayName, description, startDate }) => {
    if (type === FUNDRAISER_TYPE.SCRATCHCARDS) {
        return (
            <Fragment>
                <h1>Kjøpsvilkår</h1>
                <br />

                <h2>Innledning</h2>

                <p>
                    Salget av digitale skrapelodd (“innsamlingskampanjen”)
                    arrangeres av Spond AS, Myntgt. 2, 0151 Oslo (“Spond”) på
                    vegne av lag og grupper som ønsker å bruke dette som en
                    kilde til finansiering av aktivitetene sine.
                </p>

                <p>
                    Hvert lodd koster NOK 50 og betales med Vipps.
                    Vinnersannsynligheten per lodd er 1:50.
                </p>

                <p>
                    Hvis du ønsker mer informasjon enn du finner i disse
                    kjøpsvilkårene - se artikkel i vårt hjelpesenter.
                </p>

                <h2>Trekning og premier</h2>
                <p>
                    Innsamlingskampanjen er forhåndstrukket og resultatet av
                    trekningen blir presentert kjøper umiddelbart etter
                    skraping.
                </p>

                <p>
                    Premiene blir sendt til vinnerne, og porto/frakt bekostes av
                    Spond AS. Formålet med innsamlingskampanjen er å finansiere
                    lag og grupper, og gevinstene som kan vinnes har ubetydelig
                    økonomisk verdi.
                </p>

                <h2>Angrerett</h2>
                <p>
                    Angrerettloven gir i utgangspunktet forbrukere 14 dagers
                    angrerett på produkter og tjenester de har kjøpt via
                    internett. Angreretten gjelder likevel ikke ved levering av
                    digitalt innhold som ikke leveres på et fysisk medium, slik
                    som digitale skrapelodd. Dette forutsetter at leveringen er
                    begynt med forbrukerens uttrykkelige samtykke og at
                    forbrukeren erkjenner at angreretten dermed går tapt. Før du
                    kjøper de forhåndstrukne skrapeloddene vil du derfor bli
                    orientert om at du ved å bekrefte kjøpet erkjenner at du
                    ikke har angrerett.
                </p>

                <h2>Mangler ved premie</h2>
                <p>
                    Hvis det skulle være mangler ved premien du mottar må du
                    snarest mulig ta kontakt med Spond på support@spond.com. Ved
                    mangler vil Spond normalt sende deg en ny premie av lik
                    eller lignende art.
                </p>

                <h2>Feil i tjenesten - ansvarsbegrensning</h2>
                <p>
                    Uansett hvor hardt vi prøver kan vi ikke helt sikre oss mot
                    at det oppstår feil. Dersom tjenesten ikke skulle fungere
                    som den skal vil vi utbedre dette raskest mulig. Hvis du for
                    eksempel har betalt for lodd og feil som vi svarer for gjør
                    at du ikke får lastet dem ned, vil du få ny mulighet så
                    snart feilen er utbedret. Ved feil som innebærer at det blir
                    flere vinnere enn forutsatt, forbeholder vi oss retten til å
                    annullere trekningen, og å gjennomføre en ny trekning.
                    Eventuelt økonomisk ansvar for oss omfatter ikke indirekte
                    tap, og er uansett begrenset til et beløp tilsvarende hva du
                    har betalt for loddet eller loddene som det er noe feil med.
                </p>

                <h2>Behandling av personopplysninger</h2>
                <p>
                    Personopplysningene som selger innhenter og lagrer benyttes
                    i henhold til personvernerklæringen som du finner her:
                    https://www.spond.com/privacy
                </p>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <h1>Kjøpsvilkår</h1>
            <br />
            <h2>Innledning</h2>
            <p>
                Dette kjøpet er regulert av de nedenstående standard
                salgsbetingelser for forbrukerkjøp av varer over Internett.
                Forbrukerkjøp over internett reguleres hovedsakelig av
                avtaleloven, forbrukerkjøpsloven, markedsføringsloven,
                angrerettloven og ehandelsloven, og disse lovene gir forbrukeren
                ufravikelige rettigheter. Lovene er tilgjengelig på
                www.lovdata.no. Vilkårene i denne avtalen skal ikke forstås som
                noen begrensning i de lovbestemte rettighetene, men oppstiller
                partenes viktigste rettigheter og plikter for handelen.
            </p>
            <h2>1. Avtalen</h2>
            <p>
                Avtalen består av disse salgsbetingelsene, opplysninger gitt i
                bestillingsløsningen og eventuelt særskilt avtalte vilkår. Ved
                eventuell motstrid mellom opplysningene, går det som særskilt er
                avtalt mellom partene foran, så fremt det ikke strider mot
                ufravikelig lovgivning.
            </p>
            <p>
                Avtalen vil i tillegg bli utfylt av relevante lovbestemmelser
                som regulerer kjøp av varer mellom næringsdrivende og
                forbrukere.
            </p>
            <h2>2. Partene</h2>
            <p>
                Spond AS er selger av dugnadsproduktene og betegnes i det
                følgende som selger/selgeren. Kjøper er den forbrukeren som
                foretar bestillingen, og betegnes i det følgende som
                kjøper/kjøperen.
            </p>
            <h2>3. Pris</h2>
            <p>
                Den oppgitte prisen for varen og tjenester er den totale prisen
                kjøper skal betale. Denne prisen inkluderer alle avgifter og
                tilleggskostnader. Ytterligere kostnader som selger før kjøpet
                ikke har informert om, skal kjøper ikke bære.
            </p>
            <h2>4. Avtaleinngåelse</h2>
            <p>
                Avtalen er bindende for begge parter når kjøperen har sendt sin
                bestilling til selgeren.
            </p>
            <p>
                Selgeren forbeholder seg retten til å kansellere bestillingen
                eller del av denne, dersom produktet er utsolgt. Ved
                utsolgtsituasjoner vil kjøperen få melding om dette, eventuelt
                sammen med informasjon om alternative varer. Kjøperen vil i
                slike tilfeller få muligheten til å akseptere nye tilbud eller
                kansellere bestillingen.
            </p>
            <p>
                Dersom det har forekommet skrive- eller trykkfeil av vesentlig
                størrelse fra Selger sin side, i annonser o.l. og/eller i
                nettbutikken som er større enn 15% av normal utsalgspris, kan
                Selger ensidig endre, slette eller avvise ordre. Dette gjelder
                selv om kjøper har mottatt ordrebekreftelse eller lignende.
            </p>
            <h2>5. Betalingen</h2>
            <p>
                Selgeren kan kreve betaling for varen fra det tidspunkt den blir
                sendt fra selgeren til kjøperen.
            </p>
            <p>
                Dersom kjøperen bruker kredittkort eller debetkort ved betaling,
                kan selgeren reservere kjøpesummen på kortet ved bestilling.
                Kortet blir belastet samme dag som varen sendes.
            </p>
            <p>
                Kjøpere under 18 år kan ikke betale med etterfølgende faktura.
            </p>
            <h2>6. Levering</h2>
            <p>
                Levering er skjedd når kjøperen, eller hans representant, har
                overtatt tingen.
            </p>
            <p>
                Hvis ikke leveringstidspunkt fremgår av bestillingsløsningen,
                skal selgeren levere varen til kjøper uten unødig opphold og
                senest 30 dager etter bestillingen fra kunden. Varen skal
                leveres hos kjøperen med mindre annet er særskilt avtalt mellom
                partene.
            </p>
            <h2>7. Risikoen for varen</h2>
            <p>
                Risikoen for varen går over på kjøper når han, eller hans
                representant, har fått varene levert i tråd med punkt 6.
            </p>
            <h2>8. Angrerett</h2>
            <p>
                Med mindre avtalen er unntatt fra angrerett, kan kjøperen angre
                kjøpet av varen i henhold til angrerettloven.
            </p>
            <p>
                Kjøperen må gi selger melding om bruk av angreretten innen 14
                dager fra den dag kjøperen mottok varen. I fristen inkluderes
                alle kalenderdager.
            </p>
            <p>
                Angrefristen anses overholdt dersom melding er sendt før utløpet
                av fristen. Kjøper har bevisbyrden for at angreretten er blitt
                gjort gjeldende, og meldingen bør derfor skje skriftlig
                (angrerettskjema, e-post eller brev).
            </p>
            <p>
                For at angreretten skal kunne gjøres gjeldende, må produktet
                være levert oss i tilnærmet samme mengde og stand som du mottok
                den. Alle produkter skal leveres tilbake til selger innen
                rimelig tid. Eventuell returforsendelse må betales av kjøperen.
            </p>
            <p>
                Angrefristen utvides til 12 måneder etter utløpet av den
                opprinnelige fristen dersom selger ikke før avtaleinngåelsen
                opplyser om at det foreligger angrerett og standardisert
                angreskjema. Angreskjema kan lastes ned{" "}
                <a
                    href="https://static.spond.com/fundraisingproducts/fp-Angrerettskjema.docx"
                    target="_blank"
                    rel="noopener"
                >
                    href
                </a>
                .
            </p>
            <p>
                Ved bruk av angreretten må varen leveres tilbake til selgeren
                uten unødig opphold og senest 14 dager fra melding om bruk av
                angreretten er gitt.{" "}
            </p>
            <p>
                Selgeren er forpliktet til å tilbakebetale kjøpesummen til
                kjøperen uten unødig opphold, og senest 14 dager fra selgeren
                fikk melding om kjøperens beslutning om å benytte angreretten.
                Selger har rett til å holde tilbake betalingen til han har
                mottatt varene fra kjøperen, eller til kjøper har lagt frem
                dokumentasjon for at varene er sendt tilbake.
            </p>
            <p>
                Kjøperen kan ikke angre på kjøp av varer som forringes raskt og
                varer som etter sin art ikke kan tilbakeleveres.
            </p>
            <h2>
                9. Forsinkelse og manglende levering - kjøpernes rettigheter og
                frist for å melde krav
            </h2>
            <p>
                Levering av produktene skjer på den måten som er angitt på
                ordrebekreftelsen. Selgeren har risikoen for produktene inntil
                de overtas av kjøperen, det vil si når kjøperen har fått
                produktene i din besittelse.
            </p>
            <p>
                Ved hjemlevering med innbæring har kjøper ansvar for at det er
                mulig å frakte produktene inn i bostedet. For hjemlevering må
                kjøper være på plass for å ta imot varene og selger kan avkreve
                legitimasjon før varene overleveres. Ved hjemlevering har kjøper
                selv ansvar for å beskytte gulv, vegger, med mer.{" "}
            </p>
            <p>
                Dersom leveringen av produktene blir forsinket, vil selger
                informere kjøper sammen med informasjon om, og eventuelt når,
                levering kan skje, eller om produktet(ene) er utsolgt.
            </p>
            <h2>
                10. Mangel ved varen - kjøperens rettigheter og
                reklamasjonsfrist
            </h2>
            <p>
                Etter at kjøper har mottatt produktene, anbefaler selger at
                kjøper så snart som mulig undersøker om leveransen er i samsvar
                med ordrebekreftelsen, om produktene er blitt skadet under
                transporten, eller om produktene ellers har feil eller mangler.
            </p>
            <p>
                Hvis det foreligger en mangel ved varen må kjøper innen rimelig
                tid etter at den ble oppdaget eller burde ha blitt oppdaget, gi
                selger melding om at han eller hun vil påberope seg mangelen.
                Kjøper har alltid reklamert tidsnok dersom det skjer innen 2
                mnd. fra mangelen ble oppdaget eller burde blitt oppdaget.
                Reklamasjon kan skje senest to år etter at kjøper overtok varen.
                Dersom varen eller deler av den er ment å vare vesentlig lenger
                enn to år, er reklamasjonsfristen fem år.
            </p>
            <p>
                Dersom varen har en mangel og dette ikke skyldes kjøperen eller
                forhold på kjøperens side, kan kjøperen i henhold til reglene i
                forbrukerkjøpsloven kapittel 6 etter omstendighetene holde
                kjøpesummen tilbake, velge mellom retting og omlevering, kreve
                prisavslag, kreve avtalen hevet og/eller kreve erstatning fra
                selgeren.
            </p>
            <p>Reklamasjon til selgeren bør skje skriftlig.</p>
            <h2>11. Selgerens rettigheter ved kjøperens mislighold</h2>
            <p>
                Dersom kjøperen ikke betaler eller oppfyller de øvrige pliktene
                etter avtalen eller loven, og dette ikke skyldes selgeren eller
                forhold på selgerens side, kan selgeren i henhold til reglene i
                forbrukerkjøpsloven kapittel 9 etter omstendighetene holde varen
                tilbake, kreve oppfyllelse av avtalen, kreve avtalen hevet samt
                kreve erstatning fra kjøperen. Selgeren vil også etter
                omstendighetene kunne kreve renter ved forsinket betaling,
                inkassogebyr og et rimelig gebyr ved uavhentede varer.
            </p>
            <h2>12. Garanti</h2>
            <p>
                Garanti som gis av selgeren eller produsenten, gir kjøperen
                rettigheter i tillegg til de kjøperen allerede har etter
                ufravikelig lovgivning. En garanti innebærer dermed ingen
                begrensninger i kjøperens rett til reklamasjon og krav ved
                forsinkelse eller mangler etter punkt 9 og 10.
            </p>
            <h2>13. Personopplysninger</h2>
            <p>
                Bestemmelsene om behandling av personopplysninger som gjelder
                for avtalen er inntatt i Personvernerklæringen (lenke i bunn av
                denne siden).
            </p>
            <h2>14. Konfliktløsning</h2>
            <p>
                Klager rettes til selger innen rimelig tid, jf. punkt 9 og 10.
                Partene skal forsøke å løse eventuelle tvister i minnelighet.
                Dersom dette ikke lykkes, kan kjøperen ta kontakt med
                Forbrukerrådet for mekling. Forbrukerrådet er tilgjengelig på
                telefon 23 400 500 eller www.forbrukerradet.no.
            </p>

            <h2>Angrerettskjema</h2>
            <p>
                Angrerettskjema kan lastes ned{" "}
                <a href="https://static.spond.com/fundraisingproducts/fp-Angrerettskjema.docx">
                    her
                </a>
            </p>
        </Fragment>
    );
};

const getEnglishTerms = () => (
    <Fragment>
        <h1>Terms and conditions</h1>
        <p>
            Click{" "}
            <a
                href="https://static.spond.com/fundraisingproducts/Terms and conditions for sale of goods to consumers and cancellation form.docx"
                target="_blank"
                rel="noreferrer"
            >
                here
            </a>{" "}
            to view the terms
        </p>
    </Fragment>
);

class TermsPage extends Component {
    componentDidMount() {
        const { seller } = this.props;
        trackPageView("Terms", {
            shortId: seller.shortId,
            orgDisplayName: seller.displayName,
            groupId: seller.groupId,
        });
    }

    render() {
        const {
            seller,
            t,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { countryCode } = seller;

        let terms;
        switch (countryCode) {
            case "NOR":
                terms = getNorwegianTerms(seller);
                break;

            default:
                terms = getEnglishTerms();
        }

        return (
            <div>
                <Link to={routes.seller(salesLinkCodeOrShortId).pathname}>
                    <h4>&larr; {t("terms.back")}</h4>
                </Link>
                {terms}
                <Link to={routes.seller(salesLinkCodeOrShortId).pathname}>
                    <h4>&larr; {t("terms.back")}</h4>
                </Link>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(TermsPage))
);
