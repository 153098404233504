import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
    Product,
    ProductButton,
    ProductImage,
    ProductPrice,
    ProductTitle,
} from "./ProductLanding.container.styled";
import SpondImage from "../../../components/spond-image/SpondImage";
import NumberFormat from "react-number-format";
import DeliveryInfo from "../../../components/product/DeliveryInfo";
import LandingHeader from "../../../components/landing-header/LandingHeader";

// TODO Rename to "ProductsAndDeliveryInfo"
class ProductLandingWrapper extends Component {
    render() {
        const {
            seller,
            t,
            viewProduct,
            handleProductActionClicked,
            previewMode,
            themeType,
        } = this.props;
        const { products } = seller.productSet;

        return (
            <div>
                {previewMode && (
                    <LandingHeader
                        previewMode={true}
                        includeStyling={true}
                        seller={seller}
                        themeType={themeType}
                    />
                )}
                <div className="products-wrapper">
                    {!!products &&
                        products.map((product) => (
                            <Product key={product.id} className="product">
                                <ProductImage
                                    onClick={() => viewProduct(product.id)}
                                >
                                    <SpondImage
                                        imageUrl={product.preview}
                                        transformation="w=610&h=610"
                                        alt={product.name}
                                    />
                                </ProductImage>
                                <div className="product-title-fix">
                                    <ProductTitle
                                        dangerouslySetInnerHTML={{
                                            __html: product.name,
                                        }}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: "1",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        <ProductPrice>
                                            <NumberFormat
                                                value={product.price / 100}
                                                displayType={"text"}
                                                prefix={t(
                                                    "general.number_prefix"
                                                )}
                                            />
                                        </ProductPrice>
                                    </div>
                                </div>
                                <div className="button-wrapper">
                                    <ProductButton
                                        data-testid="product-button"
                                        backgroundColour={
                                            seller.theme.primaryButtonColour
                                        }
                                        onClick={() =>
                                            handleProductActionClicked(product)
                                        }
                                    >
                                        {!!product.variations &&
                                            product.variations.length > 0 &&
                                            t("product.select_type_label")}
                                        {(!product.variations ||
                                            product.variations.length === 0) &&
                                            t("product.add_to_cart_label")}
                                    </ProductButton>
                                </div>
                            </Product>
                        ))}
                </div>
                <DeliveryInfo value={seller.delivery} />
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

// TODO Rename to "ProductsAndDeliveryInfo"
export default withRouter(
    withTranslation()(
        connect(mapStateToProps, mapDispatchToProps)(ProductLandingWrapper)
    )
);
