/** Get an available locale key by looking up the language code ('NOR', 'GBR' etc)
 * @param countryCode String the country code to look up.
 * @param availableLocalles Object the available locales to cross reference.
 */
export const getLocaleByCountryCode = (countryCode, availableLocales) => {
    const foundLocaleByCountryCode = Object.entries(availableLocales).find(
        ([, localeObject]) => {
            return localeObject.countryCode === countryCode;
        }
    );

    return foundLocaleByCountryCode ? foundLocaleByCountryCode[0] : null;
};

/** Get the locale from browser navigator languages and if needed, map the language key into a
 * language-country locale (nb-NO)
 *
 * @param availableLocales Object An object of all available locales, used for cross reference and validation.
 * @returns String || null
 */
export const getLocaleFromBrowser = (availableLocales) => {
    const { languages = [] } = window.navigator;

    // Catch the odd eventuality where no language is set by navigator.
    if (languages.length === 0) {
        return "en-GB";
    }

    const firstLanguage = languages[0];
    const foundLocaleByCountryCode = Object.entries(availableLocales).find(
        ([key, localeObject]) => {
            // Browsers first language is infact a directly supported locale, ie bn-NO or en-GB, so
            // just return that.
            if (key === firstLanguage) {
                return true;
            }

            const foundMappableLocale = localeObject.mapping.find(
                (l) => l === firstLanguage
            );
            if (foundMappableLocale) {
                return true;
            }
            return false;
        }
    );

    return foundLocaleByCountryCode ? foundLocaleByCountryCode[0] : null;
};

/** Detect the locale key (ie. nb-NO etc)
 * @param clubSettings Object The club settings object as retrieved from server.
 * @param currentLocale A current preferred locale as set by the user (Only available in testing env.)
 * @returns String The locale detected.
 */
export const detectLocaleKey = (clubSettings, currentLocale) => {
    const availableLocales = {
        no: { countryCode: "NOR", mapping: ["no", "nb", "nn"] },
        en: { countryCode: "GBR", mapping: ["en"] },

        us: { countryCode: "USA", mapping: ["en-US"] },
        de: { countryCode: "DEU", mapping: ["de"] },
        da: { countryCode: "DNK", mapping: ["da", "da-DK"] },
        es: { countryCode: "ESP", mapping: ["es"] },
        fr: { countryCode: "FRA", mapping: ["fr"] },
        it: { countryCode: "ITA", mapping: ["it"] },
        nl: { countryCode: "NLD", mapping: ["nl"] },
        sv: { countryCode: "SWE", mapping: ["sv"] },
    };

    // currentLocale is available in testing via the language selection UI. Not available
    // in production.
    if (currentLocale && availableLocales[currentLocale]) {
        return currentLocale;
    }

    if (clubSettings) {
        return getLocaleByCountryCode(
            clubSettings.countryCode,
            availableLocales
        );
    }

    // Finally, if no locale was could be set, detect browser language.
    return getLocaleFromBrowser(availableLocales) || "en-GB";
};

export const getLocaleKeyFromCountryCode = (countryCode) => {
    const localeLib = {
        NOR: "nb-NO",
        GBR: "en-GB",
        USA: "en-US",
        DEU: "de",
        DNK: "da",
        ESP: "es",
        FRA: "fr",
        ITA: "it",
        NLD: "nl",
        SWE: "sv",
    };

    return localeLib[countryCode];
};

export const getLocaleSettings = (locale) => {
    const localeLib = {
        "nb-NO": {
            currency: {
                commaSeparator: ",",
                thousandDelimiter: " ",
                minimumProductPrice: 1000,
            },
            phoneNumber: {
                significantDigitCount: 8,
                countryPrefix: "+47",
            },
        },
        "en-GB": {
            currency: {
                commaSeparator: ".",
                thousandDelimiter: ",",
                minimumProductPrice: 200,
            },
            phoneNumber: {
                significantDigitCount: 10,
                countryPrefix: "+44",
            },
        },
    };

    return localeLib[locale];
};

const dictionary = {
    AC: "ASC",
    BD: "BGD",
    BE: "BEL",
    BF: "BFA",
    BG: "BGR",
    BA: "BIH",
    BB: "BRB",
    WF: "WLF",
    BL: "BLM",
    BM: "BMU",
    BN: "BRN",
    BO: "BOL",
    BH: "BHR",
    BI: "BDI",
    BJ: "BEN",
    BT: "BTN",
    JM: "JAM",
    BV: "BVT",
    BW: "BWA",
    WS: "WSM",
    BQ: "BES",
    BR: "BRA",
    BS: "BHS",
    JE: "JEY",
    BY: "BLR",
    BZ: "BLZ",
    RU: "RUS",
    RW: "RWA",
    RS: "SRB",
    TL: "TLS",
    RE: "REU",
    TM: "TKM",
    TJ: "TJK",
    RO: "ROU",
    TK: "TKL",
    GW: "GNB",
    GU: "GUM",
    GT: "GTM",
    GS: "SGS",
    GR: "GRC",
    GQ: "GNQ",
    GP: "GLP",
    JP: "JPN",
    GY: "GUY",
    GG: "GGY",
    GF: "GUF",
    GE: "GEO",
    GD: "GRD",
    GB: "GBR",
    GA: "GAB",
    SV: "SLV",
    GN: "GIN",
    GM: "GMB",
    GL: "GRL",
    GI: "GIB",
    GH: "GHA",
    OM: "OMN",
    TN: "TUN",
    JO: "JOR",
    HR: "HRV",
    HT: "HTI",
    HU: "HUN",
    HK: "HKG",
    HN: "HND",
    HM: "HMD",
    VE: "VEN",
    PR: "PRI",
    PS: "PSE",
    PW: "PLW",
    PT: "PRT",
    SJ: "SJM",
    PY: "PRY",
    IQ: "IRQ",
    PA: "PAN",
    PF: "PYF",
    PG: "PNG",
    PE: "PER",
    PK: "PAK",
    PH: "PHL",
    PN: "PCN",
    PL: "POL",
    PM: "SPM",
    ZM: "ZMB",
    EH: "ESH",
    EE: "EST",
    EG: "EGY",
    ZA: "ZAF",
    EC: "ECU",
    IT: "ITA",
    VN: "VNM",
    SB: "SLB",
    ET: "ETH",
    SO: "SOM",
    ZW: "ZWE",
    SA: "SAU",
    ES: "ESP",
    ER: "ERI",
    ME: "MNE",
    MD: "MDA",
    MG: "MDG",
    MF: "MAF",
    MA: "MAR",
    MC: "MCO",
    UZ: "UZB",
    MM: "MMR",
    ML: "MLI",
    MO: "MAC",
    MN: "MNG",
    MH: "MHL",
    MK: "MKD",
    MU: "MUS",
    MT: "MLT",
    MW: "MWI",
    MV: "MDV",
    MQ: "MTQ",
    MP: "MNP",
    MS: "MSR",
    MR: "MRT",
    IM: "IMN",
    UG: "UGA",
    TZ: "TZA",
    MY: "MYS",
    MX: "MEX",
    IL: "ISR",
    FR: "FRA",
    IO: "IOT",
    SH: "SHN",
    FI: "FIN",
    FJ: "FJI",
    FK: "FLK",
    FM: "FSM",
    FO: "FRO",
    NI: "NIC",
    NL: "NLD",
    NO: "NOR",
    NA: "NAM",
    VU: "VUT",
    NC: "NCL",
    NE: "NER",
    NF: "NFK",
    NG: "NGA",
    NZ: "NZL",
    NP: "NPL",
    NR: "NRU",
    NU: "NIU",
    CK: "COK",
    XK: "XKX",
    CI: "CIV",
    CH: "CHE",
    CO: "COL",
    CN: "CHN",
    CM: "CMR",
    CL: "CHL",
    CC: "CCK",
    CA: "CAN",
    CG: "COG",
    CF: "CAF",
    CD: "COD",
    CZ: "CZE",
    CY: "CYP",
    CX: "CXR",
    CR: "CRI",
    CW: "CUW",
    CV: "CPV",
    CU: "CUB",
    SZ: "SWZ",
    SY: "SYR",
    SX: "SXM",
    KG: "KGZ",
    KE: "KEN",
    SS: "SSD",
    SR: "SUR",
    KI: "KIR",
    KH: "KHM",
    KN: "KNA",
    KM: "COM",
    ST: "STP",
    SK: "SVK",
    KR: "KOR",
    SI: "SVN",
    KP: "PRK",
    KW: "KWT",
    SN: "SEN",
    SM: "SMR",
    SL: "SLE",
    SC: "SYC",
    KZ: "KAZ",
    KY: "CYM",
    SG: "SGP",
    SE: "SWE",
    SD: "SDN",
    DO: "DOM",
    DM: "DMA",
    DJ: "DJI",
    DK: "DNK",
    VG: "VGB",
    DE: "DEU",
    YE: "YEM",
    DZ: "DZA",
    US: "USA",
    UY: "URY",
    YT: "MYT",
    UM: "UMI",
    LB: "LBN",
    LC: "LCA",
    LA: "LAO",
    TV: "TUV",
    TW: "TWN",
    TT: "TTO",
    TR: "TUR",
    LK: "LKA",
    LI: "LIE",
    LV: "LVA",
    TO: "TON",
    LT: "LTU",
    LU: "LUX",
    LR: "LBR",
    LS: "LSO",
    TH: "THA",
    TF: "ATF",
    TG: "TGO",
    TD: "TCD",
    TC: "TCA",
    LY: "LBY",
    VA: "VAT",
    VC: "VCT",
    AE: "ARE",
    AD: "AND",
    AG: "ATG",
    AF: "AFG",
    AI: "AIA",
    VI: "VIR",
    IS: "ISL",
    IR: "IRN",
    AM: "ARM",
    AL: "ALB",
    AO: "AGO",
    AQ: "ATA",
    AS: "ASM",
    AR: "ARG",
    AU: "AUS",
    AT: "AUT",
    AW: "ABW",
    IN: "IND",
    AX: "ALA",
    AZ: "AZE",
    IE: "IRL",
    ID: "IDN",
    UA: "UKR",
    QA: "QAT",
    MZ: "MOZ",
};

export const getAlpha3CountryCodeByAlpha2Code = (code) => {
    if (typeof code !== "string" || code.length !== 2) {
        return code;
    } else {
        return dictionary[code];
    }
};

export const getAlpha2CountryCodeByAlpha3Code = (code) => {
    if (typeof code !== "string" || code.length !== 3) {
        return code;
    } else {
        for (const key in dictionary) {
            if (dictionary[key] === code) {
                return key;
            }
        }
        return code;
    }
};

export function Alpha3CountryCodeToEmoji(alpha3CountryCode) {
    const alpha2CountryCode =
        getAlpha2CountryCodeByAlpha3Code(alpha3CountryCode);
    const flagOffset = 0x1f1e6;
    const asciiOffset = 0x41;

    const firstChar = String.fromCodePoint(
        alpha2CountryCode.codePointAt(0) - asciiOffset + flagOffset
    );
    const secondChar = String.fromCodePoint(
        alpha2CountryCode.codePointAt(1) - asciiOffset + flagOffset
    );
    const flag = firstChar + secondChar;
    return flag;
}
