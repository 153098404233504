const ALL = require("../../assets/icons/all.svg");
const CLOTHING = require("../../assets/icons/clothing.svg");
const SPORTS = require("../../assets/icons/sports.svg");
const FOOD = require("../../assets/icons/food.svg");
const GAMES = require("../../assets/icons/games.svg");
const ELECTRONICS = require("../../assets/icons/electronics.svg");
const BOOKS = require("../../assets/icons/books.svg");
const HOME = require("../../assets/icons/home.svg");
const TOP = require("../../assets/icons/top.svg");
const CARD = require("../../assets/icons/payment-card-number.svg");

const categoryIcons = {
    ALL,
    CLOTHING,
    SPORTS,
    FOOD,
    GAMES,
    ELECTRONICS,
    BOOKS,
    HOME,
    TOP,
    CARD,
};

export default categoryIcons;
