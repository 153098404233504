import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { withTranslation } from "react-i18next";

import { PurchaseModalButton } from "../../../components/button/Buttons";

import {
    LooserContainer,
    HeaderText1,
    HeaderText2,
} from "../styled-components/ScratchcardModel.styled";

class ScratchcardLoseModal extends Component {
    reload() {
        // Let the page know that we've been scratching scratchcards this session
        // to customize messaging to the customer
        window.location.search += `?scratched=t`;
    }

    render() {
        const { t, hasMoreTickets, onNextTicketClicked, seller } = this.props;

        return (
            <Fragment>
                <Modal
                    center={true}
                    open={true}
                    showCloseIcon={false}
                    onClose={() => {}}
                    classNames={{ modal: "unstyled-wide-scratchcard-modal" }}
                >
                    <LooserContainer bgImage={seller.theme.loserBackground}>
                        <HeaderText1 strokeColor={seller.theme.primaryColour}>
                            {t("modal.lose_title")}
                        </HeaderText1>
                        <HeaderText2 strokeColor={seller.theme.primaryColour}>
                            {t("modal.lose_description")}
                        </HeaderText2>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "10px",
                                justifyContent: "center",
                            }}
                        >
                            {hasMoreTickets && (
                                <PurchaseModalButton
                                    id="ok_button"
                                    theme={seller.theme}
                                    onClick={() => onNextTicketClicked()}
                                >
                                    {t("actions.scratch_next")}
                                </PurchaseModalButton>
                            )}

                            {!hasMoreTickets && (
                                <PurchaseModalButton
                                    id="ok_button"
                                    theme={seller.theme}
                                    onClick={() => this.reload()}
                                >
                                    OK
                                </PurchaseModalButton>
                            )}
                        </div>
                    </LooserContainer>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(
    connect(mapStateToProps)(ScratchcardLoseModal)
);
