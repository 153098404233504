import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import qs from "query-string";
import { withTranslation } from "react-i18next";

import ImageGallery from "react-image-gallery";
import CloseButton from "../../../components/button/CloseButton";
import { addToCart, clearCart } from "../../../redux/seller";
import ScrollToTop from "../../../components/scroll-to-top/ScrollToTop";
import CurrencyFormat from "../../../components/currency-format/CurrencyFormat";
import { transformImageUrl } from "../../../components/spond-image/SpondImage";

import {
    GalleryNavigation,
    GalleryWrapper,
    ProductDescription,
    ProductDetails,
    ProductParagraph,
    ProductPrice,
    ProductTitle,
    VariationSelector,
    ViewProductContainerBed,
} from "./ViewProductContainer.styled";

import "react-image-gallery/styles/scss/image-gallery.scss";
import { ThemeButton } from "../../../components/button/Buttons";
import { CheckoutOrKeepShoppingModal } from "../checkout-or-keep-shopping/CheckoutOrKeepShoppingModal";
import { getFreeShippingCountdown } from "../landing/ProductLanding.utils";
import StickyShoppingCart from "../../../components/product/shopping-cart/StickyShoppingCart";
import { routes } from "../../../app/routes";
import { getImagesToDisplay } from "./utils";

class ViewProductContainer extends Component {
    constructor(props) {
        super(props);

        this.shoppingCartRef = React.createRef();
        const { location } = this.props;
        const { orderId, key } = qs.parse(location.search);
        const currentProduct = this.getCurrentProduct(props);
        const { variations } = currentProduct;
        const firstVariationId =
            variations && variations[0] ? variations[0].id : undefined;

        this.state = {
            loading: true,
            orderId: orderId,
            key,
            selectedVariationId: firstVariationId,
            checkoutOrKeepShoppingModalOpen: false,
        };
    }

    openCheckoutOrKeepShoppingModal = () => {
        this.setState({
            ...this.state,
            checkoutOrKeepShoppingModalOpen: true,
        });
    };

    closeCheckoutOrKeepShoppingModal = () => {
        this.setState({
            ...this.state,
            checkoutOrKeepShoppingModalOpen: false,
        });
    };

    onSelectedVariationChange(event) {
        const id = !!event.currentTarget.value
            ? event.currentTarget.value
            : undefined;
        this.setState({ selectedVariationId: id });
    }

    closeAndTriggerProductAdd(productId, productVariationId) {
        const {
            history,
            seller,
            location,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { state = {} } = location;
        const { referral } = seller;
        const { landingScrollPosition, selectedCategory } = state;
        history.push(
            routes.seller(salesLinkCodeOrShortId).pathname + `${referral}/`,
            {
                product: { productId, productVariationId },
                landingScrollPosition,
                selectedCategory,
            }
        );
    }

    handleAddProductToCart(product, productVariationId) {
        const { addProductToCart, cart } = this.props;
        if (!cart?.length) {
            this.openCheckoutOrKeepShoppingModal();
        } else if (this.shoppingCartRef) {
            this.shoppingCartRef.activatePopover(product);
        }
        addProductToCart(product.id, productVariationId);
    }

    getCurrentProduct = (props) => {
        const { seller, match } = props;
        const { products } = seller.productSet;
        const {
            params: { productId },
        } = match;

        return products.find((product) => product.id.toString() === productId);
    };

    render() {
        const {
            seller,
            t,
            history,
            location,
            cart,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { state = {} } = location;
        const { selectedVariationId } = this.state;

        const foundProduct = this.getCurrentProduct(this.props);

        if (!foundProduct) return <div>Invalid product</div>;

        const { price, name, variations, variationName, preview, description } =
            foundProduct;

        const humanReadablePrice = <CurrencyFormat value={price} t={t} />;
        // Should not show variation images in the image gallery.
        const galleryImages = getImagesToDisplay(foundProduct).map((img) => ({
            original: transformImageUrl(img, "q_auto,w_700,c_limit"),
        }));

        const { landingScrollPosition, selectedCategory } = state;
        const { source, profiledId } = qs.parse(location.source);

        const selectedVariationName =
            variations &&
            variations.length &&
            variations[this.state.selectedVariationId]?.variationName;
        const freeShippingCountdown = getFreeShippingCountdown(cart, seller);
        return (
            <>
                <ScrollToTop />
                <CloseButton
                    onClick={() =>
                        history.push(
                            routes.seller(salesLinkCodeOrShortId).pathname,
                            {
                                landingScrollPosition,
                                selectedCategory,
                            }
                        )
                    }
                />
                <ViewProductContainerBed>
                    <ScrollToTop />
                    <GalleryWrapper>
                        <ImageGallery
                            items={galleryImages}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            showThumbnails={false}
                            renderRightNav={(onClick, disabled) => (
                                <GalleryNavigation
                                    onClick={onClick}
                                    disabled={disabled}
                                    pos="RIGHT"
                                >
                                    <span />
                                </GalleryNavigation>
                            )}
                            renderLeftNav={(onClick, disabled) => (
                                <GalleryNavigation
                                    onClick={onClick}
                                    disabled={disabled}
                                    pos="LEFT"
                                >
                                    <span />
                                </GalleryNavigation>
                            )}
                        />
                    </GalleryWrapper>
                    <ProductTitle>{name}</ProductTitle>
                    {variations && variations.length > 0 && (
                        <div>
                            <h4 style={{ marginBottom: "0px" }}>
                                {t("product.select_type_label", {
                                    type: variationName,
                                })}
                            </h4>
                            <VariationSelector
                                onChange={(event) =>
                                    this.onSelectedVariationChange(event)
                                }
                            >
                                {variations.map((variation) => (
                                    <option
                                        key={variation.id}
                                        value={variation.id}
                                    >
                                        {variation.name}
                                    </option>
                                ))}
                            </VariationSelector>
                        </div>
                    )}
                    <ProductDetails>
                        <ProductPrice>{humanReadablePrice}</ProductPrice>
                        <ThemeButton
                            data-testid={"add-product-button"}
                            theme={seller.theme}
                            onClick={() =>
                                this.handleAddProductToCart(
                                    foundProduct,
                                    selectedVariationId
                                )
                            }
                        >
                            {t("product.add_to_cart_label")}
                        </ThemeButton>
                    </ProductDetails>
                    <ProductDescription>
                        <ProductParagraph>{description}</ProductParagraph>
                    </ProductDescription>
                    <StickyShoppingCart
                        ref={(ref) => (this.shoppingCartRef = ref)}
                        cart={cart}
                        seller={seller}
                        t={t}
                    />
                </ViewProductContainerBed>
                <CheckoutOrKeepShoppingModal
                    open={this.state.checkoutOrKeepShoppingModalOpen}
                    onClose={() => this.closeCheckoutOrKeepShoppingModal()}
                    variationName={selectedVariationName}
                    source={source}
                    profileId={profiledId}
                    keepShopping={() => this.closeCheckoutOrKeepShoppingModal()}
                    seller={seller}
                    product={foundProduct}
                    freeShippingCountdown={freeShippingCountdown}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.sellerReducer,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearShoppingCart: () => dispatch(clearCart()),
    addProductToCart: (productId, productVariationId) =>
        dispatch(addToCart({ productId, productVariationId })),
});

export default withTranslation()(
    withRouter(
        connect(mapStateToProps, mapDispatchToProps)(ViewProductContainer)
    )
);
