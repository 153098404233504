import LandingHeader from "../../../components/landing-header/LandingHeader";
import React, { Component } from "react";
import { SelectMonthlyOrSinglePurchase } from "./SelectMonthlyOrSinglePurchase";
import * as mixpanelEvents from "../../../mixpanelEvents";
import { apiClient } from "../../../api/ApiClient";
import {
    ScratchCardExperiment,
    FeatureToggleName,
    MixpanelFields,
    FeatureToggles,
    FeatureToggle,
    Seller,
} from "../../../api/types";
import type { ScratchThemeType } from "./CustomScratch.define";
import { withTranslation, WithTranslation } from "react-i18next";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";

import {
    Heading1,
    Terms,
} from "../landing/ScratchcardLanding.container.styled";
import "../../product/landing/ProductLanding.container.scss";

import {
    PurchaseButton,
    PurchaseButtonThin,
    PurchaseButtonWrapper,
} from "../../../components/button/Buttons";
import { Input } from "../../../components/input/Input";
import {
    CurrencyFormatter,
    CurrencyWithDecimal,
} from "../../../utils/Currency";

import PreCheckoutModal from "../pre-checkout/PreCheckoutModal.container";
import SpondImage from "../../../components/spond-image/SpondImage";
import paymentMethodsImage from "../../../assets/payment-methods.png";

import {
    CustomBuyBed,
    NarrowWrapper,
    CustomDescripton,
    LandingBackground,
    ExpandablePurchaseButtonWrapper,
    RecurringWarningTitle,
    RecurringWarningText,
} from "./ScratchcardWrapper.styled";
import { ScratchThemeConfig } from "./CustomScratch.define";
import { routes } from "../../../app/routes";

type State = {
    showPreCheckoutModal: boolean;
    ticketCount: number;
    customBuyClicked: boolean;
    contribution?: number;
    customAmount?: number;
    scratchCardExperiment: ScratchCardExperiment | null;
    subscriptionExperimentTriggered?: null | Record<number | "custom", boolean>;
    subscriptionExperimentCount?: number;
    recurringSelected: boolean;
    showSubscriptionIsUnavailableInfo: {
        count: number;
        custom: boolean;
    } | null;
};

type ScratchcardWrapperProps = {
    previewMode: boolean;
    seller: Seller;
    isSTB?: boolean;
    themeType: ScratchThemeType;
} & WithTranslation &
    RouteComponentProps<{ salesLinkCodeOrShortId: string }>;

type ShowRecurringWarningWithTicketCount = null | {
    count: number;
    custom: boolean;
};

class ScratchcardWrapper extends Component<ScratchcardWrapperProps> {
    state: State = {
        showPreCheckoutModal: false,
        ticketCount: 0,
        customBuyClicked: false,
        contribution: undefined,
        customAmount: undefined,
        scratchCardExperiment: ScratchCardExperiment.TICKET_BUNDLES_5_3_1,
        subscriptionExperimentTriggered: null,
        recurringSelected: false,
        showSubscriptionIsUnavailableInfo: null,
    };

    setShowSubscriptionIsUnavailableInfo = (
        showSubscriptionIsUnavailableInfo: ShowRecurringWarningWithTicketCount
    ) => {
        this.setState({ showSubscriptionIsUnavailableInfo });
    };

    availableTicketCounts() {
        return [5, 3, 1];
    }

    maxTicketCount() {
        return Math.max(...this.availableTicketCounts());
    }

    onBuyClicked(ticketCount: number) {
        this.setShowSubscriptionIsUnavailableInfo(null);
        this.setState({
            showPreCheckoutModal: true,
            ticketCount,
            contribution: undefined,
            customAmount: undefined,
            customBuyClicked: false,
        });
    }

    onPurchaseAmountChanged(event: React.ChangeEvent<HTMLInputElement>) {
        const { seller } = this.props;
        if (Number(event.target.value) <= 9999999) {
            this.setState({
                contribution: CurrencyWithDecimal(seller, event.target.value),
                customAmount: event.target.value,
            });
        }
    }

    onProceedBuyCustom() {
        this.setState({
            showPreCheckoutModal: true,
            ticketCount: this.maxTicketCount(),
        });
    }

    getMixpanelFields(): MixpanelFields | null {
        if (!this.state.scratchCardExperiment) return null;
        return {
            ticketCount: this.state.ticketCount,
            experimentName: this.state.scratchCardExperiment,
            recurringSelected: this.state.recurringSelected,
        };
    }

    render() {
        const {
            t,
            seller,
            themeType,
            previewMode,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const {
            ticketCount,
            showPreCheckoutModal,
            customBuyClicked,
            contribution,
            showSubscriptionIsUnavailableInfo,
        } = this.state;

        const subscriptionExperimentEnabled =
            this.state.scratchCardExperiment ===
            ScratchCardExperiment.SUBSCRIPTION_TEST;

        return (
            <>
                {!this.state.showSubscriptionIsUnavailableInfo && (
                    <LandingHeader
                        seller={seller}
                        themeType={themeType}
                        previewMode={previewMode}
                    />
                )}
                <div className="products-wrapper">
                    {!previewMode && (
                        <PreCheckoutModal
                            open={showPreCheckoutModal}
                            t={t}
                            mixpanelFields={this.getMixpanelFields()}
                            ticketCount={ticketCount}
                            contribution={contribution}
                            seller={seller}
                        />
                    )}
                    {showSubscriptionIsUnavailableInfo && (
                        <NarrowWrapper
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "20px",
                                marginTop: 35,
                            }}
                        >
                            <RecurringWarningTitle
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        "landing.choose_your_own_contribution_recurring_warning_title"
                                    ),
                                }}
                            />
                            <RecurringWarningText
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        "landing.choose_your_own_contribution_recurring_warning_text"
                                    ),
                                }}
                            />
                            <PurchaseButton
                                type="button"
                                theme={seller.theme}
                                onClick={() => {
                                    if (
                                        !showSubscriptionIsUnavailableInfo.custom
                                    ) {
                                        this.onBuyClicked(
                                            showSubscriptionIsUnavailableInfo.count
                                        );
                                    } else {
                                        this.onProceedBuyCustom();
                                    }
                                    mixpanelEvents.pressedContinueOneTimePurchase(
                                        {
                                            ticket_count:
                                                showSubscriptionIsUnavailableInfo.count,
                                            custom_contribution:
                                                showSubscriptionIsUnavailableInfo.custom,
                                        }
                                    );
                                }}
                            >
                                {t("landing.continue_with_one_time_purchase")}
                            </PurchaseButton>
                        </NarrowWrapper>
                    )}
                    {!showSubscriptionIsUnavailableInfo && (
                        <NarrowWrapper>
                            <div
                                style={{
                                    margin: "0 0 20px 0",
                                    position: "relative",
                                    padding: "8px",
                                    backgroundColor: "white",
                                    boxShadow:
                                        "0px 6px 16px 12px rgba(21, 0, 0, 0.12)",
                                    lineHeight: 1,
                                }}
                            >
                                <LandingBackground
                                    backgroundImage={`${seller.theme.landingBackground}?w=650`}
                                >
                                    {!themeType && (
                                        <>
                                            <div
                                                style={{
                                                    top: "48px",
                                                    left: "0",
                                                    width: "100%",
                                                }}
                                            >
                                                <Heading1
                                                    name="group-name"
                                                    theme={seller.theme}
                                                    style={{
                                                        textAlign: "center",
                                                        margin: "0px 30px",
                                                        lineHeight: "110%",
                                                    }}
                                                >
                                                    {seller.groupName}
                                                </Heading1>
                                            </div>

                                            {
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        padding:
                                                            "20px 0 20px 0",
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        left: "0px",
                                                        bottom: "28px",
                                                    }}
                                                >
                                                    <SpondImage
                                                        className="egg"
                                                        imageUrl={
                                                            seller.theme
                                                                .landingScratchImage
                                                        }
                                                        alt="egg"
                                                        style={{
                                                            height: "125px",
                                                        }}
                                                    />
                                                </div>
                                            }

                                            {!previewMode && (
                                                <Link
                                                    to={
                                                        routes.seller(
                                                            salesLinkCodeOrShortId
                                                        ).scratchcardsInfo
                                                    }
                                                >
                                                    <div
                                                        id="scratch_cards_info_icon"
                                                        style={{
                                                            borderRadius:
                                                                "100%",
                                                            backgroundImage: `url(${seller.theme.prizeInfoImage})`,
                                                            textAlign: "center",
                                                            backgroundSize:
                                                                "cover",
                                                            width: "38px",
                                                            height: "38px",
                                                            position:
                                                                "absolute",
                                                            bottom: "26px",
                                                            left: "27px",
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </Link>
                                            )}
                                        </>
                                    )}
                                </LandingBackground>
                            </div>
                            {!previewMode && (
                                <div>
                                    <Terms
                                        style={{ margin: "25px auto 0px auto" }}
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                "scratch_page.purchase_terms_link"
                                            ),
                                        }}
                                    />
                                </div>
                            )}
                            <div
                                className="button-wrapper"
                                style={{ padding: "20px 0" }}
                            >
                                {this.availableTicketCounts().map(
                                    (count, i) => (
                                        <PurchaseButtonWrapper key={i}>
                                            <ExpandablePurchaseButtonWrapper>
                                                <PurchaseButton
                                                    isPreview={previewMode}
                                                    onClick={() => {
                                                        if (!count) return;
                                                        if (previewMode) return;
                                                        if (
                                                            subscriptionExperimentEnabled
                                                        ) {
                                                            this.setState(
                                                                (
                                                                    state: State
                                                                ) => ({
                                                                    subscriptionExperimentCount:
                                                                        state.subscriptionExperimentCount ===
                                                                        count
                                                                            ? null
                                                                            : count,
                                                                    subscriptionExperimentTriggered:
                                                                        {
                                                                            ...state.subscriptionExperimentTriggered,
                                                                            [count]:
                                                                                true,
                                                                        },
                                                                })
                                                            );
                                                        } else {
                                                            this.onBuyClicked(
                                                                count
                                                            );
                                                        }
                                                        mixpanelEvents.pressedPurchaseButton(
                                                            {
                                                                ticket_count:
                                                                    count,
                                                                custom_contribution:
                                                                    false,
                                                            }
                                                        );
                                                    }}
                                                    style={{
                                                        marginBottom: "15px",
                                                        maxWidth: "100%",
                                                        transition: "0.3s",
                                                        transform:
                                                            count === 0
                                                                ? `scale(0.9)`
                                                                : "scale(1)",
                                                        opacity:
                                                            count === 0
                                                                ? "0"
                                                                : "1",
                                                    }}
                                                    theme={seller.theme}
                                                >
                                                    <span
                                                        style={{
                                                            opacity:
                                                                count === 0
                                                                    ? 0
                                                                    : 1,
                                                            transition:
                                                                "opacity 0.3s",
                                                        }}
                                                    >
                                                        {t(
                                                            seller.isSTB
                                                                ? "landing.contribute_and_choose_x_attemps"
                                                                : themeType
                                                                ? ScratchThemeConfig[
                                                                      themeType
                                                                  ]
                                                                      .purchaseTicketStringKey
                                                                : "actions.purchase_ticket",
                                                            {
                                                                count: count,
                                                                amount: CurrencyFormatter(
                                                                    t,
                                                                    seller,
                                                                    seller.scratchcardPrice *
                                                                        count
                                                                ),
                                                            }
                                                        )}
                                                    </span>
                                                </PurchaseButton>
                                            </ExpandablePurchaseButtonWrapper>
                                            {subscriptionExperimentEnabled && (
                                                <SelectMonthlyOrSinglePurchase
                                                    theme={seller.theme}
                                                    isEnabled={
                                                        this.state
                                                            .subscriptionExperimentCount ===
                                                        count
                                                    }
                                                    onSubscribe={() => {
                                                        this.setShowSubscriptionIsUnavailableInfo(
                                                            {
                                                                count,
                                                                custom: false,
                                                            }
                                                        );
                                                        this.setState({
                                                            recurringSelected:
                                                                true,
                                                        });
                                                        mixpanelEvents.choseMonthlySubscription(
                                                            {
                                                                ticket_count:
                                                                    count,
                                                                custom_contribution:
                                                                    false,
                                                            }
                                                        );
                                                    }}
                                                    onOneTimePurchase={() => {
                                                        this.onBuyClicked(
                                                            count
                                                        );
                                                        mixpanelEvents.choseSinglePayment(
                                                            {
                                                                ticket_count:
                                                                    count,
                                                                custom_contribution:
                                                                    false,
                                                            }
                                                        );
                                                    }}
                                                />
                                            )}
                                        </PurchaseButtonWrapper>
                                    )
                                )}

                                {!customBuyClicked && (
                                    <PurchaseButtonThin
                                        isPreview={previewMode}
                                        onClick={() => {
                                            !previewMode &&
                                                this.setState({
                                                    customBuyClicked: true,
                                                });
                                        }}
                                        theme={seller.theme}
                                        style={{
                                            marginTop: "15px",
                                            maxWidth: "100%",
                                        }}
                                    >
                                        {t(
                                            "actions.choose_your_own_contribution"
                                        )}
                                    </PurchaseButtonThin>
                                )}

                                {customBuyClicked && (
                                    <CustomBuyBed>
                                        <CustomDescripton>
                                            {t(
                                                "choose_your_own_contribution_description_variable",
                                                {
                                                    TicketAmount:
                                                        this.maxTicketCount(),
                                                }
                                            )}
                                        </CustomDescripton>
                                        <Input
                                            required
                                            type="number"
                                            style={{
                                                height: "50px",
                                                textAlign: "center",
                                            }}
                                            placeholder={t(
                                                "landing.placeholder_enter_you_contribution"
                                            )}
                                            name="purchase_amount"
                                            ref={(e) => {
                                                if (e) e.focus();
                                            }}
                                            value={this.state.customAmount}
                                            onChange={(e) =>
                                                this.onPurchaseAmountChanged(e)
                                            }
                                        />
                                        {subscriptionExperimentEnabled ? (
                                            <SelectMonthlyOrSinglePurchase
                                                isEnabled={true}
                                                theme={seller.theme}
                                                disabled={
                                                    (contribution || 0) <=
                                                    seller.scratchcardPrice *
                                                        this.maxTicketCount()
                                                }
                                                onSubscribe={() => {
                                                    this.setShowSubscriptionIsUnavailableInfo(
                                                        {
                                                            count: this.maxTicketCount(),
                                                            custom: true,
                                                        }
                                                    );
                                                    this.setState({
                                                        recurringSelected: true,
                                                    });
                                                    mixpanelEvents.choseMonthlySubscription(
                                                        {
                                                            ticket_count:
                                                                this.maxTicketCount(),
                                                            custom_contribution:
                                                                true,
                                                        }
                                                    );
                                                }}
                                                onOneTimePurchase={() => {
                                                    if (!previewMode) {
                                                        this.onProceedBuyCustom();
                                                        mixpanelEvents.choseSinglePayment(
                                                            {
                                                                ticket_count:
                                                                    this.maxTicketCount(),
                                                                custom_contribution:
                                                                    true,
                                                            }
                                                        );
                                                    }
                                                }}
                                            />
                                        ) : (
                                            <PurchaseButton
                                                isPreview={previewMode}
                                                disabled={
                                                    (contribution || 0) <=
                                                    seller.scratchcardPrice *
                                                        this.maxTicketCount()
                                                }
                                                onClick={() => {
                                                    if (!previewMode) {
                                                        this.onProceedBuyCustom();
                                                        mixpanelEvents.pressedPurchaseButton(
                                                            {
                                                                ticket_count:
                                                                    this.maxTicketCount(),
                                                                custom_contribution:
                                                                    true,
                                                            }
                                                        );
                                                    }
                                                }}
                                                theme={seller.theme}
                                                style={{
                                                    marginTop: "15px",
                                                    maxWidth: "100%",
                                                }}
                                            >
                                                {t(
                                                    "actions.contribute_x_and_get_x_tickets",
                                                    {
                                                        tickets:
                                                            this.maxTicketCount(),
                                                        amount: CurrencyFormatter(
                                                            t,
                                                            seller,
                                                            contribution || 0
                                                        ),
                                                    }
                                                )}
                                            </PurchaseButton>
                                        )}
                                    </CustomBuyBed>
                                )}
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img
                                    alt=""
                                    src={paymentMethodsImage}
                                    style={{ height: "30px", width: "138px" }}
                                />
                            </div>
                        </NarrowWrapper>
                    )}
                </div>
            </>
        );
    }
}

export const UnwrappedScratchcardWrapper = ScratchcardWrapper;

export default withTranslation()(withRouter(ScratchcardWrapper));
