import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import * as previewAvatar from "../../../assets/fc_preview_profile_image.png";
import { ScratchThemeConfig, ScratchThemeType } from "./CustomScratch.define";

export const ImgBanner = styled.div`
    position: relative;
    background-image: url("${({ bannerImg }) => bannerImg}");
    background-repeat: no-repeat;
    width: 90vw;
    max-width: 400px;
    height: 100px;
    background-position: top;
    background-size: contain;
    margin: 0 auto 60px auto;
`;

export const Avatar = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  top: ${({ previewMode }) => (previewMode ? "42" : "36")}px;
  left: 50%;
  transform: translateX(-50%);
  background-image: url('${({ image }) => image}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #fff;
}
`;

const CustomBanner = ({ seller, previewMode, themeType }) => {
    const { sellerImage, logo } = seller;
    return (
        <ImgBanner bannerImg={ScratchThemeConfig[themeType].banner}>
            <Avatar
                previewMode={previewMode}
                image={previewMode ? previewAvatar : sellerImage || logo}
            />
        </ImgBanner>
    );
};

CustomBanner.defaultProps = {
    previewMode: false,
};

const translateCustomBanner = withTranslation()(CustomBanner);

export { translateCustomBanner as CustomBanner };
