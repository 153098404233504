import Modal from "react-responsive-modal";
import React from "react";
import { useTranslation } from "react-i18next";
import {
    CheckoutOrKeepShopping,
    CheckoutOrKeepShoppingContentProps,
} from "./CheckoutOrKeepShopping";

type CheckoutOrKeepShoppingModal = {
    open: boolean;
    onClose: () => void;
};

export const CheckoutOrKeepShoppingModal = ({
    open,
    onClose,
    ...checkoutOrKeepShoppingProps
}: CheckoutOrKeepShoppingModal & CheckoutOrKeepShoppingContentProps) => {
    const t = useTranslation().t;
    return (
        // @ts-ignore
        <Modal
            center={true}
            open={open}
            onClose={onClose}
            classNames={{ modal: "action-modal" }}
        >
            {" "}
            {checkoutOrKeepShoppingProps.product && (
                <CheckoutOrKeepShopping {...checkoutOrKeepShoppingProps} />
            )}
        </Modal>
    );
};
