import { getSellerDisplayName } from "./strings/strings";
import { trackEvent } from "../EventTracker";
import { routes } from "../app/routes";

export const getShareLink = (salesLinkCodeOrShortId) => {
    if (window.location && window.location.protocol && window.location.host) {
        return (
            `${window.location.protocol}//${window.location.host}` +
            routes.seller(salesLinkCodeOrShortId).pathname
        );
    } else {
        const code =
            salesLinkCodeOrShortId.length <= 7
                ? salesLinkCodeOrShortId.substring(0, 6) + "B"
                : salesLinkCodeOrShortId;
        return `https://fundraising.spond.com` + routes.seller(code).pathname;
    }
};

export const proceedNavigateShare = ({
    seller,
    t,
    orderId,
    salesLinkCodeOrShortId,
}) => {
    const { groupId, shortId, groupName, sellerFirstName = "" } = seller;

    try {
        navigator
            .share({
                title: !!sellerFirstName
                    ? t("general.share_title", {
                          sellerName: getSellerDisplayName(seller),
                          groupName: groupName,
                      })
                    : t("general.share_title_organisation_seller", {
                          groupName: groupName,
                      }),
                url: getShareLink(salesLinkCodeOrShortId),
            })
            .then(() => {
                trackEvent("SHARE_SCRATCHCARD_COMPLETED", {
                    shortId: shortId,
                    orgDisplayName: groupName,
                    groupId: groupId,
                    orderId: orderId,
                });
            });
    } catch (err) {}
};
