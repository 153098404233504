import React from "react";

export default (props) => (
    <div
        style={{
            margin: "40px auto",
            maxWidth: props.maxWidth ? props.maxWidth : "100%",
        }}
    >
        {props.children}
    </div>
);
