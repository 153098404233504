import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { trackPageView } from "../../../EventTracker";
import { routes } from "../../../app/routes";

const getNorwegian = () => {
    return (
        <>
            <h1>
                Behandling av personopplysninger i forbindelse med Spond Dugnad
            </h1>
            <h4>Sist oppdatert: 14.05.2020</h4>
            <p>
                Denne personvernerklæringen regulerer Spond AS («Spond» eller
                «vi») sin behandling av personopplysningene dine i forbindelse
                med at du bruker tjenesten Spond dugnad (heretter kalt
                «Nettbutikken»). Personvernerklæringen gir blant informasjon om
                hvilke opplysninger som samles inn, hvordan personopplysningene
                brukes, og hvilke rettigheter du har i den forbindelse.
            </p>
            <h2>1 Informasjon vi samler inn</h2>
            <h3>1.1 Opplysninger du gir oss</h3>
            <p>
                Når du handler i Nettbutikken må du registrere navn, e-post,
                telefonnummer, og adresse. Overnevnte samles inn via VIPPS eller
                Stripe avhengig av hvilken betalingsmetode du velger ved
                utsjekk. Ved kjøp av digitale lodd vil vi også innhente
                leveringsadressen din i forbindelse med valg av eventuell
                premie.
            </p>
            <h3>
                1.2 Opplysninger Spond registrerer når du bruker Nettbutikken
            </h3>
            <p>
                I tillegg til informasjonen nevnt over, behandler Spond
                informasjon i forbindelse med dine kjøp i Nettbutikken, blant
                annet informasjon om (i) varen, (ii) kjøpstidspunkt, og (iii)
                beløpet som ble betalt.
            </p>
            <p>
                Vi behandler også følgende informasjon i forbindelse med dine
                kjøp i Nettbutikken, (i) domenet som Nettbutikken blir besøkt
                fra, (ii) hvilken nettleser som benyttes, (iii) varigheten av
                besøket, (iv) IP-adresse, (v) enhetsinformasjon, (vi)
                informasjonskapsler, (vii) anonyme identifikatorer, (viii)
                tidspunkt for besøket, (ix) hvordan du benytter Nettbutikken
                (deriblant hvilke funksjoner du benytter), (x) informasjon om
                geografisk posisjon og (xi) hvor lang tid det tar for deg å
                gjennomføre oppgaver i Nettbutikken.
            </p>
            <h2>2 Hvordan vi bruker personopplysningene dine</h2>
            <h3>2.1 Gjennomføring av avtalen med deg</h3>
            <p>
                Spond bruker personopplysningene dine til å gjennomføre avtalen
                med deg om bruk av og kjøp i Nettbutikken.
            </p>
            <h3>2.2 Personalisering</h3>
            <p>
                Vi bruker opplysningene til å gi deg en best mulig
                brukeropplevelse, for eksempel i form av gjenoppretting av
                handlekurv og tilpasning av Nettbutikken til din enhet.
            </p>
            <p>
                Behandlingsgrunnlaget for denne behandlingen av
                personopplysningene er legitime interesser, hvor vår legitime
                interesse er å tilby en brukervennlig og relevant Nettbutikk til
                våre kunder.
            </p>
            <h3>2.3 Utvikling, forbedring, statistikk og måling</h3>
            <p>
                Informasjonen blir brukt til å bedre forstå hvordan brukerne
                våre får tilgang til og bruker Nettbutikken, med det formål å
                bedre kunne respondere på brukernes preferanser og ønsker og å
                forbedre Nettbutikken i sin helhet.
            </p>
            <p>
                Behandlingsgrunnlaget for denne behandlingen av
                personopplysningene er legitime interesser, hvor vår legitime
                interesse er å forbedre og videreutvikle Nettbutikken.
            </p>
            <h3>2.4 Anonymisering</h3>
            <p>
                I de tilfellene Spond ikke har behov for å behandle dine
                personopplysninger på individnivå, vil Spond anonymisere
                personopplysningene før behandlingen finner sted.
            </p>
            <p>
                Behandlingsgrunnlaget for denne behandlingen av
                personopplysningene er legitime interesser, hvor vår legitime
                interesse er å skape og bruke anonyme datasett.
            </p>
            <h2>3 Samtykke</h2>
            <p>
                Noen behandlingsformer krever at vi på forhånd innhenter
                samtykke til behandlingen. I de tilfellene vi ber om ditt
                samtykke, vil samtykkeerklæringen detaljert beskrive de ulike
                formålene for behandlingen. Det er alltid frivilling å avgi
                samtykke, og du kan alltid fortsette å handle i Nettbutikken.
                Samtykket kan når som helst trekkes tilbake ved å benytte
                avmeldingsmekanismene i våre forsendelser.
            </p>
            <h2>4 Hvordan vi deler personopplysningene dine</h2>
            <h3>4.1 Generelt</h3>
            <p>
                Vi deler bare personopplysningene dine med andre hvis det er
                nødvendig for å gjennomføre kjøp og leveranse av varene i
                Nettbutikken, eller vi skulle være forpliktet til slik deling i
                henhold til lov, forskrift eller rettslige prosesser.
            </p>
            <p>
                Enhver deling utover slik deling som nevnt i dette punktet
                krever ditt samtykke. Dette er ikke til hinder for at vi kan
                benytte databehandlere til å behandle personopplysninger på våre
                vegne (i medhold av en databehandleravtale). Noen av våre
                databehandlere befinner seg utenfor EU/EØS. For å sikre at disse
                databehandlerne behandler personopplysninger på en forsvarlig
                måte benytter vi EUs standardavtaler for overføring av
                personopplysninger til tredjeland. Databehandlere lokalisert i
                USA kan alternativt være tilknyttet Privacy Shield ordningen.
            </p>
            <p>
                Spond benytter flere underleverandører, slik som lagertjeneste
                og transportører for å gjennomføre avtalen med deg som kunde om
                kjøp og leveranse av varene i Nettbutikken.
            </p>
            <h3>4.2 Lagerløsning</h3>
            <p>
                Spond bruker lager- og logistikkløsningen Lettbutikk.no, som
                driftes av selskapet Lettbutikk AS, til å levere varer/gevinster
                fra Nettbutikken til deg som kunde.
            </p>
            <p>
                For å kunne oppfylle kjøpsavtalen med deg, og i henhold til
                inngått databehandleravtale, deler Spond følgende av dine
                personopplysninger med Lettbutikk AS: (i) navn, (ii) e-post,
                (iii) telefonnummer, (iv) adresse og (v) hva som skal leveres.
            </p>
            <h3>4.3 Transportør</h3>
            <p>
                Ovennevnte informasjon deles også med vår(e)
                transportleverandør(er). For tiden bruker Spond HeltHjem
                Netthandel AS, PostNord AS og Bring AS som transportør for å
                levere varer som du har bestilt i Nettbutikken eller eventuelle
                gevinster du vinner når du kjøper digitale skrapelodd. Du kan
                lese mer om hvordan transportør behandler dine
                personopplysninger i deres personvernerklæring.
            </p>
            <h3>4.4 Selger/ansvarlig medlem for dugnad</h3>
            <p>
                Den som har invitert deg til å delta i Spond dugnad vil få vite
                at du har deltatt i dugnaden, kjøpstidspunkt og hvor mye du har
                handlet for.
            </p>
            <h2>5 Innsyn, retting og sletting av personopplysninger</h2>
            <p>
                Du har rett til innsyn i personopplysningene vi har registrert
                om deg. Hvis noen av opplysningene vi har registrert om deg ikke
                er korrekte, eller du ønsker at opplysninger slettes, kan du ta
                kontakt med oss på{" "}
                <a
                    href="mailto:support+fp@spond.com"
                    target="_blank"
                    rel="noopener"
                >
                    support+fp@spond.com
                </a>
                . Merk at vi ikke kan slette opplysninger som vi trenger for å
                gjennomføre transaksjoner, eller opplysninger som trengs for
                eksempel av regnskapsmessige hensyn.
            </p>
            <p>
                Vi vil uansett ikke lagre personopplysningene dine lenger enn
                det som er nødvendig for å oppfylle formålet med behandlingen og
                våre lovpålagte plikter.
            </p>
            <h2>6 Informasjonskapsler (cookies)</h2>
            <p>
                Vi benytter informasjonskapsler (cookies) og andre teknologier
                for å øke brukeropplevelsen og få mest mulig effektiv bruk av
                Nettbutikken. En informasjonskapsel er et stykke data som sendes
                fra en internettside som besøkes, og som lagres lokalt på
                brukerens nettleser. Hensikten er å bruke informasjonskapslene
                til å bevare data relatert til brukerens preferanser og
                kontoinnstillinger, samt til å evaluere og sammenstille
                statistikk om brukerens aktivitet. De fleste nettlesere godtar
                informasjonskapsler som standard. Du kan når som helst avslå
                bruken av informasjonskapsler ved å gjøre endre innstillingene i
                nettleseren til ikke å godta informasjonskapsler. Merk
                imidlertid at noen deler av Nettbutikken kanskje ikke fungerer
                på riktig måte dersom informasjonskapsler blir fjernet. Du kan
                lese mer om hvilke cookies vi bruker og hva de gjør ved å følge
                lenken i bunnen av denne siden.
            </p>
            <h2>7 Informasjonssikkerhet</h2>
            <p>
                Vi har gjennomført organisatoriske og tekniske rutiner og tiltak
                som skal sørge for at dine personopplysninger ikke kommer på
                avveie, ikke endres utilsiktet, og at de er tilgjengelige ved
                behov.
            </p>
            <h2>8 Endringer i personvernerklæringen</h2>
            <p>
                Vi vil nå og da komme til å oppdatere denne
                personvernerklæringen for å reflektere eventuelle endringer i
                Nettbutikken. Endret personvernerklæring vil i slike tilfeller
                bli publisert på denne siden. Ved større endringer vil vi varsle
                deg direkte på egnet måte, enten via Nettbutikken eller e-post.
            </p>
            <h2>9 Behandlingsansvarlig og kontaktinformasjon</h2>
            <p>
                Spond AS, organisasjonsnummer 998 209 218, Myntgata 2, 0151,
                Oslo, er behandlingsansvarlig for behandling av dine
                personopplysninger slik som beskrevet i denne
                personvernerklæringen.
            </p>
            <p>
                Vi kan kontaktes på epost:{" "}
                <a
                    href="mailto:support+fp@spond.com"
                    target="_blank"
                    rel="noopener"
                >
                    support+fp@spond.com
                </a>
                . Se også{" "}
                <a href="https://get.spond.help" target="_blank" rel="noopener">
                    https://get.spond.help
                </a>{" "}
                hvor du kan lese hjelpeartikler og chatte med supportteamet
                vårt. Spørsmål om personvern kan også rettes til Sponds
                personvernombud som kan kontaktes på{" "}
                <a
                    href="mailto:privacy@spond.com"
                    target="_blank"
                    rel="noopener"
                >
                    privacy@spond.com
                </a>
                .
            </p>
        </>
    );
};

const getEnglish = () => <></>;

class PrivacyPage extends Component {
    componentDidMount() {
        const { seller } = this.props;
        trackPageView("Privacy", {
            shortId: seller.shortId,
            orgDisplayName: seller.displayName,
            groupId: seller.groupId,
        });
    }

    render() {
        const {
            seller,
            t,

            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { countryCode } = seller;

        let content;
        switch (countryCode) {
            case "NOR":
                content = getNorwegian();
                break;

            default:
                content = getEnglish();
        }

        return (
            <div>
                // TODO
                <Link to={routes.seller(salesLinkCodeOrShortId).pathname}>
                    <h4>&larr; {t("terms.back")}</h4>
                </Link>
                {content}
                <Link to={routes.seller(salesLinkCodeOrShortId).pathname}>
                    <h4>&larr; {t("terms.back")}</h4>
                </Link>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(PrivacyPage))
);
