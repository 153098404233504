import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import { withTranslation } from "react-i18next";

const CountdownHolder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const CountdownHeading = styled.h2`
    margin-bottom: 15px;
    font-size: 28px;
    font-family: "Dosis", sans-serif !important;
    font-weight: 700 !important;
    text-transform: uppercase;
`;

const CountdownCellHolder = styled.div`  
  & + & {
    margin-left: 15px;
`;

const CountdownCell = styled.div`
    text-transform: uppercase;
`;

const CountdownCounterCell = styled(CountdownCell)`
    padding: 8px;
    border: ${({ themeColor }) => `2px solid ${themeColor || "#399013"}`};
    border-radius: 6px;
    font-size: 28px;
    font-weight: 700;
    min-height: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    font-family: "Dosis", sans-serif;

    & + & {
        margin-left: 10px;
    }

    &:before {
        display: block;
        content: "MM ";
        font-weight: 600;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
`;

const CountdownAnimationHolder = styled.span`
    line-height: 1;
    position: relative;
    display: block;
    overflow: hidden;
    margin-left: 6px;
    width: 33px;
    height: 24px;
`;

const CountdownNumberHolder = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    transition: 0.3s ease transform;
    text-indent: 0;
`;

const CountdownLabelCell = styled(CountdownCell)`
    font-size: 12px;
    color: #333;
    font-weight: 600;
    font-family: "Dosis", sans-serif;
`;

const RaffleCountdown = ({ endDate, t, themeColor }) => {
    const [days, setDays] = useState("--");
    const [hours, setHours] = useState("--");
    const [minutes, setMinutes] = useState("--");
    const [seconds, setSeconds] = useState("--");

    const firstUpdate = useRef(true);

    const tick = (interval) => {
        if (endDate != null) {
            const now = moment();
            const endMoment = moment(endDate);

            if (endMoment.isBefore(now) && interval) {
                return clearInterval(interval);
            }

            const diffTime = endMoment - now;
            const duration = moment.duration(diffTime, "milliseconds");

            setDays(
                `${
                    duration.asDays() > 0 ? Math.floor(duration.asDays()) : 0
                }`.padStart(2, "0")
            );
            setHours(
                `${duration.hours() > 0 ? duration.hours() : 0}`.padStart(
                    2,
                    "0"
                )
            );
            setMinutes(
                `${duration.minutes() > 0 ? duration.minutes() : 0}`.padStart(
                    2,
                    "0"
                )
            );
            setSeconds(
                `${duration.seconds() > 0 ? duration.seconds() : 0}`.padStart(
                    2,
                    "0"
                )
            );
        }
    };

    useEffect(() => {
        if (firstUpdate.current) {
            tick();
        }

        const interval = setInterval(() => {
            tick(interval);
        }, 1000);

        return () => clearInterval(interval);
    });

    return (
        <div id="superdraw_count_down">
            <CountdownHeading>{t("superdraw.prize_draw")}</CountdownHeading>
            <CountdownHolder>
                <CountdownCellHolder id="superdraw_count_down_days">
                    <CountdownCounterCell themeColor={themeColor}>
                        {days}
                    </CountdownCounterCell>
                    <CountdownLabelCell>{t("general.days")}</CountdownLabelCell>
                </CountdownCellHolder>
                <CountdownCellHolder id="superdraw_count_down_hours">
                    <CountdownCounterCell themeColor={themeColor}>
                        {hours}
                    </CountdownCounterCell>
                    <CountdownLabelCell>
                        {t("general.hours")}
                    </CountdownLabelCell>
                </CountdownCellHolder>
                <CountdownCellHolder id="superdraw_count_down_minutes">
                    <CountdownCounterCell themeColor={themeColor}>
                        {minutes}
                    </CountdownCounterCell>
                    <CountdownLabelCell>
                        {t("general.minutes")}
                    </CountdownLabelCell>
                </CountdownCellHolder>
                <CountdownCellHolder id="superdraw_count_down_seconds">
                    <CountdownCounterCell themeColor={themeColor}>
                        {seconds}
                    </CountdownCounterCell>
                    <CountdownLabelCell>
                        {t("general.seconds")}
                    </CountdownLabelCell>
                </CountdownCellHolder>
            </CountdownHolder>
        </div>
    );
};

export default withTranslation()(RaffleCountdown);
