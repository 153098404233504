import React from "react";
import * as styles from "./Input.module.scss";

const Input = ({ type, name, value, onChange, label, ...props }) => {
    return (
        <label className={styles.inputWrapper}>
            <input
                className={styles.input}
                name={name}
                type={type}
                value={value}
                onChange={onChange}
                placeholder="."
                {...props}
            />
            <span className={styles.label}>{label}</span>
        </label>
    );
};

export default Input;
