import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ScrollToTop from "../../../../components/scroll-to-top/ScrollToTop";
import Avatar from "../../../../components/avatar/Avatar";
import styled from "styled-components";
import { getShareLink, proceedNavigateShare } from "../../../../utils/share";
import {
    RaffleOutlineButton,
    RaffleProductButton,
} from "../../../../components/button/Buttons";
import {
    getSellerDisplayName,
    getShareEmailBody,
    getShareEmailSubject,
} from "../../../../utils/strings/strings";
import moment from "moment";
import RaffleCountdown from "../../../../containers/super-draw/countdown/SuperDrawCountdown.component";
import qs from "query-string";
import { apiClient } from "../../../../api/ApiClient";
import { routes } from "../../../../app/routes";

export const ContentHolder = styled.div`
    margin: 15px auto;
    width: 100%;
`;

const TicketHeading = styled.h2`
    margin-bottom: 15px;
    font-size: 28px;
    font-family: "Dosis", sans-serif !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    text-align: center;
`;

const TicketBox = styled.div`
    padding: 12px 16px;
    border: 1px solid #999;
    border-radius: 5px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
    max-width: 80px;
    font-family: "Dosis", sans-serif !important;
    font-weight: 700 !important;
    font-size: 18px;
    flex: 1 0 50%;

    margin-bottom: 10px;

    &:not(first-child) {
        margin-left: 10px;
    }
`;

const TicketHolder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 440px;
    flex-wrap: wrap;
    margin: 0 auto;
`;

const ThankYouTitle = styled.h1`
    text-align: center;
    margin-top: 25px;
    font-weight: 400;
`;

export class InnerSuperDrawThankYouPage extends React.Component {
    constructor(props) {
        super(props);

        const {
            match: {
                params: { extOrderId },
            },
            location,
        } = this.props;
        const { key } = qs.parse(location.search);
        this.state = {
            extOrderId,
            key,
        };
    }

    onFacebookShareClicked() {
        const {
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;

        if (window.FB) {
            window.FB.ui(
                {
                    method: "share",
                    href: getShareLink(salesLinkCodeOrShortId),
                },
                function (response) {}
            );
        } else {
            console.error("FB is undefined");
        }
    }

    onNavigatorShareClicked = () => {
        const {
            seller,
            t,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        proceedNavigateShare({
            seller,
            t,
            salesLinkCodeOrShortId,
        });
    };

    componentDidMount() {
        const { extOrderId } = this.state;
        apiClient
            .orderStatusCheck(this.props.seller.shortId, extOrderId)
            .then((res) => {
                const { superdrawTickets } = res.data;
                this.setState({ tickets: superdrawTickets });
            });
    }

    render() {
        const { tickets } = this.state;
        const {
            seller,
            t,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const {
            groupName,
            description,
            sellerImage,
            superdrawDrawTime,
            theme,
        } = seller;
        const sellerPersonName = getSellerDisplayName(seller);

        const drawMoment = moment(superdrawDrawTime);
        const sellerName = groupName;
        const i18nKey = sellerName
            ? "superdraw.order_successful_title"
            : "order_successful.body_plain";
        return (
            <>
                <ScrollToTop />
                <div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Avatar imageUrl={sellerImage} />
                    </div>

                    <ThankYouTitle>
                        <Trans t={t} i18nKey={i18nKey}>
                            {/* NOTE: This text is not hard coded, even though it may look that way. This is how react-i18next API works.*/}
                            Thank you for supporting <b>{{ sellerName }}</b>
                        </Trans>
                    </ThankYouTitle>

                    <p style={{ textAlign: "center" }}>
                        {t("superdraw.order_successful_notification_info")}
                    </p>

                    <ContentHolder
                        style={{ padding: "0 20px", boxSizing: "border-box" }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                margin: "0 auto 30px auto",
                            }}
                        >
                            <div
                                style={{
                                    marign: "15px auto",
                                    textAlign: "center",
                                }}
                            >
                                <TicketHeading>
                                    {t("superdraw.your_entries")}
                                </TicketHeading>
                                {!tickets && <p>{t("landing.loading")}</p>}
                                {tickets && (
                                    <TicketHolder id="your_entries">
                                        {tickets.map((ticket) => (
                                            <TicketBox key={ticket.id}>
                                                {ticket.key}
                                            </TicketBox>
                                        ))}
                                    </TicketHolder>
                                )}
                            </div>
                            <div style={{ margin: "30px auto" }}>
                                <RaffleCountdown
                                    endDate={drawMoment}
                                    themeColor={theme.primaryButtonColour}
                                />
                            </div>
                            <div
                                style={{
                                    marginTop: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <RaffleProductButton
                                    onClick={() =>
                                        (window.location.href = routes.seller(
                                            salesLinkCodeOrShortId
                                        ).pathname)
                                    }
                                    theme={theme}
                                    id="enter_super_draw_again"
                                >
                                    {t("superdraw.enter_super_draw_again")}
                                </RaffleProductButton>
                            </div>
                            <div
                                style={{
                                    margin: "15px auto 0 auto",
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                }}
                            >
                                {navigator.share && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        <RaffleOutlineButton
                                            theme={theme}
                                            id="share_sales_link"
                                            onClick={() =>
                                                this.onNavigatorShareClicked()
                                            }
                                        >
                                            {t("stats.share_sales_link")}
                                        </RaffleOutlineButton>
                                    </div>
                                )}
                                {!navigator.share && (
                                    <>
                                        {window.FB && (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <RaffleOutlineButton
                                                    theme={theme}
                                                    id="share_facebook"
                                                    onClick={() =>
                                                        this.onFacebookShareClicked()
                                                    }
                                                >
                                                    {t(
                                                        "order_successful.share_facebook"
                                                    )}
                                                </RaffleOutlineButton>
                                            </div>
                                        )}

                                        <a
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                width: "100%",
                                                textDecoration: "none",
                                            }}
                                            href={`mailto:?subject=${getShareEmailSubject(
                                                t,
                                                sellerPersonName,
                                                groupName
                                            )}&body=${getShareEmailBody(
                                                t,
                                                description,
                                                getShareLink(
                                                    salesLinkCodeOrShortId
                                                )
                                            )}`}
                                        >
                                            <RaffleOutlineButton
                                                theme={theme}
                                                id="share_email"
                                            >
                                                {t(
                                                    "order_successful.share_email"
                                                )}
                                            </RaffleOutlineButton>
                                        </a>
                                    </>
                                )}
                            </div>
                        </div>
                    </ContentHolder>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
    cart: state.sellerReducer.cart,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(InnerSuperDrawThankYouPage))
);
