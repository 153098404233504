import {
    ADD_TO_CART,
    CLEAR_CART,
    GET_SELLER_ERROR,
    GET_SELLER_SUCCESS,
    REMOVE_FROM_CART,
    UPDATE_QUANTITY_IN_CART,
} from "./types";
import { Seller } from "../api/types";

export type CartItem = {
    productId: string;
    quantity: number;
    productVariationId?: string;
};

const forceClearSessionStorage = () =>
    Object.entries(localStorage)
        .filter(([key]) => key.includes("cart_"))
        .forEach(([key]) => sessionStorage.removeItem(key));

type SellerReducerState = {
    cart: CartItem[];
    seller?: Seller;
};
let defaultState = {
    cart: [] as CartItem[],
    seller: {} as Seller,
};

export function addingToCart(
    cart: CartItem[],
    {
        productId,
        productVariationId,
    }: {
        productId: string;
        productVariationId?: string;
    }
): CartItem[] {
    const alreadyExistsIndex = cart.findIndex(
        (cartItem) =>
            cartItem.productId === productId &&
            cartItem.productVariationId === productVariationId
    );
    if (alreadyExistsIndex === -1) {
        cart.push({
            productId,
            productVariationId,
            quantity: 1,
        });
    } else {
        cart[alreadyExistsIndex].quantity += 1;
    }
    return cart;
}

export default (
    state: SellerReducerState = defaultState,
    action: {
        type: string;
        payload?: any;
    }
) => {
    switch (action.type) {
        case GET_SELLER_SUCCESS: {
            return {
                ...state,
                seller: action.payload,
            };
        }
        case GET_SELLER_ERROR: {
            return {
                ...state,
                getSellerFailed: true,
            };
        }
        case CLEAR_CART: {
            const { cart, ...rest } = state;
            forceClearSessionStorage();
            return {
                ...rest,
                cart: [],
            };
        }
        case ADD_TO_CART: {
            const cart = addingToCart([...state.cart], action.payload);
            return {
                ...state,
                cart,
            };
        }
        case REMOVE_FROM_CART: {
            let cart: CartItem[] = [...state.cart];
            const { productId, productVariationId } = action.payload;

            if (!cart.length) {
                return { ...state };
            }

            cart = cart.filter(
                (cartItem) =>
                    !(
                        cartItem.productId === productId &&
                        cartItem.productVariationId === productVariationId
                    )
            );

            return {
                ...state,
                cart,
            };
        }

        case UPDATE_QUANTITY_IN_CART: {
            let cart: CartItem[] = [...state.cart];
            const { productId, productVariationId, quantity } = action.payload;

            if (!cart.length) {
                return { ...state };
            }

            for (let i = 0; i < cart.length; i++) {
                const cartItem = cart[i];

                if (
                    cartItem.productId === productId &&
                    (!productVariationId ||
                        cartItem.productVariationId === productVariationId)
                ) {
                    cartItem.quantity = parseInt(quantity);
                    break;
                }
            }

            return {
                ...state,
                cart,
            };
        }

        default:
            return state;
    }
};
