import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { withRouter } from "react-router-dom";

import ScrollToTop from "../../../components/scroll-to-top/ScrollToTop";
import {
    EntryTimeStatusBed,
    InnerContentHolder,
    MetaDescription,
    MetaTitle,
    OuterContentHolder,
    ProductButtonHolder,
    RaffleProductsWrapper,
    SuperDrawLandingBed,
    ThemeIndicator,
    ThemeIndicatorsBed,
    ThemePhotoImg,
    ThemePhotosBed,
} from "../styled-components/SuperDrawLanding.container.styled";
import { RaffleProductButton } from "../../../components/button/Buttons";
import RaffleCountdown from "../countdown/SuperDrawCountdown.component";
import CurrencyFormat from "../../../components/currency-format/CurrencyFormat";
import BackgroundImage from "../../../components/background-image/BackgroundImage";
import LandingHeader from "../../../components/landing-header/LandingHeader";

import * as paypalLogo from "../../../assets/pay-paypal.png";
import * as mastercardLogo from "../../../assets/pay-mastercard.png";
import * as visaLogo from "../../../assets/pay-visa.png";
import qs from "query-string";
import { Slide } from "react-slideshow-image";
import Footer from "../../../components/footer/Footer";
import { routes } from "../../../app/routes";
import { isUITest } from "../../../utils/env";

class SuperDrawLandingContainer extends Component {
    state = {
        themeIndex: 0,
    };

    componentDidMount() {
        const { price, quantity } = qs.parse(this.props.location.search);
        this.setState({ price, quantity });
    }

    onContributeClicked(product) {
        const {
            location,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { source, profileId } = qs.parse(location.search);
        window.location.href =
            routes.seller(salesLinkCodeOrShortId).superdraw.checkout +
            `?price=${product.price}&quantity=${product.quantity}&${
                !!source ? `source=${source}` : ""
            }${source && profileId ? "&" : ""}${
                profileId ? `profileId=${profileId}` : ""
            }`;
    }

    updateThemeIndex(newIndex) {
        if (newIndex !== -1) {
            this.setState({ themeIndex: newIndex });
        }
    }

    onThemeIndexChanged(newIndex) {
        this.setState({ themeIndex: newIndex });
    }

    render() {
        const { t, seller, previewMode, preivewSellerInfo } = this.props;
        const {
            shortId,
            superdrawPrizes,
            priceTiers,
            theme,
            themeType,
            sellerImage,
            superdrawDrawTime,
            endTime,
            superdrawPrizeInfo,
        } = previewMode ? preivewSellerInfo : seller;
        const { price } = this.state;

        const drawMoment = moment(superdrawDrawTime);
        const hasEntryClosed =
            !previewMode && moment(endTime).isBefore(moment());
        const hasEnded = moment(drawMoment).isBefore(moment());

        let superDrawPriceTier = priceTiers;
        if (previewMode && !!!priceTiers) {
            superDrawPriceTier = [
                {
                    price,
                    quantity: 1,
                },
                {
                    price: price * 2,
                    quantity: 2,
                },
            ];
        }

        return (
            <>
                {previewMode && (
                    <LandingHeader
                        previewMode={previewMode}
                        includesStyling={false}
                        seller={preivewSellerInfo}
                        themeType={themeType}
                    />
                )}
                <SuperDrawLandingBed>
                    <ScrollToTop />
                    {!previewMode && (
                        <BackgroundImage theme={theme} shorten={!sellerImage}>
                            {!hasEnded && (
                                <LandingHeader
                                    seller={seller || preivewSellerInfo}
                                    t={t}
                                />
                            )}
                            {hasEnded && (
                                <>
                                    <h2
                                        style={{
                                            marginTop: "30px",
                                            marginBottom: "0",
                                        }}
                                    >
                                        {t("landing.fundraising_ended")}
                                    </h2>
                                    <p
                                        style={{
                                            textAlign: "center",
                                            marginTop: "0",
                                            marginBottom: "30px",
                                        }}
                                    >
                                        {t("superdraw.end_description")}
                                    </p>
                                </>
                            )}
                        </BackgroundImage>
                    )}

                    <OuterContentHolder>
                        <InnerContentHolder>
                            <RaffleProductsWrapper>
                                {superdrawPrizes.length && (
                                    <>
                                        {!hasEnded && (
                                            <>
                                                <ThemePhotosBed id="theme_photos">
                                                    <Slide
                                                        {...{
                                                            defaultIndex:
                                                                this.state
                                                                    .themeIndex,
                                                            indicators: false,
                                                            transitionDuration:
                                                                isUITest()
                                                                    ? 50000
                                                                    : 500,
                                                            arrows: false,
                                                            autoplay:
                                                                !previewMode &&
                                                                superdrawPrizes.length >
                                                                    1,
                                                            onChange: (
                                                                oldIndex,
                                                                newIndex
                                                            ) => {
                                                                this.onThemeIndexChanged(
                                                                    newIndex
                                                                );
                                                            },
                                                        }}
                                                    >
                                                        {superdrawPrizes.map(
                                                            (prize, index) => (
                                                                <div
                                                                    className="each-slide"
                                                                    key={
                                                                        prize.id
                                                                    }
                                                                >
                                                                    <ThemePhotoImg
                                                                        src={
                                                                            prize.previewUrl
                                                                        }
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </Slide>
                                                </ThemePhotosBed>
                                                {superdrawPrizes.length > 1 && (
                                                    <ThemeIndicatorsBed>
                                                        {superdrawPrizes.map(
                                                            (prize, index) => (
                                                                <ThemeIndicator
                                                                    key={index}
                                                                    activeColor={
                                                                        theme.primaryButtonColour
                                                                    }
                                                                    active={
                                                                        this
                                                                            .state
                                                                            .themeIndex ===
                                                                        index
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </ThemeIndicatorsBed>
                                                )}
                                            </>
                                        )}
                                        <div
                                            style={{
                                                marginTop: "20px",
                                                marginBottom: "20px",
                                            }}
                                        >
                                            <RaffleCountdown
                                                endDate={drawMoment}
                                                themeColor={
                                                    theme.primaryButtonColour
                                                }
                                            />
                                        </div>

                                        {hasEntryClosed && !hasEnded && (
                                            <EntryTimeStatusBed>
                                                {t(
                                                    "superdraw.entry_closed_and_draw_is_about_to_start"
                                                )}
                                            </EntryTimeStatusBed>
                                        )}
                                        {hasEnded && (
                                            <EntryTimeStatusBed>
                                                {t(
                                                    "superdraw.draw_time_expired_description"
                                                )}
                                            </EntryTimeStatusBed>
                                        )}

                                        {!hasEntryClosed && (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    margin: "0 auto",
                                                    display: "flex",
                                                }}
                                            >
                                                <ProductButtonHolder>
                                                    {superDrawPriceTier.map(
                                                        (priceTier, idx) => (
                                                            <div
                                                                key={
                                                                    priceTier.quantity
                                                                }
                                                                className="product-button-wrapper"
                                                            >
                                                                <RaffleProductButton
                                                                    style={{
                                                                        display:
                                                                            "inline-block",
                                                                    }}
                                                                    theme={
                                                                        theme
                                                                    }
                                                                    id="support_action_text"
                                                                    isPreview={
                                                                        previewMode
                                                                    }
                                                                    onClick={() =>
                                                                        !previewMode &&
                                                                        this.onContributeClicked(
                                                                            priceTier
                                                                        )
                                                                    }
                                                                    priceTier={
                                                                        priceTier
                                                                    }
                                                                    quantity={
                                                                        priceTier.quantity
                                                                    }
                                                                    quantityText={
                                                                        <CurrencyFormat
                                                                            value={
                                                                                priceTier.price
                                                                            }
                                                                            fixedDecimalScale={
                                                                                2
                                                                            }
                                                                            t={
                                                                                t
                                                                            }
                                                                        />
                                                                    }
                                                                >
                                                                    {t(
                                                                        "superdraw.support_action_text",
                                                                        {
                                                                            count: priceTier.quantity,
                                                                        }
                                                                    )}
                                                                </RaffleProductButton>
                                                            </div>
                                                        )
                                                    )}
                                                    <div>
                                                        <RaffleProductButton
                                                            style={{
                                                                display:
                                                                    "inline-block",
                                                            }}
                                                            theme={theme}
                                                            quantity={1}
                                                            isPreview={
                                                                previewMode
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    !previewMode
                                                                ) {
                                                                    window.location.href = `https://static.spond.com/fundraisingproducts/frc-superdraw-postal-entry.pdf`;
                                                                }
                                                            }}
                                                            quantityText={t(
                                                                "superdraw.post_entry_enter_without_contributing"
                                                            )}
                                                            quantitySubtext={t(
                                                                "superdraw.no_purchase_necessary"
                                                            )}
                                                            quantityTextSize={
                                                                16
                                                            }
                                                            iconVerticalAlign={
                                                                -1
                                                            }
                                                        >
                                                            {t(
                                                                "superdraw.post_entry"
                                                            )}
                                                        </RaffleProductButton>
                                                    </div>

                                                    <div
                                                        style={{
                                                            margin: "30px auto 0 auto",
                                                        }}
                                                    >
                                                        <img
                                                            src={paypalLogo}
                                                            width={60}
                                                        />
                                                        <img
                                                            src={mastercardLogo}
                                                            width={60}
                                                        />
                                                        <img
                                                            src={visaLogo}
                                                            width={60}
                                                        />
                                                    </div>
                                                </ProductButtonHolder>
                                            </div>
                                        )}

                                        {!hasEnded &&
                                            superdrawPrizeInfo?.title && (
                                                <MetaTitle>
                                                    {superdrawPrizeInfo.title}
                                                </MetaTitle>
                                            )}
                                        {!hasEnded &&
                                            superdrawPrizeInfo?.description && (
                                                <MetaDescription
                                                    dangerouslySetInnerHTML={{
                                                        __html: superdrawPrizeInfo.description,
                                                    }}
                                                />
                                            )}
                                    </>
                                )}
                            </RaffleProductsWrapper>
                        </InnerContentHolder>
                    </OuterContentHolder>
                </SuperDrawLandingBed>
                {!previewMode && (
                    <Footer
                        custermTermsLink={
                            "https://www.spond.com/fundraising-purchasing-terms-superdraw"
                        }
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(
    withRouter(
        connect(mapStateToProps, mapDispatchToProps)(SuperDrawLandingContainer)
    )
);
