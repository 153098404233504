import styled from "styled-components";
import { ClickableButton } from "../../../components/button/Buttons";
import React from "react";

export const ProductLandingBed = styled.div`
    width: 100%;
    max-width: 100vw;
    min-width: 100%;
`;

export const Product = styled.div`
    /*
  flex: 1;
  */
    box-sizing: border-box;
    margin-bottom: 60px;
`;

export const ProductTitle = styled.h2`
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    text-align: left;
`;

export const ProductPrice = styled.h3`
    font-weight: normal;
    font-size: 14px;
    margin: 0;
    margin-bottom: 9px;
    text-align: left;
`;

export const ProductImage = styled.div`
    position: relative;
    margin-bottom: 9px;
    cursor: pointer;

    img {
        width: 100%;
        border-radius: 2px;
    }
`;

export const ProductPriceTag = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    padding: 10px;
`;

export const ProductButton = styled(ClickableButton)`
    width: 100%;
    max-width: 100%;
    background-color: ${({ backgroundColour }) =>
        backgroundColour ? backgroundColour : "#1c8585"};

    @media only screen and (max-width: 767px) {
        font-size: 14px;
        padding-left: 0px;
        padding-right: 0px;
    }
`;
