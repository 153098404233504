import styled from "styled-components";

export const SuperDrawLandingBed = styled.div`
    margin: 0 auto;
    padding: 15px 0 0 0;
    text-align: center;
`;

export const RaffleProductsWrapper = styled.div`
    margin: 0 auto;

    h2 {
        margin-top: 15px;
        font-size: 24px;
        font-weight: 600;
        line-height: 0.96;
        letter-spacing: normal;
    }

    .link,
    .link:visited,
    .link:focus,
    .link:active {
        color: #47b7cb;
        cursor: pointer;
    }
`;

export const OuterContentHolder = styled.div`
    width: 100%;
    box-sizing: border-box;
    position: relative;
    top: -45px;
`;

export const InnerContentHolder = styled.div`
    max-width: 440px;
    margin: 0 auto;
    padding: 20px 0;
    text-align: center;
    box-sizing: border-box;
`;

export const ThemePhotosBed = styled.div`
    max-width: 440px;
    min-height: 180px;
    overflow: hidden;

    .images-wrap {
        align-items: center;

        div {
            display: inline-block !important;
        }
    }
`;

export const ThemePhotoImg = styled.img`
    max-width: 100%;
    object-fit: cover;
    min-height: 120px;
`;

export const ThemeIndicatorsBed = styled.div`
    max-width: 100%;
    display: inline-block;
    text-align: center;
`;

export const ThemeIndicator = styled.div`
    background: ${({ activeColor, active }) =>
        active ? activeColor : "#e0e0e0"};
    width: 10px;
    height: 10px;
    border-radius: 5px;
    display: inline-block;
    margin: 0 2px;
`;

export const ProductButtonHolder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 15px auto;
    width: 90%;

    .product-button-wrapper:not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const MetaTitle = styled.div`
    font-size: 16px;
    text-align: left;
    font-weight: bold;
    padding: 0 20px;
`;

export const MetaDescription = styled(MetaTitle)`
    font-size: 14px;
    color: #767676;
    font-weight: normal;
    margin-top: 0.5rem;
`;

export const EntryTimeStatusBed = styled.div`
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 10px;
`;
