import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import qs from "query-string";
import { withTranslation } from "react-i18next";

import Avatar from "../../../components/avatar/Avatar";
import { ThemeButton } from "../../../components/button/Buttons";
import ContentWrapper from "../../../components/content-wrapper/ContentWrapper";
import { trackEvent } from "../../../EventTracker";
import { routes } from "../../../app/routes";

class OrderCancelledPage extends Component {
    componentDidMount() {
        const { seller, location } = this.props;
        const { orderId } = qs.parse(location.search);
    }

    trackGoBack() {
        const { seller, location } = this.props;
        const { orderId } = qs.parse(location.search);

        trackEvent("CLICK_GO_BACK", {
            shortId: seller.shortId,
            orgDisplayName: seller.displayName,
            groupId: seller.groupId,
            orderId,
        });
    }

    render() {
        const {
            seller,
            t,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;

        return (
            <div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Avatar
                        imageUrl={seller.sellerImage}
                        shortId={seller.shortId}
                    />
                </div>

                <h1 style={{ textAlign: "center", marginTop: "25px" }}>
                    {seller.sellerFirstName} {seller.sellerLastName}
                </h1>

                <ContentWrapper maxWidth="400px">
                    <h4 style={{ textAlign: "center", margin: "40px auto" }}>
                        {t("order_cancelled.body")}
                    </h4>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <Link
                            to={routes.seller(salesLinkCodeOrShortId).pathname}
                            onClick={() => this.trackGoBack()}
                            style={{ width: "100%", textAlign: "center" }}
                        >
                            <ThemeButton
                                theme={seller.theme}
                                style={{ width: "100%" }}
                            >
                                {t("order_cancelled.go_back_label")}
                            </ThemeButton>
                        </Link>
                    </div>
                </ContentWrapper>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(OrderCancelledPage))
);
