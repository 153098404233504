import styled from "styled-components";
import CategoryIcons from "../../../components/icons/categoryIcons";

export const SuperDrawCheckoutBed = styled.div`
    display: inline-block;
    text-align: center;
`;

export const FormHolder = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 480px;
`;

export const ButtonHolder = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
`;

export const Input = styled.input`
    padding: 12px;
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid #ccc;
    outline: none;
    font-size: 16px;
`;

export const FormLabel = styled.label`
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 5px;
    text-align: left;
`;

export const CheckboxLabel = styled.label`
    font-size: 16px;
    font-weight: normal;
    margin-top: 20px;
`;

export const PaymentCardIcon = styled.div.attrs((props) => ({
    style: { backgroundImage: `url(${CategoryIcons["CARD"]})` },
}))`
    -webkit-tap-highlight-color: transparent;
    position: relative;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    height: 25px;
    justify-content: center;
    width: 40px;
    display: inline-block;
`;
