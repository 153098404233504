import styled from "styled-components";

export const TicketHolder = styled.div.attrs((props) => ({
    style: { height: `${props.height}px` },
}))`
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    border-radius: 3px;
    box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.25);
    padding: 5px;
    background: white;

    @media only screen and (max-width: 767px) {
        padding: 0;
        box-shadow: none;
        max-width: 100%;
    }
`;

export const TicketBackgroundHolder = styled.div.attrs((props) => ({
    style: { height: `${props.height}px` },
}))`
    background-image: url("${(props) => props.bgImage}");
    background-size: cover;
    background-repeat: repeat;

    @media only screen and (max-width: 767px) {
        padding: 0;
        box-shadow: none;
        max-width: 100%;
    }
`;

export const TicketInnerHolder = styled.div.attrs((props) => ({
    style: { height: `${props.height}px` },
}))`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    user-select: none;
`;

export const TicketIconHolder = styled.div.attrs((props) => ({
    style: { animationDelay: `${props.entryDelay}s` },
}))`
    display: flex;
    width: 33%;
    justify-content: center;
    margin-bottom: 15px;
    opacity: 0;
`;

export const TicketTitleHolder = styled.div`
    text-align: center;
    z-index: 20;
    position: relative;
    pointer-events: none;
`;

export const TicketTitle = styled.h1`
    padding-top: 30px;
    padding-bottom: 8px;
    line-height: 1em;
    color: ${(props) => props.color};
    font-weight: 700;
    margin: 0;
    letter-spacing: 2px;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
        1px 1px 0 #fff;
`;

export const TicketSubtitle = styled.h3`
    line-height: 1em;
    color: ${(props) => props.color};
    margin: 0;
    letter-spacing: 2px;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
        1px 1px 0 #fff;
`;

export const NumberOfTickets = styled.div`
    position: absolute;
    letter-spacing: 1px;
    bottom: 18px;
    right: 0px;
    height: 35px;
    font-size: 1.2em;
    padding-right: 1em;
    color: ${(props) => props.color};
    font-weight: 700;
    margin: 0;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
        1px 1px 0 #fff;
    z-index: 20;
`;

export const RevealAllButton = styled.button.attrs((props) => ({
    style: {
        opacity: props.introAnimationComplete ? 1 : 0,
    },
}))`
    position: relative;
    cursor: pointer;
    display: block;
    transition: all 0.5s;
    border-radius: 3px;
    border: 2px solid ${(props) => props.buttonStrokeColor};
    background-color: ${(props) => props.buttonColor};
    color: ${(props) => props.buttonTextColor};
    font-size: 16px;
    padding: 8px 12px;
    font-weight: bold;
    pointer-events: auto;
    width: 120px;
`;

export const TicketPriceInfoHolder = styled(TicketTitleHolder).attrs(
    (props) => ({
        style: { height: `${props.height}px` },
    })
)`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    bottom: 18px;
    width: 100%;
    text-align: center;
    z-index: 20;

    h3 {
        @media only screen and (max-device-width: 640px),
            only screen and (max-device-width: 667px),
            only screen and (max-width: 480px) {
            margin-bottom: 0px;
        }
    }
`;

export const TicketIcon = styled.div`
    width: 82px;
    height: 105px;
    background-size: cover;
    cursor: pointer;
`;

export const TicketInfoButton = styled.button`
    border-radius: 100%;
    background-color: transparent;
    background-image: url("${(props) => props.src}");
    border: 0px;
    outline: none;
    background-size: cover;
    width: 38px;
    height: 38px;
    position: absolute;
    display: block;
    bottom: 20px;
    left: 20px;
    z-index: 20;
`;

export const CanvasWrapper = styled.div.attrs((props) => ({
    style: {
        width: `${props.width}px`,
        height: `${props.height}px`,
    },
}))`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 330px;
    margin-top: 0px;
    justify-content: center;
`;

export const Canvas = styled.canvas.attrs((props) => ({
    style: {
        width: `${props.width}px`,
        height: `${props.height}px`,
    },
}))`
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
`;
