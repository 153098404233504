// Allows injecting of environmental variables at runtime
const _window = window;
const { _env_ } = _window;
export const {
    BASE_URL,
    API_URL,
    REACT_APP_HOST_DIR,
    REACT_APP_API_URL,
    REACT_APP_STATIC_CONTENT,
    REACT_APP_VERSION,
    ENVIRONMENT,
    MIXPANEL_TOKEN,
} = _env_;
