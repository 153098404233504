import styled from "styled-components";

export const Input = styled.input`
    font-size: 16px;
    padding: 8px;
    background: #fcfcfc;
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    width: 100%;
    box-sizing: border-box;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
`;
