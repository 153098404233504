import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-responsive-modal";
import { withTranslation } from "react-i18next";

import { PurchaseButton } from "../../../components/button/Buttons";
import { isValidShoppingFrom } from "../../../utils/validationHelpers";
import SpondImage from "../../../components/spond-image/SpondImage";
import { apiClient } from "../../../api/ApiClient";
import { routes } from "../../../app/routes";

const Holder = styled.form`
    padding: 15px;
    color: #393939;
    max-width: 100%;
    box-sizing: border-box;
    position: relative;
`;

const Card = styled.div`
    @media only screen and (min-width: 400px) {
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        max-width: 640px;
        margin: 15px auto;
        background-color: white;
        box-shadow: 0px 6px 16px 12px rgba(21, 0, 0, 0.07);
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
    }

    h2 {
        font-size: 18px;
        margin-bottom: 10px;
        text-align: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #e4e4e4;
    }
`;

const FormLabel = styled.label`
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
`;

const Input = styled.input`
    font-size: 16px;
    padding: 8px;
    background: #fcfcfc;
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    width: 100%;
    box-sizing: border-box;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
`;

const FormGroup = styled.div`
    margin: 15px 0;
`;

const PrizeGridHolder = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 480px;
    margin: 30px auto;
    justify-content: center;
    box-sizing: border-box;
`;

const PrizeHolder = styled.div`
    width: 50%;
    text-align: center;
    box-sizing: border-box;

    &:nth-child(odd) {
        padding-right: 10px;
    }

    &:nth-child(even) {
        padding-left: 10px;
    }
`;

class ScratchcardPrize extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            form: {
                variation: "",
                name: "",
                email: "",
                phoneNumber: "",
                address1: "",
                postCode: "",
                city: "",
            },
            showConfirmationModal: false,
        };
    }

    componentDidMount = () => {
        const {
            orderId,
            seller: { shortId, campaignId },
            selectedPrizeId,
            scratched,
        } = this.props;
        apiClient
            .getProducts(campaignId)
            .then((res) => {
                const products = res.data.products;
                const deliveryTime = res.data.deliveryTime;
                const { form } = this.state;

                if (selectedPrizeId)
                    this.selectPrize(selectedPrizeId, products, form);

                this.setState({
                    products: products,
                    deliveryTime,
                });
            })
            .catch((err) => {
                console.error("Error while retrieving products", err);
            });

        apiClient.manuallyHandle
            .orderStatusCheck(shortId, orderId)
            .then((res) => {
                const tickets = res.data.tickets;
                this.setState({
                    hasMoreTickets:
                        tickets.filter((t) => !t.revealed).length > 0,
                    ready: true,
                });
            })
            .catch((err) => {
                console.error("Error while retrieving scratchcards", err);
                this.setState({
                    ready: true,
                    hasMoreTickets: false,
                });
            });
    };

    selectPrize = (selectedPrizeId, products, form) => {
        const selected = products.find((p) => p.id === selectedPrizeId);
        if (selected.variations) {
            form.variation = selected.variations[0].id;
        }
        this.setState({
            selectedPrize: selected,
            form,
        });
    };

    onFormChange = (e) => {
        const { value } = e.target;
        const { form } = this.state;

        form[e.target.getAttribute("name")] = value;

        this.setState({
            form,
        });
    };

    onSubmitPrizeSelection = (e) => {
        const {
            orderId,
            scratchcardId,
            updateKey,
            seller: { shortId, countryCode },
            t,
        } = this.props;
        const { form, selectedPrize } = this.state;

        if (
            !!!form.phoneNumber ||
            !!!form.name ||
            !!!form.email ||
            !!!form.address1 ||
            !!!form.postCode ||
            !!!form.city ||
            !isValidShoppingFrom(
                countryCode,
                form.phoneNumber,
                form.email,
                form.postCode,
                t
            )
        ) {
            return;
        }
        e.preventDefault();

        // Repeated call: lock
        if (this.justSelectedLotteryPrize) return;
        this.justSelectedLotteryPrize = true;

        setTimeout(() => {
            // Repeated call: release
            this.justSelectedLotteryPrize = false;
        }, 10000);

        // Request guard: lock
        if (this.requestingLotteryPrize) return;
        this.requestingLotteryPrize = true;

        apiClient
            .selectLotteryPrize(shortId, orderId, scratchcardId, {
                name: form.name,
                email: form.email,
                phoneNumber: form.phoneNumber,
                address: {
                    address1: form.address1,
                    postCode: form.postCode,
                    city: form.city,
                },
                prizeSelection: {
                    productId: selectedPrize.id,
                    variantId: selectedPrize.variations
                        ? this.state.form.variation
                        : "",
                },
            })
            .then(() => this.setState({ showConfirmationModal: true }))
            .catch((err) => {
                console.error(
                    `Error while submitting prize selection for scratchcard ${scratchcardId}`
                );
                toast("En feil oppstod under innsending av skjema", {
                    type: "error",
                });
            })
            .finally(() => {
                // Request guard: release
                this.requestingLotteryPrize = false;
            });
    };

    onPrizeClicked = (product) => {
        const {
            history,
            seller: { shortId },
            orderId,
            scratchcardId,
            scratched,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { products, form } = this.state;
        this.selectPrize(product.id, products, form);
        history.push(
            routes
                .seller(salesLinkCodeOrShortId)
                .scratchcards.order(orderId)
                .scratchcard(scratchcardId).prizeForm +
                `?selectedPrizeId=${product.id}${
                    scratched ? "&scratched=t" : ""
                }`
        );
    };

    onConfirmationDismissed = () => {
        const {
            orderId,
            history,
            scratched,

            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        // Let the page know that we've been scratching scratchcards this session
        // to customize messaging to the customer
        history.push(
            routes.seller(salesLinkCodeOrShortId).scratchcards.order(orderId)
                .pathname + `${scratched ? "?scratched=t" : ""}`
        );
    };

    render() {
        const {
            selectedPrize,
            form,
            ready,
            hasMoreTickets,
            showConfirmationModal,
            deliveryTime,
        } = this.state;

        const {
            t,
            seller,
            orderId,
            scratchcardId,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;

        if (!ready) {
            return null;
        }

        if (selectedPrize) {
            const { preview, name, description } = selectedPrize;

            return (
                <Holder>
                    <h1 style={{ textAlign: "center" }}>{name}</h1>
                    <PrizeHolder style={{ margin: "15px auto", width: "100%" }}>
                        <SpondImage
                            imageUrl={preview}
                            style={{
                                maxWidth: "180px",
                                boxShadow: "0px 3px 11px rgba(0, 0, 0, 0.25)",
                            }}
                        />
                        <p
                            style={{
                                fontSize: "16px",
                                marginTop: "5px",
                                textAlign: "center",
                                marginBottom: "30px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                maxWidth: 650,
                                lineHeight: 1.5,
                            }}
                        >
                            {description}
                            <br />
                            <Link
                                to={
                                    routes
                                        .seller(salesLinkCodeOrShortId)
                                        .scratchcards.order(orderId)
                                        .scratchcard(scratchcardId).selectPrize
                                }
                            >
                                <span
                                    style={{
                                        fontSize: "16px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        this.setState({ selectedPrize: null })
                                    }
                                >
                                    {t("prize.choose_another_prize")}
                                </span>
                            </Link>
                        </p>
                    </PrizeHolder>

                    <p style={{ textAlign: "center", marginBottom: "30px" }}>
                        {deliveryTime
                            ? deliveryTime
                            : t("prize.chosen_description")}
                    </p>

                    <Card>
                        <h2
                            style={{
                                textAlign: "center",
                                marginBottom: "15px",
                                paddingBottom: "15px",
                            }}
                        >
                            Leveringsinformasjon
                        </h2>
                        {selectedPrize.variations && (
                            <FormGroup>
                                <FormLabel>Størrelse</FormLabel>
                                <select
                                    name="variation"
                                    value={form.variation}
                                    onChange={(e) => this.onFormChange(e)}
                                    style={{
                                        width: "100%",
                                        padding: "8px",
                                        fontSize: "16px",
                                    }}
                                >
                                    {selectedPrize.variations.map((v) => (
                                        <option key={v.id} value={v.id}>
                                            {v.name}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>
                        )}
                        <FormGroup>
                            <FormLabel>{t("general.your_name")}</FormLabel>
                            <Input
                                required
                                type="text"
                                name="name"
                                value={form.name}
                                onChange={(e) => this.onFormChange(e)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>{t("general.street_address")}</FormLabel>
                            <Input
                                required
                                type="text"
                                name="address1"
                                placeholder={t(
                                    "general.street_address_placeholder"
                                )}
                                value={form.address1}
                                onChange={(e) => this.onFormChange(e)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>{t("general.post_code")}</FormLabel>
                            <Input
                                required
                                type="text"
                                name="postCode"
                                placeholder={t("general.post_code_placeholder")}
                                maxLength="4"
                                value={form.postCode}
                                onChange={(e) => this.onFormChange(e)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>{t("general.city")}</FormLabel>
                            <Input
                                required
                                type="text"
                                name="city"
                                placeholder={t("general.city_placeholder")}
                                value={form.city}
                                onChange={(e) => this.onFormChange(e)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>{t("general.email")}</FormLabel>
                            <Input
                                required
                                type="text"
                                name="email"
                                value={form.email}
                                onChange={(e) => this.onFormChange(e)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>{t("general.phoneNumber")}</FormLabel>
                            <Input
                                required
                                type="text"
                                name="phoneNumber"
                                value={form.phoneNumber}
                                onChange={(e) => this.onFormChange(e)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <div
                                style={{
                                    width: "100%",
                                    textAlign: "center",
                                    marginTop: "30px",
                                }}
                            >
                                <PurchaseButton
                                    theme={seller.theme}
                                    onClick={this.onSubmitPrizeSelection}
                                >
                                    {hasMoreTickets && (
                                        <span id="send_prize_button">
                                            {t("actions.send_and_scratch_next")}
                                        </span>
                                    )}
                                    {!hasMoreTickets && (
                                        <span id="send_prize_button">
                                            {t("actions.send")}
                                        </span>
                                    )}
                                </PurchaseButton>
                            </div>
                        </FormGroup>
                    </Card>

                    <Modal
                        center={true}
                        open={showConfirmationModal}
                        showCloseIcon={false}
                        onClose={() => {}}
                        classNames={{ modal: "action-modal" }}
                    >
                        <h1 style={{ textAlign: "center" }}>
                            {t("prize.address_sent")}
                        </h1>
                        <p style={{ textAlign: "center" }}>
                            {deliveryTime
                                ? deliveryTime
                                : t("prize.order_description")}
                        </p>
                        <PurchaseButton
                            theme={seller.theme}
                            onClick={() => this.onConfirmationDismissed()}
                        >
                            {hasMoreTickets && (
                                <span id="ok_button">
                                    {t("actions.scratch_next")}
                                </span>
                            )}
                            {!hasMoreTickets && <span id="ok_button">OK</span>}
                        </PurchaseButton>
                    </Modal>
                </Holder>
            );
        }

        return (
            <Holder>
                <h1 style={{ textAlign: "center" }}>
                    {t("prize.choose_prize")}
                </h1>
                <p
                    style={{
                        textAlign: "center",
                        maxWidth: "420px",
                        margin: "15px auto",
                    }}
                >
                    {t("prize.choose_description")}
                </p>

                <PrizeGridHolder>
                    {this.state.products.map((product, idx) => (
                        <PrizeHolder
                            id="prize-item"
                            key={product.id}
                            onClick={() => this.onPrizeClicked(product)}
                        >
                            <SpondImage
                                imageUrl={product.preview}
                                style={{
                                    maxWidth: "100%",
                                    boxShadow:
                                        "0px 3px 11px rgba(0, 0, 0, 0.25)",
                                }}
                            />
                            <p
                                style={{
                                    marginTop: "5px",
                                    textAlign: "center",
                                    marginBottom: "30px",
                                }}
                            >
                                {product.name}
                            </p>
                        </PrizeHolder>
                    ))}
                </PrizeGridHolder>
            </Holder>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(ScratchcardPrize))
);
