import React, { useEffect, useRef, useState } from "react";
import Layout from "../../../components/cake-lottery/layout/Layout";
import Button from "../../../components/cake-lottery/button/Button";
import * as styles from "./CakeSummaryPage.module.scss";
import ProfilePicture from "../../../components/cake-lottery/profile-picture/ProfilePicture";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import ReactConfetti from "react-confetti";
import Confetti from "../../../components/cake-lottery/confetti/Confetti";
import GlassContainer from "../../../components/cake-lottery/glass-container/GlassContainer";
import { getShareLink } from "../../../utils/share";
import {
    getShareEmailBody,
    getShareEmailSubject,
} from "../../../utils/strings/strings";
import { routes } from "../../../app/routes";

const CakeSummaryPage = ({ t, seller }) => {
    const [containerSize, setContainerSize] = useState({});
    const containerEl = useRef(null);
    const { salesLinkCodeOrShortId } = useParams();
    useEffect(() => {
        setContainerSize({
            width: containerEl.current?.getBoundingClientRect().width,
            height: containerEl.current?.getBoundingClientRect().height,
        });
    }, []);

    const shareOnFacebook = () => {
        if (window.FB) {
            window.FB.ui(
                {
                    method: "share",
                    href: getShareLink(salesLinkCodeOrShortId),
                },
                function (response) {}
            );
        }
    };

    const handleShareSalesLink = () => {
        const { groupName } = seller;
        const sellerName = seller
            ? seller.sellerFirstName +
              (seller.sellerLastName ? " " + seller.sellerLastName : "")
            : "";

        const shareDescription = !!sellerName
            ? t("general.share_title", {
                  sellerName: sellerName,
                  groupName: groupName,
              })
            : t("general.share_title_organisation_seller", {
                  groupName: groupName,
              });
        try {
            navigator
                .share({
                    text: shareDescription,
                    title: shareDescription,
                    url: getShareLink(salesLinkCodeOrShortId),
                })
                .then(() => {});
        } catch (err) {}
    };

    const { theme, sellerName, groupName } = seller;
    const shareLink = getShareLink(salesLinkCodeOrShortId);

    return (
        <Layout className={styles.summaryPage} hasCake={true} theme={theme}>
            <Confetti position="top" margin="30" />
            <ReactConfetti
                numberOfPieces={50}
                recycle={false}
                confettiSource={{ x: containerSize.width / 2, y: 350 }}
                width={containerSize.width}
                height={containerSize.height}
            />
            <div className={styles.outerContainer}>
                <GlassContainer className={styles.summaryContainer}>
                    <div className={styles.avatarContainer}>
                        <Link
                            to={routes.seller(salesLinkCodeOrShortId).pathname}
                        >
                            <ProfilePicture imageUrl={seller?.sellerImage} />
                        </Link>
                    </div>
                    <div className={styles.container} ref={containerEl}>
                        <h1>{t("no_tickets.title")}</h1>
                        <p>{t("raffle.thank_you_for_contribution")}</p>
                    </div>
                    <div className={styles.buttonContainer}>
                        {navigator.share && (
                            <div className={styles.buttonWrapper}>
                                <Button
                                    theme={theme}
                                    onClick={() => handleShareSalesLink()}
                                >
                                    {t("order_successful.share_with_others")}
                                </Button>
                            </div>
                        )}
                        {!navigator.share && (
                            <>
                                <div className={styles.buttonWrapper}>
                                    <Button
                                        theme={seller.theme}
                                        onClick={() => shareOnFacebook()}
                                    >
                                        {t("order_successful.share_facebook")}
                                    </Button>
                                </div>
                                <div className={styles.buttonWrapper}>
                                    <a
                                        href={`mailto:?subject=${getShareEmailSubject(
                                            t,
                                            sellerName,
                                            groupName
                                        )}&body=${getShareEmailBody(
                                            t,
                                            seller.description,
                                            getShareLink(salesLinkCodeOrShortId)
                                        )}`}
                                    >
                                        <Button
                                            className={styles.button}
                                            theme={seller.theme}
                                        >
                                            {t("order_successful.share_email")}
                                        </Button>
                                    </a>
                                </div>
                            </>
                        )}
                        <div className={styles.buttonWrapper}>
                            <Button
                                theme={theme}
                                className={styles.button}
                                onClick={() => {
                                    navigator.clipboard.writeText(shareLink);
                                    toast(t("toast.clipboard_copy"), {
                                        type: "info",
                                    });
                                }}
                            >
                                {t("general.copy_link")}
                            </Button>
                        </div>
                    </div>
                </GlassContainer>
            </div>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default connect(mapStateToProps)(CakeSummaryPage);
