export const getShareEmailBody = (t, description, shareLink) => {
    return t("order_successful.email_body", { description, shareLink });
};

export const getShareEmailSubject = (t, sellerName, groupName) => {
    return !!sellerName
        ? t("order_successful.email_subject", { sellerName, groupName })
        : t("order_successful.email_subject_organisation_seller", {
              groupName,
          });
};

export const getSellerDisplayName = (seller) => {
    return seller && seller.sellerFirstName
        ? seller.sellerFirstName +
              (seller.sellerLastName ? " " + seller.sellerLastName : "")
        : "";
};
