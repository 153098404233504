import { Component } from "react";
import { Helmet } from "react-helmet";

import { CurrencyFormatter } from "../../../utils/Currency";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
    PurchaseButton,
    PurchaseButtonThin,
} from "../../../components/button/Buttons";
import { ORDER_STATUS } from "../../../data/consts";
import { getShareLink, proceedNavigateShare } from "../../../utils/share";
import {
    getSellerDisplayName,
    getShareEmailBody,
    getShareEmailSubject,
} from "../../../utils/strings/strings";
import { trackEvent } from "../../../EventTracker";
import PreCheckoutModal from "../pre-checkout/PreCheckoutModal.container";
import DOMPurify from "dompurify";
import { routes } from "../../../app/routes";
import Avatar from "../../../components/avatar/Avatar";
import React from "react";
import {
    NoteActionRow,
    NoteBed,
    NoteInnerBed,
    NoteRow,
} from "./ScratchcardNoTickets.container.styled";
import { Seller, Ticket } from "../../../api/types";

type Props = {
    t: any;
    seller: Seller;
    orderId: string;
    match: {
        params: {
            salesLinkCodeOrShortId: string;
        };
    };
    hasScratched: boolean;
    orderStatus: unknown;
    allTickets?: Array<Ticket>;
} & RouteComponentProps;

class ScratchcardNoTickets extends Component<Props> {
    state = {
        showPreCheckoutModal: false,
        ticketCount: undefined as number | undefined,
    };

    onFacebookShareClicked() {
        const {
            seller,
            orderId,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;

        if ((window as any).FB) {
            (window as any).FB.ui(
                {
                    method: "share",
                    href: getShareLink(salesLinkCodeOrShortId),
                },
                function () {}
            );
        }

        trackEvent("SHARE_FACEBOOK_ORDER_COMPLETED", {
            shortId: seller.shortId,
            orgDisplayName: getSellerDisplayName(seller),
            groupId: seller.groupId,
            orderId: orderId,
        });
    }

    onNavigatorShareClicked = () => {
        const {
            seller,
            orderId,
            t,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        proceedNavigateShare({
            seller,
            t,
            orderId,
            salesLinkCodeOrShortId,
        });
    };

    claimTicketPrize = (ticket: { id: string }) => {
        const {
            history,
            orderId,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        history.push(
            routes
                .seller(salesLinkCodeOrShortId)
                .scratchcards.order(orderId)
                .scratchcard(ticket.id).selectPrize
        );
    };

    onBuyClicked = (ticketCount: number) => {
        this.setState({ showPreCheckoutModal: true, ticketCount });
    };

    render() {
        const {
            seller,
            seller: { campaignStatus, groupName, sellerImage, description },
            t,
            hasScratched,
            orderStatus,
            allTickets,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { ticketCount, showPreCheckoutModal } = this.state;

        const sellerDisplayName = getSellerDisplayName(seller);

        const hasSellerImage = !!sellerImage;
        const refunded = orderStatus == ORDER_STATUS.REFUNDED;
        const ticketsPrizeToBeClaimed =
            allTickets?.filter(
                (t) => t.hasPrize && t.revealed && !!!t.prizeClaimed
            ) ?? [];
        const showNote =
            !!!hasScratched ||
            refunded ||
            (ticketsPrizeToBeClaimed && ticketsPrizeToBeClaimed.length > 0);
        const hasEnded = campaignStatus !== "ACTIVE";

        // Scratchcard sellers, for now, will only have 1 product (the scratchcard)
        return (
            <>
                <Helmet>
                    <style type="text/css">
                        {`
                            .container {
                                min-height: auto !important;
                            }
                        `}
                    </style>
                </Helmet>
                <PreCheckoutModal
                    open={showPreCheckoutModal}
                    t={t}
                    ticketCount={ticketCount}
                    seller={seller}
                />
                <div className="page-wrapper">
                    <div className="row" style={{ padding: "30px" }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Avatar imageUrl={sellerImage} />
                        </div>
                        {showNote && (
                            <NoteBed hasSellerImage={hasSellerImage}>
                                {!refunded && (
                                    <NoteInnerBed>
                                        <NoteRow>
                                            {t(
                                                "order_successful.already_scratched"
                                            )}
                                        </NoteRow>

                                        {ticketsPrizeToBeClaimed &&
                                            ticketsPrizeToBeClaimed.length >
                                                0 && (
                                                <>
                                                    <NoteRow>
                                                        {t(
                                                            "order_successful.you_won",
                                                            {
                                                                count: ticketsPrizeToBeClaimed.length,
                                                            }
                                                        )}
                                                    </NoteRow>
                                                    {ticketsPrizeToBeClaimed.map(
                                                        (ticket) => (
                                                            <NoteActionRow
                                                                key={ticket.id}
                                                                theme={
                                                                    seller.theme
                                                                }
                                                                onClick={() =>
                                                                    this.claimTicketPrize(
                                                                        ticket
                                                                    )
                                                                }
                                                            >
                                                                {t(
                                                                    "actions.pick_prize"
                                                                )}
                                                            </NoteActionRow>
                                                        )
                                                    )}
                                                </>
                                            )}
                                    </NoteInnerBed>
                                )}

                                {refunded && (
                                    <NoteInnerBed>
                                        <NoteRow
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    "order_successful.refunded"
                                                ),
                                            }}
                                        />
                                    </NoteInnerBed>
                                )}
                            </NoteBed>
                        )}

                        <h1
                            style={{
                                textAlign: "center",
                                marginTop: "30px",
                                marginBottom: "10px",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: !refunded
                                    ? t("no_tickets.title")
                                    : !!sellerDisplayName
                                    ? t("landing.title", {
                                          firstName: DOMPurify.sanitize(
                                              sellerDisplayName,
                                              {
                                                  USE_PROFILES: { html: false },
                                              }
                                          ),
                                          groupName:
                                              "<span style=display:inline-block>" +
                                              DOMPurify.sanitize(groupName, {
                                                  USE_PROFILES: { html: false },
                                              }) +
                                              "</span>",
                                      })
                                    : t("title_organisation_seller", {
                                          groupName:
                                              "<span style=display:inline-block>" +
                                              DOMPurify.sanitize(groupName, {
                                                  USE_PROFILES: { html: false },
                                              }) +
                                              "</span>",
                                      }),
                            }}
                        />
                        <p
                            style={{
                                textAlign: "center",
                                marginTop: "0",
                                paddingBottom: "35px",
                                maxWidth: "220px",
                                margin: "0 auto",
                                boxSizing: "border-box",
                            }}
                        >
                            {!!refunded
                                ? description
                                : !!sellerDisplayName
                                ? t("no_tickets.description", {
                                      groupName: groupName,
                                      sellerName: sellerDisplayName,
                                  })
                                : t(
                                      "no_tickets.organisation_seller_description",
                                      {
                                          groupName: groupName,
                                      }
                                  )}
                        </p>

                        {!hasEnded && (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginBottom: "15px",
                                    }}
                                >
                                    <PurchaseButton
                                        theme={seller.theme}
                                        onClick={() => this.onBuyClicked(5)}
                                    >
                                        {t("actions.purchase_ticket", {
                                            count: 5,
                                            amount: CurrencyFormatter(
                                                t,
                                                seller,
                                                seller.scratchcardPrice * 5
                                            ),
                                        })}
                                    </PurchaseButton>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginBottom: "15px",
                                    }}
                                >
                                    <PurchaseButton
                                        theme={seller.theme}
                                        onClick={() => this.onBuyClicked(3)}
                                    >
                                        {t("actions.purchase_ticket", {
                                            count: 3,
                                            amount: CurrencyFormatter(
                                                t,
                                                seller,
                                                seller.scratchcardPrice * 3
                                            ),
                                        })}
                                    </PurchaseButton>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginBottom: "15px",
                                    }}
                                >
                                    <PurchaseButton
                                        theme={seller.theme}
                                        onClick={() => this.onBuyClicked(1)}
                                    >
                                        {t("actions.purchase_ticket", {
                                            count: 1,
                                            amount: CurrencyFormatter(
                                                t,
                                                seller,
                                                seller.scratchcardPrice * 1
                                            ),
                                        })}
                                    </PurchaseButton>
                                </div>
                                {(navigator as any).share && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <PurchaseButtonThin
                                            theme={seller.theme}
                                            onClick={() =>
                                                this.onNavigatorShareClicked()
                                            }
                                        >
                                            Del med andre
                                        </PurchaseButtonThin>
                                    </div>
                                )}
                                {!navigator.share && (
                                    <>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginBottom: "15px",
                                            }}
                                        >
                                            <PurchaseButtonThin
                                                theme={seller.theme}
                                                onClick={() =>
                                                    this.onFacebookShareClicked()
                                                }
                                            >
                                                {t(
                                                    "order_successful.share_facebook"
                                                )}
                                            </PurchaseButtonThin>
                                        </div>

                                        <a
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                width: "100%",
                                            }}
                                            href={`mailto:?subject=${getShareEmailSubject(
                                                t,
                                                sellerDisplayName,
                                                groupName
                                            )}&body=${getShareEmailBody(
                                                t,
                                                description,
                                                getShareLink(
                                                    salesLinkCodeOrShortId
                                                )
                                            )}`}
                                        >
                                            <PurchaseButtonThin
                                                theme={seller.theme}
                                                onClick={null}
                                            >
                                                {t(
                                                    "order_successful.share_email"
                                                )}
                                            </PurchaseButtonThin>
                                        </a>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(ScratchcardNoTickets);
