import React, { Component } from "react";
import { Canvas, CanvasWrapper } from "../scratchcards/Scratchcard.styled";
import {
    Scratchable,
    TicketSymbol,
    Transitions,
} from "../scratchcards/TicketObject";

class TicketCanvas extends Component {
    constructor(props) {
        super(props);

        this.SPRITE_HEIGHT = null;
        this.SPRITE_WIDTH = null;
        this.SPRITE_PADDING = 10;
        this.EXTRA_TOP_PADDING = 15;

        this.isDrawing = false;
        this.lastPoint = null;
        this.touchStart = this.touchStart.bind(this);
        this.touchMove = this.touchMove.bind(this);
        this.touchEnd = this.touchEnd.bind(this);
        this.eggRef = null;
        this.scratchables = [];
        this.ticketSymbols = [];
        this.retinaRatio = 2.5;

        this.brush = new Image();
        this.brush.src = require("../../assets/brush.png");

        this.width = this.props.width;
        this.height = this.props.height;

        this.initTickets();
    }

    componentDidMount() {
        this.SPRITE_HEIGHT = window.innerWidth / 3.7;
        this.SPRITE_WIDTH = window.innerWidth / 3.7;

        if (this.SPRITE_WIDTH > 110) {
            this.SPRITE_HEIGHT = 110;
            this.SPRITE_WIDTH = 110;
        } else if (this.SPRITE_WIDTH < 90) {
            this.SPRITE_HEIGHT = 90;
            this.SPRITE_WIDTH = 90;
        }

        const scratchableCanvas = this.scratchableCanvas;
        const symbolCanvas = this.symbolCanvas;
        scratchableCanvas.width = this.props.width * this.retinaRatio;
        scratchableCanvas.height = this.props.height * this.retinaRatio;

        symbolCanvas.width = this.props.width * this.retinaRatio;
        symbolCanvas.height = this.props.height * this.retinaRatio;

        scratchableCanvas.addEventListener("mousedown", this.touchStart);
        scratchableCanvas.addEventListener("touchstart", this.touchStart);
        scratchableCanvas.addEventListener("mousemove", this.touchMove);
        scratchableCanvas.addEventListener("touchmove", this.touchMove);
        scratchableCanvas.addEventListener("mouseup", this.touchEnd);
        scratchableCanvas.addEventListener("touchend", this.touchEnd);

        this.scratchableContext = scratchableCanvas.getContext("2d");
        this.scratchableContext.scale(this.retinaRatio, this.retinaRatio);
        this.symbolContext = symbolCanvas.getContext("2d");
        this.symbolContext.scale(this.retinaRatio, this.retinaRatio);
    }

    componentDidUpdate(prevProps) {
        const { ticket: preTicket } = prevProps;
        const { ticket } = this.props;

        if (preTicket.id !== ticket.id) {
            this.clearCanvases();
            this.initTickets();
        }
    }

    componentWillUnmount() {
        const canvas = this.scratchableCanvas;
        canvas.removeEventListener("touchstart", this.touchStart);
        canvas.removeEventListener("mousedown", this.touchStart);
        canvas.removeEventListener("mousemove", this.touchMove);
        canvas.removeEventListener("touchmove", this.touchMove);
        canvas.removeEventListener("mouseup", this.touchEnd);
        canvas.removeEventListener("touchend", this.touchEnd);
    }

    getPositionFromIndex = (index) => {
        const canvas = this.scratchableCanvas;
        const canvasWidth = canvas.width / this.retinaRatio;
        const canvasHeight = canvas.height / this.retinaRatio;

        const areaWidth = this.SPRITE_WIDTH * 3 + this.SPRITE_PADDING * 2;
        const areaHeight = this.SPRITE_HEIGHT * 3 + this.SPRITE_PADDING * 2;
        const areaX = (canvasWidth - areaWidth) / 2;
        const areaY = (canvasHeight - areaHeight) / 2 + this.EXTRA_TOP_PADDING;

        const row = Math.floor(index / 3);
        const col = index - row * 3;
        const x = Math.round((areaWidth / 3) * col + areaWidth / 3 / 2) + areaX;
        const y =
            Math.round((areaHeight / 3) * row + areaHeight / 3 / 2) + areaY;

        return { x, y };
    };

    initTickets = () => {
        const { seller, ticket } = this.props;
        const spritePromises = [];

        this.symbolLibrary = ticket.fieldIcons.map((icon, index) => {
            const scratchableSprite = new Image();
            const singlePromise = new Promise((resolve, reject) => {
                scratchableSprite.onload = () => {
                    resolve();
                };
                scratchableSprite.src = icon;
            });
            spritePromises.push(singlePromise);
            return scratchableSprite;
        });

        const scratchablePromise = new Promise((resolve, reject) => {
            this.scratchableSprite = new Image();

            this.scratchableSprite.onload = () => {
                resolve();
            };
            this.scratchableSprite.src = seller.theme.scratchImage;
        });

        spritePromises.push(scratchablePromise);
        Promise.all(spritePromises).then(() => {
            const { ticketNumbers } = this.props;
            this.ticketSymbols = [];
            this.scratchables = [];
            this.clearCanvases();

            for (let t = 0; t < 9; t++) {
                const position = this.getPositionFromIndex(t);
                const size = {
                    width: this.SPRITE_WIDTH,
                    height: this.SPRITE_HEIGHT,
                };
                const scratchable = new Scratchable(t, {
                    position,
                    size,
                    opacity: 0,
                });
                scratchable.startTransition(Transitions.IN, t * 50);

                this.scratchables.push(scratchable);
            }

            for (let t = 0; t < 9; t++) {
                const position = this.getPositionFromIndex(t);
                const size = {
                    width: this.SPRITE_WIDTH,
                    height: this.SPRITE_HEIGHT,
                };
                const ticketNumber = ticketNumbers[t];
                const symbol = new TicketSymbol(
                    t,
                    { position, size, opacity: 1 },
                    ticketNumber
                );
                this.ticketSymbols.push(symbol);
            }

            this.drawScratchables();
            // Wait for animation to finish before drawing symbols to canvas underneath.
            setTimeout(this.drawSymbols.bind(this), 1500);
        });
    };

    clearCanvases = () => {
        this.scratchableContext.globalAlpha = 1;
        this.scratchableContext.clearRect(
            0,
            0,
            this.scratchableCanvas.width,
            this.scratchableCanvas.width
        );
        this.symbolContext.globalAlpha = 1;
        this.symbolContext.clearRect(
            0,
            0,
            this.symbolCanvas.width,
            this.symbolCanvas.height
        );
    };

    drawScratchables() {
        let isSomeInTransition = false;

        this.scratchables.forEach((scratchable) => {
            const pixelPosition = {
                x: scratchable.position.x - scratchable.currentSize.width / 2,
                y: scratchable.position.y - scratchable.currentSize.height / 2,
            };

            if (scratchable.transition === Transitions.OUT) {
                if (!scratchable.isDelaying) {
                    this.scratchableContext.globalAlpha = 0.07;
                    this.scratchableContext.globalCompositeOperation =
                        "destination-out";
                    this.scratchableContext.fillStyle = "blue";
                    this.scratchableContext.fillRect(
                        pixelPosition.x,
                        pixelPosition.y,
                        scratchable.currentSize.width,
                        scratchable.currentSize.height
                    );
                }
            } else {
                this.scratchableContext.clearRect(
                    pixelPosition.x,
                    pixelPosition.y,
                    scratchable.currentSize.width,
                    scratchable.currentSize.height
                );
                this.scratchableContext.globalAlpha = scratchable.opacity;
                this.scratchableContext.globalCompositeOperation =
                    "source-over";
                this.scratchableContext.drawImage(
                    this.scratchableSprite,
                    pixelPosition.x,
                    pixelPosition.y,
                    scratchable.currentSize.width,
                    scratchable.currentSize.height
                );
            }

            if (scratchable.transition !== Transitions.NONE) {
                isSomeInTransition = true;
            }
            scratchable.tick();
        });

        if (isSomeInTransition) {
            setTimeout(this.drawScratchables.bind(this), 16);
        }
    }

    drawSymbols = () => {
        if (!this.scratchableCanvas) {
            return;
        }
        const { introCompleteCallback } = this.props;
        const { ticketNumbers } = this.props;
        this.symbolContext.clearRect(
            0,
            0,
            this.scratchableCanvas.width,
            this.scratchableCanvas.height
        );

        let isSomeInTransition = false;

        this.ticketSymbols.forEach((symbol, index) => {
            const pixelPosition = {
                x: symbol.position.x - symbol.currentSize.width / 2,
                y: symbol.position.y - symbol.currentSize.height / 2,
            };

            this.symbolContext.globalAlpha = symbol.opacity;
            this.symbolContext.drawImage(
                this.symbolLibrary[index],
                pixelPosition.x,
                pixelPosition.y,
                symbol.currentSize.width,
                symbol.currentSize.height
            );

            if (symbol.transition !== Transitions.NONE) {
                isSomeInTransition = true;
            }
            symbol.tick();
        });

        if (isSomeInTransition) {
            setTimeout(this.drawSymbols.bind(this), 16);
        } else {
            introCompleteCallback();
        }
    };

    getPosition = (event) => {
        const canvasBounds = this.scratchableCanvas.getBoundingClientRect();

        const scroll = window.scrollY || 0;

        const touchX =
            event.touches && event.touches.length > 0
                ? event.touches[0].clientX
                : 0;
        const touchY =
            event.touches && event.touches.length > 0
                ? event.touches[0].clientY
                : 0;

        const x = (event.pageX || touchX) - canvasBounds.x;
        const y = (event.pageY || touchY) - canvasBounds.y - scroll;
        return { x, y };
    };

    revealAll = () => {
        this.touchEnd();
        const delayArray = new Array(9)
            .fill(null)
            .map((value, index) => index * 50);
        for (let i = delayArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [delayArray[i], delayArray[j]] = [delayArray[j], delayArray[i]];
        }
        this.scratchables.forEach((scratchable, index) => {
            scratchable.startTransition(Transitions.OUT, delayArray[index]);
        });
        this.drawScratchables();
    };

    highlightWinner = (winnerNumber) => {
        this.revealAll();

        setTimeout(() => {
            this.ticketSymbols.forEach((symbol, index) => {
                if (symbol.ticketNumber === winnerNumber) {
                    symbol.startTransition(Transitions.WINNER, 0);
                } else {
                    symbol.startTransition(Transitions.LOOSER, 0);
                }
            });

            this.drawSymbols();
        }, 500);
    };

    getTicketByPosition(position) {
        const AREA_THRESHOLD = 40;
        const found = this.scratchables.find((scratchable) => {
            const withinX =
                position.x > scratchable.position.x - AREA_THRESHOLD &&
                position.x < scratchable.position.x + AREA_THRESHOLD;
            const withinY =
                position.y > scratchable.position.y - AREA_THRESHOLD &&
                position.y < scratchable.position.y + AREA_THRESHOLD;
            return withinX && withinY;
        });

        if (found) {
            return found.index;
        }

        return null;
    }

    touchStart = (event) => {
        const isSomeInTransition =
            this.scratchables.some(
                (scratchable) => scratchable.transition !== Transitions.NONE
            ) ||
            this.ticketSymbols.some(
                (symbol) => symbol.transition !== Transitions.NONE
            );
        if (isSomeInTransition) {
            return;
        }

        this.isDrawing = true;
        this.lastPoint = this.getPosition(event);
        this.scratchableContext.globalCompositeOperation = "destination-out";
    };

    touchMove(event) {
        event.preventDefault();
        if (!this.isDrawing) return;

        const ctx = this.scratchableContext;
        const lastPoint = this.lastPoint;
        const currentPoint = this.getPosition(event);
        const dist = Math.sqrt(
            Math.pow(currentPoint.x - lastPoint.x, 2) +
                Math.pow(currentPoint.y - lastPoint.y, 2)
        );
        const angle = Math.atan2(
            currentPoint.x - lastPoint.x,
            currentPoint.y - lastPoint.y
        );
        const offsetX = this.brush.width / 2;
        const offsetY = this.brush.height / 2;
        let pointsInPath = [currentPoint];
        this.scratchableContext.globalCompositeOperation = "destination-out";
        for (let x, y, i = 0; i < dist; i++) {
            x = lastPoint.x + Math.sin(angle) * i - offsetX;
            y = lastPoint.y + Math.cos(angle) * i - offsetY;
            ctx.drawImage(this.brush, x, y);
            if (i !== 0 && i % 10 === 0) {
                pointsInPath.push({ x, y });
            }
        }

        this.lastPoint = currentPoint;

        pointsInPath.forEach((point) => {
            const whichTicket = this.getTicketByPosition(point);
            if (whichTicket !== null) {
                this.scratchables[whichTicket].increaseAmountScratched();
            }

            if (
                whichTicket !== null &&
                this.scratchables[whichTicket].amountScratched > 6
            ) {
                this.props.singleScratchCompleteCallback(whichTicket);
            }
        });
    }

    touchEnd(event) {
        this.isDrawing = false;
    }

    render() {
        return (
            <CanvasWrapper width={this.width} height={this.height}>
                <Canvas
                    width={this.width}
                    height={this.height}
                    ref={(el) => (this.symbolCanvas = el)}
                />
                <Canvas
                    width={this.width}
                    height={this.height}
                    ref={(el) => (this.scratchableCanvas = el)}
                />
            </CanvasWrapper>
        );
    }
}

export { TicketCanvas };
