import React from "react";
import { connect } from "react-redux";
import { Route, Switch, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./cakeGlobalStyles.css";
import * as styles from "./CakeLotteryWrapper.module.scss";

import { CakeTicketsContextProvider } from "../../../context/CakeTicketsContext";
import CakeLotteryLandingWrapper from "../cake-lottery-landing-wrapper/CakeLotteryLandingWrapper";
import CakeLotteryGameWrapper from "../cake-lottery-game-wrapper/CakeLotteryGameWrapper";
import { routes } from "../../../app/routes";

const CakeLotteryWrapper = ({ seller, t }) => {
    const { orderId, salesLinkCodeOrShortId } = useParams();

    const { campaignStatus } = seller;
    const hasEnded = campaignStatus === "CLOSED";
    if (hasEnded) {
        return (
            <div style={{ margin: "60px auto" }}>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "180px",
                        fontWeight: "bold",
                    }}
                >
                    {t("landing.fundraising_ended")}
                </p>
            </div>
        );
    }

    return (
        <CakeTicketsContextProvider>
            <div className={`${styles.container} cakelotteryWrapper`}>
                <Switch>
                    <Route
                        exact
                        path={routes.seller(":salesLinkCodeOrShortId").pathname}
                    >
                        <div className={styles.outerWrapper}>
                            <div className={styles.innerWrapper}>
                                <CakeLotteryLandingWrapper
                                    t={t}
                                    seller={seller}
                                />
                            </div>
                        </div>
                    </Route>
                    <Route
                        exact
                        path={routes
                            .seller(":salesLinkCodeOrShortId")
                            .raffle.order(":orderId")}
                    >
                        <div className={styles.gameOuterWrapper}>
                            <div className={styles.gameInnerWrapper}>
                                <CakeLotteryGameWrapper
                                    t={t}
                                    seller={seller}
                                    orderId={orderId}
                                />
                            </div>
                        </div>
                    </Route>
                </Switch>
            </div>
        </CakeTicketsContextProvider>
    );
};

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(connect(mapStateToProps)(CakeLotteryWrapper));
