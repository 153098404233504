import React from "react";
import "./SuperDrawLayout.scss";
import Footer from "../../../components/footer/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default ({ children }) => (
    <div
        style={{
            height: "100%",
            display: "inline-block",
            justifyContent: "center",
            width: "100%",
        }}
    >
        <div
            style={{
                maxWidth: "480px",
                margin: "0 auto",
                padding: "20px",
                textAlign: "center",
                boxSizing: "border-box",
            }}
        >
            {children}
        </div>
        <Footer
            custermTermsLink={
                "https://spond.com/fundraising-purchasing-terms-superdraw"
            }
        />
    </div>
);
