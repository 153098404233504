import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import "./AppLayout.scss";
import Footer from "../components/footer/Footer";

export default ({ children, isCustomProductFooter }) => (
    <Fragment>
        <Helmet>
            <style type="text/css">
                {` body { background-color: #ffffff; display: inline-flex; justify-content: center; width: 100%;}`}
            </style>
        </Helmet>
        <div className="container">
            <div className="page-wrapper">
                <div className="row">{children}</div>
            </div>
            <Footer isCustomProductFooter={isCustomProductFooter} />
        </div>
    </Fragment>
);
