import styled from "styled-components";
import * as primaryBg from "../../../assets/background-blue.jpg";

export const OuterContentHolder = styled.div`
    width: 100%;
    box-sizing: border-box;
`;

export const InnerContentHolder = styled.div`
    max-width: 540px;
    margin: 0 auto;
    padding: 20px 0 20px 0;
    text-align: center;
    box-sizing: border-box;
`;

export const Header = styled.div`
    background-image: url(${primaryBg});
    width: 100%;
    min-height: ${({ minHeight }) => (minHeight ? minHeight : "180px")};
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-position-x: center;
    position: relative;

    @media (max-width: 720px) {
        background-size: 1024px;
        min-height: 95px;
    }
`;

export const HeaderInner = styled.div`
    max-width: 375px;
    margin: 0 auto;
    padding: 30px 20px;
    text-align: center;

    p {
        font-size: 16px;
        color: #fff;
    }

    h3 {
        font-size: 30px;
        color: #fff;
        font-weight: 600;
        line-height: 1.25;
    }
`;
