import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import qs from "query-string";
import { withTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { ORDER_STATUS } from "../../../data/consts";

import Scratchcard from "../../../components/scratchcards/Scratchcard";
import ScratchcardWonModalContainer from "../../../containers/scratchcards/win/ScratchcardWonModal.container";
import ScratchcardLoseModalContainer from "../../../containers/scratchcards/loss/ScratchcardLoseModal.container";
import ScratchcardNoTickets from "../../../containers/scratchcards/no-tickets/ScratchcardNoTickets.container";
import ScrollToTop from "../../../components/scroll-to-top/ScrollToTop";

import { ScratchcardBg } from "../../../layout/ScratchcardLayout";
import Snowfall from "react-snowfall";
import {
    getScratchThemeType,
    ScratchThemeType,
} from "../../../containers/scratchcards/ScratchcardWrapper/CustomScratch.define";
import { apiClient } from "../../../api/ApiClient";
import { routes } from "../../../app/routes";
import { Seller, Ticket } from "../../../api/types";

type Props = RouteComponentProps<{
    orderId: string;
    salesLinkCodeOrShortId: string;
}> & {
    t: any;
    seller: Seller;
};

type State = {
    orderId: string;
    hasScratched: boolean;
    loading: boolean;
    key: string | string[] | null;
    showWonModal: boolean;
    showLoseModal: boolean;
    remainingTickets: Array<Ticket>;
    currentTicket?: Ticket;
    ready?: boolean;
    allTickets?: Ticket[];
    orderStatus?: unknown;
    themeType?: unknown;
};

class ScratchcardPage extends Component<Props> {
    state: State;
    constructor(props: Props) {
        super(props);

        const {
            match: {
                params: { orderId },
            },
            location,
        } = this.props;
        const { key, scratched } = qs.parse(location.search);
        this.state = {
            loading: true,
            orderId,
            key,
            showWonModal: false,
            showLoseModal: false,
            remainingTickets: [],
            hasScratched: !!scratched,
        };
    }

    componentDidMount() {
        const {
            seller: { shortId, theme },
        } = this.props;
        const { orderId, hasScratched } = this.state;

        apiClient
            .orderStatusCheck(shortId, orderId)
            .then((res) => {
                const tickets: Ticket[] = res.data.tickets;

                this.setState(
                    {
                        ready: true,
                        remainingTickets: tickets.filter((t) => !t.revealed),
                        allTickets: tickets,
                        hasScratched,
                        themeType: getScratchThemeType(theme.id),
                        orderStatus: res.data.status,
                    },
                    () => this.prepareNextTicket()
                );
            })
            .catch((err) => {
                console.error("Error while retrieving scratchcards", err);
                this.setState({
                    ready: true,
                    tickets: [],
                });
            });
    }

    onInfoClicked = () => {
        const {
            history,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { orderId } = this.state;

        history.push(
            routes.seller(salesLinkCodeOrShortId).scratchcardsInfo +
                `?orderId=${orderId}`
        );
    };

    prepareNextTicket = () => {
        const { remainingTickets } = this.state;

        if (remainingTickets.length > 0) {
            const currentTicket = remainingTickets.pop();

            this.setState({
                remainingTickets,
                currentTicket,
            });
        }
    };

    onNextTicketClicked = () => {
        this.prepareNextTicket();
        this.setState({
            showLoseModal: false,
            showWonModal: false,
        });
    };

    onFinishedScratching = (hasPrize: boolean) => {
        setTimeout(() => {
            if (hasPrize) {
                this.setState({ showWonModal: true });
            } else {
                this.setState({ showLoseModal: true });
            }
        }, 200);
    };

    onSelectPrizeClicked = () => {
        const {
            history,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { orderId, currentTicket } = this.state;

        if (!currentTicket)
            throw Error("Invariant: Expected currentTicket to be set");

        const { id: scratchcardId } = currentTicket;

        history.push(
            routes
                .seller(salesLinkCodeOrShortId)
                .scratchcards.order(orderId)
                .scratchcard(scratchcardId).selectPrize + `?scratched=t`
        );
    };

    getTicketNumbers = (ticket: Ticket) => {
        let numbersMap: Record<string, number> = {};
        let index = 0;
        ticket.fieldIcons.forEach((icon) => {
            if (!!!numbersMap[icon]) {
                numbersMap[icon] = index;
                index++;
            }
        });

        return ticket.fieldIcons.map((icon) => numbersMap[icon]);
    };

    render() {
        const { seller, t } = this.props;
        const {
            ready,
            showWonModal,
            showLoseModal,
            remainingTickets,
            allTickets,
            orderStatus,
            orderId,
            hasScratched,
            themeType,
        } = this.state;
        const { groupName } = seller;

        if (!ready) {
            return null;
        }

        const { currentTicket } = this.state;

        if (
            this.state.ready &&
            (orderStatus == ORDER_STATUS.REFUNDED ||
                (!remainingTickets.length && !currentTicket))
        ) {
            return (
                <>
                    <ScratchcardNoTickets
                        hasScratched={hasScratched}
                        allTickets={allTickets}
                        seller={seller}
                        orderId={orderId}
                        orderStatus={orderStatus}
                        t={t}
                    />
                </>
            );
        }

        if (!currentTicket || !allTickets) {
            return null;
        }

        return (
            <ScratchcardBg bgImage={seller.theme.fullscreenBackground}>
                {themeType === ScratchThemeType.christmas && (
                    <Snowfall snowflakeCount={40} />
                )}
                <div className="page-wrapper nopad">
                    <ScrollToTop />
                    <Scratchcard
                        seller={seller}
                        ticket={currentTicket}
                        displayName={groupName}
                        orderId={orderId}
                        ticketNumbers={this.getTicketNumbers(currentTicket)}
                        currentTicketNum={
                            allTickets.length - remainingTickets.length
                        }
                        totalTicketCount={allTickets.length}
                        onInfoClicked={this.onInfoClicked}
                        onFinishedScratching={this.onFinishedScratching}
                    />

                    {showWonModal && (
                        <ScratchcardWonModalContainer
                            onSelectPrizeClicked={this.onSelectPrizeClicked}
                        />
                    )}

                    {showLoseModal && (
                        <ScratchcardLoseModalContainer
                            hasMoreTickets={remainingTickets.length >= 1}
                            onNextTicketClicked={this.onNextTicketClicked}
                        />
                    )}
                </div>
            </ScratchcardBg>
        );
    }
}

const mapStateToProps = (state: { sellerReducer: { seller: Seller } }) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(ScratchcardPage))
);
