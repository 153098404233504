import React, { Component } from "react";
import qs from "query-string";
import { withRouter } from "react-router-dom";
import SpotTheBallJudgeContainer from "../../containers/spot-the-ball/judge/SpotTheBallJudge.container";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";
import { apiClient } from "../../api/ApiClient";

class SpotTheBallReviewLandingPage extends Component {
    constructor(props) {
        super(props);

        const { location } = this.props;
        const {
            match: {
                params: { imageId },
            },
        } = this.props;
        const { token } = qs.parse(location.search);
        this.state = {
            imageId: imageId,
            token: token,
            judged: false,
            gameImage: {},
        };
        this.getImage();
    }

    getImage() {
        const { imageId, token } = this.state;
        apiClient
            .getGameImage(imageId, token)
            .then((res) => {
                this.setState({ ...this.state, gameImage: res.data });
            })
            .catch((err) => {
                const message = err.response.data.message || "Unknown error";
                toast("Unable to retrieve image: " + message, {
                    type: "error",
                });
            });
    }

    judgeImage(coords) {
        const { imageId, token } = this.state;
        apiClient
            .judgeGameImage(imageId, coords, token)
            .then(() => {
                toast("Image successfully judged", { type: "success" });
                this.setState({ ...this.state, judged: true });
            })
            .catch((err) => {
                const message = err.response.data.message || "Unknown error";
                toast("Unable to update image:" + message, { type: "error" });
            });
    }

    rejectImage() {
        const { imageId, token } = this.state;
        apiClient
            .rejectGameImage(imageId, token)
            .then(() => {
                toast("Image successfully rejected", { type: "success" });
                this.setState({ ...this.state, judged: true });
            })
            .catch((err) => {
                const message = err.response.data.message || "Unknown error";
                toast("Unable to reject image: " + message, { type: "error" });
            });
    }

    render() {
        const { gameImage, judged } = this.state;
        const { t } = this.props;

        return (
            <div>
                {judged && (
                    <div style={{ textAlign: "center", paddingTop: 50 }}>
                        <h2>{t("stb.judged")}</h2>
                        <p>{t("stb.successfully_processed")}</p>
                    </div>
                )}
                {!!gameImage.url && !judged && (
                    <SpotTheBallJudgeContainer
                        contest={{ gameImage }}
                        remainingAttempts={1}
                        onSpotCompleted={(c) => this.judgeImage(c)}
                        onRejectImage={() => this.rejectImage()}
                    />
                )}
            </div>
        );
    }
}

export default withRouter(withTranslation()(SpotTheBallReviewLandingPage));
