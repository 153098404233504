import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import qs from "query-string";

import ScratchcardPrize from "../../../containers/scratchcards/prize/ScratchcardPrize.container";

class ScratchcardPrizePage extends Component {
    constructor(props) {
        super(props);

        const {
            match: {
                params: { orderId, scratchcardId },
            },
            location,
        } = this.props;
        const { key, scratched } = qs.parse(location.search);
        this.state = {
            orderId,
            scratchcardId,
            key,
            scratched,
        };
    }

    render() {
        const { orderId, scratchcardId, key, scratched } = this.state;

        return (
            <div className="page-wrapper nopad">
                <ScratchcardPrize
                    orderId={orderId}
                    scratchcardId={scratchcardId}
                    scratched={scratched}
                    updateKey={key}
                />
            </div>
        );
    }
}

export default withRouter(ScratchcardPrizePage);
