import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ScrollToTop from "../../../components/scroll-to-top/ScrollToTop";
import Avatar from "../../../components/avatar/Avatar";
import styled from "styled-components";
import qs from "query-string";
import Slot from "../../../components/superdraw/Slot";
import { RaffleButton } from "../../../components/button/Buttons";
import Modal from "react-responsive-modal";
import Scrim from "../../../components/scrim/Scrim";
import { getSellerDisplayName } from "../../../utils/strings/strings";
import DOMPurify from "dompurify";
import {
    ThemeIndicator,
    ThemeIndicatorsBed,
    ThemePhotoImg,
    ThemePhotosBed,
} from "../../../containers/super-draw/styled-components/SuperDrawLanding.container.styled";
import { Slide } from "react-slideshow-image";
import { apiClient } from "../../../api/ApiClient";
import { isUITest } from "../../../utils/env";

const ContentHolder = styled.div`
    margin: 15px auto;
    width: 100%;
`;

const TicketBox = styled.div`
    padding: 12px 16px;
    border: 1px solid ${({ theme }) => theme.primaryButtonColour || "#3CA010"};
    border-radius: 5px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
    max-width: 80px;
    font-family: "Dosis", sans-serif !important;
    font-weight: 700 !important;
    font-size: 18px;
    flex: 1 0 50%;

    margin-bottom: 10px;

    &:not(first-child) {
        margin-left: 10px;
    }

    background-color: ${({ winner, theme }) =>
        winner ? theme.primaryButtonColour || "#3CA010" : "#EEF7E2"};
    color: ${({ winner }) => (winner ? "#FFF" : "#333")};
`;

const TicketHolder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 540px;
    flex-wrap: wrap;
    margin: 0 auto;
`;

const DrawArea = styled.div`
    position: relative;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
    background-color: ${({ theme }) => theme.primaryButtonColour || "#3CA010"};
    margin-top: 30px;
`;

const DrawHolder = styled.div`
    opacity: 0.1;
    transition: opacity 0.5s ease;
    transition-delay: 0.5s;
`;

const StartButtonHolder = styled.div`
    position: absolute;
    left: 50%;
    margin-top: -24px;
    margin-left: -90px;
    top: 50%;
    transition: opacity 1s ease;
    min-width: 180px;
`;

const DrawColumnHolder = styled.div`
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
    display: flex;
    justify-content: center;
    column-gap: 30px;
`;

const DrawColumn = styled.div`
    margin-top: ${({ topMargin }) => (topMargin ? "15px" : "0")};

    h2 {
        margin-top: ${({ topMargin }) => (topMargin ? "1em" : "0")};
        margin-bottom: 0.25em;
        font-family: "Dosis", sans-serif !important;
        font-weight: 700;
        color: white;
    }
`;

class SuperDrawDrawPage extends Component {
    constructor(props) {
        super(props);

        const {
            match: {
                params: { extOrderId },
            },
            location,
        } = this.props;
        const { key } = qs.parse(location.search);
        this.state = {
            extOrderId,
            key,
            ticketValue1: 0,
            ticketValue2: 0,
            ticketValue3: 0,
            startedDraw: false,
            startedSlot1: false,
            startedSlot2: false,
            startedSlot3: false,
            runningSlot1: false,
            runningSlot2: false,
            runningSlot3: false,

            drawHolderRef: React.createRef(),
            startButtonRef: React.createRef(),

            showWinModal: false,
            showLoseModal: false,
            winningPlacement: null,
            themeIndex: 0,

            revealed: false,
        };
    }

    refreshDrawStatus = (revealed = false) => {
        const { extOrderId, key } = this.state;

        apiClient
            .orderStatusCheck(this.props.seller.shortId, extOrderId)
            .then((res) => {
                const { superdrawTickets, superdrawWinners, superdrawPrizes } =
                    res.data;

                this.setState(
                    { tickets: superdrawTickets, superdrawPrizes, revealed },
                    () => {
                        superdrawWinners?.length &&
                            this.setState({
                                ticketValue1: superdrawWinners[0].key,
                                ticketValue2:
                                    superdrawWinners.length > 1
                                        ? superdrawWinners[1].key
                                        : undefined,
                                ticketValue3:
                                    superdrawWinners.length > 2
                                        ? superdrawWinners[2].key
                                        : undefined,
                            });
                    }
                );
            });
    };

    startDraw = () => {
        const initialDelay = 1000;
        const animDuration = 6000;

        this.setState(
            {
                startedDraw: true,
            },
            () => {
                setTimeout(() => {
                    this.setState({
                        startedDraw: true,
                        startedSlot1: true,
                        runningSlot1: true,
                    });
                }, initialDelay);

                setTimeout(() => {
                    this.setState(
                        {
                            runningSlot1: false,
                        },
                        () => {
                            setTimeout(() => {
                                this.setState({
                                    startedSlot2: true,
                                    runningSlot2: true,
                                });
                            }, 250);
                        }
                    );
                }, animDuration + initialDelay);

                setTimeout(() => {
                    this.setState(
                        {
                            runningSlot2: false,
                        },
                        () => {
                            setTimeout(() => {
                                this.setState({
                                    startedSlot3: true,
                                    runningSlot3: true,
                                });
                            }, 250);
                        }
                    );
                }, animDuration * 2 + initialDelay);

                setTimeout(() => {
                    this.setState(
                        {
                            runningSlot2: false,
                        },
                        () => {
                            setTimeout(() => {
                                this.setState({
                                    startedSlot3: true,
                                    runningSlot3: true,
                                });
                            }, 250);
                        }
                    );
                }, animDuration * 3 + initialDelay);

                setTimeout(() => {
                    const { tickets, superdrawPrizes } = this.state;
                    let hasWinners = false;
                    let placements = [];

                    for (let i = 0; i < tickets.length; i++) {
                        const ticket = tickets[i];
                        if (ticket.isWinner) {
                            hasWinners = ticket.isWinner;
                            placements.push(
                                superdrawPrizes.findIndex(
                                    (p) => p.id === ticket.prizeId
                                ) + 1
                            );
                        }
                    }

                    placements = placements.sort((a, b) => (a > b ? 1 : -1));

                    this.setState(
                        {
                            runningSlot3: false,
                            showWinModal: !!hasWinners,
                            showLoseModal: !hasWinners,
                            winningPlacements: placements,
                        },
                        () => {
                            this.refreshDrawStatus(true);
                        }
                    );
                }, animDuration * 3 + initialDelay + 500);
            }
        );
    };

    componentDidMount() {
        this.refreshDrawStatus();
    }

    updateThemeIndex(newIndex) {
        if (newIndex !== -1) {
            this.setState({ themeIndex: newIndex });
        }
    }

    onThemeIndexChanged(newIndex) {
        this.setState({ themeIndex: newIndex });
    }

    render() {
        const { seller, t } = this.props;
        const {
            shortId,
            imageUrl,
            clubImageUrl,
            groupName,
            sellerFirstName = "",
            sellerLastName = "",
            superdrawPrizes,
            isUsingOrgImage,
            theme,
        } = seller;
        const {
            tickets,
            ticketValue1,
            ticketValue2,
            ticketValue3,
            startedSlot1,
            startedSlot2,
            startedSlot3,
            runningSlot1,
            runningSlot2,
            runningSlot3,
            drawHolderRef,
            startButtonRef,
            startedDraw,
            showWinModal,
            showLoseModal,
            winningPlacements,
            revealed,
        } = this.state;

        const sellerName = getSellerDisplayName(seller);
        const isGroupMode =
            sellerFirstName.length === 0 && sellerLastName.length === 0;

        if (startedDraw) {
            startButtonRef.current.style.opacity = "0.0";
            drawHolderRef.current.style.opacity = "1.0";
        }

        return (
            <>
                <ScrollToTop />
                <div style={{ maxWidth: "480px" }}>
                    <div
                        style={{
                            margin: "25px auto 0 auto",
                            textAlign: "center",
                        }}
                    >
                        <Avatar
                            width="100px"
                            imageUrl={imageUrl}
                            clubImageUrl={clubImageUrl}
                            shortId={`${shortId.substring(0, 6)}B`}
                            style={{ border: "1px solid white" }}
                            isUsingOrgImage={isUsingOrgImage}
                        />
                    </div>

                    <ContentHolder
                        style={{ padding: "0 20px", boxSizing: "border-box" }}
                    >
                        <div style={{ margin: "0 auto 30px auto" }}>
                            <h2
                                style={{
                                    textAlign: "center",
                                    fontSize: "32px",
                                }}
                            >
                                {t("superdraw.super_draw_title")}
                            </h2>
                            <div>
                                <p style={{ textAlign: "center" }}>
                                    {t(
                                        isGroupMode
                                            ? "order_successful.thanks_for_support_organisation_seller"
                                            : "order_successful.thanks_for_support",
                                        {
                                            sellerName,
                                            groupName,
                                        }
                                    )}
                                </p>
                                {t("superdraw.good_luck_in_the_draw")}
                            </div>

                            <DrawArea theme={theme}>
                                <DrawHolder ref={drawHolderRef}>
                                    <DrawColumnHolder>
                                        <DrawColumn>
                                            <h2>
                                                {t("superdraw.first_place")}
                                            </h2>
                                            <Slot
                                                label={ticketValue1}
                                                started={startedSlot1}
                                                running={runningSlot1}
                                            />
                                        </DrawColumn>
                                    </DrawColumnHolder>
                                    <DrawColumnHolder>
                                        <DrawColumn topMargin>
                                            <h2>
                                                {t("superdraw.second_place")}
                                            </h2>
                                            <Slot
                                                label={ticketValue2}
                                                started={startedSlot2}
                                                running={runningSlot2}
                                            />
                                        </DrawColumn>
                                    </DrawColumnHolder>
                                    <DrawColumnHolder>
                                        <DrawColumn topMargin>
                                            <h2>
                                                {t("superdraw.third_place")}
                                            </h2>
                                            <Slot
                                                label={ticketValue3}
                                                started={startedSlot3}
                                                running={runningSlot3}
                                            />
                                        </DrawColumn>
                                    </DrawColumnHolder>
                                </DrawHolder>

                                <StartButtonHolder ref={startButtonRef}>
                                    <RaffleButton
                                        id="start_draw"
                                        style={{
                                            backgroundColor: "white",
                                            color: "#333",
                                        }}
                                        onClick={() => {
                                            this.startDraw();
                                        }}
                                    >
                                        {t("superdraw.start_draw")}
                                    </RaffleButton>
                                </StartButtonHolder>
                            </DrawArea>

                            <div id="your_entries">
                                <h3 style={{ marginTop: "30px" }}>
                                    Your entries
                                </h3>
                                {!tickets && <>{t("landing.loading")}</>}
                                {tickets && (
                                    <TicketHolder>
                                        {tickets
                                            .sort((a, b) => {
                                                if (a.ticket > b.ticket) {
                                                    return 1;
                                                } else if (
                                                    a.ticket < b.ticket
                                                ) {
                                                    return -1;
                                                } else {
                                                    return 0;
                                                }
                                            })
                                            .map((ticket) => (
                                                <TicketBox
                                                    theme={theme}
                                                    key={ticket.id}
                                                    winner={
                                                        revealed &&
                                                        ticket.isWinner &&
                                                        ticket.isRevealed
                                                    }
                                                >
                                                    {ticket.key}
                                                </TicketBox>
                                            ))}
                                    </TicketHolder>
                                )}
                            </div>

                            <ThemePhotosBed id="theme_photos">
                                <Slide
                                    {...{
                                        defaultIndex: this.state.themeIndex,
                                        indicators: false,
                                        transitionDuration: isUITest()
                                            ? 50000
                                            : 500,
                                        arrows: false,
                                        autoplay: superdrawPrizes.length > 1,
                                        onChange: (oldIndex, newIndex) => {
                                            this.onThemeIndexChanged(newIndex);
                                        },
                                    }}
                                >
                                    {superdrawPrizes.map((prize, index) => (
                                        <div
                                            className="each-slide"
                                            key={prize.id}
                                        >
                                            <ThemePhotoImg
                                                src={prize.previewUrl}
                                            />
                                        </div>
                                    ))}
                                </Slide>
                            </ThemePhotosBed>
                            {superdrawPrizes.length > 1 && (
                                <ThemeIndicatorsBed>
                                    {superdrawPrizes.map((prize, index) => (
                                        <ThemeIndicator
                                            key={index}
                                            activeColor={
                                                theme.primaryButtonColour
                                            }
                                            active={
                                                this.state.themeIndex === index
                                            }
                                        />
                                    ))}
                                </ThemeIndicatorsBed>
                            )}
                        </div>
                    </ContentHolder>
                </div>

                <Modal
                    center={true}
                    open={showWinModal}
                    onClose={() => {
                        this.setState({ showWinModal: false });
                    }}
                    classNames={{ modal: "win-modal" }}
                    styles={{
                        modal: { backgroundColor: theme.primaryButtonColour },
                    }}
                >
                    <Scrim show={true} />
                    <div
                        style={{ padding: "20px", textAlign: "center" }}
                        id="draw_result_modal"
                    >
                        {winningPlacements &&
                            winningPlacements.map((winningPlacement) => (
                                <div key={winningPlacement}>
                                    <h2
                                        style={{
                                            fontFamily: "Dosis, sans-serif",
                                            fontSize: "30px",
                                            fontWeight: "700",
                                        }}
                                    >
                                        {t("end.title_congratulations")}
                                        <br />
                                        {winningPlacement === 1 && (
                                            <>{t("superdraw.won_1st_prize")}</>
                                        )}
                                        {winningPlacement === 2 && (
                                            <>{t("superdraw.won_2nd_prize")}</>
                                        )}
                                        {winningPlacement === 3 && (
                                            <>{t("superdraw.won_3rd_prize")}</>
                                        )}
                                    </h2>
                                    <p>
                                        {winningPlacement === 1 && (
                                            <>
                                                {t(
                                                    "superdraw.congratulations_description",
                                                    {
                                                        string: superdrawPrizes[0]
                                                            .title,
                                                    }
                                                )}
                                                <br />
                                            </>
                                        )}
                                        {winningPlacement === 2 && (
                                            <>
                                                {t(
                                                    "superdraw.congratulations_description",
                                                    {
                                                        string: superdrawPrizes[1]
                                                            .title,
                                                    }
                                                )}
                                                <br />
                                            </>
                                        )}
                                        {winningPlacement === 3 && (
                                            <>
                                                {t(
                                                    "superdraw.congratulations_description",
                                                    {
                                                        string: superdrawPrizes[2]
                                                            .title,
                                                    }
                                                )}
                                                <br />
                                            </>
                                        )}
                                        {t("superdraw.reach_winner_text")}
                                    </p>
                                </div>
                            ))}
                    </div>
                </Modal>

                <Modal
                    center={true}
                    open={showLoseModal}
                    onClose={() => {
                        this.setState({ showLoseModal: false });
                    }}
                    classNames={{ modal: "lose-modal" }}
                >
                    <div
                        style={{ padding: "20px", textAlign: "center" }}
                        id="draw_result_modal"
                    >
                        <div>
                            <h2
                                style={{
                                    fontFamily: "Dosis, sans-serif",
                                    fontSize: "30px",
                                    fontWeight: "700",
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: t("end.lose_description", {
                                        displayName: DOMPurify.sanitize(
                                            isGroupMode
                                                ? `${groupName}`
                                                : `${sellerName}`,
                                            { USE_PROFILES: { html: false } }
                                        ),
                                    }),
                                }}
                            ></h2>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(SuperDrawDrawPage))
);
