import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Button from "../../../components/cake-lottery/button/Button";
import Layout from "../../../components/cake-lottery/layout/Layout";
import PickerContainer from "../../../components/cake-lottery/ticket-container/TicketContainer";
import CakeTicketsContext from "../../../context/CakeTicketsContext";
import { CurrencyFormatter } from "../../../utils/Currency";
import * as styles from "./CakeSelectTickets.module.scss";

const SelectTicketsPage = ({ seller, nextPage, t }) => {
    const [onboardingStep, setOnboardingStep] = useState(0);
    const { selectedTickets, selectTicket, removeTicket } =
        useContext(CakeTicketsContext);
    const updateOnboardingStep = () => {
        if (onboardingStep < 2) {
            setOnboardingStep(onboardingStep + 1);
        }
    };
    const isOnOnboardingStep = (n) =>
        (n === onboardingStep && selectedTickets.length > 0) ||
        (n === 0 && selectedTickets.length === 0);
    const showButton = selectedTickets.length > 0 && onboardingStep >= 1;
    const { raffleTicketPrice: unitPrice, theme } = seller;

    return (
        <Layout className={styles.layout} hasCake theme={theme}>
            <div className={styles.onboardingWrapper}>
                {isOnOnboardingStep(0) && (
                    <h1>{t("raffle.ticket_selection_stage1_heading")}</h1>
                )}
                {isOnOnboardingStep(1) && (
                    <>
                        <h1>{t("raffle.ticket_selection_stage2_heading")}</h1>
                        <p>{t("raffle.ticket_selection_stage2_text")}</p>
                    </>
                )}
                {isOnOnboardingStep(2) && (
                    <>
                        <h1>
                            {t("raffle.ticket_selection_stage3_heading", {
                                ticketCount: selectedTickets.length,
                            })}
                        </h1>
                        <p>{t("raffle.ticket_selection_stage3_text")}</p>
                    </>
                )}
            </div>
            <PickerContainer
                ticketCount={50}
                selectTicket={(number) => {
                    selectTicket(number);
                    updateOnboardingStep();
                }}
                removeTicket={(number) => {
                    removeTicket(number);
                    updateOnboardingStep();
                }}
                selectedTickets={selectedTickets}
                theme={theme}
            />
            <div className={styles.staticButtonWrapper}>
                {!!selectedTickets.length && (
                    <Button
                        theme={theme}
                        onClick={() => showButton && nextPage()}
                        disabled={!showButton}
                    >
                        {selectedTickets.length > 0 && (
                            <>
                                {t("raffle.ticket_purchase_button", {
                                    formattedPrice: CurrencyFormatter(
                                        t,
                                        seller,
                                        unitPrice * selectedTickets.length
                                    ),
                                })}
                            </>
                        )}
                    </Button>
                )}
            </div>
            {showButton && (
                <div
                    className={[
                        styles.buttonWrapper,
                        showButton ? styles.open : "",
                    ].join(" ")}
                >
                    <Button
                        theme={theme}
                        onClick={() => showButton && nextPage()}
                    >
                        {t("raffle.ticket_purchase_button", {
                            formattedPrice: CurrencyFormatter(
                                t,
                                seller,
                                unitPrice * selectedTickets.length
                            ),
                        })}
                    </Button>
                </div>
            )}
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(connect(mapStateToProps)(SelectTicketsPage));
