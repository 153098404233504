import React from "react";
import TicketShape from "./TicketShape";

const Ticket = ({
    number,
    selected,
    selectTicket,
    removeTicket,
    selectedFill,
    selectedTextFill,
    indicator,
    theme,
    ...props
}) => {
    return (
        <TicketShape
            onClick={() =>
                selected
                    ? removeTicket && removeTicket(number)
                    : selectTicket && selectTicket(number)
            }
            selected={selected}
            selectedFill={
                selectedFill ||
                theme?.primaryButtonColour ||
                "var(--color-accent)"
            }
            selectedTextFill={
                selectedTextFill || theme?.primaryButtonTextColour || "white"
            }
            indicator={indicator}
            {...props}
        >
            {number}
        </TicketShape>
    );
};

export default Ticket;
