export const ORDER_STATUS = {
    PENDING: "PENDING",
    PAYMENT_RESERVED: "PAYMENT_RESERVED",
    PAYMENT_CAPTURED: "PAYMENT_CAPTURED",
    PAYMENT_CAPTURE_FAILED: "PAYMENT_CAPTURE_FAILED",
    PAID: "PAID",
    REFUNDED: "REFUNDED",
    FULFILLED: "FULFILLED",
};

export const FUNDRAISER_TYPE = {
    PRODUCTS: "PRODUCTS",
    SCRATCHCARDS: "SCRATCHCARDS",
    SPOT_THE_BALL: "SPOT_THE_BALL",
    RAFFLE: "RAFFLE",
    RECURRING_RAFFLE: "RECURRING_RAFFLE",
    SUPER_DRAW: "SUPERDRAW",
};

export const COLORS = {
    $spondGray10BasePrimary: "#FFFFFF",
    $spondGray10BaseSecondary: "#FFFFFF",
    $spondGray10BaseTertiary: "#FFFFFF",
    $spondGray10ElevatedPrimary: "#FFFFFF",
    $spondGray10ElevatedSecondary: "#FFFFFF",
    $spondGray10ElevatedTertiary: "#FFFFFF",

    /* Group background (input field, secondary button background, pickers, cards, etc.) */
    $spondGray9BasePrimary: "#FFFFFF",
    $spondGray9BaseSecondary: "#F3F3F3",
    $spondGray9BaseTertiary: "#FFFFFF",
    $spondGray9ElevatedPrimary: "#FFFFFF",
    $spondGray9ElevatedSecondary: "#F3F3F3",
    $spondGray9ElevatedTertiary: "#FFFFFF",

    /* hover background*/
    $spondBlue9BaseSecondary: "#E0F7FF",
    $spondBlue9ElevatedSecondary: "#E0F7FF",

    /* Avatar/Image placeholder background color */
    $spondGray7BasePrimary: "#E0E0E0",
    $spondGray7BaseSecondary: "#D3D3D3",
    $spondGray7BaseTertiary: "#F3F3F3",
    $spondGray7ElevatedPrimary: "#E0E0E0",
    $spondGray7ElevatedSecondary: "#D3D3D3",
    $spondGray7ElevatedTertiary: "#E0E0E0",

    /* Seperator line */
    $spondGray6BasePrimary: "#CBCBCB",
    $spondGray6BaseSecondary: "#C0C0C0",
    $spondGray6BaseTertiary: "#CBCBCB",
    $spondGray6ElevatedPrimary: "#CBCBCB",
    $spondGray6ElevatedSecondary: "#C0C0C0",
    $spondGray6ElevatedTertiary: "#CBCBCB",

    /* Icons, primary button background, large text link color */
    $spondGray4BasePrimary: "#949494",
    $spondGray4BaseSecondary: "#BDBDBD",
    $spondGray4BaseTertiary: "#949494",
    $spondGray4ElevatedPrimary: "#949494",
    $spondGray4ElevatedSecondary: "#BDBDBD",
    $spondGray4ElevatedTertiary: "#949494",

    $spondBlue4BasePrimary: "#0080A3",
    $spondBlue4BaseSecondary: "#0099B8",
    $spondBlue4BaseTertiary: "#00A1C7",
    $spondBlue4ElevatedPrimary: "#00A1C7",
    $spondBlue4ElevatedSecondary: "#0099B8",
    $spondBlue4ElevatedTertiary: "#00A1C7",

    $spondRed4BasePrimary: "#F5D4A",
    $spondRed4BaseSecondary: "#F4C2E",
    $spondRed4BaseTertiary: "#F5D4A",
    $spondRed4ElevatedPrimary: "#F5D4A",
    $spondRed4ElevatedSecondary: "#F4C2E",
    $spondRed4ElevatedTertiary: "#F5D4A",

    $spondOrange4BasePrimary: "#EC7100",
    $spondOrange4BaseSecondary: "#E06C00",
    $spondOrange4BaseTertiary: "#E06C00",
    $spondOrange4ElevatedPrimary: "#EC7100",
    $spondOrange4ElevatedSecondary: "#E06C00",
    $spondOrange4ElevatedTertiary: "#EC7100",

    /* Secondary text, error message text, small text link color */
    $spondGray3BasePrimary: "#767676",
    $spondGray3BaseSecondary: "#6E6E6E",
    $spondGray3BaseTertiary: "#767676",
    $spondGray3ElevatedPrimary: "#767676",
    $spondGray3ElevatedSecondary: "#6E6E6E",
    $spondGray3ElevatedTertiary: "#767676",

    /* Primary text for background 9 to 10 */
    $spondGray1BasePrimary: "#212121",
    $spondGray1BaseSecondary: "#181818",
    $spondGray1BaseTertiary: "#212121",
    $spondGray1ElevatedPrimary: "#212121",
    $spondGray1ElevatedSecondary: "#181818",
    $spondGray1ElevatedTertiary: "#212121",
};

export const DARK_COLORS = {
    $spondGray10BasePrimary: "#000000",
    $spondGray10BaseSecondary: "#000000",
    $spondGray10BaseTertiary: "#000000",
    $spondGray10ElevatedPrimary: "#000000",
    $spondGray10ElevatedSecondary: "#000000",
    $spondGray10ElevatedTertiary: "#000000",

    /* Group background (input field, secondary button background, pickers, cards, etc.) */
    $spondGray9BasePrimary: "#000000",
    $spondGray9BaseSecondary: "#1C1C1C",
    $spondGray9BaseTertiary: "#2C2C2C",
    $spondGray9ElevatedPrimary: "#1C1C1C",
    $spondGray9ElevatedSecondary: "#2C2C2C",
    $spondGray9ElevatedTertiary: "#3A3A3A",

    /* hover background*/
    $spondBlue9BaseSecondary: "#002430",
    $spondBlue9ElevatedSecondary: "#0B303F",

    /* Avatar/Image placeholder background color */
    $spondGray7BasePrimary: "#2C2C2C",
    $spondGray7BaseSecondary: "#3A3A3A",
    $spondGray7BaseTertiary: "#484848",
    $spondGray7ElevatedPrimary: "#3A3A3A",
    $spondGray7ElevatedSecondary: "#484848",
    $spondGray7ElevatedTertiary: "#575757",

    /* Seperator line */
    $spondGray6BasePrimary: "#3A3A3A",
    $spondGray6BaseSecondary: "#484848",
    $spondGray6BaseTertiary: "#5D5D5D",
    $spondGray6ElevatedPrimary: "#484848",
    $spondGray6ElevatedSecondary: "#5D5D5D",
    $spondGray6ElevatedTertiary: "#686868",

    /* Icons, primary button background, large text link color */
    $spondGray4BasePrimary: "#888888",
    $spondGray4BaseSecondary: "#8B8B8B",
    $spondGray4BaseTertiary: "#00C0FA",
    $spondGray4ElevatedPrimary: "#8B8B8B",
    $spondGray4ElevatedSecondary: "#8C8C8C",
    $spondGray4ElevatedTertiary: "#909090",

    $spondBlue4BasePrimary: "#00B0E6",
    $spondBlue4BaseSecondary: "#00B4EB",
    $spondBlue4BaseTertiary: "#00C0FA",
    $spondBlue4ElevatedPrimary: "#00B4EB",
    $spondBlue4ElevatedSecondary: "#00C0FA",
    $spondBlue4ElevatedTertiary: "#00BFFF",

    $spondRed4BasePrimary: "#FF3C1A",
    $spondRed4BaseSecondary: "#FF462E",
    $spondRed4BaseTertiary: "#FF4839",
    $spondRed4ElevatedPrimary: "#FF462E",
    $spondRed4ElevatedSecondary: "#FF4839",
    $spondRed4ElevatedTertiary: "#FF5349",

    $spondOrange4BasePrimary: "#D66A00",
    $spondOrange4BaseSecondary: "#DB6C00",
    $spondOrange4BaseTertiary: "#DF6B00",
    $spondOrange4ElevatedPrimary: "#DB6C00",
    $spondOrange4ElevatedSecondary: "#DF6B00",
    $spondOrange4ElevatedTertiary: "#E76D00",

    /* Secondary text, error message text, small text link color */
    $spondGray3BasePrimary: "#8E8E8E",
    $spondGray3BaseSecondary: "#919191",
    $spondGray3BaseTertiary: "#959595",
    $spondGray3ElevatedPrimary: "#919191",
    $spondGray3ElevatedSecondary: "#959595",
    $spondGray3ElevatedTertiary: "#989898",

    /* Primary text for background 9 to 10 */
    $spondGray1BasePrimary: "#EFEFEF",
    $spondGray1BaseSecondary: "#FCFCFC",
    $spondGray1BaseTertiary: "#FFFFFF",
    $spondGray1ElevatedPrimary: "#FCFCFC",
    $spondGray1ElevatedSecondary: "#FFFFFF",
    $spondGray1ElevatedTertiary: "#FFFFFF",
};
