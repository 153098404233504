import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import moment from "moment";

import { getSeller } from "../redux/seller";

class Preloader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
        };
    }

    componentDidMount() {
        const {
            getSeller,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        getSeller(salesLinkCodeOrShortId);
    }

    componentDidUpdate() {
        const { seller } = this.props;
        const { ready } = this.state;

        if (seller && !ready) {
            switch (seller.countryCode) {
                case "NOR":
                    i18n.changeLanguage("no", () => {
                        this.setState({ ...this.state, ready: true });
                    });
                    moment.locale("nb");
                    break;
                default:
                    i18n.changeLanguage("en", () => {
                        this.setState({ ...this.state, ready: true });
                    });
                    moment.locale("en");
            }
        }
    }

    render() {
        const { getSellerFailed, children } = this.props;
        const { ready } = this.state;

        if (getSellerFailed) {
            return (
                <div>
                    <h1 style={{ textAlign: "center" }}>
                        {i18n.t("not_found.body")}
                    </h1>
                </div>
            );
        }

        return ready ? children : null;
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
    getSellerFailed: state.sellerReducer.getSellerFailed,
});

const mapDispatchToProps = (dispatch) => ({
    getSeller: (salesLinkCodeOrShortId) =>
        dispatch(getSeller(salesLinkCodeOrShortId)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Preloader)
);
