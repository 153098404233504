import { Product } from "../../../api/types";

export function getImagesToDisplay(product: Product): string[] {
    return (
        getVariationImages(product) ||
        getTopLevelImages(product) || [product.preview]
    );
}

function getVariationImages(product: Product): string[] | null {
    if (!product.variations || product.variations.length === 0) {
        return null;
    }
    const variationImages = product.variations
        .filter((variation) => variation)
        .flatMap((variation) => variation.images ?? [])
        .filter((image) => image) as string[];
    return variationImages.length > 0 ? variationImages : null;
}

function getTopLevelImages(product: Product): string[] | null {
    const images = product.images;
    if (images === null || images.length === 0) {
        return null;
    }
    const productImages = images.filter((it) => it);
    return productImages.length > 0 ? productImages : null;
}
