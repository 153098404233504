import styled from "styled-components";

const ModalBaseContainer = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    height: 300px;
    max-width: 300px;
    width: 100%;
    justify-content: center;
`;

export const LooserContainer = styled(ModalBaseContainer)`
    background-image: ${(props) => `url(${props.bgImage})`};
`;

export const WinnerContainer = styled(ModalBaseContainer)`
    background-image: ${(props) => `url(${props.bgImage})`};
`;

export const HeaderText1 = styled.h3`
    color: white;
    font-size: 2.5em;
    font-weight: 700;
    margin: 0;
    text-align: center;
`;

export const HeaderText2 = styled.h3`
    color: white;
    font-size: 1.5em;
    font-weight: 700;
    margin: 0;
    text-align: center;
`;

export const ConfettiWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 4000000;
`;
