import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import ScratchcardLanding from "../../../containers/scratchcards/landing/ScratchcardLanding.container";
import ProductLanding from "../../../containers/product/landing/ProductLanding.container";
import SpotLanding from "../../../containers/spot-the-ball/landing/SpotTheBallLanding.container";
import SuperDrawLanding from "../../../containers/super-draw/landing/SuperDrawLanding.container";
import { FUNDRAISER_TYPE } from "../../../data/consts";
import AppLayout from "../../../layout/AppLayout";
import Footer from "../../../components/footer/Footer";
import CakeLotteryWrapper from "../../cake-lottery/cake-lottery-wrapper/CakeLotteryWrapper";
import { isProd } from "../../../utils/env";

class LandingPage extends Component {
    render() {
        const {
            seller: { type, theme },
        } = this.props;
        const isCustomProductFooter = isProd()
            ? theme.id === "AFA4902A85DA8625E8FA429658E58D45"
            : false;

        if (type === FUNDRAISER_TYPE.SCRATCHCARDS) {
            return (
                <AppLayout>
                    <ScratchcardLanding />
                </AppLayout>
            );
        } else if (type === FUNDRAISER_TYPE.PRODUCTS) {
            return (
                <AppLayout isCustomProductFooter={isCustomProductFooter}>
                    <ProductLanding />
                </AppLayout>
            );
        } else if (type === FUNDRAISER_TYPE.RAFFLE) {
            return <CakeLotteryWrapper />;
        } else if (type === FUNDRAISER_TYPE.SUPER_DRAW) {
            return <SuperDrawLanding />;
        } else {
            return (
                <>
                    <SpotLanding />
                    <Footer />
                </>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(connect(mapStateToProps)(LandingPage));
