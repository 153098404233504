import React, { useEffect, useState } from "react";
import * as styles from "./CakePreview.module.scss";

const CakePreview = ({ t, products, onProductSelected }) => {
    const [selectedProductId, setSelectedProductId] = useState();

    useEffect(() => {
        if (products.length) {
            setSelectedProductId(products[0].id);
            onProductSelected(products[0]);
        }
    }, [products]);

    const onClick = (product) => {
        setSelectedProductId(product.id);
        onProductSelected(product);
    };

    return (
        <div className={styles.container}>
            {products.length > 1 && <h1>{t("actions.pick_prize")}</h1>}
            <div className={styles.productContainer}>
                {products.map((product) => (
                    <div
                        key={product.id}
                        className={`${styles.productBox} ${
                            product.id === selectedProductId
                                ? styles.selected
                                : ""
                        }`}
                        onClick={() => onClick(product)}
                        selected={product.id === selectedProductId}
                    >
                        <img src={product.preview} alt={product.name} />
                        <h3>{product.name}</h3>
                        <p>{product.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CakePreview;
