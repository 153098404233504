export const EVENT_TYPES = {
    PAGE_VIEW: "PAGE_VIEW",
};

export const trackEvent = (eventName, props) => {
    // if (window.amplitude) {
    //   if (props.groupId) {
    //     window.amplitude.getInstance().setUserProperties({
    //       groupId: props.groupId
    //     });
    //   }
    //   window.amplitude.getInstance().logEvent(eventName, props);
    // }
};

export const trackPageView = (page, props) => {
    trackEvent(EVENT_TYPES.PAGE_VIEW, {
        ...props,
        page,
    });
};
