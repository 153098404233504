import React, { useEffect, useState } from "react";
import Button from "../../../components/cake-lottery/button/Button";
import Layout from "../../../components/cake-lottery/layout/Layout";
import * as styles from "./CakeStart.module.scss";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import RulesModal from "./rules/CakeRulesModal";
import ProfilePicture from "../../../components/cake-lottery/profile-picture/ProfilePicture";
import GlassContainer from "../../../components/cake-lottery/glass-container/GlassContainer";
import * as previewAvatar from "../../../assets/fc_preview_profile_image.png";
import DOMPurify from "dompurify";
import { getCampaignURL } from "../../../utils/misc/misc";
import { apiClient } from "../../../api/ApiClient";

const CakeStartPage = ({ seller, nextPage, t, previewSeller }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const _seller = previewSeller || seller;
    const modal = {
        open: () => setModalOpen(true),
        close: () => setModalOpen(false),
    };
    const { salesLinkCodeOrShortId } = useParams();
    const isPreview = !!previewSeller;
    const displayName = isPreview
        ? t("preview.placeholder_first_name")
        : seller.sellerFirstName;
    const purifiedGroupNameElement = `<span name='group-name'>${DOMPurify.sanitize(
        _seller.groupName,
        { USE_PROFILES: { html: false } }
    )}</span>`;

    const { theme, campaignId, groupName } = _seller;
    const campaignURL = getCampaignURL(salesLinkCodeOrShortId);

    useEffect(() => {
        if (isPreview) {
            return;
        }
        apiClient
            .getProducts(campaignId)
            .then((res) => {
                const products = res.data;
                setProducts(products.products);
            })
            .catch((err) => {
                console.error("Error while retrieving products", err);
            });
    }, []);

    return (
        <Layout
            isPreview={isPreview}
            className={isPreview ? styles.layoutPreview : styles.layout}
            hasCake
            theme={theme}
        >
            <GlassContainer className={styles.startPage}>
                <div className={styles.avatarContainer}>
                    {isPreview ? (
                        <img
                            alt={"Avatar"}
                            className={styles.previewAvatar}
                            src={previewAvatar}
                        />
                    ) : (
                        <ProfilePicture imageUrl={_seller?.sellerImage} />
                    )}
                </div>
                <h1
                    className="font-bold"
                    dangerouslySetInnerHTML={{
                        __html: t(
                            !!!displayName
                                ? "landing.subtitle_organization"
                                : "landing.title",
                            {
                                firstName: DOMPurify.sanitize(displayName, {
                                    USE_PROFILES: { html: false },
                                }),
                                groupName: purifiedGroupNameElement,
                            }
                        ),
                    }}
                />
                <br />
                <p className="font-bold" name="fundraiser-purpose">
                    {_seller.description}
                </p>
                <br />
                <p
                    dangerouslySetInnerHTML={{
                        __html: t("landing.raffle_intro", {
                            groupName: purifiedGroupNameElement,
                        }),
                    }}
                />
                <br />
                <div className="">
                    <Button theme={theme} onClick={isPreview ? null : nextPage}>
                        {t("landing.raffle_continue_to_selection")}
                    </Button>
                </div>
                <button
                    className={styles.openModalLink}
                    onClick={isPreview ? null : modal.open}
                >
                    {t("landing.raffle_read_more")}
                </button>
            </GlassContainer>
            <div className={styles.prizeProviderContainer}>
                <img alt={"Prize Logo"} src={theme?.prizeProviderLogo} />
            </div>
            <RulesModal
                open={modalOpen}
                closeModal={modal.close}
                t={t}
                products={products}
                groupName={groupName}
                campaignURL={campaignURL}
            />
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withRouter(connect(mapStateToProps)(CakeStartPage));
