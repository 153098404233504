// @ts-check

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";

import "moment/locale/nb";

import "./index.css";
import App from "./app/App";
import configureStore from "./redux/store";

import { initI18Next } from "./localization/i18n";
import { REACT_APP_VERSION, ENVIRONMENT } from "./Env";

initI18Next();

// Activate local Sentry debugging by commenting out this if statement
if (ENVIRONMENT !== "local") {
    Sentry.init({
        dsn: "https://25e4de972fda4a97b148141a1749bc8f@o74102.ingest.sentry.io/5517464",
        environment: ENVIRONMENT,
        integrations: [new Sentry.BrowserTracing()],
        release: REACT_APP_VERSION,
        sampleRate: 0.25, // send 25% of exceptions to Sentry
    });
}

ReactDOM.render(
    <Provider store={configureStore()}>
        <App />
    </Provider>,
    document.getElementById("root")
);
