import React from "react";
import * as styles from "./GlassContainer.module.scss";

const GlassContainer = ({ className, children }) => {
    return (
        <div className={styles.glassWrapper}>
            <div className={[styles.glassContainer, className].join(" ")}>
                {children}
            </div>
        </div>
    );
};

export default GlassContainer;
