import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { Header, HeaderInner } from "../game/BallGame.styled";
import {
    ButtonHolder,
    CheckboxBed,
    CheckboxLabel,
    ContentHolder,
    FormHolder,
    FormLabel,
    Input,
    LabelText,
} from "./SpotTheBallForm.container.styled";
import Avatar from "../../../components/avatar/Avatar";
import { QuizNormalButton } from "../../../components/button/Buttons";
import parsePhoneNumber from "libphonenumber-js";

import { initiateStandardPayment } from "../../../utils/payment";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAlpha2CountryCodeByAlpha3Code } from "../../../utils/localeHelpers";

class SpotTheBallForm extends Component {
    state = {
        name: { value: "", error: false, isEmpty: true },
        phoneNumber: { value: "", error: false, isEmpty: true },
        email: { value: "", error: false, isEmpty: true },
        anonymize: { value: false },
        terms: { value: false },
        isSubmitting: false,
        hasSubmitted: false,
    };

    onBuyerNameChange = (val) => {
        const state = { ...this.state };
        state["name"].value = val;

        this.setState(state, () => this.validateForm());
    };

    onBuyerPhoneNumberChange = (val) => {
        const state = { ...this.state };
        state["phoneNumber"].value = val;

        this.setState(state, () => this.validateForm());
    };

    onBuyerEmailChange = (val) => {
        const state = { ...this.state };
        state["email"].value = val;

        this.setState(state, () => this.validateForm());
    };

    onCheckBoxChange = (val, key) => {
        const state = { ...this.state };
        state[key].value = val;

        this.setState(state);
    };

    parsePhoneNumberByCountry = (phoneNumber) => {
        const {
            seller: { countryCode },
        } = this.props;
        return parsePhoneNumber(
            phoneNumber,
            getAlpha2CountryCodeByAlpha3Code(countryCode)
        );
    };

    validateForm = () => {
        const { name, phoneNumber, email } = this.state;
        const formattedPhoneNumber = this.parsePhoneNumberByCountry(
            phoneNumber.value
        );
        const formData = {
            name: {
                value: name.value,
                error: !name.value,
                isEmpty: name.value.trim() === "",
            },
            email: {
                value: email.value,
                error: !email.value || !/\S+@\S+\.\S+/.test(email.value),
                isEmpty: email.value.trim() === "",
            },
            phoneNumber: {
                value: phoneNumber.value,
                error:
                    !phoneNumber.value ||
                    !formattedPhoneNumber ||
                    !formattedPhoneNumber.isValid(),
                isEmpty: phoneNumber.value.trim() === "",
            },
        };
        this.setState(formData);
        return !(
            formData.name.error ||
            formData.email.error ||
            formData.phoneNumber.error
        );
    };

    handlePaymentError = (error) => {
        this.setState({ isSubmitting: false });
        toast(
            error.response?.data?.message ||
                this.props.t("toast.form_submit_error"),
            { type: "error" }
        );
    };

    onSubmitClicked = () => {
        const {
            seller,
            contribution,
            quantity,
            source,
            profileId,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const {
            name: { value: name },
            phoneNumber: { value: phoneNumber },
            email: { value: email },
            anonymize: { value: anonymize },
        } = this.state;

        this.setState({ hasSubmitted: true });
        if (!this.validateForm()) {
            return;
        }

        const formattedPhoneNumber =
            this.parsePhoneNumberByCountry(phoneNumber);
        this.setState({ isSubmitting: true });

        initiateStandardPayment({
            cart: [
                {
                    quantity,
                    contribution,
                },
            ],
            seller,
            type: "CARD",
            source,
            sellerProfileId: profileId,
            formData: {
                name,
                phoneNumber: formattedPhoneNumber.number,
                email,
                anonymous: anonymize,
            },
            errorCallback: this.handlePaymentError,
            salesLinkCodeOrShortId,
        });
    };

    render() {
        const {
            t,
            seller: { sellerImage, shortId, endTime },
        } = this.props;
        const {
            name,
            phoneNumber,
            email,
            anonymize,
            isSubmitting,
            hasSubmitted,
            terms,
        } = this.state;

        const endDate = moment(endTime);

        const FormLabelExt = ({ error, isEmpty, children }) => (
            <>
                {!hasSubmitted && <FormLabel>{children}</FormLabel>}
                {hasSubmitted && (
                    <FormLabel style={{ color: error ? "#cc0000" : "inherit" }}>
                        {children}
                        {isEmpty && <span>&nbsp;({t("error.required")})</span>}
                    </FormLabel>
                )}
            </>
        );

        return (
            <div style={{ color: "#4c4c4c" }}>
                <Header>
                    <HeaderInner>
                        <div
                            style={{
                                position: "absolute",
                                left: "50%",
                                marginLeft: "-50px",
                                bottom: "-40px",
                            }}
                        >
                            <Avatar
                                width="100px"
                                imageUrl={sellerImage}
                                shortId={`${shortId.substring(0, 6)}B`}
                                style={{ border: "1px solid white" }}
                            />
                        </div>
                    </HeaderInner>
                </Header>
                <ContentHolder
                    style={{ padding: "0 20px", boxSizing: "border-box" }}
                >
                    <FormHolder style={{ marginTop: "50px" }}>
                        <h2
                            style={{
                                textAlign: "center",
                                marginBottom: "0",
                            }}
                        >
                            {t("landing.please_enter_contact_details")}
                        </h2>
                        <p
                            style={{
                                textAlign: "center",
                                marginTop: "10px",
                                marginBottom: "0",
                            }}
                        >
                            {t("landing.enter_contact_details_description", {
                                endDate: endDate.format("Do MMMM"),
                            })}
                        </p>
                        <FormLabelExt error={name.error} isEmpty={name.isEmpty}>
                            {t("general.your_name")}
                        </FormLabelExt>
                        <Input
                            onChange={(e) =>
                                this.onBuyerNameChange(e.target.value)
                            }
                            id="your_name_input"
                            type="text"
                            placeholder={t("general.your_name")}
                            value={name.value}
                            required
                        />
                        <FormLabelExt
                            error={phoneNumber.error}
                            isEmpty={phoneNumber.isEmpty}
                        >
                            {t(
                                hasSubmitted &&
                                    phoneNumber.error &&
                                    !phoneNumber.isEmpty
                                    ? "error.error_invalid_phone_number"
                                    : "general.phoneNumber"
                            )}
                        </FormLabelExt>
                        <Input
                            type="tel"
                            onChange={(e) =>
                                this.onBuyerPhoneNumberChange(e.target.value)
                            }
                            id="your_phone_input"
                            value={phoneNumber.value}
                            placeholder={t("stb.your_phone")}
                            required
                        />

                        <FormLabelExt
                            error={email.error}
                            isEmpty={email.isEmpty}
                        >
                            {t(
                                hasSubmitted && email.error && !email.isEmpty
                                    ? "error.error_invalid_email"
                                    : "general.your_email"
                            )}
                        </FormLabelExt>
                        <Input
                            type="email"
                            onChange={(e) =>
                                this.onBuyerEmailChange(e.target.value)
                            }
                            id="your_email_input"
                            value={email.value}
                            placeholder={t("general.your_email")}
                            required
                        />

                        <CheckboxLabel>
                            <CheckboxBed>
                                <input
                                    type="checkbox"
                                    id="terms"
                                    onChange={(e) =>
                                        this.onCheckBoxChange(
                                            e.target.checked,
                                            "terms"
                                        )
                                    }
                                    checked={terms.value}
                                />
                            </CheckboxBed>
                            <LabelText
                                for="terms"
                                dangerouslySetInnerHTML={{
                                    __html: t("stb.terms"),
                                }}
                            />
                        </CheckboxLabel>

                        <CheckboxLabel>
                            <CheckboxBed>
                                <input
                                    id="highScore"
                                    type="checkbox"
                                    onChange={(e) =>
                                        this.onCheckBoxChange(
                                            !e.target.checked,
                                            "anonymize"
                                        )
                                    }
                                    checked={!anonymize.value}
                                />
                            </CheckboxBed>
                            <LabelText for="highScore">
                                {t("form.show_on_high_score_description")}
                            </LabelText>
                        </CheckboxLabel>
                        <ButtonHolder>
                            <QuizNormalButton
                                onClick={() => this.onSubmitClicked()}
                                disabled={isSubmitting || !terms.value}
                                loading={isSubmitting}
                            >
                                {t("actions.submit")}
                            </QuizNormalButton>
                        </ButtonHolder>
                    </FormHolder>
                </ContentHolder>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(SpotTheBallForm))
);
