import { getCartTotalPrice } from "../../../pages/campaigns/shopping-cart/ShoppingCart.utils";
import { Seller } from "../../../api/types";
import { CartItem } from "../../../redux/sellerReducer";

export function getFreeShippingCountdown(
    cart: CartItem[],
    seller: Seller
): number | null {
    const freeShippingThreshold = seller?.productSet?.freeShippingThreshold;
    if (freeShippingThreshold)
        return freeShippingThreshold - getCartTotalPrice(cart, seller);
    else return null;
}
