import styled from "styled-components";

import * as primaryBg from "../../../assets/background-blue.jpg";

export const STBProductsWrapper = styled.div`
    margin: 0 auto;
    h2 {
        margin-top: 15px;
        font-size: 24px;
        font-weight: 600;
        line-height: 0.96;
        letter-spacing: normal;
    }
    .link,
    .link:visited,
    .link:focus,
    .link:active {
        color: #47b7cb;
        cursor: pointer;
    }
`;

export const STBDescription = styled.h1`
    text-align: ${({ isSubTitle }) => (isSubTitle ? "center" : "left")};
    margin-top: 10px;
    margin-bottom: 10px;
    padding: ${({ isSubTitle }) => (isSubTitle ? "0 10px" : "0")};
    font-size: 18px;
    font-weight: 400;
    color: white;
    word-break: break-word;
`;

export const STBProductCard = styled.div`
    border-radius: 3px;
    box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    box-sizing: border-box;
    max-width: 320px;
    margin: 0 auto;
    position: relative;
`;

export const STBProductCardPriceLabel = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 4px;
    color: white;
    font-weight: normal;
    background-color: turquoise;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
`;

export const STBProductCardInner = styled.div`
    padding: 10px;
`;

export const STBBuyButtonHolder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 15px auto;

    div:not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const STBLandingHeader = styled.div`
    background-image: url(${({ background }) => background});
    width: 100%;
    min-height: 180px;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: auto;
    position: relative;
`;

export const STBBackgroundBanner = styled.div`
    background-image: url(${({ background }) => background});
    width: 100%;
    min-height: 480px;
    background-position-x: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    box-sizing: border-box;

    @media screen and (max-width: 740px) {
        min-height: 280px;
    }
`;

export const STBBackgroundBannerInner = styled.div`
    display: flex;
    width: 100%;
    max-width: 540px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px;
    align-items: center;
    color: white;

    h2 {
        font-size: 30px;
        margin-bottom: 0;
    }
    p {
        margin-top: 10px;
    }
    h2,
    p {
        color: white;
    }
    img {
        max-width: 120px !important;
    }

    div:last-child {
        width: 100%;
        padding-left: 20px;
    }

    @media screen and (max-width: 740px) {
        min-height: 280px;
        padding: 20px 15px;

        img {
            max-width: 90px !important;
        }
    }
`;

export const STBTwoColumn = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 500px;
    padding: 20px;
    margin-top: 40px;
    box-sizing: border-box;

    &.how-does-it-work {
        img {
            max-width: 130px;
        }
    }
    img {
        max-width: 120px;
    }

    div:last-child {
        text-align: right;
        padding-left: 15px;
    }

    @media screen and (max-width: 740px) {
        padding: 20px 15px;

        &.how-does-it-work {
            img {
                max-width: 100px;
            }
        }
        img {
            max-width: 90px;
        }
    }

    h2 {
        font-size: 30px;
        margin-bottom: 0;
    }

    p {
        margin-top: 10px;
    }
`;

export const OuterContentHolder = styled.div`
    width: 100%;
    box-sizing: border-box;
`;

export const InnerContentHolder = styled.div`
    max-width: 540px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    box-sizing: border-box;
`;

export const STBHeader = styled.div`
    background-image: url(${primaryBg});
    width: 100%;
    min-height: ${({ minHeight }) => (minHeight ? minHeight : "180px")};
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-position-x: center;
    position: relative;

    @media (max-width: 720px) {
        background-size: 1024px;
        min-height: 95px;
    }
`;

export const STBTwoColumnDescription = styled.p``;
