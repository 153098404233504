import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { FooterLogo } from "./Footer.styled";
import { SellerReducerType } from "../../utils/test/testdata";

export const defaultBuyerTermsLink =
    "https://www.spond.com/fundraising-purchasing-terms-products";

const CustomProductFooter = () => {
    const { t } = useTranslation();
    return (
        <span
            dangerouslySetInnerHTML={{
                __html: t("footer.privacy_link_label_magazines"),
            }}
        />
    );
};

const ProductFooter = ({
    buyerTermsLink,
}: {
    buyerTermsLink?: string | null;
}) => {
    const { t } = useTranslation();
    return (
        <a href={buyerTermsLink || defaultBuyerTermsLink} target="_blank">
            {t("footer.privacy_link_label")}
        </a>
    );
};

const Footer = ({
    seller,
    cart,
    isCustomProductFooter,
}: SellerReducerType & { isCustomProductFooter: boolean }) => {
    const [stickyCartIsShowing, setStickyCartIsShowing] = useState(false);
    const buyerTermsLink = seller?.buyerTermsLink;
    const { t } = useTranslation();

    useEffect(() => {
        setStickyCartIsShowing(
            document.getElementsByClassName("sticky-shopping-cart").length > 0
        );
    }, [cart]);

    const isProbablyPhone =
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPhone/i);

    const stickyBottomExtraPaddingsstyle = isProbablyPhone
        ? "calc(90px + (2 * env(safe-area-inset-bottom)))"
        : "90px";

    if (!seller) return null;
    return (
        <footer
            style={{
                paddingBottom: stickyCartIsShowing
                    ? stickyBottomExtraPaddingsstyle
                    : "0px",
            }}
        >
            <div style={{ padding: "15px" }}>
                <FooterLogo />
                <p
                    style={{ textAlign: "center" }}
                    dangerouslySetInnerHTML={{ __html: t("footer.promotion") }}
                />
                <p style={{ textAlign: "center" }}>
                    {t("footer.body", { groupName: seller.groupName })}
                </p>
                <p
                    style={{ textAlign: "center" }}
                    dangerouslySetInnerHTML={{
                        __html: t("footer.contact", {
                            campaignURL: encodeURIComponent(
                                window.location.href
                            ),
                        }),
                    }}
                />
                <p style={{ textAlign: "center" }}>
                    {isCustomProductFooter ? (
                        <CustomProductFooter />
                    ) : (
                        <ProductFooter buyerTermsLink={buyerTermsLink} />
                    )}
                    &nbsp;|&nbsp;
                    <a
                        href="https://www.spond.com/cookies"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("footer.cookies_link_label")}
                    </a>
                </p>
            </div>
        </footer>
    );
};

const mapStateToProps = (state: any) => ({
    seller: state.sellerReducer.seller,
    cart: state.sellerReducer.cart,
});

export default connect(mapStateToProps)(Footer);
