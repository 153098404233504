import React from "react";
import ReactConfetti from "react-confetti";
import useWindowSize from "../../hooks/useWindowSize";
import * as styles from "./Scrim.module.scss";

const Scrim = ({ show }) => {
    const { width, height } = useWindowSize();
    return (
        <div className={[styles.scrim, show ? styles.show : ""].join(" ")}>
            {show && (
                <ReactConfetti
                    width={width}
                    height={height}
                    recycle={false}
                    numberOfPieces={1000}
                    initialVelocityY={20}
                    initialVelocityX={10}
                    confettiSource={{ x: width / 2, y: height / 2 }}
                />
            )}
        </div>
    );
};

export default Scrim;
