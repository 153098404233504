export type FeatureToggles = {
    toggles: FeatureToggle[];
};

export enum ScratchCardExperiment {
    TICKET_BUNDLES_1_3_5 = "ticket_bundles_1_3_5",
    TICKET_BUNDLES_5_3_1 = "ticket_bundles_5_3_1",
    SUBSCRIPTION_TEST = "subscription_test",
    REFERENCE = "reference",
}

export enum FeatureToggleName {
    FundraisingRetention = "FundraisingRetention",
    OngoingMessageTime = "OngoingMessageTime",
    FundraisingLayoutImprovement = "FundraisingLayoutImprovement",
    ATTPrompt = "ATTPrompt",
    BrandPartnershipExposurePotentialMixpanelTracking = "BrandPartnershipExposurePotentialMixpanelTracking",
    CreateFRCWithoutPayoutAccount = "CreateFRCWithoutPayoutAccount",
    FundraisingLandingPageExperiments = "FundraisingLandingPageExperiments",
}

export type FeatureToggle = {
    name: FeatureToggleName;
    enabled: boolean;
    impressionData: boolean;
    variant: {
        name: string;
        enabled: boolean;
    };
};

export type ProductVariationParam = {
    id: string;
    name: string;
    images: string[];
};

export type Product = {
    id: string;
    preview: string;
    name: string;
    images: string[];
    price: number;
    variations: ProductVariationParam[];
    description: string;
};

export type ProductSet = {
    title: string;
    description: string;
    deliveryTime: string;
    learnMoreDesc: string;
    learnMoreLink: string;
    shippingCost?: number | null;
    freeShippingThreshold?: number | null;
    products: Product[];
};

export type Theme = {
    background?: string;
    primaryColour?: string;
    primaryButtonColour?: string;
    primaryButtonStrokeColour?: string;
    primaryButtonTextColour?: string;
    landingBackground?: string;
    landingScratchImage?: string;
    prizeInfoImage?: string;
    fullscreenBackground?: string;
};

export type ThemeWithId = {
    id: string;
} & Theme;

export type CampaignStatus = "UPCOMING" | "ACTIVE" | "CLOSED";

export type CampaignType =
    | "PRODUCTS"
    | "SCRATCHCARDS"
    | "SPOT_THE_BALL"
    | "RAFFLE"
    | "SUPERDRAW";

export type Seller = {
    groupId: string;
    logo?: string;
    displayName: string;
    extraProduct?: Product;
    sellerImage: string;
    shortId: string;
    sellerFirstName: string;
    sellerLastName: string;
    groupName: string;
    description: string;
    type?: CampaignType;
    superdrawDrawTime?: string;
    groupSeller: string;
    countryCode: string;
    products: Product[];
    productSet?: ProductSet;
    theme: ThemeWithId;
    campaignStatus: CampaignStatus;
    buyerTermsLink?: string | null;
    scratchcardPrice: number;
    isSTB?: boolean;
};
export type OrderParam = {
    id?: string;
    status?: OrderStatus;
    sellerId?: string;
    sellerProfileId?: string;
    source?: string;
    buyerName?: string;
    buyerEmail?: string;
    buyerPhone?: string;
    anonymous?: boolean;
    purchases?: OrderItemParam[];
    tickets?: OrderLotteryTicketParam[];
    attempts?: OrderSpotAttemptParam[];
    raffleTickets?: OrderRaffleTicketParam[];
    superdrawTickets?: OrderSuperdrawTicketParam[];
    superdrawWinners?: OrderSuperdrawTicketParam[];
    superdrawPrizes?: SuperdrawPrizeParam[];
    superdrawDrawTime?: string;
    gameImage?: SpotTheBallImageParam;
    address?: AddressParam;
    prize?: PrizeClaimParam;
    paymentToken?: PaymentTokenData;
    salesLinkCode?: string | null;
    mixpanelFields?: MixpanelFields;
};

export type MixpanelFields = {
    experimentName: ScratchCardExperiment;
    ticketCount: number;
    recurringSelected: boolean;
};

export enum OrderStatus {
    PENDING,
    PAYMENT_RESERVED,
    PAYMENT_RESERVE_FAILED,
    PAYMENT_CAPTURED,
    PAYMENT_CAPTURE_FAILED,
    FULFILLED,
    REFUNDED,
    ABORTED,
    PAID,
}

export type OrderLotteryTicketParam = {
    id: string;
    revealed: boolean;
    hasPrize: boolean;
    prizeClaimed: boolean;
    fieldIcons: string[];
};

export type OrderRaffleTicketParam = {
    id: string;
    label: string;
    winningLabel: string;
    hasPrize: boolean;
    revealed: boolean;
    prizeClaimed: boolean;
};

export type OrderSuperdrawTicketParam = {
    id: string;
    key: string;
    isWinner: boolean;
    isRevealed: boolean;
    prizeId: string;
};

export type SuperdrawPrizeParam = {
    id: string;
    title: string;
    description: string;
    previewUrl: string;
    videoUrl: string;
    type: string;

    currency: string;
    amount: number;
};

export type SpotTheBallImageParam = {
    url: string;
    height: number;
    width: number;
    ballX: number;
    ballY: number;
    isRevealed: boolean;
    isWinner: boolean;
};

export enum GiftCardbrand {
    AMAZON,
    REWARDS,
}

export enum PrizeType {
    GIFT_CARD,
    PROVIDED,
    PRODUCT,
}

export type PrizeClaimParam = {
    description: string;
    type: PrizeType;
    availableBrands: GiftCardbrand[];
    brand: string;
    cardValue: number;
    currency: string;
    claimed: boolean;
};

export type PaymentTokenData = {
    protocolVersion: string;
    signedMessage: string;
    signature: string;
    version: string;
    data: string;
    ephemeralPublicKey: string;
    publicKeyHash: string;
    transactionId: string;
};

export type AddressParam = {
    address1?: string;
    address2?: string;
    postCode?: string;
    city?: string;
    countryCode?: string;
};

export type OrderItemParam = {
    productId: string;
    variantId: string;
    quantity: number;
    contribution: number;
    name: string;
    variantName: string;
    label: string;
};

export type OrderPlacementParam = {
    name: string;
    email: string;
    phoneNumber: string;
    address: AddressParam;
    prizeSelection: OrderItemParam;
};

export type OrderSpotAttemptParam = {
    name: string;
    xCoord: number;
    yCoord: number;
    resX: number;
    resY: number;
    distance: number;
    rank: number;
    isCurrentUser: boolean;
    anonymous: boolean;
    winner: boolean;
};

export type PriceTier = {
    textColour: string;
    label: string;
    bgColour: string;
};

export type Ticket = {
    id: string;
    revealed: boolean;
    fieldIcons: Array<string>;
    hasPrize: boolean;
    prizeClaimed: boolean;
};
