import styled from "styled-components";
import { COLORS } from "../../../data/consts";

export const CartContentsHolder = styled.div`
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    margin: 25px auto;
`;

export const EmptyCartLabel = styled.p`
    text-align: center;
`;

export const CartItemWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0;

    & + & {
        border-top: 1px solid #e4e4e4;
    }
`;

export const CartItemImage = styled.div`
    padding-right: 15px;
    cursor: pointer;

    img {
        vertical-align: middle;
    }
`;

export const CartItemDescription = styled.div`
    font-weight: 500;
    cursor: pointer;
`;

export const CartItemDetails = styled.div`
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    flex: 1;
`;

export const CartItemQuantity = styled.div``;

export const CartItemPrice = styled.div`
    display: flex;
    flex-direction: column;
    text-align: right;
    min-width: 80px;
    padding-left: 15px;
    box-sizing: border-box;
`;

export const CartItemTotalLabel = styled.div`
    font-weight: 600;
    font-size: 18px;
`;

export const CartItemTotalPriceLabel = styled.div`
    display: flex;
    justify-content: flex-end;
    flex: 1;
    font-weight: 600;
    font-size: 18px;
`;

export const CheckoutTerms = styled.div`
    text-align: center;
    color: ${COLORS.$spondGray3BasePrimary};
    font-weight: 500;
    margin-top: 25px;

    & a {
        color: #757575;
    }
`;
