import React from "react";
import { Link, useParams } from "react-router-dom";

import SpondImage from "../spond-image/SpondImage";
import { routes } from "../../app/routes";

type Props = {
    imageUrl?: string;
};

type Params = {
    salesLinkCodeOrShortId: string;
};

export default ({ imageUrl }: Props) => {
    const { salesLinkCodeOrShortId } = useParams<Params>();
    return (
        <Link to={routes.seller(salesLinkCodeOrShortId).pathname}>
            <SpondImage
                imageUrl={imageUrl}
                transformation="t_avatar"
                style={{
                    width: "125px",
                    height: "125px",
                    objectFit: "cover",
                    borderRadius: "50%",
                }}
            />
        </Link>
    );
};
