import React from "react";

const dots = [
    () => (
        <svg
            width="7"
            height="7"
            viewBox="0 0 7 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="2.0874"
                y="7.56543"
                width="5.99267"
                height="5.99267"
                rx="2.99634"
                transform="rotate(-121.011 2.0874 7.56543)"
                fill="#2D9CDB"
            />
        </svg>
    ),
    () => (
        <svg
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="11.928"
                y="4.26953"
                width="9.58827"
                height="9.58827"
                rx="4.79414"
                transform="rotate(127.235 11.928 4.26953)"
                fill="#2D9CDB"
            />
        </svg>
    ),
    () => (
        <svg
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="20.2322"
                y="15.0947"
                width="16.7795"
                height="16.7795"
                rx="5"
                transform="rotate(163.577 20.2322 15.0947)"
                fill="#6FCF97"
            />
        </svg>
    ),
    () => (
        <svg
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="10.8792"
                y="13.8384"
                width="11.9853"
                height="11.9853"
                rx="5"
                transform="rotate(-163.906 10.8792 13.8384)"
                fill="#EB5757"
            />
        </svg>
    ),
    () => (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="20.7544"
                y="15.2515"
                width="16.7795"
                height="16.7795"
                rx="5"
                transform="rotate(159.636 20.7544 15.2515)"
                fill="#F2C94C"
            />
        </svg>
    ),
    () => (
        <svg
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="6.4741"
                cy="7.08091"
                r="6.30011"
                transform="rotate(-20.093 6.4741 7.08091)"
                fill="#2F80ED"
            />
        </svg>
    ),
];

export default dots;
