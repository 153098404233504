import styled from "styled-components";

export const NoteBed = styled.div<{ hasSellerImage: boolean }>`
    display: flex;
    justify-content: center;
    margin-top: ${({ hasSellerImage }) => (hasSellerImage ? "30px" : "0px")};
`;

export const NoteInnerBed = styled.div`
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 12px 20px;
    max-width: 400px;
`;

export const NoteRow = styled.div`
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 2px;
`;

export const NoteActionRow = styled(NoteRow)`
    color: ${({ theme }) => theme.primaryButtonColour};
    &:hover {
        cursor: pointer;
    }
`;
