import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { Header, HeaderInner } from "../game/BallGame.styled";
import Avatar from "../../../components/avatar/Avatar";
import {
    QuizNormalButton,
    QuizOutlineButton,
} from "../../../components/button/Buttons";
import SpotTheBallResultCanvas from "../../../components/spot-the-ball/SpotTheBallResultCanvas.component";
import { getShareLink, proceedNavigateShare } from "../../../utils/share";
import {
    getShareEmailBody,
    getShareEmailSubject,
} from "../../../utils/strings/strings";

import {
    ActionsBed,
    ButtonBed,
    ContentHolder,
    DescriptionBed,
    EmailLink,
    ShareActionsBed,
    ThankYouBed,
    YourSubmissionDescription,
    YourSubmissionTitle,
} from "./SpotTheBallThankYou.container.styled";
import DOMPurify from "dompurify";
import { routes } from "../../../app/routes";

class SpotTheBallThankYou extends Component {
    onFacebookShareClicked() {
        const {
            seller,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;

        if (window.FB) {
            window.FB.ui(
                {
                    method: "share",
                    href: getShareLink(salesLinkCodeOrShortId),
                },
                function (response) {}
            );
        } else {
            console.error("FB is undefined");
        }
    }

    onNavigatorShareClicked = () => {
        const {
            seller,
            t,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        proceedNavigateShare({
            seller,
            t,
            salesLinkCodeOrShortId,
        });
    };

    onPlayAgain = () => {
        const {
            history,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        history.push(routes.seller(salesLinkCodeOrShortId).pathname);
    };

    render() {
        const {
            seller: {
                sellerImage,
                shortId,
                sellerFirstName,
                sellerLastName,
                groupName,
                startDate,
                endTime,
                description,
                groupSeller,
                countryCode,
            },
            contest,
            t,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { gameImage, attempts } = contest;
        const representHimself = shortId?.endsWith("0");

        const sellerName = sellerFirstName
            ? sellerFirstName + (sellerLastName ? " " + sellerLastName : "")
            : "";

        const endDate = moment(endTime);

        return (
            <>
                <Header>
                    <HeaderInner>
                        <div
                            style={{
                                position: "absolute",
                                left: "50%",
                                marginLeft: "-50px",
                                bottom: "-40px",
                            }}
                        >
                            <Avatar
                                width="100px"
                                imageUrl={sellerImage}
                                style={{ border: "1px solid white" }}
                            />
                        </div>
                    </HeaderInner>
                </Header>
                <ContentHolder>
                    <ThankYouBed>
                        <h2 style={{ textAlign: "center" }}>
                            {t("no_tickets.title")}
                        </h2>
                        <DescriptionBed
                            dangerouslySetInnerHTML={{
                                __html: t(
                                    "landing.spot_thank_you_description",
                                    {
                                        string1: DOMPurify.sanitize(
                                            groupSeller ||
                                                (representHimself &&
                                                    countryCode === "GBR")
                                                ? `${groupName}`
                                                : `${sellerFirstName} and ${groupName}`,
                                            { USE_PROFILES: { html: false } }
                                        ),
                                        string2: endDate.format("MMMM Do"),
                                    }
                                ),
                            }}
                        ></DescriptionBed>
                        <ActionsBed>
                            <QuizNormalButton
                                onClick={() => this.onPlayAgain()}
                            >
                                {t("actions.play_again")}
                            </QuizNormalButton>
                        </ActionsBed>
                        <ShareActionsBed>
                            {navigator.share && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginBottom: "15px",
                                    }}
                                >
                                    <QuizOutlineButton
                                        onClick={() =>
                                            this.onNavigatorShareClicked()
                                        }
                                    >
                                        Share fundraiser
                                    </QuizOutlineButton>
                                </div>
                            )}
                            {!navigator.share && (
                                <>
                                    {window.FB && (
                                        <ButtonBed>
                                            <QuizOutlineButton
                                                onClick={() =>
                                                    this.onFacebookShareClicked()
                                                }
                                            >
                                                {t(
                                                    "order_successful.share_facebook"
                                                )}
                                            </QuizOutlineButton>
                                        </ButtonBed>
                                    )}
                                    <ButtonBed>
                                        <EmailLink
                                            href={`mailto:?subject=${getShareEmailSubject(
                                                t,
                                                sellerName,
                                                groupName
                                            )}&body=${getShareEmailBody(
                                                t,
                                                description,
                                                getShareLink(
                                                    salesLinkCodeOrShortId
                                                )
                                            )}`}
                                        >
                                            <QuizOutlineButton>
                                                {t(
                                                    "order_successful.share_email"
                                                )}
                                            </QuizOutlineButton>
                                        </EmailLink>
                                    </ButtonBed>
                                </>
                            )}
                        </ShareActionsBed>
                        <YourSubmissionTitle>
                            {t("landing.your_submission")}
                        </YourSubmissionTitle>
                        <YourSubmissionDescription>
                            <SpotTheBallResultCanvas
                                imageUrl={gameImage.url}
                                width={gameImage.width}
                                height={gameImage.height}
                                attempts={attempts}
                            />
                        </YourSubmissionDescription>
                    </ThankYouBed>
                </ContentHolder>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(SpotTheBallThankYou))
);
