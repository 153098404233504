import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { trackPageView } from "../../EventTracker";

class NotFoundPage extends Component {
    componentDidMount() {
        trackPageView("NotFound");
    }

    render() {
        const { t } = this.props;

        return <h1 style={{ textAlign: "center" }}>{t("not_found.body")}</h1>;
    }
}

export default withTranslation()(NotFoundPage);
