import React, { Component } from "react";
import Modal from "react-responsive-modal";

import { CardButton, VippsButton } from "../../../components/button/Buttons";
import { initiateStandardPayment } from "../../../utils/payment";
import * as mixpanelEvents from "../../../mixpanelEvents";

import "./PreCheckoutModal.container.scss";
import {
    FormGroup,
    Heading,
    InputElement,
    Label,
    Wrapper,
} from "./PreCheckoutModal.container.styled";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import qs from "query-string";
import { withRouter } from "react-router-dom";

const InputWrapper = ({ type, name, ...rest }) => (
    <InputElement type={type} name={name} {...rest} />
);

const KEY_LOCAL_STORAGE_FORM = "frc_checkout_form";

class PreCheckoutModal extends Component {
    state = {
        isCardSubmitting: false,
        isVippsSubmitting: false,
        form: {
            buyerName: { value: "", error: false },
            buyerEmail: { value: "", error: false },
        },
    };

    componentDidMount() {
        const localForm = localStorage.getItem(KEY_LOCAL_STORAGE_FORM);

        if (localForm) {
            const { buyerName, buyerEmail } = JSON.parse(localForm);
            const { form } = this.state;
            form.buyerName.value = buyerName;
            form.buyerEmail.value = buyerEmail;
            this.setState({ form });
        }
    }

    handlePaymentError = (err) => {
        toast(this.props.t("toast.form_submit_error"), { type: "error" });
        this.setState({
            isCardSubmitting: false,
            isVippsSubmitting: false,
        });
    };

    onBuyClicked(type) {
        if (!this.validateForm()) return;
        const {
            ticketCount,
            seller,
            contribution,
            location,
            mixpanelFields,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const {
            form: { buyerName, buyerEmail },
        } = this.state;
        const { source, profileId } = qs.parse(location.search);

        this.setState({
            isCardSubmitting: type === "CARD",
            isVippsSubmitting: type === "VIPPS",
        });

        initiateStandardPayment({
            cart: [
                {
                    undefined,
                    quantity: ticketCount,
                    contribution,
                },
            ],
            seller,
            type,
            source,
            sellerProfileId: profileId,
            formData: {
                name: buyerName.value,
                email: buyerEmail.value,
            },
            errorCallback: this.handlePaymentError,
            salesLinkCodeOrShortId,
            mixpanelFields,
        });

        localStorage.setItem(
            KEY_LOCAL_STORAGE_FORM,
            JSON.stringify({
                buyerName: buyerName.value,
                buyerEmail: buyerEmail.value,
            })
        );

        mixpanelEvents.pressedPayButton({
            type,
            ticket_count: ticketCount,
        });
    }

    validateForm() {
        const {
            form: { buyerName, buyerEmail },
        } = this.state;
        const EMAIL_REGEX = /\S+@\S+\.\S+/;

        return !(
            buyerName.value.length < 2 || !EMAIL_REGEX.test(buyerEmail.value)
        );
    }

    onFormChange(key, val) {
        const { form } = this.state;
        form[key].value = val;
        this.setState({ form });
    }

    render() {
        const { t, ticketCount, seller, open } = this.props;
        const {
            form: { buyerName, buyerEmail },
            isCardSubmitting,
            isVippsSubmitting,
        } = this.state;
        const { sellerFirstName: firstName, groupName } = seller;

        const isValidForm = this.validateForm();

        return (
            <Modal
                center={true}
                open={open}
                onClose={() => {}}
                showCloseIcon={false}
                classNames={{
                    overlay: "pre-checkout-modal-overlay",
                    modal: "pre-checkout-modal",
                }}
            >
                <Wrapper>
                    <Heading>
                        {!!firstName
                            ? t("landing.title", {
                                  firstName,
                                  groupName,
                              })
                            : t("landing.title_organisation_seller", {
                                  groupName,
                              })}
                    </Heading>
                    <FormGroup>
                        <Label>{t("general.your_name")}</Label>
                        <InputWrapper
                            type="text"
                            name="name"
                            placeholder={t("general.your_name")}
                            value={buyerName.value}
                            onChange={(e) =>
                                this.onFormChange("buyerName", e.target.value)
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{t("general.email")}</Label>
                        <InputWrapper
                            type="email"
                            name="email"
                            placeholder={t("general.your_email")}
                            value={buyerEmail.value}
                            onChange={(e) =>
                                this.onFormChange("buyerEmail", e.target.value)
                            }
                        />
                        <span>{t("checkout.email_receipt_footnote")}</span>
                    </FormGroup>
                    <FormGroup>
                        {seller.countryCode === "NOR" && (
                            <div style={{ marginBottom: "15px" }}>
                                <VippsButton
                                    onClick={() => this.onBuyClicked("VIPPS")}
                                    disabled={!isValidForm}
                                    loading={isVippsSubmitting ? 1 : undefined}
                                    style={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        marginTop: "5px",
                                    }}
                                >
                                    {t("actions.buy_with")}
                                </VippsButton>
                            </div>
                        )}
                        <CardButton
                            onClick={() => this.onBuyClicked("CARD")}
                            disabled={!isValidForm}
                            loading={isCardSubmitting ? 1 : undefined}
                            style={{
                                width: "100%",
                                maxWidth: "100%",
                                marginTop: "5px",
                            }}
                        >
                            {t("actions.buy_with_card")}
                        </CardButton>
                    </FormGroup>
                </Wrapper>
            </Modal>
        );
    }
}

export default withRouter(PreCheckoutModal);
