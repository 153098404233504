import React, { Component } from "react";

import {
    OuterContentHolder,
    InnerContentHolder,
} from "../game/BallGame.styled";
import {
    QuizNormalButton,
    QuizThinButton,
} from "../../../components/button/Buttons";
import { withTranslation } from "react-i18next";

const MAX_CANVAS_SIZE = 540;

const getCoordinates = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left,
        y = event.clientY - rect.top;
    return {
        x,
        y,
    };
};

class SpotTheBallSelectorContainer extends Component {
    state = {
        distance: null,
        hasPlacedBall: false,
        previousAttempts: [],
        canvasWidth: MAX_CANVAS_SIZE,
        canvasHeight: 0,
    };

    contentHolderRef = null;
    canvasRef = null;
    canvasBackgroundRef = null;

    ballPosX = null;
    ballPosY = null;

    hoverCursorPosX = null;
    hoverCursorPosY = null;
    isDragging = false;

    onTouchStart = (e) => {
        this.ballPosX = null;
        this.ballPosY = null;

        this.renderCanvas();
    };

    onTouchMove = (e) => {
        const touchEvent = e.touches[0];
        const { x, y } = getCoordinates(touchEvent);

        // Touch move is handled similar to dragging
        this.hoverCursorPosX = this.limitXBounds(x - 30);
        this.hoverCursorPosY = this.limitYBounds(y - 30);

        this.ballPosX = null;
        this.ballPosY = null;

        this.renderCanvas();
    };

    onTouchEnd = (e) => {
        this.ballPosX = this.limitXBounds(this.hoverCursorPosX);
        this.ballPosY = this.limitYBounds(this.hoverCursorPosY);

        this.renderCanvas();
    };

    onMouseMove = (e) => {
        const { x, y } = getCoordinates(e);

        // If the user is dragging, move the ball position and hide hover cursor
        if (this.isDragging) {
            this.hoverCursorPosX = null;
            this.hoverCursorPosY = null;

            this.ballPosX = this.limitXBounds(x);
            this.ballPosY = this.limitYBounds(y);
        } else {
            this.hoverCursorPosX = this.limitXBounds(x);
            this.hoverCursorPosY = this.limitYBounds(y);
        }

        this.renderCanvas();
    };

    onMouseDown = (e) => {
        this.isDragging = true;
        this.renderCanvas();
    };

    onMouseUp = (e) => {
        this.isDragging = false;
        this.renderCanvas();
    };

    onMouseOut = (e) => {
        this.isDragging = false;
        this.hoverCursorPosX = null;
        this.hoverCursorPosY = null;

        this.renderCanvas();
    };

    onClick = (e) => {
        const { x, y } = getCoordinates(e);

        this.hoverCursorPosX = null;
        this.hoverCursorPosY = null;

        this.ballPosX = x;
        this.ballPosY = y;

        this.renderCanvas();
    };

    onNextClicked = (e) => {
        const { previousAttempts, canvasWidth, canvasHeight } = this.state;
        const { anonymous } = this.props;

        this.setState({
            previousAttempts: [
                ...previousAttempts,
                {
                    xCoord: this.ballPosX,
                    yCoord: this.ballPosY,
                    resX: canvasWidth,
                    resY: canvasHeight,
                    anonymous: anonymous,
                },
            ],
        });

        this.ballPosX = null;
        this.ballPosY = null;

        this.setState({ hasPlacedBall: false });
    };

    limitXBounds(x) {
        if (x < 0) return 0;
        if (x > this.state.canvasWidth) return this.state.canvasWidth;
        return x;
    }

    limitYBounds(y) {
        if (y < 0) return 0;
        if (y > this.state.canvasHeight) return this.state.canvasHeight;
        return y;
    }

    onDoneClicked = (e) => {
        const { previousAttempts, canvasWidth, canvasHeight } = this.state;
        const { remainingAttempts, anonymous, onSpotCompleted } = this.props;
        let updatedPreviousAttempts = previousAttempts;
        if (previousAttempts.length < remainingAttempts) {
            updatedPreviousAttempts = [
                ...previousAttempts,
                {
                    xCoord: this.limitXBounds(this.ballPosX),
                    yCoord: this.limitYBounds(this.ballPosY),
                    resX: canvasWidth,
                    resY: canvasHeight,
                    anonymous: anonymous,
                },
            ];
            this.setState({ previousAttempts: updatedPreviousAttempts });

            this.ballPosX = null;
            this.ballPosY = null;
        }

        onSpotCompleted(updatedPreviousAttempts);
    };

    onUndoClicked = (e) => {
        const { previousAttempts } = this.state;

        if (this.ballPosX) {
            this.ballPosX = null;
            this.ballPosY = null;
            this.renderCanvas();
            return;
        }

        previousAttempts.pop();
        this.ballPosX = null;
        this.ballPosY = null;
        this.setState({ previousAttempts: [...previousAttempts] });
    };

    renderCanvas = () => {
        const { previousAttempts, canvasWidth, canvasHeight, hasPlacedBall } =
            this.state;
        const { backgroundImage } = this;
        const {
            contest: { gameImage },
        } = this.props;
        const { width, height } = gameImage;

        // Protect against undef refs while unmounting
        if (!this.backgroundCanvasRef) {
            return;
        }

        const ctxBackground = this.backgroundCanvasRef.getContext("2d");
        const backgroundCanvasRect =
            this.backgroundCanvasRef.getBoundingClientRect();
        this.backgroundCanvasRef.width =
            backgroundCanvasRect.width * devicePixelRatio;
        this.backgroundCanvasRef.height =
            backgroundCanvasRect.height * devicePixelRatio;
        ctxBackground.scale(devicePixelRatio, devicePixelRatio);

        ctxBackground.clearRect(
            0,
            0,
            this.backgroundCanvasRef.width,
            this.backgroundCanvasRef.height
        );
        ctxBackground.drawImage(
            backgroundImage,
            0,
            0,
            canvasWidth,
            canvasHeight
        );

        const ctx = this.canvasRef.getContext("2d");
        const canvasRect = this.canvasRef.getBoundingClientRect();
        this.canvasRef.width = canvasRect.width * devicePixelRatio;
        this.canvasRef.height = canvasRect.height * devicePixelRatio;
        ctx.scale(devicePixelRatio, devicePixelRatio);

        ctx.clearRect(0, 0, this.canvasRef.width, this.canvasRef.height);
        ctx.globalCompositeOperation = "multiply";

        // Draw previous guesses
        for (let i = 0; i < previousAttempts.length; i++) {
            const { xCoord, yCoord } = previousAttempts[i];

            ctx.beginPath();
            ctx.arc(xCoord, yCoord, 10, 0, 2 * Math.PI, false);
            ctx.fillStyle = "rgba(255, 255, 255, 0.75";
            ctx.fill();
        }

        // Draw clicked pos ball
        if (this.ballPosX != null) {
            ctx.beginPath();
            ctx.strokeStyle = "rgba(0, 0, 0, 0.1)";
            ctx.arc(this.ballPosX, this.ballPosY, 10, 0, 2 * Math.PI, false);
            ctx.fillStyle = "white";
            ctx.fill();
            ctx.stroke();
        }

        // Hover ball
        if (this.hoverCursorPosX != null) {
            ctx.beginPath();
            ctx.arc(
                this.hoverCursorPosX,
                this.hoverCursorPosY,
                20,
                0,
                2 * Math.PI,
                false
            );
            ctx.lineWidth = 6;
            ctx.strokeStyle = "rgba(255, 255, 255, 0.8)";
            ctx.stroke();

            ctx.beginPath();
            ctx.arc(
                this.hoverCursorPosX,
                this.hoverCursorPosY,
                5,
                0,
                2 * Math.PI,
                false
            );
            ctx.fillStyle = "rgba(255, 255, 255, 0.75";
            ctx.fill();
        }

        this.setState({
            hasPlacedBall: this.ballPosX || this.ballPosY,
        });
        /*
        // Correct placement
        const correctXFullSize = 960;
        const correctYFullSize = 660;
        const scaleFactorX = canvasWidth / width;
        const scaleFactorY = canvasHeight / height;
        const correctX = correctXFullSize*scaleFactorX;
        const correctY = correctYFullSize*scaleFactorY;

        const { distance } = this.state;
        if (this.ballPosX) {
          const newDistance = Math.round(Math.sqrt(Math.pow(this.ballPosX - correctX, 2) + Math.pow(this.ballPosY - correctY, 2)) * 100) / 100;
          if (distance !== newDistance) {
            this.setState({
              distance: newDistance,
              hasPlacedBall: true,
            });
          }
        }
        */
    };

    applyCanvasWidth = () => {
        const { backgroundImage, contentHolderRef } = this;
        const {
            canvasWidth: prevCanvasWidth,
            canvasHeight: prevCanvasHeight,
            previousAttempts,
        } = this.state;
        // Scale the canvas to fit
        const MARGIN = 20;

        // Recalculate canvas size
        if (contentHolderRef) {
            const contentHolderWidth = contentHolderRef.clientWidth;
            const canvasWidth = Math.min(contentHolderWidth, MAX_CANVAS_SIZE);
            const canvasHeight =
                (canvasWidth * backgroundImage.height) / backgroundImage.width;

            this.setState({ canvasWidth, canvasHeight }, () => {
                // Recalculate all positions
                const scaleFactorX = canvasWidth / prevCanvasWidth;
                const scaleFactorY = canvasHeight / prevCanvasHeight;

                const updatedPreviousAttempts = previousAttempts.map(
                    (coords) => {
                        return {
                            xCoord: coords.xCoord * scaleFactorX,
                            yCoord: coords.yCoord * scaleFactorY,
                            resX: canvasWidth,
                            resY: canvasHeight,
                            anonymous: coords.anonymous,
                        };
                    }
                );

                if (this.ballPosX) {
                    this.ballPosX = this.ballPosX * scaleFactorX;
                    this.ballPosY = this.ballPosY * scaleFactorX;
                }

                this.setState({ previousAttempts: updatedPreviousAttempts });
            });
        } else {
            this.renderCanvas();
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.previousAttempts !== this.state.previousAttempts) {
            this.renderCanvas();
        }
    }

    componentDidMount() {
        const {
            contest: { gameImage },
        } = this.props;

        this.backgroundImage = new Image();
        this.backgroundImage.onload = () => {
            this.applyCanvasWidth();
        };
        this.backgroundImage.src = gameImage.url;
        // TODO: Should probably add debounce
        window.addEventListener("resize", this.applyCanvasWidth);
        window.addEventListener("deviceorientation", this.applyCanvasWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.applyCanvasWidth);
        window.removeEventListener("deviceorientation", this.applyCanvasWidth);
    }

    render() {
        const { hasPlacedBall, previousAttempts, canvasWidth, canvasHeight } =
            this.state;
        const { remainingAttempts, t } = this.props;

        const sessionRemainingAttempts =
            remainingAttempts - previousAttempts.length;
        const hasMoreAttempts = sessionRemainingAttempts > 1;
        const showUndoButton =
            previousAttempts.length > 0 && remainingAttempts > 1;

        return (
            <>
                <OuterContentHolder>
                    <InnerContentHolder
                        ref={(ref) => {
                            this.contentHolderRef = ref;
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                margin: "-55px auto 30px auto",
                                width: "100%",
                                flexDirection: "column",
                                userSelect: "none",
                                outline: "none",
                                WebkitTouchCallout: "none",
                                WebkitUserSelect: "none",
                            }}
                        >
                            <div
                                style={{
                                    position: "relative",
                                    width: `${canvasWidth}px`,
                                    height: `${canvasHeight}px`,
                                    margin: "0 auto",
                                    minHeight: "120px",
                                    userSelect: "none",
                                    outline: "none",
                                    WebkitTouchCallout: "none",
                                    WebkitUserSelect: "none",
                                }}
                                id="stb_place_circle_image"
                            >
                                <canvas
                                    width={canvasWidth}
                                    height={canvasHeight}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: `${canvasWidth}px`,
                                        height: `${canvasHeight}px`,
                                        userSelect: "none",
                                        outline: "none",
                                        WebkitTouchCallout: "none",
                                        WebkitUserSelect: "none",
                                    }}
                                    ref={(ref) => {
                                        this.backgroundCanvasRef = ref;
                                    }}
                                ></canvas>
                                <canvas
                                    width={canvasWidth}
                                    height={canvasHeight}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: `${canvasWidth}px`,
                                        height: `${canvasHeight}px`,
                                        touchAction: "none",
                                        userSelect: "none",
                                        outline: "none",
                                        WebkitTouchCallout: "none",
                                        WebkitUserSelect: "none",
                                    }}
                                    ref={(ref) => {
                                        this.canvasRef = ref;
                                    }}
                                    onTouchStart={(e) => this.onTouchStart(e)}
                                    onTouchMove={(e) => this.onTouchMove(e)}
                                    onTouchEnd={(e) => this.onTouchEnd(e)}
                                    onMouseMove={(e) => this.onMouseMove(e)}
                                    onMouseOut={(e) => this.onMouseOut(e)}
                                    onMouseDown={(e) => this.onMouseDown(e)}
                                    onMouseUp={(e) => this.onMouseUp(e)}
                                    onClick={(e) => this.onClick(e)}
                                ></canvas>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "30px",
                                }}
                            >
                                {showUndoButton && (
                                    <QuizThinButton
                                        style={{ marginRight: "10px" }}
                                        onClick={() => this.onUndoClicked()}
                                    >
                                        {t("terms.back")}
                                    </QuizThinButton>
                                )}

                                {hasMoreAttempts && (
                                    <QuizNormalButton
                                        onClick={() => this.onNextClicked()}
                                        disabled={!hasPlacedBall}
                                    >
                                        {t("actions.next")}
                                    </QuizNormalButton>
                                )}
                                {!hasMoreAttempts && (
                                    <QuizNormalButton
                                        onClick={() => this.onDoneClicked()}
                                        disabled={!hasPlacedBall}
                                    >
                                        {t("actions.done")}
                                    </QuizNormalButton>
                                )}
                            </div>
                        </div>
                    </InnerContentHolder>
                </OuterContentHolder>
            </>
        );
    }
}

export default SpotTheBallSelectorContainer;
