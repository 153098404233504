import styled from "styled-components";

export const TableCell = styled.div`
    display: table-cell;
    padding: 5px 0;

    border-bottom: 1px solid #f0f0f0;
`;

export const TableRow = styled.div`
    display: table-row;

    ${TableCell}:first-child {
        padding-left: 15px;
    }

    ${TableCell}:last-child {
        padding-right: 15px;
        text-align: right;
    }
`;

export const HighscoreListHolder = styled.div`
    max-width: 380px;
    margin: 20px auto;
    text-align: left;
`;

export const HeaderTableCell = styled(TableCell)`
    color: #767676;
`;
