import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Button from "../../../components/cake-lottery/button/Button";
import CakePreview from "../../../components/cake-lottery/cake-preview/CakePreview";
import Input from "../../../components/cake-lottery/input/Input";
import Layout from "../../../components/cake-lottery/layout/Layout";
import * as styles from "./CakeShippingPage.module.scss";
import Confetti from "../../../components/cake-lottery/confetti/Confetti";
import { isValidShoppingFrom } from "../../../utils/validationHelpers";
import { apiClient } from "../../../api/ApiClient";

const CakeShippingPage = ({
    t,
    nextPage,
    missingTicketsRedirect,
    seller,
    orderId,
}) => {
    const [contactInfo, setContactInfo] = useState({
        name: "",
        email: "",
        phone: "",
        address1: "",
        postCode: "",
        city: "",
    });
    const [products, setProducts] = useState([]);
    const [deliveryInfo, setDeliveryInfo] = useState({});
    const [tickets, setTickets] = useState([]);
    const [selectedProductId, setSelectedProductId] = useState();
    const { campaignId, theme } = seller;

    const onChange = (e) => {
        setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
    };

    const onSubmit = () => {
        const { name, email, phone, address1, postCode, city } = contactInfo;
        const { shortId, countryCode } = seller;

        if (!isValidShoppingFrom(countryCode, phone, email, postCode, t)) {
            return;
        }

        // Grab winning ticket
        const winningTicket = tickets.filter((t) => t.hasPrize)[0];

        apiClient
            .selectPrize(shortId, orderId, {
                name,
                email,
                phoneNumber: phone,
                address: {
                    address1,
                    postCode,
                    city,
                },
                prizeSelection: {
                    productId: selectedProductId,
                },
            })
            .catch((_) => {
                console.error(
                    `Error while submitting prize selection for raffle ${winningTicket.id}`
                );
                // toast('En feil oppstod under innsending av skjema', { type: 'error' });
            });

        nextPage("summary");
    };

    useEffect(() => {
        const { shortId } = seller;
        apiClient.orderStatusCheck(shortId, orderId).then((res) => {
            const { raffleTickets } = res.data;

            if (!raffleTickets) {
                return missingTicketsRedirect();
            }

            setTickets(raffleTickets);
        });
    }, [seller]);

    const onProductSelected = (product) => {
        setSelectedProductId(product.id);
    };

    useEffect(() => {
        apiClient
            .getProducts(campaignId)
            .then((res) => {
                const { products, deliveryTime, description, title } = res.data;
                setProducts(products);
                setDeliveryInfo({
                    deliveryTime,
                    description,
                    title,
                });
            })
            .catch((err) => {
                console.error("Error while retrieving products", err);
            });
    }, []);

    const isFormValid = () => {
        return (
            contactInfo.name &&
            contactInfo.email &&
            contactInfo.phone &&
            contactInfo.address1 &&
            contactInfo.postCode &&
            contactInfo.city
        );
    };
    const isDisabled = !isFormValid();

    return (
        <Layout className={styles.deliveryContainer} theme={theme}>
            <Confetti position="top" margin="20" />
            <h1 className="font-bold">{t("raffle.prize_form_heading")}</h1>
            <CakePreview
                t={t}
                products={products}
                onProductSelected={onProductSelected}
            />
            <br />
            <h3>{t("raffle.prize_form_subheading")}</h3>
            <p>{t(deliveryInfo?.deliveryTime)}</p>
            <br />
            <form onSubmit={onSubmit}>
                <Input
                    label={t("general.name")}
                    name="name"
                    value={contactInfo.name}
                    onChange={onChange}
                    required
                />
                <Input
                    label={t("general.email")}
                    name="email"
                    value={contactInfo.email}
                    onChange={onChange}
                    required
                />
                <Input
                    label={t("general.phoneNumber")}
                    name="phone"
                    value={contactInfo.phone}
                    onChange={onChange}
                    required
                />
                <Input
                    label={t("general.street_address")}
                    name="address1"
                    value={contactInfo.address1}
                    onChange={onChange}
                    required
                />
                <Input
                    label={t("general.post_code")}
                    name="postCode"
                    maxLength="4"
                    value={contactInfo.postCode}
                    onChange={onChange}
                    required
                />
                <Input
                    label={t("general.city")}
                    name="city"
                    value={contactInfo.city}
                    onChange={onChange}
                    required
                />
                <br />
                <Button onClick={onSubmit} disabled={isDisabled}>
                    {t("actions.send")}
                </Button>
            </form>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withRouter(
    withTranslation()(connect(mapStateToProps)(CakeShippingPage))
);
