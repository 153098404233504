import { OneTimePurchaseButton } from "./OneTimePurchaseButton";
import { SubscriptionButton } from "./SubscriptionButton";
import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Theme } from "../../../api/types";

import {
    CheckoutButtonsContainer,
    SelectCheckoutType,
    CheckoutInfo,
} from "./ScratchcardWrapper.styled";

export function SelectMonthlyOrSinglePurchase(props: {
    onSubscribe: () => void;
    onOneTimePurchase: () => void;
    disabled?: boolean;
    theme: Theme;
    isEnabled: boolean;
}) {
    const [wasEnabled, setWasEnabled] = useState(false);
    const { t } = useTranslation();

    useLayoutEffect(() => {
        if (props.isEnabled) setWasEnabled(true);
    }, [props.isEnabled]);

    return (
        <SelectCheckoutType
            theme={props.theme}
            isEnabled={props.isEnabled}
            wasEnabled={wasEnabled}
        >
            <CheckoutInfo>{t("select_purchase_option")}</CheckoutInfo>
            <CheckoutButtonsContainer>
                <SubscriptionButton
                    theme={props.theme}
                    onClick={() => props.onSubscribe()}
                    disabled={props.disabled}
                />
                <OneTimePurchaseButton
                    theme={props.theme}
                    onClick={() => props.onOneTimePurchase()}
                    disabled={props.disabled}
                />
            </CheckoutButtonsContainer>
        </SelectCheckoutType>
    );
}
