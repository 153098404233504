import styled, { keyframes, css } from "styled-components";
import Color from "color";

type SelectCheckoutTypeProps = { isEnabled: boolean; wasEnabled?: boolean };

export const CustomBuyBed = styled.div`
    margin: 15px 0;
`;

export const CustomDescripton = styled.div`
    margin: 15px 0;
    font-size: 14px;
    text-align: center;
`;

export const LandingBackground = styled.div<{ backgroundImage: string }>`
    background-image: url(${({ backgroundImage }) => backgroundImage});
    background-size: cover;
    background-position: center;
    padding: 20px 0 0 0;
    background-repeat: no-repeat;
    min-height: 200px;
`;

export const SelectCheckoutType = styled.div<SelectCheckoutTypeProps>`
    --height: 260px;

    &,
    & * {
        box-sizing: border-box;
    }

    position: relative;
    z-index: 1;

    height: var(--height);
    padding-bottom: 20px;

    ${(p) =>
        p.isEnabled &&
        css`
            animation: ${keyframes`
            0% {
                margin-top: calc(-1 * var(--height));
                opacity: 0;
            }
            90% {
                margin-top: 0;
                opacity: 0;
            }
        `} 0.2s ease-in-out forwards;
        `};

    ${(p) =>
        !p.isEnabled &&
        css<SelectCheckoutTypeProps>`
            ${(p) =>
                p.wasEnabled &&
                css`
                    animation: ${keyframes`
                0% {
                    opacity: 1;
                }
                10% {
                    margin-top: 0;
                    opacity: 0;
                }
                to {
                    margin-top: calc(-1 * var(--height));
                    opacity: 0;
                }
            `} 0.2s ease-in-out forwards;
                `};
            ${(p) =>
                !p.wasEnabled &&
                css`
                    display: none;
                `};
            pointer-events: none;
        `};
`;

export const SelectCheckoutTypeButton = styled.button`
    cursor: pointer;
    padding: 20px;
    background-color: white;
    appearance: none;
    border: 2px solid
        ${({ theme }) =>
            Color(theme.primaryButtonColour || "#3CA010")
                .alpha(0.3)
                .string()};
    transition: border-color 0.3s;
    border-radius: 4px;
    display: grid;
    gap: 8px;

    &[disabled] {
        opacity: 0.5;
        pointer-events: none;
    }

    :hover {
        border-color: ${({ theme }) => theme.primaryButtonColour || "#3CA010"};
    }
`;

export const CheckoutButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: row;
    justify-content: stretch;
    gap: 10px;
    text-align: center;
`;

export const ExpandablePurchaseButtonWrapper = styled.div`
    position: relative;
    z-index: 2;
`;

export const SubscriptionBoxTitle = styled.div`
    color: #303030;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
`;

export const SubscriptionBoxText = styled.div`
    color: #303030;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
`;

export const CheckoutInfo = styled.div`
    color: #393939;
    text-align: center;
    font-size: 14px;
    margin-bottom: 15px;
`;

export const RecurringWarningTitle = styled.div`
    color: #000;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
`;

export const RecurringWarningText = styled.div`
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
`;

export const NarrowWrapper = styled.div`
    width: 90vw;
    max-width: 420px;
    padding: 0 15px;
    margin-bottom: 15px;
    box-sizing: border-box;
`;
