import React from "react";

import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

import no from "./locales/no.json";
import en from "./locales/en.json";

const resources = {
    no: {
        common: no,
    },
    en: {
        common: en,
    },
};

export const initI18Next = () => {
    i18n
        // load translation using xhr -> see /public/locales
        // learn more: https://github.com/i18next/i18next-xhr-backend
        .use(Backend)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            lng: "no",
            fallbackLng: "no",
            debug: true,
            defaultNS: "common",
            resources,
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
        });
    return i18n;
};
