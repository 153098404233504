import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import qs from "query-string";
import { withTranslation } from "react-i18next";

import { trackEvent } from "../../../EventTracker";
import Avatar from "../../../components/avatar/Avatar";
import DeliveryInfo from "../../../components/product/DeliveryInfo";
import {
    Button,
    PurchaseButton,
    PurchaseButtonThin,
} from "../../../components/button/Buttons";
import ContentWrapper from "../../../components/content-wrapper/ContentWrapper";
import ScrollToTop from "../../../components/scroll-to-top/ScrollToTop";
import { clearCart } from "../../../redux/seller";
import { getShareLink } from "../../../utils/share";

import AcceptMarketingModal from "../../../containers/orders/AcceptMarketingModal.container";
import {
    getShareEmailBody,
    getShareEmailSubject,
    getSellerDisplayName,
} from "../../../utils/strings/strings";
import { routes } from "../../../app/routes";

class OrderCompletedPage extends Component {
    constructor(props) {
        super(props);

        const { location } = this.props;
        const { orderId, key } = qs.parse(location.search);
        this.state = {
            loading: true,
            orderId: orderId,
            key,
        };
    }

    componentDidMount() {
        const { clearShoppingCart } = this.props;

        // Clear shopping cart now that the purchase is completed
        clearShoppingCart();
    }

    trackGoBack() {
        const { seller, location } = this.props;
        const { orderId } = qs.parse(location.search);

        trackEvent("CLICK_GO_BACK", {
            shortId: seller.shortId,
            orgDisplayName: seller.displayName,
            groupId: seller.groupId,
            orderId,
        });
    }

    shareOnFacebook() {
        const {
            seller,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { orderId } = this.state;

        if (window.FB) {
            window.FB.ui(
                {
                    method: "share",
                    href: getShareLink(salesLinkCodeOrShortId),
                },
                function (response) {}
            );
        }

        trackEvent("SHARE_FACEBOOK_ORDER_COMPLETED", {
            shortId: seller.shortId,
            orgDisplayName: seller.displayName,
            groupId: seller.groupId,
            orderId: orderId,
        });
    }

    handleShareSalesLink() {
        const {
            seller,
            t,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { groupName } = seller;
        const sellerName = seller
            ? seller.sellerFirstName +
              (seller.sellerLastName ? " " + seller.sellerLastName : "")
            : "";
        const { orderId } = this.state;

        const shareDescription = !!sellerName
            ? t("general.share_title", {
                  sellerName: sellerName,
                  groupName: groupName,
              })
            : t("general.share_title_organisation_seller", {
                  groupName: groupName,
              });
        try {
            navigator
                .share({
                    text: shareDescription,
                    title: shareDescription,
                    url: getShareLink(salesLinkCodeOrShortId),
                })
                .then(() => {
                    trackEvent("SHARE_NATIVE_ORDER_COMPLETED", {
                        shortId: seller.shortId,
                        orgDisplayName: seller.groupName,
                        groupId: seller.groupId,
                        orderId: orderId,
                    });
                });
        } catch (err) {}
    }

    trackShareOnEmail() {
        const { seller } = this.props;
        const { orderId } = this.state;

        trackEvent("SHARE_EMAIL_ORDER_COMPLETED", {
            shortId: seller.shortId,
            orgDisplayName: seller.groupName,
            groupId: seller.groupId,
            orderId: orderId,
        });
    }

    render() {
        const {
            seller,
            t,

            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { orderId } = this.state;
        const {
            groupName,
            campaignStatus,
            sellerFirstName = "",
            sellerLastName = "",
        } = seller;
        const sellerName = getSellerDisplayName(seller);
        const active = campaignStatus === "ACTIVE";
        const isGroupMode =
            sellerFirstName.length === 0 && sellerLastName.length === 0;

        return (
            <div>
                <ScrollToTop />
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Avatar imageUrl={seller.sellerImage} />
                </div>

                <h1 style={{ textAlign: "center", marginTop: "25px" }}>
                    {t("order_successful.body_plain")}
                </h1>

                <p style={{ textAlign: "center" }}>
                    {t(
                        isGroupMode
                            ? "order_successful.thanks_for_support_organisation_seller"
                            : "order_successful.thanks_for_support",
                        {
                            sellerName,
                            groupName,
                        }
                    )}
                </p>

                {active && (
                    <ContentWrapper maxWidth="320px">
                        <div style={{ marginBottom: "15px" }}>
                            <a
                                href={
                                    routes.seller(salesLinkCodeOrShortId)
                                        .pathname
                                }
                            >
                                <PurchaseButton
                                    theme={seller.theme}
                                    onClick={() => this.trackGoBack()}
                                >
                                    {t("order_successful.back_label")}
                                </PurchaseButton>
                            </a>
                        </div>
                        {navigator.share && (
                            <div>
                                <Button
                                    onClick={() => this.handleShareSalesLink()}
                                >
                                    {t("order_successful.share_with_others")}
                                </Button>
                            </div>
                        )}
                        {!navigator.share && (
                            <>
                                <div style={{ marginBottom: "15px" }}>
                                    <PurchaseButtonThin
                                        theme={seller.theme}
                                        onClick={() => this.shareOnFacebook()}
                                    >
                                        {t("order_successful.share_facebook")}
                                    </PurchaseButtonThin>
                                </div>

                                <a
                                    href={`mailto:?subject=${getShareEmailSubject(
                                        t,
                                        sellerName,
                                        groupName
                                    )}&body=${getShareEmailBody(
                                        t,
                                        seller.description,
                                        getShareLink(salesLinkCodeOrShortId)
                                    )}`}
                                    onClick={() => this.trackShareOnEmail()}
                                >
                                    <PurchaseButtonThin theme={seller.theme}>
                                        {t("order_successful.share_email")}
                                    </PurchaseButtonThin>
                                </a>
                            </>
                        )}
                    </ContentWrapper>
                )}

                <div style={{ marginTop: "80px" }}>
                    <ContentWrapper>
                        <DeliveryInfo value={seller.delivery} />
                    </ContentWrapper>
                </div>

                {seller.marketingSubscriptionEnabled && (
                    <AcceptMarketingModal orderId={orderId} />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

const mapDispatchToProps = (dispatch) => ({
    clearShoppingCart: () => dispatch(clearCart()),
});

export default withTranslation()(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderCompletedPage))
);
