import React from "react";
import * as styles from "./Ticket.module.scss";

const TicketShape = ({
    children,
    className,
    fill = "#fff",
    textFill = "#000",
    selectedFill = "var(--color-accent)",
    selectedTextFill = "#FFF",
    selected,
    indicator,
    ...props
}) => {
    return (
        <div
            className={[styles.ticketShapeWrapper, className].join(" ")}
            {...props}
        >
            <div>
                <svg
                    width="100%"
                    viewBox="0 0 50 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={[
                        styles.ticket,
                        selected ? styles.selected : "",
                        indicator ? styles.indicator : "",
                    ].join(" ")}
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M.857 4a4 4 0 0 1 4-4h40.286a4 4 0 0 1 4 4v12c-2.564 0-4.643 2.239-4.643 5s2.079 5 4.643 5v11a4 4 0 0 1-4 4H4.857a4 4 0 0 1-4-4V26C3.421 26 5.5 23.761 5.5 21s-2.079-5-4.643-5V4Z"
                        fill={
                            indicator ? "white" : selected ? selectedFill : fill
                        }
                    />
                    <text
                        x="50%"
                        y="50%"
                        fill={
                            indicator
                                ? "#333"
                                : selected
                                ? selectedTextFill
                                : textFill
                        }
                        dominantBaseline="middle"
                        textAnchor="middle"
                    >
                        {children}
                    </text>
                </svg>
            </div>
            <svg
                width="100%"
                viewBox="0 0 50 41"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.ticketShadow}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M.857 4a4 4 0 0 1 4-4h40.286a4 4 0 0 1 4 4v12c-2.564 0-4.643 2.239-4.643 5s2.079 5 4.643 5v11a4 4 0 0 1-4 4H4.857a4 4 0 0 1-4-4V26C3.421 26 5.5 23.761 5.5 21s-2.079-5-4.643-5V4Z"
                    fill="#000"
                    fillOpacity=".11"
                />
            </svg>
        </div>
    );
};

export default TicketShape;
