import React from "react";
import cakeImage from "../../../assets/cakeBackground.jpeg";
import * as styles from "./CakeBackground.module.scss";

const CakeBackground = ({ background, mobileBackground }) => {
    return (
        <>
            <img
                src={background}
                alt="Background"
                className={`${styles.cakeBackground} ${styles.desktop}`}
            />
            <img
                src={mobileBackground}
                alt="Background"
                className={`${styles.cakeBackground} ${styles.mobile}`}
            />
        </>
    );
};

export default CakeBackground;
