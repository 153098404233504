import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { FUNDRAISER_TYPE, ORDER_STATUS } from "../../../data/consts";
import qs from "query-string";
import PulseLoader from "react-spinners/PulseLoader";
import { apiClient } from "../../../api/ApiClient";
import { routes } from "../../../app/routes";
import { Seller } from "../../../api/types";
import moment from "moment";

type Props = {
    seller: Seller;
    location: string;
    match: {
        params: { salesLinkCodeOrShortId: string };
    };
};

type State = {
    redirectUrl?: string;
};

function getRedirectUrlByType({
    status,
    salesLinkCodeOrShortId,
    type,
    superdrawDrawTime,
    orderId,
}: {
    status: string;
    salesLinkCodeOrShortId: string;
    type?: string;
    superdrawDrawTime?: string;
    orderId: string;
}): string | null {
    if (
        status === ORDER_STATUS.FULFILLED ||
        status === ORDER_STATUS.PAID ||
        status === ORDER_STATUS.PAYMENT_CAPTURED
    ) {
        if (type === FUNDRAISER_TYPE.SCRATCHCARDS) {
            return routes
                .seller(salesLinkCodeOrShortId)
                .scratchcards.order(orderId).pathname;
        } else if (type === FUNDRAISER_TYPE.SPOT_THE_BALL) {
            return (
                routes.seller(salesLinkCodeOrShortId).spotTheBall.pathname +
                `?orderId=${orderId}`
            );
        } else if (type === FUNDRAISER_TYPE.RAFFLE) {
            return routes.seller(salesLinkCodeOrShortId).raffle.order(orderId);
        } else if (type === FUNDRAISER_TYPE.SUPER_DRAW) {
            if (moment(superdrawDrawTime).isBefore(moment())) {
                return routes
                    .seller(salesLinkCodeOrShortId)
                    .superdraw.order(orderId).drawPathname;
            } else {
                return routes
                    .seller(salesLinkCodeOrShortId)
                    .superdraw.order(orderId).pathname;
            }
        } else {
            return (
                routes.seller(salesLinkCodeOrShortId).orderSuccessful +
                `?orderId=${orderId}`
            );
        }
    } else if (status === ORDER_STATUS.PAYMENT_CAPTURE_FAILED) {
        return (
            routes.seller(salesLinkCodeOrShortId).orderCancelled +
            `?orderId=${orderId}`
        );
    }
    return null;
}

class VippsFallbackPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    fetchOrderStatusUntilReady = (failureCount: number) => {
        const {
            seller: { shortId, type, superdrawDrawTime },
            location,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { orderId }: { orderId: string } = qs.parse(
            location.search as any
        ) as any;
        apiClient.manuallyHandle
            .orderStatusCheck(shortId, orderId)
            .then((res) => {
                const { status } = res.data;
                const redirectUrl = getRedirectUrlByType({
                    status,
                    salesLinkCodeOrShortId,
                    type,
                    superdrawDrawTime,
                    orderId,
                });
                if (redirectUrl) {
                    this.setState({ redirectUrl });
                } else {
                    setTimeout(() => this.fetchOrderStatusUntilReady(0), 2000);
                }
            })
            .catch((err) => {
                console.error("Error fetching vipps status for order", err);
                if (failureCount < 5) {
                    // Wait one second before retrying
                    setTimeout(
                        () => this.fetchOrderStatusUntilReady(failureCount + 1),
                        2000
                    );
                } else {
                    this.setState({
                        redirectUrl: routes.seller(salesLinkCodeOrShortId)
                            .pathname,
                    });
                }
            });
    };

    componentDidMount() {
        this.fetchOrderStatusUntilReady(0);
    }

    render() {
        const {
            seller: { type },
        } = this.props;
        const { redirectUrl } = this.state;
        if (redirectUrl) {
            return <Redirect to={redirectUrl} />;
        }
        return (
            <div className="page-wrapper">
                <div className="container">
                    <div className="row">
                        <div
                            style={{
                                margin: "0 auto",
                                paddingTop: "50px",
                                width: "100%",
                                maxWidth: "980px",
                            }}
                        >
                            {type === FUNDRAISER_TYPE.SCRATCHCARDS && (
                                <h3 style={{ textAlign: "center" }}>
                                    Laster inn skrapelodd. Vennligst vent...
                                </h3>
                            )}
                            <div
                                style={{
                                    marginTop: "80px",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <PulseLoader
                                    sizeUnit={"px"}
                                    size={20}
                                    color={"#333"}
                                    loading={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    seller: state.sellerReducer.seller,
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(VippsFallbackPage));
