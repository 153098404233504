import type { ReduxState } from "../../../redux/rootReducer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import React from "react";

import "../../product/landing/ProductLanding.container.scss";

import ScrollToTop from "../../../components/scroll-to-top/ScrollToTop";

import ScratchcardWrapper from "../ScratchcardWrapper/ScratchcardWrapper";
import { CustomBanner } from "../ScratchcardWrapper/CustomBanner";
import { getScratchThemeType } from "../ScratchcardWrapper/CustomScratch.define";
import { CustomIntro } from "../ScratchcardWrapper/CustomIntro";

const ScratchcardLandingBed = styled.div<{ isCustom?: boolean }>`
    position: relative;
    top: ${({ isCustom }) => (isCustom ? "-15" : "0")}px;
`;

function ScratchcardLanding() {
    const { t } = useTranslation();
    const seller = useSelector(
        (state: ReduxState) => state.sellerReducer.seller
    );
    const themeType = getScratchThemeType(seller.theme.id);

    if (seller.campaignStatus !== "ACTIVE") {
        return (
            <div>
                <h1
                    style={{ textAlign: "center", marginBottom: "10px" }}
                    dangerouslySetInnerHTML={{
                        __html: t("landing.ended_heading_sc"),
                    }}
                />
                <p
                    style={{ textAlign: "center", marginBottom: "180px" }}
                    dangerouslySetInnerHTML={{
                        __html: t("landing.ended_body_sc"),
                    }}
                />
            </div>
        );
    }

    return (
        <ScratchcardLandingBed isCustom={!!themeType}>
            <ScrollToTop />
            {!!themeType && (
                <CustomBanner seller={seller} themeType={themeType} />
            )}
            <ScratchcardWrapper
                previewMode={false}
                seller={seller}
                themeType={themeType}
            />
            {!!themeType && <CustomIntro themeType={themeType} />}
        </ScratchcardLandingBed>
    );
}

export default ScratchcardLanding;
