import React from "react";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import * as iconShipping from "../../assets/icon_shipping.png";

const DeliveryInfoBox = styled.div`
    box-sizing: border-box;
    padding: 20px;
    border-radius: 30px;
    background-color: #f5f5f5;
    max-width: 400px;
    margin: 20px 15px;
`;

const DeliveryInfoBoxWrapper = styled.div`
    margin: 20px auto;
    display: flex;
    justify-content: center;
`;

const DeliveryInfo = ({ t, value }) => (
    <DeliveryInfoBoxWrapper>
        <DeliveryInfoBox style={{ display: "flex", alignItems: "center" }}>
            <div>
                <img
                    src={iconShipping}
                    style={{
                        width: "40px",
                        height: "40px",
                        paddingRight: "15px",
                    }}
                    alt="shipping icon"
                />
            </div>
            <div>{value}</div>
        </DeliveryInfoBox>
    </DeliveryInfoBoxWrapper>
);

export default withTranslation()(DeliveryInfo);
