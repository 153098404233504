import * as Sentry from "@sentry/browser";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import FsLightbox from "fslightbox-react";

import { trackEvent, trackPageView } from "../../../EventTracker";
import CloseButton from "../../../components/button/CloseButton";
import DeliveryInfo from "../../../components/product/DeliveryInfo";
import NumberFormat from "react-number-format";
import { Trans, withTranslation } from "react-i18next";
import { removeFromCart, updateQuantityInCart } from "../../../redux/seller";
import { CardButton, VippsButton } from "../../../components/button/Buttons";
import ScrollToTop from "../../../components/scroll-to-top/ScrollToTop";
import {
    initiateStandardPayment,
    isEligibleForExtraProduct,
} from "../../../utils/payment";
import SpondImage, {
    transformImageUrl,
} from "../../../components/spond-image/SpondImage";
import ExtraProductModal from "../../../containers/product/extra-product/ExtraProductModal.container";
import OrderFields from "../../../components/product/shopping-cart/OrderFields";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DOMPurify from "dompurify";
import { isValidShoppingFrom } from "../../../utils/validationHelpers";
import {
    CartContentsHolder,
    CartItemDescription,
    CartItemDetails,
    CartItemImage,
    CartItemPrice,
    CartItemQuantity,
    CartItemTotalLabel,
    CartItemTotalPriceLabel,
    CartItemWrapper,
    CheckoutTerms,
    EmptyCartLabel,
} from "./ShoppingCart.styled";
import qs from "query-string";
import { createCartSummary } from "./ShoppingCart.utils";
import { CartShippingFeeInformation } from "./ShoppingCart.components";

import styled from "styled-components";
import { routes } from "../../../app/routes";

export const Card = styled.div`
    @media only screen and (min-width: 640px) {
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        max-width: 640px;
        margin: 15px auto;
        background-color: white;
        box-shadow: 0px 6px 16px 12px rgba(21, 0, 0, 0.07);
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
    }
`;

// This text should never get rendered. Making sure of this by testing that it's properly replaced by the localization string in a test.
export const buyerTermsDefaultText = "(Error) Ved å fortsette godkjenner du";

export class ShoppingCartPage extends Component {
    constructor(props) {
        super(props);
        this.orderFieldsRef = React.createRef();

        this.state = {
            lightboxToggler: {},
            showModalExtraProduct: false,
            form: {
                name: "",
                email: "",
                phoneNumber: "",
                address1: "",
                postCode: "",
                city: "",
            },
        };
    }

    componentDidMount() {
        const { seller } = this.props;
        trackPageView("ShoppingCart", {
            shortId: seller.shortId,
            orgDisplayName: seller.displayName,
            groupId: seller.groupId,
        });
    }

    handleHideExtraProduct() {
        this.setState({
            showModalExtraProduct: false,
        });
    }

    handleRemoveItemClicked(cartItem) {
        const { removeItemFromCart } = this.props;
        const { productId, productVariationId } = cartItem;
        removeItemFromCart(productId, productVariationId);
    }

    handleQuantityChanged(e, cartItem) {
        const { updateItemQuantityInCart } = this.props;
        const { productId, productVariationId } = cartItem;

        updateItemQuantityInCart(productId, productVariationId, e.target.value);
    }

    handleNetsPayment(method) {
        const {
            cart,
            seller,
            t,
            location,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { source, profileId } = qs.parse(location.search);
        const { form } = this.state;

        const { extraProduct, countryCode } = seller;

        if (
            !isValidShoppingFrom(
                countryCode,
                form.phoneNumber,
                form.email,
                form.postCode,
                t
            )
        ) {
            return;
        }

        if (isEligibleForExtraProduct(cart, extraProduct)) {
            this.setState({
                showModalExtraProduct: true,
                showModalBuyNow: false,
                selectedPaymentMethod: method,
            });
        } else {
            initiateStandardPayment({
                cart,
                seller,
                type: method,
                source,
                sellerProfileId: profileId,
                formData: form,
                errorCallback: this.handlePaymentError,
                salesLinkCodeOrShortId,
            });
        }
    }

    handlePaymentError = (err) => {
        if (err?.response?.data?.errorKey === "outOfStock") {
            toast(this.props.t("toast.out_of_stock"), { type: "error" });
        } else {
            this.orderFieldsRef.highlightErrorField(err);
            toast(this.props.t("toast.form_submit_error"), { type: "error" });
        }
        Sentry.captureException(err);
    };

    shouldShowLightbox(productId, productVariationId) {
        return !!this.state.lightboxToggler[
            `${productId}-${productVariationId}`
        ];
    }

    onFormChange = (e) => {
        const { value } = e.target;
        const { form } = this.state;

        form[e.target.getAttribute("name")] = value;

        this.setState({
            form,
        });
    };

    toggleLightbox(productId, productVariationId) {
        const newLightboxToggler = {
            ...this.state.lightboxToggler,
        };

        const key = `${productId}-${productVariationId}`;
        newLightboxToggler[key] = !newLightboxToggler[key];

        if (newLightboxToggler[productId]) {
            trackEvent("SHOW_LIGHTBOX_SHOPPING_CART", {
                productId,
            });
        }

        this.setState({
            ...this.state,
            lightboxToggler: {
                ...newLightboxToggler,
            },
        });
    }

    render() {
        const {
            cart,
            seller,
            t,
            location,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { countryCode } = seller;
        const { source, profileId } = qs.parse(location.search);
        const { selectedPaymentMethod, showModalExtraProduct } = this.state;

        if (!cart)
            return (
                <Redirect
                    to={
                        routes.seller(salesLinkCodeOrShortId).pathname +
                        `${seller.referral}`
                    }
                />
            );

        const { cartSummaryPerProduct, totalPrice, finalShippingFee } =
            createCartSummary(cart, seller);
        const { buyerTermsLink } = seller;

        let quantityOptions = [];
        for (let i = 1; i < 100; i++) {
            quantityOptions.push(i);
        }

        return (
            <Card className="card">
                <ScrollToTop />
                <h1
                    style={{
                        textAlign: "center",
                        marginTop: "25px",
                        marginBottom: "25px",
                        position: "relative",
                    }}
                >
                    {t("shopping_cart.page_heading")}
                    <div
                        style={{
                            position: "absolute",
                            right: "-15px",
                            top: "-10px",
                        }}
                    >
                        <Link
                            to={
                                routes.seller(salesLinkCodeOrShortId).pathname +
                                `?${!!source ? `source=${source}` : ""}${
                                    source && profileId ? "&" : ""
                                }${profileId ? `profileId=${profileId}` : ""}`
                            }
                        >
                            <CloseButton />
                        </Link>
                    </div>
                </h1>

                <CartContentsHolder>
                    {!cartSummaryPerProduct.length && (
                        <EmptyCartLabel>
                            {t("shopping_cart.empty_label")}
                        </EmptyCartLabel>
                    )}

                    {cartSummaryPerProduct.length > 0 &&
                        cartSummaryPerProduct.map((item) => (
                            <CartItemWrapper
                                key={`${item.productId}-${item.productVariationId}`}
                            >
                                <FsLightbox
                                    types={["image"]}
                                    toggler={this.shouldShowLightbox(
                                        item.productId,
                                        item.productVariationId
                                    )}
                                    disableThumbs={true}
                                    captions={[
                                        <>
                                            <h2>{item.product.name}</h2>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(
                                                        item.product
                                                            .description,
                                                        {
                                                            USE_PROFILES: {
                                                                html: false,
                                                            },
                                                        }
                                                    ),
                                                }}
                                            />
                                        </>,
                                    ]}
                                    sources={[
                                        transformImageUrl(
                                            item.preview,
                                            "f_auto,fl_lossy"
                                        ),
                                    ]}
                                />
                                <CartItemImage
                                    onClick={() =>
                                        this.toggleLightbox(
                                            item.product.id,
                                            item.productVariationId
                                        )
                                    }
                                >
                                    <SpondImage
                                        imageUrl={item.preview}
                                        data-testid={
                                            "ShoppingCart-item-preview"
                                        }
                                        transformation="w_70,h_70,f_auto,fl_lossy"
                                        style={{
                                            width: "35px",
                                            height: "35px",
                                            objectFit: "cover",
                                        }}
                                        alt="product"
                                    />
                                </CartItemImage>
                                <CartItemDescription
                                    onClick={() =>
                                        this.toggleLightbox(
                                            item.product.id,
                                            item.productVariationId
                                        )
                                    }
                                >
                                    {item.product.name}
                                    {item.variation &&
                                        " (" + item.variation.name + ")"}
                                </CartItemDescription>
                                <CartItemDetails>
                                    <CartItemQuantity
                                        style={{ paddingLeft: "15px" }}
                                    >
                                        <select
                                            className="quantity-select"
                                            value={item.quantity}
                                            onChange={(e) =>
                                                this.handleQuantityChanged(
                                                    e,
                                                    item
                                                )
                                            }
                                        >
                                            {quantityOptions.map((n) => {
                                                if (n < 10) {
                                                    return (
                                                        <option
                                                            key={n}
                                                            value={n}
                                                        >
                                                            &nbsp;{n}
                                                        </option>
                                                    );
                                                } else {
                                                    return (
                                                        <option
                                                            key={n}
                                                            value={n}
                                                        >
                                                            {n}
                                                        </option>
                                                    );
                                                }
                                            })}
                                        </select>
                                    </CartItemQuantity>
                                    <CartItemPrice>
                                        <span style={{ fontWeight: "600" }}>
                                            <NumberFormat
                                                value={item.totalPrice / 100}
                                                displayType={"text"}
                                                decimalSeparator={t(
                                                    "general.decimal_separator"
                                                )}
                                                thousandSeparator={t(
                                                    "general.thousand_separator"
                                                )}
                                                prefix={t(
                                                    "general.number_prefix"
                                                )}
                                            />
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "12px",
                                                color: seller.theme
                                                    .primaryButtonColour,
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                this.handleRemoveItemClicked(
                                                    item
                                                )
                                            }
                                        >
                                            {t("shopping_cart.remove")}
                                        </span>
                                    </CartItemPrice>
                                </CartItemDetails>
                            </CartItemWrapper>
                        ))}

                    {cartSummaryPerProduct.length > 0 && (
                        <>
                            <CartShippingFeeInformation
                                shippingFee={finalShippingFee}
                            />
                            <CartItemWrapper>
                                <CartItemTotalLabel>
                                    {t("shopping_cart.total_label")}
                                </CartItemTotalLabel>
                                <CartItemTotalPriceLabel>
                                    <NumberFormat
                                        value={totalPrice / 100}
                                        displayType={"text"}
                                        decimalSeparator={t(
                                            "general.decimal_separator"
                                        )}
                                        thousandSeparator={t(
                                            "general.thousand_separator"
                                        )}
                                        prefix={t("general.number_prefix")}
                                    />
                                </CartItemTotalPriceLabel>
                            </CartItemWrapper>
                        </>
                    )}
                </CartContentsHolder>

                <OrderFields
                    t={t}
                    ref={(ref) => (this.orderFieldsRef = ref)}
                    onFormChange={this.onFormChange}
                />

                {cartSummaryPerProduct.length > 0 && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "320px",
                            }}
                        >
                            {countryCode === "NOR" && (
                                <div style={{ marginBottom: "15px" }}>
                                    <VippsButton
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                        onClick={() =>
                                            this.handleNetsPayment("VIPPS")
                                        }
                                    >
                                        {t("actions.buy_with")}
                                    </VippsButton>
                                </div>
                            )}
                            <div>
                                <CardButton
                                    onClick={() =>
                                        this.handleNetsPayment("CARD")
                                    }
                                >
                                    {t("actions.buy_with_card")}
                                </CardButton>
                            </div>
                        </div>
                    </div>
                )}
                <CheckoutTerms>
                    <Trans t={t} i18nKey={"checkout.terms_parameterised"}>
                        {/* NOTE: This text is not hard coded, even though it may look that way. This is how react-i18next API works.*/}
                        {buyerTermsDefaultText}{" "}
                        <a
                            href={buyerTermsLink}
                            target="_blank"
                            style={{ fontStyle: "bold" }}
                        >
                            kjøpsvilkårene og personvernerklæringen
                        </a>
                    </Trans>
                </CheckoutTerms>

                <DeliveryInfo value={seller.delivery} />

                <ExtraProductModal
                    paymentMethod={selectedPaymentMethod}
                    showModal={showModalExtraProduct}
                    onClose={() => this.handleHideExtraProduct()}
                />
            </Card>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
    cart: state.sellerReducer.cart,
});

const mapDispatchToProps = (dispatch) => ({
    removeItemFromCart: (productId, productVariationId) =>
        dispatch(removeFromCart({ productId, productVariationId })),
    updateItemQuantityInCart: (productId, productVariationId, quantity) =>
        dispatch(
            updateQuantityInCart({ productId, productVariationId, quantity })
        ),
});

export default withRouter(
    withTranslation()(
        connect(mapStateToProps, mapDispatchToProps)(ShoppingCartPage)
    )
);
