import BezierEasing from "bezier-easing";

const Transitions = {
    NONE: "NONE",
    IN: "IN",
    OUT: "OUT",
    LOOSER: "LOOSER",
    WINNER: "WINNER",
};

const TransitionLibrary = {
    IN: {
        duration: 1000,
        start: { scale: 0.5, opacity: 1 },
        end: { scale: 1, opacity: 1 },
        ease: BezierEasing(0.34, 1.56, 0.64, 1),
    },
    OUT: {
        duration: 1000,
        start: { scale: 1, opacity: 1 },
        end: { scale: 1, opacity: 0 },
        ease: BezierEasing(0.34, 1.56, 0.64, 1),
    },
    LOOSER: {
        duration: 1000,
        start: { scale: 1, opacity: 1 },
        end: { scale: 0.7, opacity: 0.5 },
        ease: BezierEasing(0.34, 1.56, 0.64, 1),
    },
    WINNER: {
        duration: 1000,
        start: { scale: 1, opacity: 1 },
        end: { scale: 1.16, opacity: 1 },
        ease: BezierEasing(0.34, 1.56, 0.64, 1),
    },
};

const FPS = 60;

class Slot {
    constructor(index, initialConfig) {
        this._index = index;
        this._position = initialConfig.position;
        this._size = initialConfig.size;
        this._transition = Transitions.NONE;
        this._tickStep = 0;
        this._opacity = initialConfig.opacity;
        this._delay = 0;
        this._amountScratched = 0;
        this._scale = 1;
    }

    get index() {
        return this._index;
    }

    get position() {
        return this._position;
    }

    get currentSize() {
        return {
            width: this._size.width * this._scale,
            height: this._size.height * this._scale,
        };
    }

    get scale() {
        return this._scale;
    }

    get transition() {
        return this._transition;
    }

    get amountScratched() {
        return this._amountScratched;
    }

    set amountScratched(_amount) {
        this._amountScratched = _amount;
    }

    get opacity() {
        return this._opacity;
    }

    set opacity(_opacity) {
        this._opacity = _opacity;
    }

    get duration() {
        return this._duration;
    }

    get isDelaying() {
        return this._tickStep < 0;
    }

    increaseAmountScratched() {
        this._amountScratched = this._amountScratched + 1;
    }

    startTransition(_transition, delay = 0) {
        this._transition = _transition;
        this._delay = delay;
        this._tickStep = ((-1 * delay) / 1000) * FPS;
    }

    tick() {
        if (this._transition === Transitions.NONE) {
            return;
        }
        const duration = TransitionLibrary[this._transition].duration;
        const ease = TransitionLibrary[this._transition].ease;
        const startValues = TransitionLibrary[this.transition].start;
        const endValues = TransitionLibrary[this.transition].end;
        const tickTarget = (duration / 1000) * FPS;

        if (this._tickStep < 0) {
            this._scale = startValues.scale;
            this._opacity = 0;
        } else {
            const percent = this._tickStep / tickTarget;
            const scale =
                startValues.scale +
                (endValues.scale - startValues.scale) * ease(percent);
            const opacity =
                startValues.opacity +
                (endValues.opacity - startValues.opacity) * percent;
            const normalizedOpacity = Math.max(Math.min(1, opacity), 0);
            this._scale = scale;
            this._opacity = normalizedOpacity;
        }
        this._tickStep += 1;

        if (this._tickStep === tickTarget) {
            this._transition = Transitions.NONE;
        }
    }
}

class Scratchable extends Slot {
    constructor(index, initialConfig) {
        super(index, initialConfig);
    }
}

class TicketSymbol extends Slot {
    constructor(index, initialConfig, ticketNumber) {
        super(index, initialConfig);
        this._ticketNumber = ticketNumber;
    }

    get ticketNumber() {
        return this._ticketNumber;
    }
}

export { Scratchable, TicketSymbol, Transitions };
