import React, { Component } from "react";
import styled from "styled-components";
import "./OrderFields.scss";
import { Input } from "../../input/Input";

const FormGroup = styled.div`
    margin: 15px 0;
`;

const FormLabel = styled.label`
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: left;
`;

class OrderFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                name: "",
                email: "",
                phoneNumber: "",
                address1: "",
                postCode: "",
                city: "",
            },
        };
    }

    onFormChange = (e) => {
        const { value } = e.target;
        const { form } = this.state;
        const { onFormChange } = this.props;

        form[e.target.getAttribute("name")] = value;

        this.setState({
            form,
        });
        onFormChange(e);
    };

    highlightErrorField = (err) => {
        const fieldNames = {
            name: "buyerName",
            email: "buyerEmail",
            phoneNumber: "buyerPhone",
            address1: "address.address1",
            postCode: "address.postCode",
            city: "address.city",
        };
        for (const fieldName in fieldNames)
            document
                .getElementsByName(fieldName)[0]
                .classList.remove("fieldError");
        if (
            err.response.data.errorKey === "missingField" ||
            err.response.data.errorKey === "invalidField"
        ) {
            for (const fieldName in fieldNames) {
                if (err.response.data.message.includes(fieldNames[fieldName]))
                    document
                        .getElementsByName(fieldName)[0]
                        .classList.add("fieldError");
            }
        }
    };

    render() {
        const { form } = this.state;
        const { t } = this.props;
        return (
            <div>
                <FormGroup>
                    <FormLabel>{t("general.your_name")}</FormLabel>
                    <Input
                        required
                        type="text"
                        name="name"
                        value={form.name}
                        onChange={(e) => this.onFormChange(e)}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel>{t("general.email")}</FormLabel>
                    <Input
                        required
                        type="text"
                        name="email"
                        value={form.email}
                        onChange={(e) => this.onFormChange(e)}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel>{t("general.phoneNumber")}</FormLabel>
                    <Input
                        required
                        type="text"
                        name="phoneNumber"
                        value={form.phoneNumber}
                        onChange={(e) => this.onFormChange(e)}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel>{t("general.street_address")}</FormLabel>
                    <Input
                        required
                        type="text"
                        name="address1"
                        placeholder={t("general.street_address_placeholder")}
                        value={form.address1}
                        onChange={(e) => this.onFormChange(e)}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel>{t("general.post_code")}</FormLabel>
                    <Input
                        required
                        type="text"
                        name="postCode"
                        value={form.postCode}
                        placeholder={t("general.post_code_placeholder")}
                        maxLength="4"
                        onChange={(e) => this.onFormChange(e)}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel>{t("general.city")}</FormLabel>
                    <Input
                        required
                        type="text"
                        name="city"
                        value={form.city}
                        placeholder={t("general.city_placeholder")}
                        onChange={(e) => this.onFormChange(e)}
                    />
                </FormGroup>
            </div>
        );
    }
}

export default OrderFields;
