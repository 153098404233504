import React, { Component } from "react";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import Modal from "react-responsive-modal";
import { withTranslation } from "react-i18next";

import { addToCart } from "../../../redux/seller";
import { Button, ThinButton } from "../../../components/button/Buttons";
import {
    initiateStandardPayment,
    initiateStripePayment,
} from "../../../utils/payment";
import SpondImage from "../../../components/spond-image/SpondImage";
import { withRouter } from "react-router-dom";

class ExtraProductModal extends Component {
    handleAcceptExtraProduct() {
        const {
            seller,
            addProductToCart,
            paymentMethod,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { extraProduct } = seller;

        (async () => {
            await addProductToCart(extraProduct.id);
            const { cart } = this.props;

            const paymentArgs = {
                cart,
                seller,
                salesLinkCodeOrShortId,
            };
            if (paymentMethod === "VIPPS") {
                await initiateStandardPayment(paymentArgs);
            } else if (paymentMethod === "STRIPE") {
                initiateStripePayment(paymentArgs);
            }
        })();
    }

    handleRejectExtraProduct() {
        const {
            cart,
            seller,
            paymentMethod,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const paymentArgs = { cart, seller, salesLinkCodeOrShortId };
        if (paymentMethod === "VIPPS") {
            initiateStandardPayment(paymentArgs);
        } else if (paymentMethod === "STRIPE") {
            initiateStripePayment(paymentArgs);
        }
    }

    handleHideModal() {
        this.handleRejectExtraProduct();
    }

    render() {
        const { seller, showModal, onClose, t } = this.props;
        const { extraProduct } = seller;

        return (
            <Modal
                center={true}
                open={showModal}
                onClose={() => {
                    onClose();
                    this.handleHideModal();
                }}
                classNames={{ modal: "action-modal" }}
            >
                {extraProduct && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "25px",
                                position: "relative",
                            }}
                        >
                            <SpondImage
                                imageUrl={extraProduct.preview}
                                transformation="w_270,h_270,c_thumb,f_auto,fl_lossy"
                                style={{ width: "135px", height: "135px" }}
                                alt="product"
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: "-23px",
                                    background: "#f7f5f9",
                                    borderRadius: "10px",
                                    padding: "5px 8px",
                                    boxShadow:
                                        "rgba(0, 0, 0, 0.2) 0px -4px 13px",
                                }}
                            >
                                <NumberFormat
                                    value={extraProduct.price / 100}
                                    displayType={"text"}
                                    suffix={t("general.number_suffix")}
                                    prefix={t("general.number_prefix")}
                                />
                            </div>
                        </div>
                        <h3
                            style={{
                                textAlign: "center",
                                margin: "15px 0 0 0",
                            }}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        "shopping_cart.extra_product_label",
                                        {
                                            title: extraProduct.title,
                                        }
                                    ),
                                }}
                            />
                        </h3>
                        <p style={{ textAlign: "center" }}>
                            {extraProduct.description}
                        </p>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "10px",
                            }}
                        >
                            <ThinButton
                                style={{ marginRight: "10px" }}
                                onClick={() => {
                                    onClose();
                                    this.handleRejectExtraProduct();
                                }}
                            >
                                {t("actions.decline_extra_product")}
                            </ThinButton>
                            <Button
                                style={{ marginLeft: "10px" }}
                                onClick={() => {
                                    onClose();
                                    this.handleAcceptExtraProduct();
                                }}
                            >
                                {t("actions.accept_extra_product")}
                            </Button>
                        </div>
                    </div>
                )}
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
    cart: state.sellerReducer.cart,
});

const mapDispatchToProps = (dispatch) => ({
    addProductToCart: (productId, productVariationId) =>
        dispatch(addToCart({ productId, productVariationId })),
});

export default withTranslation()(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(ExtraProductModal))
);
