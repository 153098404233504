import React, { Component } from "react";

import ViewProductContainer from "../../../containers/product/view-product/ViewProductContainer";

class ViewProductPage extends Component {
    render() {
        return <ViewProductContainer />;
    }
}

export default ViewProductPage;
