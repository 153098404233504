import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Files } from "../assets/icons/bootstrap/Files";
import { CheckLg } from "../assets/icons/bootstrap/CheckLg";

const limitString = (str: string, len: number) => {
    if (str.length > len) {
        return str.substring(0, len - 1) + "…";
    } else {
        return str;
    }
};

const CopyButton = styled.button`
    margin-left: 5px;
    padding: 0;
    border: none;
    background-color: transparent;
    color: inherit;

    svg {
        width: 17px;
        height: 17px;
    }
`;

const ErrorInfo = styled.div`
    margin-left: 5px;
`;

const ErrorId = styled.div`
    margin-left: 5px;
`;

const ErrorIdField = styled.div<{
    clicked: boolean;
}>`
    cursor: copy;

    color: ${({ clicked }) => (clicked ? "#008091" : "#444444")};

    :hover {
        color: #008091;
    }

    height: 30px;
    background-color: #f5f5f5;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    display: flex;
    align-items: center;
    font-size: 14px;
`;

const ErrorToastContainer = styled.div`
    cursor: auto;
`;

export function copyDirectlyToClipboard(text: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
        try {
            let clipboard = navigator?.clipboard;
            if (clipboard) {
                const result = clipboard
                    .writeText?.(text)
                    .then((_) => resolve(true))
                    .catch((_) => reject(false));
                if (!result) {
                    reject(false);
                } else {
                    resolve(true);
                }
            } else {
                console.error(
                    "Cannot copy to clipboard. Are you using an old browser version?"
                );
                reject(false);
            }
        } catch (e) {
            console.error(e);
            reject(false);
        }
    });
}

export const ToastWithErrorId = ({ errorId }: { errorId?: string | null }) => {
    const shortenedErrorId = errorId ? limitString(errorId, 18) : null;
    const [clicked, setClicked] = useState(false);
    return (
        <ErrorToastContainer>
            <ErrorInfo>An error occurred...</ErrorInfo>
            {shortenedErrorId && (
                <ErrorIdField
                    clicked={clicked}
                    onClick={() => {
                        copyDirectlyToClipboard(errorId!!).then(() => {
                            setClicked(true);
                            setTimeout(() => {
                                setClicked(false);
                            }, 2000);
                        });
                    }}
                >
                    <ErrorId>Error ID: {shortenedErrorId}</ErrorId>
                    <CopyButton>{clicked ? <CheckLg /> : <Files />}</CopyButton>
                </ErrorIdField>
            )}
        </ErrorToastContainer>
    );
};
