import React from "react";

export const transformImageUrl = (imageUrl, transformation) => {
    if (imageUrl && imageUrl.indexOf("spond.com") !== -1 && !!transformation) {
        return `${imageUrl}?${transformation}`;
    }

    return imageUrl;
};

const SpondImage = (props) => {
    const { imageUrl, transformation, ...rest } = props;
    if (!imageUrl) {
        return null;
    }

    const transformedImageUrl = transformImageUrl(imageUrl, transformation);

    return (
        <img key={transformedImageUrl} src={transformedImageUrl} {...rest} />
    );
};

export default SpondImage;
