import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { withTranslation } from "react-i18next";
import Confetti from "react-confetti";

import { PurchaseModalButton } from "../../../components/button/Buttons";

import {
    WinnerContainer,
    HeaderText1,
    HeaderText2,
    ConfettiWrapper,
} from "../styled-components/ScratchcardModel.styled";

class ScratchcardWonModal extends Component {
    render() {
        const { seller, onSelectPrizeClicked, t } = this.props;

        return (
            <Fragment>
                <ConfettiWrapper>
                    <Confetti width={window.width} height={window.height} />
                </ConfettiWrapper>
                <Modal
                    center={true}
                    open={true}
                    showCloseIcon={false}
                    onClose={() => {}}
                    classNames={{ modal: "unstyled-wide-scratchcard-modal" }}
                >
                    <WinnerContainer bgImage={seller.theme.winnerBackground}>
                        <HeaderText1 strokeColor={seller.theme.primaryColour}>
                            {t("modal.won_title")}
                        </HeaderText1>
                        <HeaderText2 strokeColor={seller.theme.primaryColour}>
                            {t("modal.won_description")}
                        </HeaderText2>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "10px",
                                justifyContent: "center",
                            }}
                        >
                            <PurchaseModalButton
                                theme={seller.theme}
                                style={{ marginRight: "10px" }}
                                onClick={() => onSelectPrizeClicked()}
                            >
                                {t("modal.won_action")}
                            </PurchaseModalButton>
                        </div>
                    </WinnerContainer>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(connect(mapStateToProps)(ScratchcardWonModal));
