import React, { Component } from "react";
import styled from "styled-components";

import { withTranslation } from "react-i18next";

import CloseButton from "../button/CloseButton";

const InfoHolder = styled.div`
    padding: 15px;
    color: #393939;
    max-width: 100%;
    box-sizing: border-box;
    position: relative;
    max-width: 480px;
    margin: 0 auto;
`;

const Header = styled.h1`
    line-height: 1em;
    color: ${(props) => props.color};
    margin: 0;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
        1px 1px 0 #fff;
`;

const InfoCard = styled.div`
    margin: 15px auto;
    background: white;
    border-radius: 8px;
    padding: 15px 20px;
    box-shadow: 0 7px 6px 0 rgba(21, 0, 0, 0.07);
    box-sizing: border-box;

    h2 {
        font-size: 18px;
        margin-bottom: 10px;
        text-align: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #e4e4e4;
    }
`;

const PrizeList = styled.div`
    display: flex;
    flex-direction: column;
`;

const PrizeColumn = styled.div``;

const PrizeRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-items: center;

    &:first-child {
        margin-top: 20px;
    }
    &:last-child {
        margin-bottom: 20px;
    }

    ${PrizeColumn} {
        &:first-child {
            width: 100px;
            flex-basis: 100px;
            flex-shrink: 0;
        }
        &:last-child {
            display: flex;
            vertical-align: middle;
            align-items: center;
        }
    }
`;

const PrizeImage = styled.div`
    background-image: url("${({ src }) => src}");
    background-size: cover;
    width: 80px;
    height: 80px;
    box-shadow: 0 2px 6px 0 rgba(21, 0, 0, 0.15);
`;

class ScratchcardInfo extends Component {
    onCloseClicked = () => {
        const { onCloseClicked: parentOnCloseClicked } = this.props;
        parentOnCloseClicked();
    };

    render() {
        const { t, products, seller } = this.props;

        return (
            <InfoHolder>
                <Header
                    color={seller.theme.primaryColour}
                    strokeColor={seller.theme.primaryStrokeColour}
                    style={{ textAlign: "center" }}
                >
                    Info
                </Header>

                <CloseButton onClick={() => this.onCloseClicked()} />

                <InfoCard>
                    <h2>{t("info.introduction_title1")}</h2>
                    {t("info.introduction_description1_part1")}
                    <br />
                    <br />
                    {t("info.introduction_description1_part2")}
                    <a className="email" href="mailto:support@spond.com">
                        support@spond.com
                    </a>
                </InfoCard>

                <InfoCard>
                    <h2>{t("info.introduction_title2")}</h2>
                    <p style={{ marginBottom: 0 }}>
                        {t("info.introduction_description2")}
                    </p>
                    <PrizeList>
                        {products.map(({ preview, name }, idx) => (
                            <PrizeRow key={idx}>
                                <PrizeColumn>
                                    <PrizeImage src={preview} />
                                </PrizeColumn>
                                <PrizeColumn>{name}</PrizeColumn>
                            </PrizeRow>
                        ))}
                    </PrizeList>
                    <div
                        style={{
                            borderTop: "1px solid #e4e4e4",
                            paddingTop: "10px",
                            marginTop: "10px",
                            textAlign: "center",
                        }}
                    >
                        {t("info.footer")}
                    </div>
                </InfoCard>
            </InfoHolder>
        );
    }
}

export default withTranslation()(ScratchcardInfo);
