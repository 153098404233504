import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ScrollToTop from "../../components/scroll-to-top/ScrollToTop";
import queryString from "query-string";
import ScratchcardWrapper from "../../containers/scratchcards/ScratchcardWrapper/ScratchcardWrapper";
import ProductLandingWrapper from "../../containers/product/landing/ProductLandingWrapper.container";
import SpotLanding from "../../containers/spot-the-ball/landing/SpotTheBallLanding.container";
import { COLORS, FUNDRAISER_TYPE } from "../../data/consts";
import styled from "styled-components";
import i18n from "i18next";
import { CustomBanner } from "../../containers/scratchcards/ScratchcardWrapper/CustomBanner";
import { CustomIntro } from "../../containers/scratchcards/ScratchcardWrapper/CustomIntro";
import { getScratchThemeType } from "../../containers/scratchcards/ScratchcardWrapper/CustomScratch.define";
import SuperDrawLanding from "../../containers/super-draw/landing/SuperDrawLanding.container";
import CakeStartPage from "../cake-lottery/cake-start-page/CakeStart.page";
import { getAlpha3CountryCodeByAlpha2Code } from "../../utils/localeHelpers";
import { apiClient } from "../../api/ApiClient";

export const PreviewBed = styled.div`
    background: ${(props) => {
        return COLORS.$spondGray9BaseTertiary;
    }};
    border-radius: 10px;
    box-shadow: 0 0 10px
        ${(props) => (props.dark ? "rgba(255,255,255,0.3)" : "rgba(0,0,0,0.3)")};
    overflow: hidden;
`;

export const PreviewHeader = styled.div`
    background-color: ${COLORS.$spondGray7BaseTertiary};
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;

export const PreviewHeaderTitle = styled.div`
    color: ${COLORS.$spondGray2BaseTertiary};
    font-size: 16px;
    width: 100%;
    text-align: center;
    font-weight: bold;
`;

export const PreviewHeaderDescription = styled.div`
    color: ${COLORS.$spondGray3BaseTertiary};
    font-size: 12px;
    width: 100%;
    text-align: center;
    line-height: 16px;
`;

export const CustomIntroBed = styled.div`
    padding: 0 20px;
`;

class PreviewPage extends Component {
    constructor(props) {
        super(props);

        const values = queryString.parse(this.props.location.search);

        let state = {
            seller: { loaded: false, theme: {} },
            t: this.props.t,
            dark: values.dark === "true",
            lang: values.lang,
            isSTB: false,
            campaign: {
                groupName: values.name,
                description: values.description,
                scratchcardPrice: values.price,
                prices: values.price,
                template: { id: values.templateId },
                showTitle: !!values.showTitle,
                activity: values.activity,
                prizeDescription: values.prizeDescription,
                prizeType: values.prizeType,
                prizePercentage: values.prizePercentage,
            },
        };

        document.body.style.background = values.bkg
            ? `#${values.bkg}`
            : "transparent";

        state.previewBedColor = COLORS.$spondGray9BaseTertiary;
        state.navigationBarColor = COLORS.$spondGray7BaseSecondary;
        state.addressFieldColor = COLORS.$spondGray6BaseSecondary;

        this.state = state;
    }

    componentDidMount() {
        var displayMessage = (evt) => {
            if (
                evt.origin === window.origin ||
                evt.origin === "http://localhost:3000" ||
                evt.origin === "https://spond.com" ||
                evt.origin === "https://club.spond.com" ||
                /https:\/\/(club\.)?[a-z0-9]+\.spond\.com/.exec(evt.origin)
            ) {
                if (evt && evt.data) {
                    try {
                        var payload = JSON.parse(evt.data);
                        document
                            .getElementsByName("group-name")
                            .forEach((e) => (e.innerText = payload.groupName));
                        document
                            .getElementsByName("fundraiser-purpose")
                            .forEach((e) => (e.innerText = payload.purpose));
                    } catch (e) {}
                }
            }
        };
        if (window.addEventListener) {
            window.addEventListener("message", displayMessage, false);
        } else {
            window.attachEvent("onmessage", displayMessage);
        }

        if (ResizeObserver) {
            const ro = new ResizeObserver((elements) => {
                const e = elements[0].contentRect;
                if (window.parent) {
                    window.parent.postMessage(
                        {
                            event: "size",
                            height: e.height,
                        },
                        "*"
                    );
                }
            });
            const wrapper = document.getElementsByClassName("page-wrapper");
            if (wrapper.length) ro.observe(wrapper[0]);
        }

        const themeId = this.props.match.params.themeId;
        const templateId = this.state.campaign.template.id;
        const activity = this.state.campaign.activity;
        if (themeId) {
            apiClient
                .getTheme(themeId, activity)
                .then((res) => {
                    const {
                        preview,
                        primaryColour,
                        primaryStrokeColour,
                        primaryButtonColour,
                        primaryButtonTextColour,
                        primaryButtonStrokeColour,
                        themeImage,
                        background,
                        activityImage,
                        prizeInfoImage,
                        scratchImage,
                        loserBackground,
                        winnerBackground,
                        landingBackground,
                        fullscreenBackground,
                        landingScratchImage,
                        metaImage,
                        type,
                        mobileBackground,
                        prizeProviderLogo,
                    } = res.data;

                    let products = null;
                    if (this.state.lang) {
                        i18n.changeLanguage(this.state.lang);
                    } else if (
                        type === FUNDRAISER_TYPE.SPOT_THE_BALL ||
                        type === FUNDRAISER_TYPE.SUPER_DRAW
                    ) {
                        i18n.changeLanguage("en");
                    }
                    const loaded =
                        FUNDRAISER_TYPE.PRODUCTS !== type &&
                        FUNDRAISER_TYPE.SPOT_THE_BALL !== type &&
                        FUNDRAISER_TYPE.SUPER_DRAW !== type;

                    if (FUNDRAISER_TYPE.PRODUCTS === type) {
                        apiClient
                            .getProductsByTemplateId(templateId)
                            .then((res) => {
                                products = res.data;

                                const { seller } = this.state;
                                seller.products = products.products;
                                seller.productSet = {
                                    products: products.products,
                                };
                                seller.delivery = products.deliveryTime;
                                seller.loaded = true;
                                seller.type = "PRODUCTS";

                                this.setState({
                                    seller: seller,
                                });
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    }

                    if (
                        FUNDRAISER_TYPE.SPOT_THE_BALL === type ||
                        FUNDRAISER_TYPE.SUPER_DRAW === type
                    ) {
                        apiClient
                            .getTemplate(templateId)
                            .then((res) => {
                                const template = res.data;

                                const { seller } = this.state;
                                if (FUNDRAISER_TYPE.SPOT_THE_BALL === type) {
                                    seller.priceTiers =
                                        template.spotTheBallInfo.priceTiers;
                                } else {
                                    seller.superdrawPrizes =
                                        template.superdrawInfo.prizes;
                                    seller.superdrawDrawTime =
                                        template.superdrawInfo.drawTime;
                                    seller.priceTiers =
                                        template.superdrawInfo.priceTiers;
                                }

                                seller.loaded = true;

                                this.setState({
                                    seller: seller,
                                });
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    }

                    const {
                        groupName,
                        description,
                        scratchcardPrice,
                        showTitle,
                        prizeType,
                        prizeDescription,
                        prizePercentage,
                    } = this.state.campaign;

                    this.setState({
                        isSTB: type === FUNDRAISER_TYPE.SPOT_THE_BALL,
                        isProduct: type === FUNDRAISER_TYPE.PRODUCTS,
                        isRaffle: type === FUNDRAISER_TYPE.RAFFLE,
                        isScratchcards: type === FUNDRAISER_TYPE.SCRATCHCARDS,
                        isSuperDraw: type === FUNDRAISER_TYPE.SUPER_DRAW,
                        seller: {
                            loaded: loaded,
                            theme: {
                                preview,
                                primaryColour,
                                primaryStrokeColour,
                                primaryButtonColour,
                                primaryButtonTextColour,
                                primaryButtonStrokeColour,
                                themeImage,
                                background,
                                activityImage,
                                prizeInfoImage,
                                scratchImage,
                                loserBackground,
                                winnerBackground,
                                landingBackground,
                                fullscreenBackground,
                                landingScratchImage,
                                metaImage,
                                id: themeId,
                                mobileBackground,
                                prizeProviderLogo,
                            },
                            prize: {
                                type: prizeType,
                                description: prizeDescription,
                                percentage: prizePercentage,
                            },
                            type,
                            groupName,
                            description,
                            products,
                            showTitle,
                            prices: scratchcardPrice,
                            scratchcardPrice: scratchcardPrice,
                            countryCode: getAlpha3CountryCodeByAlpha2Code(
                                this.props.i18n.language.toUpperCase()
                            ),
                        },
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            console.error("Invalid theme id");
        }
    }

    doNothing = () => {};

    render() {
        const {
            t,
            seller,
            campaign,
            isSTB,
            isProduct,
            isRaffle,
            isSuperDraw,
            isScratchcards,
        } = this.state;
        const themeType = getScratchThemeType(seller.theme.id);

        if (!seller.loaded)
            return (
                <div>
                    <p>{t("landing.loading")}</p>
                </div>
            );
        return (
            <PreviewBed>
                <ScrollToTop />
                {!campaign.showTitle && <PreviewHeader />}
                {campaign.showTitle && (
                    <PreviewHeader>
                        <PreviewHeaderTitle id="preview-title">
                            {t("preview.title")}
                        </PreviewHeaderTitle>
                        <PreviewHeaderDescription>
                            {t("preview.description")}
                        </PreviewHeaderDescription>
                    </PreviewHeader>
                )}

                {isSTB && seller && (
                    <SpotLanding
                        previewMode={true}
                        preivewSellerInfo={seller}
                    />
                )}
                {isRaffle && seller && (
                    <div className={"cakelotteryWrapper"}>
                        <CakeStartPage
                            previewSeller={seller}
                            t={t}
                            themeType={themeType}
                        />
                    </div>
                )}
                {!!themeType && (
                    <CustomBanner
                        seller={seller}
                        previewMode={true}
                        themeType={themeType}
                    />
                )}
                {(isScratchcards || isProduct || isSuperDraw) && (
                    <>
                        {isScratchcards && (
                            <ScratchcardWrapper
                                previewMode={true}
                                seller={seller}
                                t={t}
                                themeType={themeType}
                            />
                        )}
                        {!!themeType && (
                            <CustomIntroBed>
                                <CustomIntro themeType={themeType} />
                            </CustomIntroBed>
                        )}
                        {isSuperDraw && seller && (
                            <SuperDrawLanding
                                previewMode={true}
                                preivewSellerInfo={seller}
                                themeType={themeType}
                            />
                        )}
                        {isProduct && (
                            <div>
                                <ProductLandingWrapper
                                    seller={seller}
                                    t={t}
                                    viewProduct={this.doNothing}
                                    handleProductActionClicked={this.doNothing}
                                    themeType={themeType}
                                    previewMode={true}
                                />
                            </div>
                        )}
                    </>
                )}
            </PreviewBed>
        );
    }
}

const mapStateToProps = (state) => ({});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(PreviewPage))
);
