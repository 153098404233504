import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    Button,
    VippsButton,
} from "../../../components/cake-lottery/button/Button";
import Input from "../../../components/cake-lottery/input/Input";
import Layout from "../../../components/cake-lottery/layout/Layout";
import Ticket from "../../../components/cake-lottery/ticket/Ticket";
import CakeTicketsContext from "../../../context/CakeTicketsContext";
import { initiateStandardPayment } from "../../../utils/payment";
import * as styles from "./CakePay.module.scss";
import { toast } from "react-toastify";
import qs from "query-string";

const comparingByIncreasingNumber = (a, b) => {
    if (a > b) return 1;
    else if (a < b) return -1;
    else return 0;
};
const comparingByIncreasingNumberString = (a, b) =>
    comparingByIncreasingNumber(parseInt(a), parseInt(b));

const CakePayPage = ({ missingTicketsRedirect, seller }) => {
    const [contactInfo, setContactInfo] = useState({ name: "", email: "" });
    const { selectedTickets } = useContext(CakeTicketsContext);
    const formRef = useRef(null);
    const { salesLinkCodeOrShortId } = useParams();
    const { t } = useTranslation();
    const { theme } = seller;
    const location = useLocation();

    const onChange = (e) => {
        setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
    };

    const handlePaymentError = (err) => {
        console.error("Payment error", err);
        return toast(t("toast.form_submit_error"), { type: "error" });
    };

    const initiatePayment = (paymentType) => {
        const { source, profileId } = qs.parse(location.search);
        const { name, email } = contactInfo;
        const cart = selectedTickets.map((ticket) => ({
            quantity: 1,
            label: ticket,
        }));

        if (!/\S+@\S+\.\S+/.test(email)) {
            return toast(t("error.error_invalid_email"), { type: "error" });
        }

        initiateStandardPayment({
            cart,
            seller,
            type: paymentType,
            source,
            sellerProfileId: profileId,
            formData: {
                name,
                phoneNumber: "",
                email,
            },
            errorCallback: handlePaymentError,
            salesLinkCodeOrShortId,
        });
    };

    const requiredFieldsFilled = () => {
        return contactInfo.name.length > 0 && contactInfo.email.length > 0;
    };
    const missingRequiredFields = !requiredFieldsFilled();
    useEffect(() => {
        if (selectedTickets.length === 0) missingTicketsRedirect();
    }, [missingTicketsRedirect, selectedTickets.length]);
    return (
        <Layout className={styles.layout} theme={theme}>
            <form ref={formRef} action="" onSubmit={onSubmit}>
                <h1 className={styles.header}>
                    {t("raffle.checkout_form_heading")}
                </h1>
                <div className={styles.ticketContainer}>
                    {selectedTickets
                        .sort(comparingByIncreasingNumberString)
                        .map((n) => (
                            <Ticket
                                key={n}
                                number={n}
                                className={styles.ticket}
                                selected
                                theme={theme}
                            />
                        ))}
                </div>
                <div>
                    <Input
                        data-testid="name-input"
                        name="name"
                        type="text"
                        label={t("general.name")}
                        value={contactInfo.name}
                        onChange={onChange}
                        required
                    />
                    <Input
                        data-testid="email-input"
                        name="email"
                        type="text"
                        label={t("general.email")}
                        value={contactInfo.email}
                        onChange={onChange}
                        required
                    />
                </div>
                <div />
                <div className={styles.buttonsWrapper}>
                    <VippsButton
                        data-testid="vipps-button"
                        type="vipps"
                        onClick={() => initiatePayment("VIPPS")}
                        submit
                        disabled={missingRequiredFields}
                    >
                        {t("actions.buy_with")}
                    </VippsButton>
                    <Button
                        data-testid="card-button"
                        theme={theme}
                        onClick={() => initiatePayment("CARD")}
                        submit
                        disabled={missingRequiredFields}
                    >
                        {t("actions.buy_with_card")}
                    </Button>
                </div>
                <p
                    className={styles.paymentInfo}
                    dangerouslySetInnerHTML={{
                        __html: t("landing.purchase_terms_link"),
                    }}
                />
            </form>
        </Layout>
    );
};
const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default connect(mapStateToProps)(CakePayPage);
