import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { shuffleArray } from "../../utils/utils";

const SlotOuterHolder = styled.div`
    display: flex;
    justify-content: center;
`;

const slotAnimation = keyframes`
  0% { transform: translateY(0); }
  100% { transform: translateY( -90% ); }
`;

const Numbers = styled.div`
    margin-top: 2px;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-name: ${({ started }) => (started ? slotAnimation : "none")};

    p {
        @media screen and (max-width: 768px) {
            font-size: 34px;
        }

        height: 1em;
        font-size: 60px;
        font-family: "Dosis", sans-serif !important;
        font-weight: 700 !important;
        line-height: 1;
        text-align: center;
        margin: 0;
        padding: 0;
    }
`;

const SlotWrapItem = styled.div`
    @media screen and (max-width: 768px) {
        height: 40px;
    }

    height: 70px;
    overflow: hidden;

    &.-d6 > ${Numbers} {
        animation-duration: 1.5s;
    }
    &.-d5 > ${Numbers} {
        animation-duration: 2s;
    }
    &.-d4 > ${Numbers} {
        animation-duration: 2.5s;
    }
    &.-d3 > ${Numbers} {
        animation-duration: 3s;
    }
    &.-d2 > ${Numbers} {
        animation-duration: 4.5s;
    }
    &.-d1 > ${Numbers} {
        animation-duration: 5s;
    }
`;

const SlotInnerHolder = styled.div`
    @media screen and (max-width: 768px) {
        padding: 0 10px;
    }

    padding: 0 20px;
    background-color: ${({ running }) => (!running ? "#c9f5b5" : "#fff")};
    opacity: ${({ started }) => (started ? 1.0 : 0.2)};
    border-radius: 8px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: background-color 0.5s ease, opacity 0.5s ease,
        border-color 0.5s ease;
`;

const Divider = styled.div`
    @media screen and (max-width: 768px) {
        height: 38px;
        font-size: 28px;
        padding: 0 5px;
    }

    height: 70px;
    font-size: 60px;
    padding: 0 10px;

    span {
        display: block;
        font-family: "Dosis", sans-serif !important;
        font-weight: 700 !important;
        line-height: 1;
        text-align: center;
    }
`;

const shuffleNumbersForDigit = (digit) => {
    const funcs = {
        shuffle_array(remove_num) {
            let numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
            numbers.splice(remove_num, 1);
            for (let i = numbers.length - 1; i >= 0; i--) {
                const rand = Math.floor(Math.random() * (i + 1));
                [numbers[i], numbers[rand]] = [numbers[rand], numbers[i]];
            }
            return numbers;
        },
        shuffle() {
            const set_num = digit;
            if (set_num >= 0) {
                let push_numbers = [];
                const numbers = this.shuffle_array(set_num);
                push_numbers.push(0);
                for (let i = 1; i < numbers.length; i++) {
                    push_numbers.push(numbers[i]);
                }
                push_numbers.push(set_num);
                return push_numbers;
            }
        },
    };

    return funcs.shuffle();
};

const Slot = ({ label, started, running }) => {
    const [numbers, setNumbers] = useState();

    useEffect(() => {
        if (!label) {
            return;
        }

        const numbers = [];
        for (let i = 0; i < label.length; i++) {
            numbers.push(shuffleNumbersForDigit(parseInt(label.charAt(i))));
        }
        setNumbers(numbers);
    }, [label]);

    if (!label || !label.length === 6 || !numbers) {
        return null;
    }

    return (
        <SlotOuterHolder id="win_code">
            <SlotInnerHolder running={running} started={started}>
                <SlotWrapItem className="-d6">
                    <Numbers started={started}>
                        {numbers[0].map((num, idx) => (
                            <p key={idx}>{num}</p>
                        ))}
                    </Numbers>
                </SlotWrapItem>
                <SlotWrapItem className="-d5">
                    <Numbers started={started}>
                        {numbers[1].map((num, idx) => (
                            <p key={idx}>{num}</p>
                        ))}
                    </Numbers>
                </SlotWrapItem>
                <SlotWrapItem className="-d4">
                    <Numbers started={started}>
                        {numbers[2].map((num, idx) => (
                            <p key={idx}>{num}</p>
                        ))}
                    </Numbers>
                </SlotWrapItem>
                <Divider>
                    <span>-</span>
                </Divider>
                <SlotWrapItem className="-d3">
                    <Numbers started={started}>
                        {numbers[3].map((num, idx) => (
                            <p key={idx}>{num}</p>
                        ))}
                    </Numbers>
                </SlotWrapItem>
                <SlotWrapItem className="-d2">
                    <Numbers started={started}>
                        {numbers[4].map((num, idx) => (
                            <p key={idx}>{num}</p>
                        ))}
                    </Numbers>
                </SlotWrapItem>
                <SlotWrapItem className="-d1">
                    <Numbers started={started}>
                        {numbers[5].map((num, idx) => (
                            <p key={idx}>{num}</p>
                        ))}
                    </Numbers>
                </SlotWrapItem>
            </SlotInnerHolder>
        </SlotOuterHolder>
    );
};

export default Slot;
