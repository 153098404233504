import React from "react";

import SpotTheBallSelectorContainer from "../selector/SpotTheBallSelector.container";
import { Header, HeaderInner } from "../game/BallGame.styled";
import { PurchaseButton } from "../../../components/button/Buttons";
import { withTranslation } from "react-i18next";

class SpotTheBallJudgeContainer extends SpotTheBallSelectorContainer {
    render() {
        const { onRejectImage, t } = this.props;

        return (
            <>
                <Header>
                    <HeaderInner>
                        <h3
                            style={{
                                margin: 0,
                                userSelect: "none",
                                outline: "none",
                                WebkitTouchCallout: "none",
                                WebkitUserSelect: "none",
                            }}
                        >
                            {t("landing.spot_the_ball")}
                        </h3>
                        <p
                            style={{
                                userSelect: "none",
                                outline: "none",
                                WebkitTouchCallout: "none",
                                WebkitUserSelect: "none",
                            }}
                        >
                            {t("stb.judge_description_part_1")}
                        </p>
                        <p
                            style={{
                                marginTop: "0",
                                marginBottom: "10px",
                                color: "white",
                                paddingBottom: "20px",
                                fontWeight: "700",
                                userSelect: "none",
                                outline: "none",
                                WebkitTouchCallout: "none",
                                WebkitUserSelect: "none",
                            }}
                        >
                            {t("stb.judge_description_part_2")}
                        </p>
                    </HeaderInner>
                </Header>

                {super.render()}

                <div style={{ textAlign: "center" }}>
                    <PurchaseButton onClick={() => onRejectImage()}>
                        {t("actions.reject")}
                    </PurchaseButton>
                </div>
            </>
        );
    }
}

export default withTranslation()(SpotTheBallJudgeContainer);
