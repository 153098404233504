import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import ScrollToTop from "../../../components/scroll-to-top/ScrollToTop";
import {
    InnerContentHolder,
    OuterContentHolder,
    STBBackgroundBanner,
    STBBackgroundBannerInner,
    STBBuyButtonHolder,
    STBDescription,
    STBLandingHeader,
    STBProductsWrapper,
    STBTwoColumn,
    STBTwoColumnDescription,
} from "./SpotTheBallLanding.container.styled";
import {
    capturePaypalPayment,
    initiatePaypalQuizPayment,
    initiateStripeQuizPayment,
} from "../../../utils/payment";
import {
    QuizNormalButton,
    QuizOutlineButton,
} from "../../../components/button/Buttons";
import Modal from "react-responsive-modal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PAYPAL_CLIENT_ID } from "../../../data/config";
import ClipLoader from "react-spinners/ClipLoader";
import {
    CurrencyFormatter,
    CurrencyWithDecimal,
} from "../../../utils/Currency";
import DOMPurify from "dompurify";
import {
    getMostExpensivePrice,
    getPricesOrderedByPrice,
} from "../../../utils/sellerHelpers";
import UkHeader from "../../../components/uk-header/UkHeader";

import qs from "query-string";

import SpondImage from "../../../components/spond-image/SpondImage";

import * as primaryBg from "../../../assets/background-blue.jpg";
import * as stbHowWorks from "../../../assets/stb-how-does-it-work.png";
import * as orangeBg from "../../../assets/background-orange.jpg";
import * as purpleBg from "../../../assets/background-purple.jpg";
import * as gifImage from "../../../assets/gift.png";
import * as medalImage from "../../../assets/medal.png";
import * as moneyImage from "../../../assets/money.png";
import { routes } from "../../../app/routes";

class SpotLanding extends Component {
    state = {
        showPaymentMethodSelector: false,
        isSubmittingPayment: false,
        selectedProduct: null,
        customContribution: "",
    };

    handleCreatePaypalOrder(data, actions) {
        this.setState({ isSubmittingPayment: true });

        const { selectedProduct, unitPrice } = this.state;
        const {
            seller,
            location,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { id: productId, price: productPrice } = selectedProduct;
        const { source, profileId } = qs.parse(location.search);

        return initiatePaypalQuizPayment({
            productId,
            seller,
            unitPrice: unitPrice ? unitPrice : productPrice,
            source,
            sellerProfileId: profileId,
            salesLinkCodeOrShortId,
        })
            .then((details) => {
                return details.id;
            })
            .catch((err) => {
                console.error("Error initiating paypal quiz payment", err);
            });
    }

    handleOnApprovePaypalOrder(data, actions) {
        const {
            seller,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;

        return capturePaypalPayment(data.orderID, seller)
            .then((details) => {
                window.location.href =
                    routes
                        .seller(salesLinkCodeOrShortId)
                        .spotTheBall.extOrder(details.extOrderId) +
                    `?key=${details.key}`;
            })
            .catch((err) => {
                console.error(err);
            });
    }

    handlePaypalOrderCancelled() {
        this.setState({
            isSubmittingPayment: false,
        });
    }

    onClosePaymentSelector() {
        this.setState({
            showPaymentMethodSelector: false,
            isSubmittingPayment: false,
        });
    }

    onStripeBuyClicked(selectedProduct) {
        this.setState({ isSubmittingPayment: true });
        const {
            seller,
            location,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { source, profileId } = qs.parse(location.search);

        initiateStripeQuizPayment({
            seller,
            unitPrice: selectedProduct.quantity,
            source,
            sellerProfileId: profileId,
            salesLinkCodeOrShortId,
        });
    }

    onContributeClicked(price, customContribution) {
        const {
            seller,
            history,
            location,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { source, profileId } = qs.parse(location.search);
        const query = customContribution
            ? `?contribution=${CurrencyWithDecimal(
                  seller,
                  customContribution
              )}&${!!source ? `source=${source}` : ""}${
                  source && profileId ? "&" : ""
              }${profileId ? `profileId=${profileId}` : ""}`
            : `?quantity=${price.quantity}&${
                  !!source ? `source=${source}` : ""
              }${source && profileId ? "&" : ""}${
                  profileId ? `profileId=${profileId}` : ""
              }`;
        history.push(
            routes.seller(salesLinkCodeOrShortId).spotTheBall.pathname + query
        );
    }

    onCustomContributionClicked(customContribution) {
        const {
            seller: { priceTiers },
        } = this.props;
        const mostExpensivePrice = getMostExpensivePrice(priceTiers);
        const unitPrice = parseInt(customContribution) * 100;
        if (unitPrice < mostExpensivePrice.price || !unitPrice) {
            this.setState({ showMinContributionTip: true });
            return;
        }

        this.onContributeClicked(null, customContribution);
    }

    showCustomContribution = () => {
        this.setState(
            {
                toggleChooseContribution: true,
            },
            () => {
                if (this.customContribution) {
                    this.customContribution.focus();
                }
            }
        );
    };

    render() {
        const { t, seller, preivewSellerInfo, previewMode } = this.props;
        const {
            showPaymentMethodSelector,
            isSubmittingPayment,
            toggleChooseContribution,
            showMinContributionTip,
            customContribution,
        } = this.state;
        const {
            description,
            groupName,
            priceTiers,
            prize,
            theme,
            campaignStatus,
        } = previewMode ? preivewSellerInfo : seller;

        const hasEnded = campaignStatus === "CLOSED";
        if (hasEnded) {
            return (
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "180px",
                        fontWeight: "bold",
                    }}
                >
                    {t("landing.fundraising_ended")}
                </p>
            );
        }

        let prizeDescription;
        if (prize?.type === "PROVIDED") {
            prizeDescription = prize.description;
        } else {
            prizeDescription = t("landing.spot_prize_description_gift_card", {
                count: prize?.percentage || 10,
            });
        }

        const orderedPrices = getPricesOrderedByPrice(priceTiers);
        const mostExpensivePrice = getMostExpensivePrice(priceTiers);
        const minimumCustomContribution = mostExpensivePrice.price;

        return (
            <div style={{ color: "#4c4c4c" }}>
                <ScrollToTop />
                <STBLandingHeader background={primaryBg}>
                    <UkHeader
                        seller={previewMode ? preivewSellerInfo : seller}
                        previewMode={previewMode}
                    />
                </STBLandingHeader>

                <OuterContentHolder>
                    <InnerContentHolder>
                        <STBProductsWrapper>
                            <div
                                style={{
                                    textAlign: "center",
                                    boxSizing: "border-box",
                                    width: "100%",
                                }}
                            >
                                <SpondImage
                                    imageUrl={theme.activityImage}
                                    transformation="q_auto"
                                    style={{
                                        maxWidth: "100%",
                                        objectFit: "cover",
                                        minHeigh: "120px",
                                    }}
                                />
                            </div>

                            <div style={{ width: "100%", margin: "0 auto" }}>
                                <div>
                                    <h2 style={{ marginBottom: "20px" }}>
                                        {t("landing.choose_contribution")}
                                    </h2>
                                </div>
                                <STBBuyButtonHolder>
                                    {orderedPrices.map((price) => (
                                        <div key={price.price}>
                                            <QuizNormalButton
                                                id="buy_button"
                                                isPreview={previewMode}
                                                onClick={() => {
                                                    !previewMode &&
                                                        this.onContributeClicked(
                                                            price
                                                        );
                                                }}
                                            >
                                                {t(
                                                    "landing.contribute_and_choose_x_attemps",
                                                    {
                                                        count: Number(
                                                            price.quantity
                                                        ),
                                                        amount: CurrencyFormatter(
                                                            t,
                                                            seller,
                                                            price.price
                                                        ),
                                                    }
                                                )}
                                            </QuizNormalButton>
                                        </div>
                                    ))}
                                </STBBuyButtonHolder>
                            </div>

                            <div style={{ marginTop: "15px" }}>
                                {!toggleChooseContribution && (
                                    <>
                                        <QuizOutlineButton
                                            isPreview={previewMode}
                                            onClick={() => {
                                                !previewMode &&
                                                    this.showCustomContribution();
                                            }}
                                        >
                                            {t(
                                                "actions.choose_your_own_contribution"
                                            )}
                                        </QuizOutlineButton>
                                    </>
                                )}
                                {toggleChooseContribution && (
                                    <>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginTop: "15px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginBottom: "15px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: "16px",
                                                        paddingTop: "10px",
                                                        maxWidth: "320px",
                                                        margin: "0 auto",
                                                        lineHeight: "150%",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {t(
                                                        "actions.choose_your_own_contribution"
                                                    )}
                                                </p>
                                                <p
                                                    style={{
                                                        fontSize: "14px",
                                                        paddingBottom: "15px",
                                                        maxWidth: "320px",
                                                        margin: "0 auto",
                                                        lineHeight: "150%",
                                                    }}
                                                >
                                                    {t(
                                                        "landing.contribute_and_choose_x_attemps_spot",
                                                        {
                                                            count: mostExpensivePrice.quantity,
                                                        }
                                                    )}
                                                </p>
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        width: "100%",
                                                        margin: "0 auto",
                                                    }}
                                                >
                                                    <input
                                                        type="tel"
                                                        placeholder={t(
                                                            "landing.placeholder_enter_you_contribution"
                                                        )}
                                                        style={{
                                                            fontSize: "16px",
                                                            padding:
                                                                "14px 12px 14px 12px",
                                                            boxSizing:
                                                                "border-box",
                                                            borderRadius: "4px",
                                                            border: "1px solid #ccc",
                                                            width: "100%",
                                                            maxWidth: "320px",
                                                            outline: "none",
                                                            textAlign: "center",
                                                        }}
                                                        value={
                                                            customContribution
                                                        }
                                                        ref={(input) =>
                                                            (this.customContribution =
                                                                input)
                                                        }
                                                        onInput={(event) =>
                                                            (event.target.value =
                                                                event.target.value.replace(
                                                                    /[^\d]/g,
                                                                    ""
                                                                ))
                                                        }
                                                        onChange={(e) =>
                                                            this.setState({
                                                                customContribution:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ width: "100%" }}>
                                                <QuizNormalButton
                                                    disabled={
                                                        customContribution <=
                                                        mostExpensivePrice.price /
                                                            100
                                                    }
                                                    onClick={() =>
                                                        this.onCustomContributionClicked(
                                                            customContribution
                                                        )
                                                    }
                                                >
                                                    {t(
                                                        "landing.contribute_and_choose_x_attemps",
                                                        {
                                                            amount: CurrencyFormatter(
                                                                t,
                                                                seller,
                                                                customContribution *
                                                                    100
                                                            ),
                                                            count: Number(
                                                                mostExpensivePrice.quantity
                                                            ),
                                                        }
                                                    )}
                                                </QuizNormalButton>
                                            </div>
                                        </div>
                                        {showMinContributionTip && (
                                            <div
                                                style={{
                                                    fontSize: "12px",
                                                    marginTop: "15px",
                                                    color: "#cc0000",
                                                }}
                                            >
                                                {t(
                                                    "landing.minimun_contribution_tip",
                                                    {
                                                        countribution:
                                                            CurrencyFormatter(
                                                                t,
                                                                seller,
                                                                minimumCustomContribution
                                                            ),
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </STBProductsWrapper>
                    </InnerContentHolder>
                </OuterContentHolder>

                <STBTwoColumn className="how-does-it-work">
                    <div>
                        <h2>{t("landing.spot_how_does_it_work_heading")}</h2>
                        <STBTwoColumnDescription
                            dangerouslySetInnerHTML={{
                                __html: t(
                                    "landing.spot_how_does_it_work_description",
                                    {
                                        displayName:
                                            '<span name="group-name" style=display:inline-block>' +
                                            DOMPurify.sanitize(groupName, {
                                                USE_PROFILES: { html: false },
                                            }) +
                                            "</span>",
                                    }
                                ),
                            }}
                        />
                    </div>
                    <div>
                        <SpondImage
                            imageUrl={stbHowWorks}
                            transformation="q_auto"
                        />
                    </div>
                </STBTwoColumn>

                <STBBackgroundBanner background={orangeBg}>
                    <STBBackgroundBannerInner>
                        <div style={{ maxWidth: "150px" }}>
                            <SpondImage
                                imageUrl={medalImage}
                                transformation="q_auto"
                                style={{ maxWidth: "120px" }}
                            />
                        </div>
                        <div>
                            <h2>
                                {t("landing.spot_what_is_the_cause_heading")}
                            </h2>
                            <STBDescription
                                isSubTitle={false}
                                name="fundraiser-purpose"
                            >
                                {description}
                            </STBDescription>
                        </div>
                    </STBBackgroundBannerInner>
                </STBBackgroundBanner>

                <STBTwoColumn>
                    <div>
                        <h2>{t("landing.spot_how_do_i_win_heading")}</h2>
                        <p>{t("landing.spot_how_do_i_win_description")}</p>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <SpondImage
                            imageUrl={moneyImage}
                            transformation="q_auto"
                        />
                    </div>
                </STBTwoColumn>

                <STBBackgroundBanner background={purpleBg}>
                    <STBBackgroundBannerInner>
                        <div style={{ maxWidth: "150px" }}>
                            <SpondImage
                                imageUrl={gifImage}
                                transformation="q_auto"
                                style={{ maxWidth: "120px" }}
                            />
                        </div>
                        <div>
                            <h2>{t("landing.spot_what_can_i_win_heading")}</h2>
                            <p>{prizeDescription}</p>
                        </div>
                    </STBBackgroundBannerInner>
                </STBBackgroundBanner>

                <Modal
                    showCloseIcon={!isSubmittingPayment}
                    closeOnOverlayClick={!isSubmittingPayment}
                    center={true}
                    open={showPaymentMethodSelector}
                    onClose={() => this.onClosePaymentSelector()}
                    classNames={{ modal: "action-modal" }}
                >
                    <div style={{ padding: "25px 15px 15px 15px" }}>
                        <div
                            style={{
                                display: isSubmittingPayment ? "block" : "none",
                            }}
                        >
                            <h3 style={{ textAlign: "center" }}>
                                {t("stb.processing_order")}
                            </h3>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <ClipLoader
                                    size={48}
                                    color={"#00b4cc"}
                                    loading={true}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                display: isSubmittingPayment ? "none" : "block",
                            }}
                        >
                            <h3 style={{ textAlign: "center" }}>
                                {t("actions.select_payment_method")}
                            </h3>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <div style={{ marginBottom: "10px" }}>
                                    <PayPalScriptProvider
                                        options={{
                                            "client-id": PAYPAL_CLIENT_ID,
                                            currency: "GBP",
                                        }}
                                    >
                                        <PayPalButtons
                                            createOrder={(data, actions) =>
                                                this.handleCreatePaypalOrder(
                                                    data,
                                                    actions
                                                )
                                            }
                                            onApprove={(data, actions) =>
                                                this.handleOnApprovePaypalOrder(
                                                    data,
                                                    actions
                                                )
                                            }
                                            onCancel={() =>
                                                this.handlePaypalOrderCancelled()
                                            }
                                            shippingPreference="NO_SHIPPING"
                                            style={{
                                                layout: "horizontal",
                                                color: "blue",
                                                height: 49,
                                                label: "pay",
                                                tagline: false,
                                            }}
                                        />
                                    </PayPalScriptProvider>
                                </div>
                                <div>
                                    <QuizNormalButton
                                        onClick={() =>
                                            this.onStripeBuyClicked()
                                        }
                                    >
                                        {t("actions.pay_with_card")}
                                    </QuizNormalButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
    withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SpotLanding))
);
