import styled from "styled-components";

export const ContentHolder = styled.div`
    margin: 15px auto;
    width: 100%;
`;

export const FormHolder = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 480px;
`;

export const ButtonHolder = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

export const Input = styled.input`
    padding: 12px;
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid #ccc;
    outline: none;
    font-size: 16px;
`;

export const FormLabel = styled.label`
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 5px;
`;

export const CheckboxLabel = styled.div`
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    font-weight: normal;
    margin-top: 20px;
    text-align: left;
`;

export const LabelText = styled.label`
    cursor: pointer;
`;

export const CheckboxBed = styled.div`
    margin-right: 8px;
`;
