import React from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";

import LandingPage from "../pages/campaigns/landing/Landing.page";
import OrderCancelledPage from "../pages/campaigns/order/OrderCancelled.page";
import OrderCompletedPage from "../pages/campaigns/order/OrderCompleted.page";
import Preloader from "./Preloader";
import NotFoundPage from "../pages/not-found/NotFound.page";
import TermsPage from "../pages/campaigns/terms/Terms.page";
import SuperDrawPostalEntryPage from "../pages/campaigns/super-draw/SuperDrawPostalEntry.page";
import VippsFallbackPage from "../pages/campaigns/vipps-fallback/VippsFallback.page";
import ShoppingCartPage from "../pages/campaigns/shopping-cart/ShoppingCart.page";
import SpotTheBallPage from "../pages/campaigns/spot-the-ball/SpotTheBall.page";
import ScratchcardPage from "../pages/campaigns/scratchcards/Scratchcard.page";
import ScratchcardInfoPage from "../pages/campaigns/scratchcards/ScratchcardInfo.page";
import ScratchcardPrizePage from "../pages/campaigns/scratchcards/ScratchcardPrize.page";
import ScratchcardPrizeFormPage from "../pages/campaigns/scratchcards/ScratchcardPrizeForm.page";
import ViewProductPage from "../pages/campaigns/product/ViewProduct.page";
import CookiesPage from "../pages/campaigns/cookies/Cookies.page";
import PrivacyPage from "../pages/campaigns/privacy/Privacy.page";
import SuperDrawCheckoutPage from "../pages/campaigns/super-draw/SuperDrawCheckout.page";
import SuperDrawThankYouPage from "../pages/campaigns/super-draw/thank-you-page/SuperDrawThankYou.page";
import SuperDrawDrawPage from "../pages/campaigns/super-draw/SuperDrawDraw.page";

import VersionPage from "../pages/version/Version.page";
import PreviewPage from "../pages/preview/Preview.page";

import AppLayout from "../layout/AppLayout";
import ScratchcardLayout from "../layout/ScratchcardLayout";
import SuperDrawLayout from "../containers/super-draw/layout/SuperDrawLayout";
import SpotLayout from "../layout/SpotLayout";
import { REACT_APP_HOST_DIR } from "../Env";
import SpotTheBallReviewLandingContainer from "../pages/spot-the-ball-review-landing/SpotTheBallReviewLanding.page";
import CakeLotteryWrapper from "../pages/cake-lottery/cake-lottery-wrapper/CakeLotteryWrapper";

export const SELLER_ROUTE = "/:salesLinkCodeOrShortId";

export default () => (
    <Router basename={`${REACT_APP_HOST_DIR}`}>
        <Switch>
            <Route exact path="/404">
                <AppLayout>
                    <NotFoundPage />
                </AppLayout>
            </Route>
            <Route exact path="/version">
                <AppLayout>
                    <VersionPage />
                </AppLayout>
            </Route>
            <Route exact path="/preview/:themeId">
                <AppLayout>
                    <PreviewPage />
                </AppLayout>
            </Route>
            <Route exact path="/review/:imageId">
                <SpotTheBallReviewLandingContainer />
            </Route>
            <Route path={SELLER_ROUTE}>
                <Preloader>
                    <Route exact path={`${SELLER_ROUTE}/cart`}>
                        <AppLayout>
                            <ShoppingCartPage />
                        </AppLayout>
                    </Route>
                    <Route exact path={`${SELLER_ROUTE}/terms`}>
                        <AppLayout>
                            <TermsPage />
                        </AppLayout>
                    </Route>
                    <Route exact path={`${SELLER_ROUTE}/privacy`}>
                        <AppLayout>
                            <PrivacyPage />
                        </AppLayout>
                    </Route>
                    <Route exact path={`${SELLER_ROUTE}/cookies`}>
                        <AppLayout>
                            <CookiesPage />
                        </AppLayout>
                    </Route>
                    <Route exact path={`${SELLER_ROUTE}/products/:productId`}>
                        <AppLayout>
                            <ViewProductPage />
                        </AppLayout>
                    </Route>
                    <Route exact path={`${SELLER_ROUTE}/order-successful`}>
                        <AppLayout>
                            <OrderCompletedPage />
                        </AppLayout>
                    </Route>
                    <Route exact path={`${SELLER_ROUTE}/order-cancelled`}>
                        <AppLayout>
                            <OrderCancelledPage />
                        </AppLayout>
                    </Route>
                    <Route exact path={`${SELLER_ROUTE}/fallback`}>
                        <VippsFallbackPage />
                    </Route>
                    <Route exact path={`${SELLER_ROUTE}/scratchcards-info`}>
                        <ScratchcardLayout>
                            <ScratchcardInfoPage />
                        </ScratchcardLayout>
                    </Route>
                    <Route
                        exact
                        path={`${SELLER_ROUTE}/scratchcards/:orderId/:scratchcardId/select-prize`}
                    >
                        <ScratchcardLayout>
                            <ScratchcardPrizePage />
                        </ScratchcardLayout>
                    </Route>
                    <Route
                        exact
                        path={`${SELLER_ROUTE}/scratchcards/:orderId/:scratchcardId/prize-form`}
                    >
                        <ScratchcardLayout>
                            <ScratchcardPrizeFormPage />
                        </ScratchcardLayout>
                    </Route>
                    <Route exact path={`${SELLER_ROUTE}/scratchcards/:orderId`}>
                        <ScratchcardLayout>
                            <ScratchcardPage />
                        </ScratchcardLayout>
                    </Route>
                    <Route exact path={`${SELLER_ROUTE}/spot-the-ball`}>
                        <SpotLayout>
                            <SpotTheBallPage />
                        </SpotLayout>
                    </Route>
                    <Route exact path={`${SELLER_ROUTE}/raffle/:orderId`}>
                        <CakeLotteryWrapper />
                    </Route>
                    <Switch>
                        <Route
                            exact
                            path={`${SELLER_ROUTE}/super-draw/checkout`}
                        >
                            <SuperDrawLayout>
                                <SuperDrawCheckoutPage />
                            </SuperDrawLayout>
                        </Route>
                        <Route
                            exact
                            path={`${SELLER_ROUTE}/super-draw/:extOrderId/draw`}
                        >
                            <SuperDrawLayout>
                                <SuperDrawDrawPage />
                            </SuperDrawLayout>
                        </Route>
                        <Route path={`${SELLER_ROUTE}/super-draw/postal-entry`}>
                            <SuperDrawLayout>
                                <SuperDrawPostalEntryPage />
                            </SuperDrawLayout>
                        </Route>
                        <Route
                            exact
                            path={`${SELLER_ROUTE}/super-draw/:extOrderId`}
                        >
                            <SuperDrawLayout>
                                <SuperDrawThankYouPage />
                            </SuperDrawLayout>
                        </Route>
                    </Switch>
                    <Route exact path={`${SELLER_ROUTE}`}>
                        <LandingPage />
                    </Route>
                </Preloader>
            </Route>
            <Route path="*">
                <Redirect to="/404" />
            </Route>
        </Switch>
    </Router>
);
