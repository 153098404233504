import mixpanel, { Config } from "mixpanel-browser";
import { MIXPANEL_TOKEN } from "./Env";

const mixpanelConfig: Partial<Config> = {
    ignore_dnt: true,
};

mixpanel.init(MIXPANEL_TOKEN, mixpanelConfig);

type CountAndCustom = {
    ticket_count: number;
    custom_contribution: boolean;
};

export function pressedPurchaseButton(opts: CountAndCustom) {
    mixpanel.track("Pressed purchase button", opts);
}

export function choseSinglePayment(opts: CountAndCustom) {
    mixpanel.track("Chose one time purchase", opts);
}

export function choseMonthlySubscription(opts: CountAndCustom) {
    mixpanel.track("Chose monthly subscription", opts);
}

export function pressedContinueOneTimePurchase(opts: CountAndCustom) {
    mixpanel.track("Pressed continue one time purchase", opts);
}

export function pressedPayButton(opts: {
    type: "VIPPS" | "CARD";
    ticket_count: number;
}) {
    mixpanel.track("Pressed pay button", opts);
}
