import React, { Component } from "react";
import { REACT_APP_VERSION } from "../../Env";

class VersionPage extends Component {
    render() {
        return <div>{`version: ${REACT_APP_VERSION}`}</div>;
    }
}

export default VersionPage;
