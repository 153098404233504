import React, { Component } from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";

import "./StickyShoppingCart.scss";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import { Product, Seller } from "../../../api/types";
import { CartItem } from "../../../redux/sellerReducer";
import SpondImage from "../../spond-image/SpondImage";
import { routes } from "../../../app/routes";

const Holder = styled.div`
    background-color: #efefef;
    border-top: 1px solid #dfdfdf;
    font-size: 16px;
    font-weight: 600;
    padding: 20px 0;
    position: fixed;
    left: 0;
    bottom: 0;
    height: 50px;
    width: 100%;
    z-index: 2;
`;

const InnerWrapper = styled.div`
    max-width: 984px;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`;

const CartContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

const Button = styled.button<{ backgroundColour?: string }>`
    border: none;
    background-color: ${({ backgroundColour }) =>
        backgroundColour ? backgroundColour : "#ff4f02"};
    color: white;
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
    border-radius: 3px;
    cursor: pointer;
`;

const ProductCountLabel = styled.p`
    font-size: 16px;
    color: #393939;
    margin: 0;
`;

const ViewProductsLabel = styled.p`
    font-size: 12px;
    color: ${({ color }) => (color ? color : "#1b8585")};
    margin: 0;
`;

const ProductAddedPopover = ({
    showPopover,
    popoverProduct,
    dismiss,
}: {
    showPopover: boolean;
    popoverProduct?: Product;
    dismiss: () => void;
}) => {
    const { t } = useTranslation();
    return (
        <div
            data-testid={"product-added-popover"}
            className="arrow_box"
            onClick={dismiss}
            style={{
                width: "280px",
                position: "absolute",
                top: "-130px",
                left: "50%",
                marginLeft: "-140px",
                opacity: showPopover ? "1" : "0",
                pointerEvents: showPopover ? "auto" : "none",
            }}
        >
            <div style={{ padding: "15px" }}>
                {popoverProduct && (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <div style={{ paddingRight: "15px" }}>
                            <SpondImage
                                imageUrl={popoverProduct.preview}
                                transformation="w_100,h_100,c_thumb"
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    objectFit: "cover",
                                }}
                                alt="product"
                            />
                        </div>
                        <div
                            style={{
                                color: "#393939",
                                fontWeight: "normal",
                            }}
                        >
                            {t("shopping_cart.x_is_added_to_cart", {
                                title: popoverProduct.name,
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

type StickyShoppingCartProps = {
    t: Function;
    seller: Seller;
    location: Location;
    cart: CartItem[];
    match: {
        params: {
            salesLinkCodeOrShortId: string;
        };
    };
};

type TimeoutType = ReturnType<typeof setTimeout>;
type StickyShoppingCartState = {
    cancellable: TimeoutType | null;
    showPopover: boolean;
    popoverProduct?: Product;
    safariFixStyle?: {
        paddingBottom: string;
        transition: string;
    };
};

class StickyShoppingCart extends Component<
    StickyShoppingCartProps,
    StickyShoppingCartState
> {
    constructor(props: StickyShoppingCartProps) {
        super(props);
        this.state = {
            showPopover: false,
            cancellable: null,
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    getCartSize() {
        const { cart } = this.props;
        let cartSize = 0;

        for (let i = 0; cart && i < cart.length; i++) {
            // @ts-ignore
            cartSize += parseInt(cart[i].quantity);
        }
        return cartSize;
    }

    activatePopover(product: Product) {
        this.showPopover(product);
    }

    handleDismissPopover() {
        this.setState({
            showPopover: false,
        });
    }

    componentWillUnmount() {
        const { cancellable } = this.state;
        if (cancellable) clearTimeout(cancellable);
        document.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll() {
        const cartSize = this.getCartSize();
        if (
            cartSize &&
            (navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/iPhone/i))
        ) {
            this.setState({
                safariFixStyle: {
                    paddingBottom:
                        "calc(20px + (2 * env(safe-area-inset-bottom)))",
                    transition: "padding 0.25s ease",
                },
            });
        }
    }

    componentWillMount() {
        document.addEventListener("scroll", this.handleScroll);
    }

    showPopover(product: Product) {
        const { cancellable } = this.state;
        if (cancellable) clearTimeout(cancellable);

        const newCancellable = setTimeout(() => {
            this.setState({
                showPopover: false,
                cancellable: null,
            });
        }, 2500);

        this.setState({
            showPopover: true,
            popoverProduct: product,
            cancellable: newCancellable,
        });
    }

    render() {
        const {
            t,
            seller,
            location,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { source, profileId } = qs.parse(location.search);
        let { showPopover, popoverProduct, safariFixStyle } = this.state;
        const cartSize = this.getCartSize();

        if (!cartSize) {
            return null;
        }

        return (
            <Holder className="sticky-shopping-cart" style={safariFixStyle}>
                <InnerWrapper
                    className="inner-wrapper"
                    style={{ position: "relative" }}
                >
                    <ProductAddedPopover
                        showPopover={showPopover}
                        popoverProduct={popoverProduct}
                        dismiss={() => this.handleDismissPopover()}
                    />
                    <CartContentWrapper>
                        <Link to={routes.seller(salesLinkCodeOrShortId).cart}>
                            <ProductCountLabel>
                                {t("shopping_cart.cart_size_label", {
                                    count: cartSize,
                                })}
                            </ProductCountLabel>
                            <ViewProductsLabel
                                color={seller.theme.primaryButtonColour}
                            >
                                {t("shopping_cart.view_products")}
                            </ViewProductsLabel>
                        </Link>
                    </CartContentWrapper>
                    <ButtonWrapper>
                        <Link
                            to={
                                routes.seller(salesLinkCodeOrShortId).cart +
                                `?${!!source ? `source=${source}` : ""}${
                                    source && profileId ? "&" : ""
                                }${profileId ? `profileId=${profileId}` : ""}`
                            }
                        >
                            <Button
                                backgroundColour={
                                    seller.theme.primaryButtonColour
                                }
                            >
                                {t("shopping_cart.checkout_label")}
                            </Button>
                        </Link>
                    </ButtonWrapper>
                </InnerWrapper>
            </Holder>
        );
    }
}

/**
 *  Introduced to mitigate problem where withRouter doesn't forward refs.
 * */
// @ts-ignore
const withRouterForwardRef = (Component) => {
    // @ts-ignore
    const WithRouter = withRouter(({ forwardedRef, ...props }) => (
        <Component ref={forwardedRef} {...props} />
    ));

    return React.forwardRef((props, ref) => (
        // @ts-ignore
        <WithRouter {...props} forwardedRef={ref} />
    ));
};
//
export default withRouterForwardRef(StickyShoppingCart);
