import { Theme, PriceTier } from "../../api/types";
import React from "react";
import styled, { css } from "styled-components";
import ClipLoader from "react-spinners/ClipLoader";

import imgVippsLogo from "../../assets/vipps_logo.png";

export const PeviewMode = css`
    &:hover {
        cursor: default;
    }
`;

type ClickableButtonProps = {
    backgroundColour?: string;
    isPreview?: boolean;
};

export const ClickableButton = styled.button<ClickableButtonProps>`
    cursor: pointer;
    text-align: center;
    width: 100%;
    padding: 15px 20px;
    border-radius: 4px;
    font-size: 16px;
    background-color: ${({ backgroundColour }) =>
        backgroundColour ? backgroundColour : "#1c8585"};
    color: white;
    font-weight: 500;
    outline: none;
    border: none;
    touch-action: manipulation;
    max-width: 400px;

    ${({ isPreview }) => (isPreview ? PeviewMode : "")};

    &[disabled] {
        opacity: 0.5;
    }

    &:hover {
        filter: brightness(105%); drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
    }
  
    &:active {
        filter: brightness(90%); drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
    }
`;

export const ClickableButtonThin = styled(ClickableButton)`
    background: transparent;
    color: #1c8585;
    border: 1px solid #1c8585;
`;

const StripeButtonElement = styled(ClickableButton)`
    border: 1px solid rgba(61, 132, 133, 1);
    border-radius: 4px;
`;

const CardButtonElement = styled(ClickableButton)`
    background-color: #f3f3f3;
    color: #393939;
    border-radius: 4px;
    max-width: 100%;
    position: relative;
`;

const PurchaseButtonElement = styled(ClickableButton)`
    background-color: #e51d1f;
    color: white;
    border: "none";
`;

const VippsButtonElement = styled(ClickableButton)`
    background-color: #ff5b24;
    color: white;
    border: "none";
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;

    &[disabled] {
        opacity: 0.45;
    }
`;

const PurchaseButtonThinElement = styled(PurchaseButtonElement)`
    border: 1px solid #e51d1f;
    color: #e51d1f;
    background: transparent;
`;

const PurchaseModalButtonElement = styled(PurchaseButtonElement)`
    width: auto;
    border: 2px solid white;
`;

const BlueButtonElement = styled(ClickableButton)`
    padding: 10px 12px;
    border-radius: 2px;
    background-color: #47b7cb;
    color: #ffffff;
    font-size: 14px;
    border: none;
    box-shadow: none;
    outline: none;
    width: auto;
    font-weight: 500;
`;

const QuizButtonBackground = {
    DEFAULT: {
        backgroundColor: "#ecf7f9",
        color: "#006976",
        opacity: "1.0",
        border: undefined,
    },
    CORRECT: {
        backgroundColor: "#00b651",
        color: "#ffffff",
    },
    CORRECT_HIGHLIGHT: {
        backgroundColor: "#ecf7f9",
        color: "#006976",
        border: "1px solid #00b651",
    },
    INCORRECT: {
        backgroundColor: "#ea4648",
        color: "#ffffff",
    },
    FADE: {
        backgroundColor: "#ecf7f9",
        color: "#006976",
        opacity: "0.25",
    },
};

const QuizButtonElement = styled(ClickableButton)<{
    background?: {
        opacity: number;
        backgroundColor: string;
        border: string;
        color: string;
    };
}>`
    padding: 12px 20px;
    border-radius: 25px;
    background-color: ${({ background }) =>
        background
            ? background.backgroundColor
            : QuizButtonBackground.DEFAULT.backgroundColor};
    color: ${({ background }) =>
        background ? background.color : QuizButtonBackground.DEFAULT.color};
    opacity: ${({ background }) =>
        background
            ? background.opacity
                ? background.opacity
                : "1.0"
            : QuizButtonBackground.DEFAULT.opacity};
    font-size: 16px;
    border: ${({ background }) =>
        background
            ? background.border
                ? background.border
                : "none"
            : QuizButtonBackground.DEFAULT.border};
    box-shadow: none;
    outline: none;
    font-weight: 500;
    width: 100%;
    position: relative;

    transition: opacity 0.5s, background-color 0.5s;

    &[disabled] {
        opacity: ${({ background }) =>
            background ? background.opacity : "0.85"};
    }
`;

const QuizNormalButtonElement = styled(ClickableButton)`
    border-radius: 4px;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.35);
    background-image: linear-gradient(
        37deg,
        #00b0c8 21%,
        #00c0d9 77%,
        #00d5b1 139%
    );
    font-weight: 600;
    padding: 15px 20px;

    &[disabled] {
        opacity: 0.5;
        cursor: default;
    }
`;

const QuizOutlineButtonElement = styled(ClickableButton)`
    border-radius: 4px;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.35);
    background-color: transparent;
    color: #00b0c8;
    font-weight: 600;
`;

const QuizThinButtonElement = styled(QuizNormalButtonElement)`
    background: transparent;
    color: #00b0c8;
    border: 1px solid #00b0c8;
    font-weight: 600;
`;

const QuizFadeButtonElement = styled(ClickableButton)`
    border-radius: 4px;
    background-image: linear-gradient(
        76deg,
        #ccf9ff 28%,
        #e5f8fb 49%,
        #e5fcff 72%
    );
    color: #114a4a;
`;

const RaffleButtonElement = styled(ClickableButton)<{ background?: string }>`
    border-radius: 4px;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.35);
    background-color: ${({ background }) =>
        background ? background : "#3CA010"};
    font-weight: 600;
    padding: 15px 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &[disabled] {
        opacity: 0.5;
        cursor: default;
    }
`;

const RaffleOutlineButtonElement = styled(ClickableButton)`
    border-radius: 4px;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.35);
    border: 1px solid ${({ theme }) => theme.primaryButtonColour || "#3CA010"};
    background-color: transparent;
    color: ${({ theme }) => theme.primaryButtonColour || "#3CA010"};
    font-weight: 600;
`;

const Button = ({
    children,
    onClick,
    ...rest
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => (
    <ClickableButton id="purchase-button" onClick={onClick} {...rest}>
        {children}
    </ClickableButton>
);

const ThinButton = ({
    children,
    onClick,
    ...rest
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => (
    <ClickableButtonThin id="purchase-button" onClick={onClick} {...rest}>
        {children}
    </ClickableButtonThin>
);

const StripeButton = ({
    children,
    onClick,
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => (
    <StripeButtonElement id="purchase-button" onClick={onClick}>
        {children}
    </StripeButtonElement>
);

const CardButton = ({
    children,
    onClick,
    loading,
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    loading?: boolean;
}) => (
    <CardButtonElement id="purchase-button" onClick={onClick}>
        {children}
        {!!loading && (
            <div style={{ position: "absolute", top: "15px", right: "15px" }}>
                <ClipLoader size={16} color={"#000000"} loading={true} />
            </div>
        )}
    </CardButtonElement>
);

const ThemeButton = ({
    children,
    onClick,
    theme,
    style,
    ...rest
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    theme: Theme;
    style?: React.CSSProperties;
}) => {
    const { primaryButtonColour, primaryButtonTextColour } = theme || {};
    return (
        <ClickableButton
            id="purchase-button"
            onClick={onClick}
            style={{
                ...style,
                background: primaryButtonColour,
                color: primaryButtonTextColour,
            }}
            {...rest}
        >
            {children}
        </ClickableButton>
    );
};

const ThemeButtonThin = ({
    children,
    onClick,
    theme,
    style,
    ...rest
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    theme: Theme;
    style?: React.CSSProperties;
}) => {
    const { primaryButtonColour } = theme || {};
    return (
        <ClickableButtonThin
            id="purchase-button"
            onClick={onClick}
            style={{
                ...style,
                background: "white",
                color: primaryButtonColour,
                borderColor: primaryButtonColour,
            }}
            {...rest}
        >
            {children}
        </ClickableButtonThin>
    );
};

const PurchaseButton = ({
    children,
    onClick,
    theme,
    style,
    ...rest
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    theme: Theme;
    style?: React.CSSProperties;
} & ClickableButtonProps &
    React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    const { primaryButtonColour, primaryButtonTextColour } = theme || {};
    return (
        <PurchaseButtonElement
            onClick={onClick}
            id="purchase-button"
            style={{
                ...style,
                background: primaryButtonColour,
                color: primaryButtonTextColour,
            }}
            {...rest}
        >
            {children}
        </PurchaseButtonElement>
    );
};

export const PurchaseButtonWrapper = styled.div``;

const VippsButton = ({
    children,
    onClick,
    theme,
    style,
    loading,
    ...rest
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    theme: Theme;
    style?: React.CSSProperties;
    loading?: boolean;
}) => {
    return (
        <VippsButtonElement
            id="purchase-button"
            onClick={onClick}
            style={{ ...style }}
            {...rest}
        >
            {children}{" "}
            <img
                alt="Vipps logo"
                src={imgVippsLogo}
                style={{
                    height: "13px",
                    width: "50px",
                    position: "relative",
                    bottom: "-5px",
                    left: "5px",
                    marginRight: "7px",
                }}
            />
            {!!loading && (
                <div
                    style={{ position: "absolute", top: "15px", right: "15px" }}
                >
                    <ClipLoader size={16} color={"#ffffff"} loading={true} />
                </div>
            )}
        </VippsButtonElement>
    );
};

const PurchaseButtonThin = ({
    onClick,
    theme,
    style,
    ...rest
}: {
    /**
     * onClick has to be specified or explicitly set to null.
     */
    onClick:
        | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
        | null;
    theme: Theme;
    style?: React.CSSProperties;
} & React.ComponentProps<typeof PurchaseButtonThinElement>) => {
    const { primaryButtonColour } = theme || {};
    return (
        <PurchaseButtonThinElement
            id="purchase-button"
            onClick={onClick}
            style={{
                ...style,
                background: "white",
                color: primaryButtonColour,
                borderColor: primaryButtonColour,
            }}
            {...rest}
        />
    );
};

const PurchaseModalButton = ({
    children,
    onClick,
    theme,
    style,
    ...rest
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    theme: Theme;
    style?: React.CSSProperties;
}) => {
    const { primaryButtonColour, primaryButtonTextColour } = theme || {};
    return (
        <PurchaseModalButtonElement
            id="purchase-button"
            onClick={onClick}
            style={{
                ...style,
                background: primaryButtonColour,
                color: primaryButtonTextColour,
            }}
            {...rest}
        >
            {children}
        </PurchaseModalButtonElement>
    );
};

const BlueButton = ({
    children,
    onClick,
    ...rest
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => (
    <BlueButtonElement id="purchase-button" onClick={onClick} {...rest}>
        {children}
    </BlueButtonElement>
);

const QuizButton = ({
    children,
    onClick,
    loading,
    ...rest
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    loading?: boolean;
}) => (
    <QuizButtonElement id="purchase-button" onClick={onClick} {...rest}>
        {children}
        {loading && (
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    marginTop: "-8px",
                    right: "12px",
                }}
            >
                <ClipLoader size={15} color={"#00b4cc"} loading={true} />
            </div>
        )}
    </QuizButtonElement>
);

const QuizNormalButton = ({
    children,
    onClick,
    loading,
    ...rest
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    loading?: boolean;
}) => (
    <QuizNormalButtonElement id="purchase-button" onClick={onClick} {...rest}>
        {children}
        {loading && (
            <div style={{ position: "absolute", top: "12px", right: "12px" }}>
                <ClipLoader size={15} color={"#00b4cc"} loading={true} />
            </div>
        )}
    </QuizNormalButtonElement>
);

const RaffleButton = ({
    children,
    onClick,
    loading,
    ...rest
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    loading?: boolean;
} & React.ComponentProps<typeof RaffleButtonElement>) => (
    <RaffleButtonElement id="purchase-button" onClick={onClick} {...rest}>
        {children}
    </RaffleButtonElement>
);

const RaffleProductButton = ({
    children,
    onClick,
    theme,
    priceTier,
    quantityText,
    quantitySubtext,
    quantityTextSize,
    quantity,
    hasIcon,
    iconVerticalAlign,
    ...rest
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    theme: Theme;
    priceTier: PriceTier;
    quantityText: string;
    quantitySubtext: string;
    quantityTextSize?: number;
    quantity: number;
    hasIcon?: boolean;
    iconVerticalAlign?: string;
}) => {
    const { primaryButtonColour, primaryButtonTextColour } = theme || {};
    const { textColour, label, bgColour } = priceTier || {};
    return (
        <RaffleButton
            id="purchase-button"
            onClick={onClick}
            background={primaryButtonColour}
            {...rest}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    position: "relative",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        margin: "0",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    {children}
                </div>
                <div
                    style={{
                        color: primaryButtonTextColour,
                        textAlign: "right",
                        fontWeight: "normal",
                        fontSize: `${
                            quantityTextSize ? quantityTextSize : 16
                        }px`,
                    }}
                >
                    <div>{quantityText}</div>
                    {quantitySubtext && (
                        <div style={{ fontSize: `12px` }}>
                            {quantitySubtext}
                        </div>
                    )}
                    {hasIcon && quantity === 1 && (
                        <img
                            src="./one-ticket.png"
                            alt="one ticket"
                            width={16}
                            style={{
                                paddingLeft: ".5em",
                                verticalAlign: `${
                                    iconVerticalAlign ? iconVerticalAlign : -2
                                }px`,
                            }}
                        />
                    )}
                    {hasIcon && quantity > 1 && (
                        <img
                            src="./two-tickets.png"
                            alt="multiple ticket"
                            width={16}
                            style={{
                                paddingLeft: ".5em",
                                verticalAlign: `${
                                    iconVerticalAlign ? iconVerticalAlign : -2
                                }px`,
                            }}
                        />
                    )}
                </div>
                {label && (
                    <div
                        style={{
                            backgroundColor: bgColour,
                            position: "absolute",
                            top: "-25px",
                            right: "-30px",
                            fontSize: "12px",
                            borderRadius: "4px",
                            fontWeight: "normal",
                            color: textColour,
                            padding: "5px 10px",
                            boxShadow: "0 3px 16px 0 rgba(0, 0, 0, 0.35)",
                        }}
                    >
                        {label}
                    </div>
                )}
            </div>
        </RaffleButton>
    );
};

const RaffleOutlineButton = ({
    children,
    onClick,
    ...rest
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => (
    <RaffleOutlineButtonElement
        id="purchase-button"
        onClick={onClick}
        {...rest}
    >
        {children}
    </RaffleOutlineButtonElement>
);

const QuizThinButton = ({
    children,
    onClick,
    ...rest
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => (
    <QuizThinButtonElement id="purchase-button" onClick={onClick} {...rest}>
        {children}
    </QuizThinButtonElement>
);

const QuizOutlineButton = ({
    children,
    onClick,
    ...rest
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => (
    <QuizOutlineButtonElement id="purchase-button" onClick={onClick} {...rest}>
        {children}
    </QuizOutlineButtonElement>
);

const QuizFadeButton = ({
    children,
    onClick,
    ...rest
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => (
    <QuizFadeButtonElement id="purchase-button" onClick={onClick} {...rest}>
        {children}
    </QuizFadeButtonElement>
);

export {
    Button,
    ThinButton,
    VippsButton,
    CardButton,
    StripeButton,
    ThemeButton,
    ThemeButtonThin,
    PurchaseButton,
    PurchaseButtonThin,
    PurchaseModalButton,
    BlueButton,
    QuizButton,
    QuizButtonBackground,
    QuizNormalButton,
    QuizThinButton,
    QuizOutlineButton,
    QuizFadeButton,
    RaffleButton,
    RaffleProductButton,
    RaffleOutlineButton,
};
