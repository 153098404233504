import styled from "styled-components";

import spondLogo from "../../assets/icons/spond_logo_white.svg";

export const FooterLogo = styled.div`
    background-image: url("${spondLogo}");
    background-repeat: no-repeat;

    margin: 20px auto;
    height: 40px;
    width: 120px;
`;
