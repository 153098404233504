import React, { useState, useContext } from "react";

import CakeStartPage from "../cake-start-page/CakeStart.page";
import CakeSelectTicketsPage from "../cake-select-tickets-page/CakeSelectTickets.page";
import CakePayPage from "../cake-pay-page/CakePay.page";
import CakeTicketsContext from "../../../context/CakeTicketsContext";

const CakeLotteryLandingWrapper = ({ t }) => {
    const [navState, setNavState] = useState();
    const { removeAllTickets } = useContext(CakeTicketsContext);

    const nextPage = (page) => {
        setNavState(page);
    };

    const resetState = () => {
        removeAllTickets();
        setNavState();
    };

    return (
        <>
            {!navState && (
                <CakeStartPage t={t} nextPage={() => nextPage("select")} />
            )}
            {navState === "select" && (
                <CakeSelectTicketsPage
                    t={t}
                    nextPage={() => nextPage("checkout")}
                    resetState={() => resetState()}
                />
            )}
            {navState === "checkout" && (
                <CakePayPage t={t} resetState={() => resetState()} />
            )}
        </>
    );
};

export default CakeLotteryLandingWrapper;
