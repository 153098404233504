import { useTranslation } from "react-i18next";
import {
    CartItemDescription,
    CartItemDetails,
    CartItemWrapper,
} from "./ShoppingCart.styled";
import NumberFormat from "react-number-format";
import React from "react";

export const CartShippingFeeInformation = ({
    shippingFee,
}: {
    shippingFee: number;
}) => {
    const t = useTranslation().t;
    return (
        <CartItemWrapper data-testid="cart-shipping">
            <CartItemDescription>
                {t("shopping_cart.shipping_label")}
            </CartItemDescription>
            <CartItemDetails>
                {!shippingFee ? (
                    t("general.free")
                ) : (
                    <NumberFormat
                        value={shippingFee / 100}
                        displayType={"text"}
                        decimalSeparator={t("general.decimal_separator")}
                        thousandSeparator={t("general.thousand_separator")}
                        prefix={t("general.number_prefix")}
                    />
                )}
            </CartItemDetails>
        </CartItemWrapper>
    );
};
