import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { withTranslation } from "react-i18next";
import { Button, ThinButton } from "../../components/button/Buttons";
import SpondImage from "../../components/spond-image/SpondImage";
import { apiClient } from "../../api/ApiClient";

class AcceptMarketingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: true,
        };
    }

    handleAccept() {
        const { orderId } = this.props;

        apiClient.subscribeToMarketing(orderId);

        this.setState({
            showModal: false,
        });
    }

    handleDecline() {
        this.setState({
            showModal: false,
        });
    }

    handleHideModal() {
        this.handleDecline();
    }

    render() {
        const {
            seller: { clubImageUrl, clubName },
            t,
        } = this.props;
        const { showModal } = this.state;

        return (
            <Modal
                center={true}
                open={showModal}
                onClose={() => {
                    this.handleHideModal();
                }}
                classNames={{ modal: "action-modal" }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                    }}
                >
                    <SpondImage
                        imageUrl={clubImageUrl}
                        transformation="w_160,h_160,c_thumb,f_auto,fl_lossy"
                        alt="club logo"
                        style={{ width: "80px" }}
                    />
                </div>
                <h3 style={{ textAlign: "center", margin: "15px 0 0 0" }}>
                    <span>{t("marketing.title", { clubName })}</span>
                </h3>
                <p
                    style={{ textAlign: "center", fontSize: "14px" }}
                    dangerouslySetInnerHTML={{
                        __html: t("marketing.description"),
                    }}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                    }}
                >
                    <ThinButton
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            this.handleDecline();
                        }}
                    >
                        {t("actions.decline_marketing")}
                    </ThinButton>
                    <Button
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                            this.handleAccept();
                        }}
                    >
                        {t("actions.accept_marketing")}
                    </Button>
                </div>
                <p
                    style={{
                        fontSize: "12px",
                        textAlign: "center",
                        marginTop: "10px",
                        marginBottom: "0",
                        color: "#999",
                        lineHeight: "140%",
                    }}
                    dangerouslySetInnerHTML={{
                        __html: t("marketing.footer"),
                    }}
                />
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(
    connect(mapStateToProps)(AcceptMarketingModal)
);
