import React from "react";
import CakeBackground from "../cake-background/CakeBackground";
import * as styles from "./GameLayout.module.scss";

const GameLayout = ({ className, hasCake, children, theme }) => {
    return (
        <div
            className={[styles.gameLayout, className].join(" ")}
            style={{
                background: theme?.primaryColour || "var(--color-gray-50)",
            }}
        >
            {children}
            {/*
      {hasCake && <CakeBackground />}
      */}
        </div>
    );
};

export default GameLayout;
