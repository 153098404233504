import { trackEvent } from "../EventTracker";
import { apiClient } from "../api/ApiClient";
import { Seller, MixpanelFields } from "../api/types";
import { CartItem } from "../redux/sellerReducer";
import { STRIPE_PUBLIC_KEY } from "../data/config";
import { isSalesLinkCode } from "./misc/misc";

export const initiateStripeQuizPayment = ({
    seller,
    unitPrice,
    source,
    sellerProfileId,
    salesLinkCodeOrShortId,
}: {
    seller: Seller;
    unitPrice: number;
    source: string;
    sellerProfileId: string;
    salesLinkCodeOrShortId: string;
}) => {
    const quantity = 1;
    const data = {
        cart: [
            {
                quantity,
                //@ts-ignore // TODO Find out if unitPrice is needed
                unitPrice,
            } as unknown as CartItem,
        ],
        seller,
        source,
        sellerProfileId,
        salesLinkCodeOrShortId: isSalesLinkCode(salesLinkCodeOrShortId)
            ? salesLinkCodeOrShortId
            : null,
    };
    return initiateStripePayment(data);
};

export const initiatePaypalQuizPayment = ({
    productId,
    seller,
    unitPrice,
    source,
    sellerProfileId,
    salesLinkCodeOrShortId,
}: {
    productId: string;
    seller: Seller;
    unitPrice: number;
    source: string;
    sellerProfileId: string;
    salesLinkCodeOrShortId: string;
}) => {
    const quantity = 1;
    const cart = [
        {
            productId,
            quantity,
            unitPrice,
        },
    ];
    const orderLines = getOrderLinesInCart(cart);
    const data = {
        ...orderLines,
        source: source === "qr" ? "qrcode" : source,
        sellerProfileId,
        salesLinkCode: isSalesLinkCode(salesLinkCodeOrShortId)
            ? salesLinkCodeOrShortId
            : null,
    };
    return apiClient
        .initiatePaypalOrder(seller.shortId, data)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.error(err);
        });
};

export const initiateStandardPayment = ({
    cart,
    seller,
    type,
    source = "standard",
    sellerProfileId,
    formData,
    errorCallback,
    salesLinkCodeOrShortId,
    mixpanelFields,
}: {
    cart: CartItem[];
    seller: Seller;
    type?: string;
    source?: string;
    sellerProfileId?: string;
    formData?: any;
    errorCallback?: (err: any) => void;
    salesLinkCodeOrShortId: string;
    mixpanelFields?: MixpanelFields;
}) => {
    const orderLines = getOrderLinesInCart(cart);
    let address = undefined;
    if (formData && formData.address1) {
        address = {
            address1: formData.address1,
            address2: formData.address2,
            postCode: formData.postCode,
            city: formData.city,
        };
    }

    if (!type) {
        type = "VIPPS";
    }

    const data = {
        purchases: orderLines,
        buyerName: formData.name,
        buyerEmail: formData.email,
        buyerPhone: formData.phoneNumber,
        address: address,
        anonymous: formData.anonymous,
        source: source === "qr" ? "qrcode" : source,
        sellerProfileId,
        salesLinkCode: isSalesLinkCode(salesLinkCodeOrShortId)
            ? salesLinkCodeOrShortId
            : null,
        mixpanelFields,
    };
    const initiatePaymentPromise =
        seller.countryCode === "GBR"
            ? apiClient.initiateCheckoutOrder(seller.shortId, data)
            : apiClient.initiateNetsOrder(seller.shortId, type, data);
    return initiatePaymentPromise
        .then((res) => {
            const { url } = res.data;
            if (window.location.origin === "http://localhost:3000") {
                window.location.href = url.replace(
                    "https://dev1.spond.dev",
                    "http://localhost:3000"
                );
            } else {
                window.location.href = url;
            }

            return true;
        })
        .catch((err) => {
            console.error(err);
            if (errorCallback) {
                errorCallback(err);
            }
            return false;
        });
};

export const initiateStripePayment = ({
    cart,
    seller,
    source = "standard",
    sellerProfileId,
    salesLinkCodeOrShortId,
}: {
    cart: CartItem[];
    seller: Seller;
    source?: string;
    sellerProfileId?: string;
    salesLinkCodeOrShortId: string | null;
}) => {
    const stripe = (window as any).Stripe(STRIPE_PUBLIC_KEY);

    const orderLines = getOrderLinesInCart(cart);

    const data = {
        ...orderLines,
        source: source === "qr" ? "qrcode" : source,
        sellerProfileId,
        salesLinkCode: isSalesLinkCode(salesLinkCodeOrShortId)
            ? salesLinkCodeOrShortId
            : null,
    };
    apiClient
        .initiateStripeCheckout(seller.shortId, data)
        .then((res) => {
            const { sessionId, orderId } = res.data;

            trackEvent("CLICK_BUY", {
                shortId: seller.shortId,
                orgDisplayName: seller.displayName,
                // TODO Find out if groupId exists at all on seller
                // @ts-ignore
                groupId: seller.groupId,
                paymentMethod: "Stripe",
                productIds: orderLines.map((ol) => ol.productId),
                orderId,
            });

            (async () => {
                await stripe.redirectToCheckout({
                    sessionId,
                });
            })();
        })
        .catch((err) => {
            console.error(err);
        });
};

// TODO This method doesn't seem to exist in the backend. Delete?
export const capturePaypalPayment = (paypalOrderId: string, seller: Seller) => {
    return apiClient
        .capturePaypalPayment(seller.shortId, { paypalOrderId })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.error(err);
        });
};

export const isEligibleForExtraProduct = (
    cart: CartItem[],
    extraProduct: CartItem
) => {
    return (
        extraProduct &&
        cart.reduce((prev, cartItem) => prev + cartItem.quantity, 0) === 1
    );
};

const getOrderLinesInCart = (cart: any[]): any[] => {
    if (!cart) {
        return [];
    }

    return cart.map(
        ({
            productId,
            productVariationId,
            quantity,
            unitPrice,
            contribution,
            label,
        }) =>
            productId
                ? {
                      unitPrice,
                      productId,
                      variantId: productVariationId,
                      quantity,
                  }
                : contribution
                ? {
                      contribution,
                  }
                : {
                      quantity,
                      unitPrice,
                      label,
                  }
    );
};
