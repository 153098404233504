import React, { useEffect, useMemo, useState } from "react";
import Button from "../../../components/cake-lottery/button/Button";
import Ticket from "../../../components/cake-lottery/ticket/Ticket";
import Arrow from "../../../components/cake-lottery/arrow/Arrow";
import { GameEndModal } from "../../../components/cake-lottery/game-end-modal/GameEndModal";
import useAnimationFrame from "../../../hooks/useAnimationFrame";
import * as styles from "./CakeTicketDraw.module.scss";
import GameLayout from "../../../components/cake-lottery/layout/GameLayout";
import { apiClient } from "../../../api/ApiClient";

const TICKET_COUNT = 50;
const CIRCLE_SIZE = 1200;
const DURATION = 12000;
const WHEEL_FULL_ROTATIONS = 2;
const TICKET_HEIGHT_PX = 64;

const positionOnCircle = (ticketIndex, wheelAngle) => {
    // The ticket's position is decided by the transform css property below, which depends on the ticket's radial and angular coordinates.
    // Radial coordinate = how far from the center the ticket is
    // Angular coordinate = how many degrees the ticket is rotated about the center
    const angularIncrementBetweenTickets = 360.0 / TICKET_COUNT;
    const startAngleOfTicket =
        (TICKET_COUNT - ticketIndex) * angularIncrementBetweenTickets + 90;
    const ticketAngularCoord = (startAngleOfTicket + wheelAngle) % 360;
    const ticketRadialCoord = (CIRCLE_SIZE - TICKET_HEIGHT_PX) / 2;
    return {
        transform: `translate(-50%, -50%) rotate(-${ticketAngularCoord}deg) translate(${ticketRadialCoord}px) rotate(${ticketAngularCoord}deg)`,
    };
};

function easeOutCircle(x) {
    return Math.sqrt(1 - Math.pow(x - 1, 2));
}

const ticketWheelFadeSidesBackgroundFun = (primaryColour) => {
    const primaryColourTransparent = primaryColour + "00";
    return `linear-gradient(to right, ${primaryColour} 0%, ${primaryColourTransparent} 40% ), linear-gradient(to left, ${primaryColour} 0%, ${primaryColourTransparent} 40% )`;
};

const hasWinningTicket = (raffleTickets) => {
    return raffleTickets.filter((t) => t.hasPrize).length > 0;
};

const isTicketSelected = (raffleTickets, label) => {
    return raffleTickets.filter((t) => t.label == label).length > 0;
};

const CakeTicketDrawPage = ({
    t,
    nextPage,
    missingTicketsRedirect,
    seller,
    orderId,
    raffleTickets,
}) => {
    const [wheelAngle, setWheelAngle] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [gameFinished, setGameFinished] = useState(false);
    const [gameStarted, setGameStarted] = useState(false);
    const winningTicketNumber = parseInt(raffleTickets?.[0]?.winningLabel);
    const numberOfWheelRotations =
        WHEEL_FULL_ROTATIONS + (winningTicketNumber - 1) / TICKET_COUNT;
    const totalAngleThatTheWheelShouldRotate = 360 * numberOfWheelRotations;
    const { theme } = seller;
    const ticketWheelFadeSidesBackground = useMemo(
        () => ticketWheelFadeSidesBackgroundFun(theme.primaryColour),
        [theme.primaryColour]
    );

    useEffect(() => {
        if (!raffleTickets?.length) return missingTicketsRedirect();
    }, [raffleTickets]);

    const startAnimation = useAnimationFrame((elapsedTime) => {
        const elapsedTimeNormalized = elapsedTime / DURATION; // goes from 0 to 1 in linear fashion with elapsed time
        const wheelRotationScalingFactor = easeOutCircle(elapsedTimeNormalized); // goes from 0 to 1, but slower and slower with elapsed time
        setWheelAngle(
            wheelRotationScalingFactor * totalAngleThatTheWheelShouldRotate
        );
        setZoom(1 + 0.8 * wheelRotationScalingFactor);
    }, DURATION);

    const startGame = async () => {
        await apiClient
            .revealRaffleTicket(seller.shortId, orderId)
            .catch((err) => {
                throw err;
            });
        setGameFinished(false);
        setGameStarted(true);
        setTimeout(() => {
            setGameFinished(() => true);
        }, DURATION + 1000);
        startAnimation();
    };

    if (!raffleTickets) return <></>;
    return (
        <GameLayout className={styles.gameLayout} theme={theme}>
            <GameEndModal
                t={t}
                theme={theme}
                isVictory={hasWinningTicket(raffleTickets)}
                open={gameFinished}
                nextPage={nextPage}
            />

            <Arrow className={styles.arrow} />
            <div
                className={styles.ticketWheelFadeSides}
                style={{ background: ticketWheelFadeSidesBackground }}
            />
            <div
                className={styles.ticketWheelContainer}
                style={{
                    transform: `translateX(-50%) scale(${zoom})`,
                }}
            >
                {[...Array(TICKET_COUNT).keys()].map((ticketIndex) => (
                    <Ticket
                        key={"ticket-" + ticketIndex}
                        style={positionOnCircle(ticketIndex, wheelAngle)}
                        number={ticketIndex + 1}
                        className={styles.ticket}
                        selected={isTicketSelected(
                            raffleTickets,
                            ticketIndex + 1
                        )}
                        theme={theme}
                    />
                ))}
            </div>
            <div className={styles.bottomContainer}>
                <h2 className="text-center">
                    {raffleTickets.length === 1 &&
                        t("raffle.draw_ticket_heading")}
                    {raffleTickets.length > 1 &&
                        t("raffle.draw_ticket_heading_plural", {
                            ticketCount: raffleTickets.length,
                        })}
                </h2>
                <p>{t("raffle.draw_ticket_text")}</p>
                <br />
                <div className={styles.yourTicketsContainer}>
                    {raffleTickets.map(({ label }, idx) =>
                        idx < 7 ? (
                            <Ticket
                                key={"selected-ticket-" + label}
                                number={label}
                                selected
                                theme={theme}
                            />
                        ) : idx === 8 ? (
                            <Ticket
                                key={"selected-ticket-" + label}
                                number={`+${raffleTickets.length - 7}`}
                                indicator
                                theme={theme}
                            />
                        ) : (
                            ""
                        )
                    )}
                </div>
                <Button
                    theme={theme}
                    onClick={startGame}
                    className={[
                        styles.button,
                        gameStarted ? styles.started : "",
                    ].join(" ")}
                >
                    {t("raffle.draw_start_button")}
                </Button>
            </div>
        </GameLayout>
    );
};

export default CakeTicketDrawPage;
