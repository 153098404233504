import styled from "styled-components";
import CategoryIcons from "../icons/categoryIcons";

export const UkHeaderInner = styled.div`
    max-width: 375px;
    margin: 0 auto;
    padding: 30px 20px;
    text-align: center;

    p {
        font-size: 16px;
        color: #fff;
    }

    h3 {
        font-size: 30px;
        color: #fff;
        font-weight: 600;
        line-height: 1.25;
    }
`;

export const UkHeaderTitle = styled.h1`
    text-align: center;
    margin-top: 15px;
    margin-bottom: 0;
    color: white;
    font-size: 24px;
`;

export const UkHeaderDescription = styled.h1`
    text-align: ${({ isSubTitle }) => (isSubTitle ? "center" : "left")};
    margin-top: 10px;
    margin-bottom: 10px;
    padding: ${({ isSubTitle }) => (isSubTitle ? "0 10px" : "0")};
    font-size: 18px;
    font-weight: 400;
    color: white;
    word-break: break-word;
`;
