import { BASE_URL } from "../../Env";
import { routes } from "../../app/routes";

export const sum = (compoundedSum: number, value: number) =>
    compoundedSum + value;

export const getCampaignURL = (salesLinkCodeOrShortId: string) =>
    `${BASE_URL}` + routes.seller(salesLinkCodeOrShortId).pathname;

export const isDefined = (value: unknown) =>
    value !== null && value !== undefined;

export const isSalesLinkCode = (salesLinkCodeOrShortId?: string | null) => {
    if (!salesLinkCodeOrShortId) return false;
    return salesLinkCodeOrShortId.length === 10;
};
