import React from "react";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { ScratchThemeConfig } from "./CustomScratch.define";

const CustomIntroBed = styled.div`
    max-width: 390px;
    margin: 22px auto 0 auto;
`;
const IntroImg = styled.img`
    border-radius: 8px;
    width: 100%;
`;
const IntroTitle = styled.h2`
    line-height: 1.3;
    margin: 10px 0;
`;
const IntroDescription = styled.div`
    color: #424242;
`;
const IntroItem = styled.div`
    padding-bottom: 46px;
`;
const customIntro = ({ t, themeType }) => {
    return (
        <CustomIntroBed>
            {ScratchThemeConfig[themeType].introList.map((intro) => (
                <IntroItem>
                    <IntroImg src={intro.img} />
                    <IntroTitle>{t(intro.titleKey)}</IntroTitle>
                    <IntroDescription>
                        {t(intro.descriptionKey)}
                    </IntroDescription>
                </IntroItem>
            ))}
        </CustomIntroBed>
    );
};

const translateCustomIntro = withTranslation()(customIntro);
export { translateCustomIntro as CustomIntro };
