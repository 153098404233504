import React from "react";
import { REACT_APP_API_URL } from "../Env";
import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from "axios";
import { API_LEVEL } from "../data/config";
import {
    FeatureToggles,
    OrderParam,
    OrderPlacementParam,
    OrderSpotAttemptParam,
} from "./types";
import { toast } from "react-toastify";
import { ToastWithErrorId } from "./ToastWithErrorId";

class ApiClient {
    private readonly baseUrl: string;
    private client: AxiosInstance;

    constructor(baseUrl: string, axiosInstance: AxiosInstance) {
        this.baseUrl = baseUrl;
        this.client = axiosInstance;
    }

    async getFeatureToggles(shortId: string): Promise<FeatureToggles> {
        return this.client
            .get(`${this.baseUrl}/core/v1/feature-toggles/proxy`, {
                params: {
                    userId: shortId,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    getProducts(campaignId: string): Promise<AxiosResponse<any>> {
        return this.client.get(
            `${this.baseUrl}/frc/v1/campaign/${campaignId}/products`
        );
    }

    revealLotteryTicket(
        shortId: string,
        orderId: string,
        ticketId: string
    ): Promise<AxiosResponse<any>> {
        return this.client.put(
            `${this.baseUrl}/frc/v1/${shortId}/order/${orderId}/reveal/${ticketId}`
        );
    }

    revealRaffleTicket(
        shortId: string,
        orderId: string
    ): Promise<AxiosResponse<any>> {
        return this.client.put(
            `${this.baseUrl}/frc/v1/${shortId}/order/${orderId}/reveal`
        );
    }

    // TODO How about this one...
    orderStatusCheck(
        shortId: string,
        orderId: string
    ): Promise<AxiosResponse<any>> {
        return this.client.put(
            `${this.baseUrl}/frc/v1/${shortId}/order/${orderId}`
        );
    }

    selectLotteryPrize(
        shortId: string,
        orderId: string,
        scratchcardId: string,
        data: OrderPlacementParam
    ): Promise<AxiosResponse<any>> {
        return this.client.post(
            `${this.baseUrl}/frc/v1/${shortId}/order/${orderId}/prize/${scratchcardId}`,
            data
        );
    }

    selectPrize(
        shortId: string,
        orderId: string,
        data: OrderPlacementParam
    ): Promise<AxiosResponse<any>> {
        return this.client.post(
            `${this.baseUrl}/frc/v1/${shortId}/order/${orderId}/prize`,
            data
        );
    }

    getGameImage(imageId: string, token: string): Promise<AxiosResponse<any>> {
        return this.client.get(
            `${this.baseUrl}/frc/v1/campaigns/images/${imageId}`,
            {
                headers: { auth: token },
            }
        );
    }

    judgeGameImage(
        imageId: string,
        coords: OrderSpotAttemptParam[],
        token: string
    ): Promise<AxiosResponse<any>> {
        return this.client.post(
            `${this.baseUrl}/frc/v1/campaigns/images/${imageId}/judge`,
            coords[0],
            { headers: { auth: token } }
        );
    }

    rejectGameImage(
        imageId: string,
        token: string
    ): Promise<AxiosResponse<any>> {
        return this.client.post(
            `${this.baseUrl}/frc/v1/campaigns/images/${imageId}/reject`,
            {},
            { headers: { auth: token } }
        );
    }

    getTheme(themeId: string, activity: string): Promise<AxiosResponse<any>> {
        return this.client.get(
            `${this.baseUrl}/frc/v1/campaigns/themes/${themeId}${
                !!activity ? "?activity=" + activity : ""
            }`
        );
    }

    getProductsByTemplateId(templateId: string): Promise<AxiosResponse<any>> {
        return this.client.get(
            `${this.baseUrl}/frc/v1/campaigns/templates/${templateId}/products`
        );
    }

    getTemplate(templateId: string): Promise<AxiosResponse<any>> {
        return this.client.get(
            `${this.baseUrl}/frc/v1/campaigns/type/${templateId}`,
            {
                headers: { "Api-Level": API_LEVEL },
            }
        );
    }

    initiateNetsOrder(
        shortId: string,
        paymentCheckoutType: string,
        data: OrderParam
    ): Promise<AxiosResponse<any>> {
        return this.client.post(
            `${this.baseUrl}/frc/v1/sellers/${shortId}/checkout/nets?checkout=${paymentCheckoutType}`,
            data
        );
    }

    initiateCheckoutOrder(
        shortId: string,
        data: OrderParam
    ): Promise<AxiosResponse<any>> {
        return this.client.post(
            `${this.baseUrl}/frc/v1/sellers/${shortId}/checkout/checkout`,
            data
        );
    }

    // TODO This method doesn't seem to exist in the backend. It should probably be removed.
    initiateStripeCheckout(
        shortId: string,
        data: OrderParam
    ): Promise<AxiosResponse<any>> {
        return this.client.post(
            `${this.baseUrl}/frc/v1/sellers/${shortId}/checkout/stripe`,
            data
        );
    }

    initiatePaypalOrder(
        shortId: string,
        data: OrderParam
    ): Promise<AxiosResponse<any>> {
        return this.client.post(
            `${this.baseUrl}/frc/v1/sellers/${shortId}/checkout/paypal`,
            data
        );
    }

    // TODO This method doesn't seem to exist in the backend. It should probably be removed.
    capturePaypalPayment(
        shortId: string,
        data: any
    ): Promise<AxiosResponse<any>> {
        return this.client.post(
            `${this.baseUrl}/frc/v1/sellers/${shortId}/checkout/paypal/capture/`,
            data
        );
    }

    getSellerDetails(shortId: string): Promise<AxiosResponse<any>> {
        return this.client.get(`${this.baseUrl}/frc/v1/sellers/${shortId}`);
    }

    getSellerDetailsBySalesLinkCode(
        salesLinkCode: string
    ): Promise<AxiosResponse<any>> {
        return this.client.get(
            `${this.baseUrl}/frc/v1/sellers/salesLinkCode/${salesLinkCode}`
        );
    }

    makeSpotTheBallAttempts(
        shortId: string,
        orderId: string,
        coords: OrderSpotAttemptParam,
        key: string
    ): Promise<AxiosResponse<any>> {
        return this.client.post(
            `${this.baseUrl}/frc/v1/${shortId}/order/${orderId}/spot`,
            coords,
            {
                params: { key },
            }
        );
    }

    subscribeToMarketing(orderId: string): Promise<AxiosResponse<any>> {
        return this.client.post("/api/marketing/subscribe", {
            orderId,
        });
    }
}

const handleError = (error: any) => {
    const statusCode = error.response?.status;
    const xSpondRequestId = error.response?.headers?.["x-spond-requestid"];
    const isClientError = 400 <= statusCode && statusCode < 500;
    const optionalErrorCode: string | null =
        !isClientError && xSpondRequestId ? xSpondRequestId : null;
    toast(<ToastWithErrorId errorId={optionalErrorCode} />, {
        type: "error",
        closeOnClick: false,
    });

    return Promise.reject(error);
};

const errorHandledAxios = (
    axiosRequestConfig?: AxiosRequestConfig
): AxiosInstance => {
    const axiosInstance = axios.create(axiosRequestConfig);
    axiosInstance.interceptors.response.use(undefined, handleError);
    return axiosInstance;
};

class DerivedApi extends ApiClient {
    readonly manuallyHandle: ApiClient;

    constructor(baseUrl: string) {
        super(baseUrl, errorHandledAxios());
        this.manuallyHandle = new ApiClient(baseUrl, axios.create());
    }
}

export const apiClient: DerivedApi = new DerivedApi(REACT_APP_API_URL);
