import styled from "styled-components";

export const Wrapper = styled.div`
    max-width: 380px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
`;

export const Heading = styled.h1`
    margin: 0 0 25px 0;
    text-align: center;
    color: #181818;
    font-size: 28px;
    line-height: 1.21;
`;

export const InputElement = styled.input`
    padding: 0 18px 0 18px;
    border-radius: 4px;
    outline: none;
    box-shadow: none;
    border: 1px solid #ccc;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    color: #6e6c6d;
    -webkit-appearance: none;
    height: 48px;
`;

export const Label = styled.div`
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    color: #6e6c6d;
`;

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    span {
        padding-top: 3px;
        font-size: 13px;
        color: #6e6c6d;
    }
`;
