import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { trackPageView } from "../../../EventTracker";
import { FUNDRAISER_TYPE } from "../../../data/consts";
import moment from "moment";
import { routes } from "../../../app/routes";

class SuperDrawPostalEntryPage extends Component {
    componentDidMount() {
        const { seller } = this.props;
        trackPageView("Terms", {
            shortId: seller.shortId,
            orgDisplayName: seller.displayName,
            groupId: seller.groupId,
        });
    }

    render() {
        const {
            t,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;

        return (
            <div>
                <Link to={routes.seller(salesLinkCodeOrShortId).pathname}>
                    <h4>&larr; {t("terms.back")}</h4>
                </Link>
                <h2>Postal entry</h2>
                <p>
                    Insert description about how you can enter by post. Lorem
                    ipsum dolor sit amet. Insert description about how you can
                    enter by post. Lorem ipsum dolor sit amet.
                </p>
                <p>
                    Insert description about how you can enter by post. Lorem
                    ipsum dolor sit amet.
                </p>
                <Link to={routes.seller(salesLinkCodeOrShortId).pathname}>
                    <h4>&larr; {t("terms.back")}</h4>
                </Link>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(SuperDrawPostalEntryPage))
);
