import styled from "styled-components";

export const ThankYouBed = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 60px auto 30px auto;
`;

export const ContentHolder = styled.div`
    margin: 15px auto;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
`;

export const DescriptionBed = styled.p`
    text-align: center;
`;

export const ActionsBed = styled.div`
    display: inline-block;
    margin-top: 15px;
    width: 100%;
    text-align: center;
`;

export const ShareActionsBed = styled.div`
    display: flex;
    margin: 15px auto 0 auto;
    flex-direction: column;
    width: 100%;
`;

export const YourSubmissionTitle = styled.h3`
    margin: 30px auto 10px auto;
`;

export const YourSubmissionDescription = styled.div`
    margin: 0 auto 30px auto;
    width: 100%;
`;

export const ButtonBed = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
`;

export const EmailLink = styled.a`
    width: 320px;
`;
