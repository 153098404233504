import React from "react";

import type { Seller } from "../../api/types";
import { useTranslation } from "react-i18next";
import Avatar from "../avatar/Avatar";
import previewAvatar from "../../assets/fc_preview_profile_image.png";
import { COLORS, FUNDRAISER_TYPE } from "../../data/consts";
import DOMPurify from "dompurify";
import styled from "styled-components";

const HeaderBed = styled.div<{
    includeStyling?: boolean;
    themeBackground?: string;
    previewMode?: boolean;
    isCustom?: boolean;
}>`
    background-image: url(${({ includeStyling, themeBackground }) =>
        includeStyling ? themeBackground : ""});
    padding-top: ${({ previewMode, isCustom }) =>
        previewMode && !isCustom ? "40px" : "0px"};
    padding-bottom: ${({ previewMode }) => (previewMode ? "5px" : "0px")};
    margin-bottom: ${({ previewMode }) => (previewMode ? "20px" : "0px")};
`;

type LandingHeaderProps = {
    seller: Seller;
    previewMode?: boolean;
    includeStyling?: true;
    themeType: number;
};

export default ({
    seller,
    previewMode,
    includeStyling,
    themeType,
}: LandingHeaderProps) => {
    const {
        logo,
        theme,
        sellerImage,
        sellerFirstName = "",
        sellerLastName = "",
        groupName,
        description,
    } = seller;

    const { t } = useTranslation();

    const firstName = previewMode
        ? t("preview.placeholder_first_name")
        : sellerFirstName;
    const { primaryColour } = theme;

    const textColour =
        seller.type === "PRODUCTS"
            ? primaryColour
            : COLORS.$spondGray1BaseSecondary;
    const isGroupMode =
        sellerFirstName.length === 0 && sellerLastName.length === 0;
    const titleKey =
        seller.type === FUNDRAISER_TYPE.SUPER_DRAW
            ? "superdraw.home_title"
            : isGroupMode && !previewMode
            ? "landing.title_organisation_seller"
            : "landing.title";

    return (
        <HeaderBed
            includeStyling={includeStyling}
            previewMode={previewMode}
            themeBackground={theme.background}
            isCustom={!!themeType}
        >
            {!themeType && (
                <div
                    style={{
                        margin: "15px 0 0 0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Avatar imageUrl={sellerImage || logo} />
                    {previewMode && (
                        <img
                            alt=""
                            style={{ height: "125px", width: "125px" }}
                            src={previewAvatar}
                        />
                    )}
                </div>
            )}
            {seller.type === FUNDRAISER_TYPE.SUPER_DRAW && (
                <div
                    style={{
                        textAlign: "center",
                        fontSize: "28px",
                        margin: "25px 10px 0 10px",
                        lineHeight: "120%",
                        color: textColour,
                    }}
                    dangerouslySetInnerHTML={{
                        __html: t(titleKey, {
                            firstName: DOMPurify.sanitize(firstName, {
                                USE_PROFILES: { html: false },
                            }),
                            groupName:
                                "<span name='group-name' style=display:inline-block>" +
                                DOMPurify.sanitize(groupName, {
                                    USE_PROFILES: { html: false },
                                }) +
                                "</span>",
                        }),
                    }}
                ></div>
            )}
            {seller.type !== FUNDRAISER_TYPE.SUPER_DRAW && (
                <h1
                    style={{
                        textAlign: "center",
                        fontSize: "28px",
                        margin: "25px 10px 0 10px",
                        lineHeight: "120%",
                        color: textColour,
                    }}
                    dangerouslySetInnerHTML={{
                        __html: t(titleKey, {
                            firstName: DOMPurify.sanitize(firstName, {
                                USE_PROFILES: { html: false },
                            }),
                            groupName:
                                "<span name='group-name' style=display:inline-block>" +
                                DOMPurify.sanitize(groupName, {
                                    USE_PROFILES: { html: false },
                                }) +
                                "</span>",
                        }),
                    }}
                ></h1>
            )}
            <NamedH2
                name="fundraiser-purpose"
                style={{
                    textAlign: "center",
                    margin: "15px auto 25px auto",
                    padding: "0 10px",
                    fontSize: "1.2em",
                    fontWeight: "400",
                    maxWidth: "560px",
                    lineHeight: "140%",
                    color: textColour,
                }}
            >
                {description}
            </NamedH2>
        </HeaderBed>
    );
};

const NamedH2 = "h2" as any as React.FC<
    React.ComponentPropsWithoutRef<"h2"> & {
        name: string;
    }
>;
