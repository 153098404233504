import React from "react";
import NumberFormat from "react-number-format";

export default ({ t, value, fixedDecimalScale }) => (
    <NumberFormat
        value={value * 0.01}
        displayType={"text"}
        decimalSeparator={t("general.decimal_separator")}
        thousandSeparator={t("general.thousand_separator")}
        decimalScale={2}
        fixedDecimalScale={fixedDecimalScale ? fixedDecimalScale : 0}
        suffix={t("general.number_suffix")}
        prefix={t("general.number_prefix")}
    />
);
