import SubscriptionSvg from "./ScratchCardSubscriptionSvg";
import { useTranslation } from "react-i18next";
import { Theme } from "../../../api/types";
import React from "react";

import {
    SelectCheckoutTypeButton,
    SubscriptionBoxTitle,
    SubscriptionBoxText,
} from "./ScratchcardWrapper.styled";

export function SubscriptionButton(props: {
    onClick: () => void;
    theme: Theme;
    disabled?: boolean;
}) {
    const { t } = useTranslation();

    return (
        <SelectCheckoutTypeButton
            type="button"
            onClick={() => props.onClick()}
            disabled={props.disabled}
        >
            <div>
                <SubscriptionSvg theme={props.theme} />
            </div>
            <SubscriptionBoxTitle>
                {t("monthly_contribution_title")}
            </SubscriptionBoxTitle>
            <SubscriptionBoxText>
                {t("monthly_contribution_text")}
            </SubscriptionBoxText>
        </SelectCheckoutTypeButton>
    );
}
