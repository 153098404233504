import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import qs from "query-string";
import { withTranslation } from "react-i18next";
import { ScratchcardBg } from "../../../layout/ScratchcardLayout";

import ScratchcardInfo from "../../../components/scratchcards/ScratchcardInfo";
import { apiClient } from "../../../api/ApiClient";
import { routes } from "../../../app/routes";

class ScratchcardInfoPage extends Component {
    constructor(props) {
        super(props);

        const { location } = this.props;
        const { orderId, key } = qs.parse(location.search);

        this.state = {
            orderId,
            key,
            products: [],
        };
    }

    componentDidMount = () => {
        const {
            seller: { campaignId },
        } = this.props;
        apiClient
            .getProducts(campaignId)
            .then((res) => {
                const products = res.data;
                this.setState({
                    products: products.products,
                });
            })
            .catch((err) => {
                console.error("Error while retrieving products", err);
            });
    };

    onCloseClicked = () => {
        const {
            history,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { orderId } = this.state;

        if (orderId) {
            history.push(
                routes
                    .seller(salesLinkCodeOrShortId)
                    .scratchcards.order(orderId).pathname
            );
        } else {
            history.push(routes.seller(salesLinkCodeOrShortId).pathname);
        }
    };

    render() {
        const { seller } = this.props;

        return (
            <ScratchcardBg bgImage={seller.theme.fullscreenBackground}>
                <div className="page-wrapper nopad">
                    <ScratchcardInfo
                        seller={seller}
                        products={this.state.products}
                        onCloseClicked={() => this.onCloseClicked()}
                    />
                </div>
            </ScratchcardBg>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(ScratchcardInfoPage))
);
