import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withTranslation } from "react-i18next";
import Modal from "react-responsive-modal";

import { ProductLandingBed } from "./ProductLanding.container.styled";
import "./ProductLanding.container.scss";

import LandingHeader from "../../../components/landing-header/LandingHeader";
import { trackEvent, trackPageView } from "../../../EventTracker";
import { addToCart } from "../../../redux/seller";
import { Button } from "../../../components/button/Buttons";
import {
    initiateStandardPayment,
    isEligibleForExtraProduct,
} from "../../../utils/payment";
import ExtraProductModal from "../extra-product/ExtraProductModal.container";
import SpondImage from "../../../components/spond-image/SpondImage";
import BackgroundImage from "../../../components/background-image/BackgroundImage";
import ProductLandingWrapper from "./ProductLandingWrapper.container";
import qs from "query-string";
import { CheckoutOrKeepShoppingModal } from "../checkout-or-keep-shopping/CheckoutOrKeepShoppingModal";
import { getFreeShippingCountdown } from "./ProductLanding.utils";
import StickyShoppingCart from "../../../components/product/shopping-cart/StickyShoppingCart";
import { routes } from "../../../app/routes";

class ProductLanding extends Component {
    constructor(props) {
        super(props);
        this.shoppingCartRef = React.createRef();
        this.state = {
            lightboxToggler: {},
            showModalSelectType: false,
            showModalBuyNow: false,
            showModalExtraProduct: false,
            categoryWidth: 0,
        };

        this.onCategorySelect = this.onCategorySelect.bind(this);

        window.addEventListener("resize", this.onWindowResize);
    }

    componentDidMount() {
        const { seller, location, history } = this.props;
        const { products } = seller;
        const {
            state: {
                product: { productId, productVariationId } = {},
                landingScrollPosition,
            } = {},
        } = location;

        if (landingScrollPosition) {
            window.scrollTo({ top: landingScrollPosition, left: 0 });
            history.replace(location.pathname);
        }

        this.onWindowResize();

        // If pushed state contains productId, this product (and variation)
        // should be added to cart using the ordinary handleAddProductToCart in order to
        // trigger any neccessary modals.
        if (productId) {
            const foundProduct = products.find(
                (product) => product.id === productId
            );
            this.handleAddProductToCart(foundProduct, productVariationId);

            // We don't want multiple adds if user refreshes page, so
            // remove the state by replacing history after 1 successful add.
            history.replace(location.pathname, {});
        }

        trackPageView("Landing", {
            shortId: seller.shortId,
            orgDisplayName: seller.displayName,
            groupId: seller.groupId,
        });
    }

    componentWillUnmount() {
        // TODO ComponentWillUnmount shouldn't be used. https://fb.me/react-unsafe-component-lifecycles .
        window.removeEventListener("resize", this.onWindowResize);
    }

    handleProductActionClicked = (product) => {
        if (!!product.variations && product.variations.length > 0) {
            // TODO Discuss with Daniel if this should instead display the CTA (Call to action) modal with size selection like previously.
            this.viewProduct(product.id);
        } else {
            this.handleAddProductToCart(product);
        }
    };

    onWindowResize = () => {
        this.setState({ categoryWidth: window.outerWidth });
    };

    handleAddProductToCart(product, productVariationId, productVariationName) {
        const {
            cart,
            addProductToCart,
            seller: { groupId, shortId, displayName },
        } = this.props;

        if (!cart || !cart.length) {
            this.setState({
                showModalBuyNow: true,
                currentProduct: product,
                variationName: productVariationName,
            });
        } else {
            if (this.shoppingCartRef) {
                this.shoppingCartRef.activatePopover(product);
            }
        }

        addProductToCart(product.id, productVariationId);

        trackEvent("ADD_TO_CART", {
            productId: product.id,
            productVariationId,
            groupId,
            shortId,
            orgDisplayName: displayName,
        });
    }

    handleVippsPayment() {
        const {
            cart,
            seller,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;

        const { extraProduct } = seller;

        if (isEligibleForExtraProduct(cart, extraProduct)) {
            this.setState({
                showModalExtraProduct: true,
                showModalBuyNow: false,
                selectedPaymentMethod: "VIPPS",
            });
        } else {
            const paymentArgs = {
                cart,
                seller,
                salesLinkCodeOrShortId,
            };
            initiateStandardPayment(paymentArgs);
        }
    }

    handleHideSelectType() {
        this.setState({
            showModalSelectType: false,
        });
    }

    handleHideBuyNow() {
        this.setState({
            showModalBuyNow: false,
        });
    }

    handleHideExtraProduct() {
        this.setState({
            showModalExtraProduct: false,
        });
    }

    onCategorySelect(categoryId) {
        window.scrollTo({ top: 0, left: 0 });
    }

    viewProduct = (productId) => {
        const {
            history,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        history.push(
            routes.seller(salesLinkCodeOrShortId).products(productId),
            {
                landingScrollPosition: window.scrollY,
            }
        );
    };

    render() {
        const { cart, seller, t, location } = this.props;
        const freeShippingCountdown = getFreeShippingCountdown(cart, seller);
        const { source, profileId } = qs.parse(location.search);
        const {
            currentProduct,
            showModalBuyNow,
            showModalSelectType,
            showModalExtraProduct,
            selectedPaymentMethod,
        } = this.state;

        if (seller.campaignStatus !== "ACTIVE") {
            return (
                <div>
                    <h1
                        style={{ textAlign: "center", marginBottom: "10px" }}
                        dangerouslySetInnerHTML={{
                            __html: t("landing.ended_heading_sc"),
                        }}
                    />
                    <p
                        style={{ textAlign: "center", marginBottom: "180px" }}
                        dangerouslySetInnerHTML={{
                            __html: t("landing.ended_body_sc"),
                        }}
                    />
                </div>
            );
        }

        return (
            <ProductLandingBed>
                <BackgroundImage
                    theme={seller.theme}
                    shorten={!seller.sellerImage}
                >
                    <LandingHeader seller={seller} t={t} />
                </BackgroundImage>

                {/* TODO Rename to "ProductsAndDeliveryInfo"*/}
                <ProductLandingWrapper
                    seller={seller}
                    t={t}
                    viewProduct={this.viewProduct}
                    handleProductActionClicked={this.handleProductActionClicked}
                />

                <StickyShoppingCart
                    ref={(ref) => (this.shoppingCartRef = ref)}
                    cart={cart}
                    seller={seller}
                    t={t}
                />

                {/* TODO Discuss with Daniel if this modal needs to be removed as it is not in use as per 2022-11-15.*/}
                <Modal
                    center={true}
                    open={showModalSelectType}
                    onClose={() => this.handleHideSelectType()}
                    classNames={{ modal: "action-modal" }}
                >
                    {currentProduct && (
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <SpondImage
                                    imageUrl={currentProduct.preview}
                                    transformation="w=610&h=610"
                                    style={{
                                        width: "80%",
                                        height: "90%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    alt={currentProduct.name}
                                />
                            </div>
                            <h3
                                style={{
                                    textAlign: "center",
                                    marginBottom: "0px",
                                }}
                            >
                                {t("product.select_type_label", {
                                    type: currentProduct.variationName,
                                })}
                            </h3>
                            <h4
                                style={{
                                    textAlign: "center",
                                    marginTop: "0px",
                                    marginBottom: "25px",
                                }}
                            >
                                {currentProduct.name}
                            </h4>
                            {currentProduct.variations &&
                                currentProduct.variations.map(
                                    (variation, idx) => (
                                        <div
                                            key={variation.id}
                                            style={{
                                                marginBottom:
                                                    idx <
                                                    currentProduct.variations
                                                        .length
                                                        ? "15px"
                                                        : "0",
                                            }}
                                        >
                                            <Button
                                                backgroundColour={
                                                    seller.theme
                                                        .primaryButtonColour
                                                }
                                                onClick={() => {
                                                    this.handleAddProductToCart(
                                                        currentProduct,
                                                        variation.id,
                                                        variation.name
                                                    );
                                                    this.handleHideSelectType();
                                                }}
                                            >
                                                {variation.name}
                                            </Button>
                                        </div>
                                    )
                                )}
                        </div>
                    )}
                </Modal>

                <ExtraProductModal
                    paymentMethod={selectedPaymentMethod}
                    showModal={showModalExtraProduct}
                    onClose={() => this.handleHideExtraProduct()}
                />

                <CheckoutOrKeepShoppingModal
                    product={currentProduct}
                    open={showModalBuyNow}
                    freeShippingCountdown={freeShippingCountdown}
                    variationName={this.state.variationName}
                    onClose={() => this.handleHideBuyNow()}
                    keepShopping={() => this.handleHideBuyNow()}
                    profileId={profileId}
                    source={source}
                    seller={seller}
                />
            </ProductLandingBed>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
    cart: state.sellerReducer.cart,
});

const mapDispatchToProps = (dispatch) => ({
    addProductToCart: (productId, productVariationId) =>
        dispatch(addToCart({ productId, productVariationId })),
});

export default withRouter(
    withTranslation()(
        connect(mapStateToProps, mapDispatchToProps)(ProductLanding)
    )
);
