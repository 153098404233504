import { Product, ProductVariationParam, Seller } from "../../../api/types";
import { sum } from "../../../utils/misc/misc";
import { CartItem } from "../../../redux/sellerReducer";

export type CartProductEntry = {
    variation?: ProductVariationParam;
    totalPrice: number;
    shippingPrice: number;
    product: Product;
    productId: string;
    quantity: number;
    productVariationId?: string;
    preview: string;
};

export const buildCartSummaryPerProduct = (
    cart: CartItem[],
    seller: Seller
): CartProductEntry[] => {
    const { extraProduct, productSet } = seller;

    const cartItemToCartProductItem = (
        cartItem: CartItem
    ): CartProductEntry => {
        const products: Product[] = [
            ...(productSet?.products || []),
            extraProduct,
        ].filter((it) => it) as Product[];
        if (products.length === 0)
            throw new Error("The products list was empty.");
        const shippingCost: number | undefined | null =
            productSet?.shippingCost;

        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            if (cartItem.productId === product.id) {
                const totalPrice: number = product.price * cartItem.quantity;
                const shippingPrice: number = shippingCost
                    ? shippingCost * cartItem.quantity
                    : 0;
                const variation: ProductVariationParam | undefined =
                    product?.variations?.find(
                        (pv: ProductVariationParam) =>
                            pv.id === cartItem.productVariationId
                    );
                const variationPreview = variation?.images?.[0];
                const productPreview = product.preview;
                return {
                    preview: variationPreview || productPreview,
                    product,
                    totalPrice,
                    shippingPrice,
                    variation,
                    ...cartItem,
                };
            }
        }
        throw new Error(
            "Couldn't match the cart items to any of the products in the products list."
        );
    };

    return (cart || []).map(cartItemToCartProductItem);
};

type CartSummary = {
    cartSummaryPerProduct: CartProductEntry[];
    totalPrice: number;
    finalShippingFee: number;
};

export const createCartSummary = (
    cart: CartItem[],
    seller: Seller
): CartSummary => {
    const { productSet } = seller;
    const cartProductSummary: CartProductEntry[] = buildCartSummaryPerProduct(
        cart,
        seller
    );
    const freeShippingThreshold: number | undefined | null =
        productSet?.freeShippingThreshold;
    const totalPriceOfProducts: number = cartProductSummary
        .map((cartItem) => cartItem.totalPrice)
        .reduce(sum, 0);
    const productSetShippingCost = productSet?.shippingCost || 0;
    const finalShippingFee: number =
        freeShippingThreshold !== null && freeShippingThreshold !== undefined
            ? totalPriceOfProducts >= freeShippingThreshold
                ? 0
                : productSetShippingCost
            : 0;
    const totalPrice: number = totalPriceOfProducts + finalShippingFee;
    return {
        cartSummaryPerProduct: cartProductSummary,
        totalPrice,
        finalShippingFee,
    };
};

export const getCartTotalPrice = (cart: CartItem[], seller: Seller) => {
    const cartItems = buildCartSummaryPerProduct(cart, seller);
    return (cartItems || [])
        .map((cartItem) => cartItem.totalPrice)
        .reduce(sum, 0);
};
