export const routes = {
    seller: (salesLinkCodeOrShortId: string) => ({
        pathname: `/${salesLinkCodeOrShortId}`,
        cart: `/${salesLinkCodeOrShortId}/cart`,
        scratchcards: {
            pathname: `/${salesLinkCodeOrShortId}/scratchcards`,
            order: (orderId: string) => ({
                pathname: `/${salesLinkCodeOrShortId}/scratchcards/${orderId}`,
                scratchcard: (scratchcardId: string) => ({
                    selectPrize: `/${salesLinkCodeOrShortId}/scratchcards/${orderId}/${scratchcardId}/select-prize`,
                    prizeForm: `/${salesLinkCodeOrShortId}/scratchcards/${orderId}/${scratchcardId}/prize-form`,
                }),
            }),
        },
        scratchcardsInfo: `/${salesLinkCodeOrShortId}/scratchcards-info`,
        spotTheBall: {
            pathname: `/${salesLinkCodeOrShortId}/spot-the-ball`,
            extOrder: (extOrderId: string) =>
                `/${salesLinkCodeOrShortId}/spot-the-ball/${extOrderId}`,
        },
        products: (productId: string) =>
            `/${salesLinkCodeOrShortId}/products/${productId}`,
        superdraw: {
            checkout: `/${salesLinkCodeOrShortId}/super-draw/checkout`,
            order: (orderId: string) => ({
                drawPathname: `/${salesLinkCodeOrShortId}/super-draw/${orderId}/draw`,
                pathname: `/${salesLinkCodeOrShortId}/super-draw/${orderId}`,
            }),
        },
        raffle: {
            order: (orderId: string) =>
                `/${salesLinkCodeOrShortId}/raffle/${orderId}`,
        },
        orderSuccessful: `/${salesLinkCodeOrShortId}/order-successful`,
        orderCancelled: `/${salesLinkCodeOrShortId}/order-cancelled`,
    }),
};
