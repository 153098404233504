import React from "react";
import * as styles from "./ProfilePicture.module.scss";
import SpondImage from "../../spond-image/SpondImage";

const ProfilePicture = ({ imageUrl, onClick }) => {
    return (
        <div className={styles.wrapper} onClick={onClick}>
            <SpondImage
                imageUrl={imageUrl}
                transformation="t_avatar"
                className={styles.profilePicture}
            />
        </div>
    );
};

export default ProfilePicture;
