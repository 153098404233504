import React from "react";
import styled from "styled-components";

export const BackgroundDiv = styled.div`
    background-image: url("${(props) => props.background}");
    position: relative;
    z-index: ${(props) => (props.zIndex ? props.zIndex : -20)};
    padding-top: 25px;
    padding-bottom: 10px;
    width: 100vw;
    top: -45px;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-size: cover;
    background-position: bottom;
`;

export default ({ theme, shorten, children }) => {
    const { background } = theme;
    const height = shorten ? "170px" : "300px";

    return (
        <BackgroundDiv
            className="background-image"
            background={background}
            height={height}
        >
            {children}
        </BackgroundDiv>
    );
};
