import {
    ADD_TO_CART,
    CLEAR_CART,
    GET_SELLER_ERROR,
    GET_SELLER_SUCCESS,
    REMOVE_FROM_CART,
    UPDATE_QUANTITY_IN_CART,
} from "./types";
import { apiClient } from "../api/ApiClient";

export const getSeller = (salesLinkCodeOrShortId) => (dispatch) => {
    return (
        salesLinkCodeOrShortId.length > 7
            ? apiClient.getSellerDetailsBySalesLinkCode(salesLinkCodeOrShortId)
            : apiClient.getSellerDetails(salesLinkCodeOrShortId)
    )
        .then((res) => {
            dispatch({
                type: GET_SELLER_SUCCESS,
                payload: {
                    ...res.data,
                    salesLinkCodeOrShortId,
                },
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_SELLER_ERROR,
                payload: err,
            });
        });
};

export const addToCart =
    ({ productId, productVariationId, quantity }) =>
    (dispatch) => {
        dispatch({
            type: ADD_TO_CART,
            payload: {
                productId,
                productVariationId,
                quantity,
            },
        });
    };

export const removeFromCart =
    ({ productId, productVariationId }) =>
    (dispatch) => {
        dispatch({
            type: REMOVE_FROM_CART,
            payload: {
                productId,
                productVariationId,
            },
        });
    };

export const updateQuantityInCart =
    ({ productId, productVariationId, quantity }) =>
    (dispatch) => {
        dispatch({
            type: UPDATE_QUANTITY_IN_CART,
            payload: {
                productId,
                productVariationId,
                quantity,
            },
        });
    };

export const clearCart = () => (dispatch) => {
    dispatch({
        type: CLEAR_CART,
    });
};
