import React from "react";
import * as styles from "./SimpleModal.module.scss";

export const SimpleModal = ({ open, children }) => {
    return (
        <div
            className={[
                styles.modalWrapper,
                open ? styles.showModalWrapper : " ",
            ].join(" ")}
        >
            <div
                className={[styles.modal, open ? styles.showModal : ""].join(
                    " "
                )}
            >
                {children}
            </div>
        </div>
    );
};
