import { useRef, useEffect } from "react";

const useAnimationFrame = (callback, duration) => {
    // Use useRef for mutable variables that we want to persist
    // without triggering a re-render on their change
    const requestRef = useRef();
    const previousTimeRef = useRef();
    let start;

    const animate = () => {
        const time = new Date().getTime();
        if (start === undefined) start = time;
        if (previousTimeRef.current !== undefined) {
            callback(time - start);
        }
        previousTimeRef.current = time;
        if (time < start + duration)
            requestRef.current = requestAnimationFrame(animate);
    };

    useEffect(() => {
        return () => cancelAnimationFrame(requestRef.current);
    }, []); // Make sure the effect runs only once

    return () => (requestRef.current = requestAnimationFrame(animate));
};

export default useAnimationFrame;
