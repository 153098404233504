import styled from "styled-components";

import { ClickableButton } from "../../../components/button/Buttons";

import downarrow from "../../../assets/downarrow.svg";
import navigationArrow from "../../../assets/navigationArrow.svg";

export const ViewProductContainerBed = styled.div`
    max-width: 700px;
    margin: 0 auto;
    position: relative;
`;

export const GalleryWrapper = styled.div`
    margin: -15px -15px 15px -15px;

    .image-gallery-slide-wrapper {
        min-height: 300px;
    }

    .image-gallery-content,
    .image-gallery-image,
    .image-gallery-slide {
        max-height: 300px;
    }

    .image-gallery-slide-wrapper > button {
        outline: none;
    }

    img.image-gallery-image {
        max-height: 300px;
        height: 300px;
    }
`;

export const GalleryNavigation = styled.button`
    align-content: center;
    background-color: transparent;
    border: 0px;
    display: flex;
    height: 30px;
    justify-content: center;
    left: ${({ pos }) => (pos === "LEFT" ? "0" : "auto")};
    padding: 50px 10px;
    position: absolute;
    right: ${({ pos }) => (pos === "RIGHT" ? "0" : "auto")};
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    z-index: 2;

    span {
        background-image: url(${navigationArrow});
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        height: 28px;
        position: relative;
        width: 14px;
        transform: ${({ pos }) => (pos === "LEFT" ? "rotate(-180deg)" : "")};
    }
`;

export const ProductTitle = styled.h2`
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 1em 0;
    text-align: left;
`;

export const ProductDetails = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const ProductPrice = styled.h3`
    font-weight: 500;
    font-size: 25px;
    margin: 0;
    white-space: nowrap;
    padding-right: 20px;
`;

export const ProductButton = styled(ClickableButton)`
    font-size: 14px;
    padding: 1em 2em;
    flex: 0;
    white-space: nowrap;
`;

export const ProductDescription = styled.div`
    margin: 1em 0 3em;

    @media (min-width: 700px) {
        margin-bottom: 5em;
    }

    ul {
        margin: 0.5em 0;
        padding-left: 18px;
    }
`;

export const ProductParagraph = styled.p``;

export const VariationSelector = styled.select`
    width: 100%;
    padding: 1em 0.5em;
    font-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; /* Remove default arrow */
    border: 1px solid #c4c4c4;
    margin: 0 0 0.5em 0;
    position: relative;
    background-image: url("${downarrow}");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 95% 12px;
`;
