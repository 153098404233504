import christmasBannerImg from "./assets/banner.png";
import easterBannerImg from "./assets/easter-top-banner.png";
import may2022BannerImg from "./assets/2022-may-image-banner.png";
import IntroImg1 from "./assets/frc-christmas-image-1.jpg";
import IntroImg2 from "./assets/frc-christmas-image-2.jpg";
import IntroImg3 from "./assets/frc-christmas-image-3.jpg";
import easterIntroImg1 from "./assets/frc-easter-image-1.jpg";
import easterIntroImg2 from "./assets/frc-easter-image-2.jpg";
import easterIntroImg3 from "./assets/frc-easter-image-3.jpg";
import may2022Img1 from "./assets/2022-may-image-1.png";
import may2022Img2 from "./assets/2022-may-image-2.png";
import may2022Img3 from "./assets/2022-may-image-3.png";

import summer2022Img1 from "./assets/frc-summer-2022-image-1.png";
import summer2022Img2 from "./assets/frc-summer-2022-image-2.png";
import summer2022Img3 from "./assets/frc-summer-2022-image-3.png";
import summer2022BannerImg from "./assets/frc-summer-2022-top-asset.png";

import halloweenImg1 from "./assets/frc-halloween-image-1.png";
import halloweenImg2 from "./assets/frc-halloween-image-2.png";
import halloweenImg3 from "./assets/frc-halloween-image-3.png";
import halloweenBannerImg from "./assets/frc-halloween-top-asset.png";
import { isProd } from "../../../utils/env";

export const CUSTOMIZE_CHRISTMAS_THEME_ID_PRODUCTION =
    "7E11FC6A55CAA91924D93AE17268FF90"; // production
export const CUSTOMIZE_CHRISTMAS_THEME_ID_STAGING =
    "F3DE093BE3261C75BFF89AE46943385A"; //staging
export const CUSTOMIZE_CHRISTMAS_THEME_ID_STAGING2 = ""; //staging2

/***
 * FRC - Easter theme 2022 adjustments
 * https://www.figma.com/file/rV9Pb9XPI1WC6oiryv1jvL/FRC---Easter-theme-2022-adjustments?node-id=0%3A1
 */
export const CUSTOMIZE_EASTER_THEME_ID_PRODUCTION =
    "779231B0E3466170B8A7BD09C04737E8";
export const CUSTOMIZE_EASTER_THEME_ID_STAGING =
    "6920EFE72AADA23825DF1B72DF62A0FC";
export const CUSTOMIZE_EASTER_THEME_ID_STAGING2 =
    "4F8071FA6E05470075E7A2573CEADAC8";

/***
 * FRC - May theme 2022
 *  https://jira-spond.atlassian.net/browse/MON-82
 */
export const CUSTOMIZE_MAY_THEME_ID_PRODUCTION =
    "A66D35175072BBF573CC033AB47156A9";
export const CUSTOMIZE_MAY_THEME_ID_STAGING =
    "AC8E3311B5D3314D5A7957DEBBC3BCC4";
export const CUSTOMIZE_MAY_THEME_ID_STAGING2 = "";

/***
 * FRC - summer theme 2022
 *  https://www.figma.com/file/l2dg64TSVJoP0q7AbYd3Ji/FRC---Summer-theme-2022-adjustments?node-id=0%3A1
 */
export const SUMMER_2022_THEME_ID_PRODUCTION =
    "20313535797346CD5E799E17899B5537";
export const SUMMER_2022_THEME_ID_STAGING = "AC8E3311B5D3314D5A7957DEBBC3BCC4";
export const SUMMER_2022_THEME_ID_STAGING2 = "";

/***
 * FRC - halloween theme
 *  https://www.figma.com/file/VMbhnVC1MtuCILzDZX3Onk/FRC---Halloween-theme-2022-adjustments?node-id=1%3A160
 */
export const HALLOWEEN_THEME_ID_PRODUCTION = "FC500F8B7C7F06A4DBD34DA32B82C135";
export const HALLOWEEN_THEME_ID_STAGING = "";
export const HALLOWEEN_THEME_ID_STAGING2 = "ECB8650085E72A4EE84CBF18B15A51E3";

export enum ScratchThemeType {
    normal = 0, // value 0 can be judged whether is custom theme
    christmas = 1,
    easter = 2,
    may2022 = 3,
    summer2022 = 4,
    halloween = 5,
}

export function getScratchThemeType(themeId: string): number {
    const isProduction = isProd();
    const isStaging = window.origin === "https://staging1.spond.dev";
    const typeMap = new Map([
        [
            isProduction
                ? CUSTOMIZE_CHRISTMAS_THEME_ID_PRODUCTION
                : isStaging
                ? CUSTOMIZE_CHRISTMAS_THEME_ID_STAGING
                : CUSTOMIZE_CHRISTMAS_THEME_ID_STAGING2,
            ScratchThemeType.christmas,
        ],
        [
            isProduction
                ? CUSTOMIZE_EASTER_THEME_ID_PRODUCTION
                : isStaging
                ? CUSTOMIZE_EASTER_THEME_ID_STAGING
                : CUSTOMIZE_EASTER_THEME_ID_STAGING2,
            ScratchThemeType.easter,
        ],
        [
            isProduction
                ? CUSTOMIZE_MAY_THEME_ID_PRODUCTION
                : isStaging
                ? CUSTOMIZE_MAY_THEME_ID_STAGING
                : CUSTOMIZE_MAY_THEME_ID_STAGING2,
            ScratchThemeType.may2022,
        ],
        [
            isProduction
                ? SUMMER_2022_THEME_ID_PRODUCTION
                : isStaging
                ? SUMMER_2022_THEME_ID_STAGING
                : SUMMER_2022_THEME_ID_STAGING2,
            ScratchThemeType.summer2022,
        ],
        [
            isProduction
                ? HALLOWEEN_THEME_ID_PRODUCTION
                : isStaging
                ? HALLOWEEN_THEME_ID_STAGING
                : HALLOWEEN_THEME_ID_STAGING2,
            ScratchThemeType.halloween,
        ],
    ]);
    return typeMap.get(themeId) || ScratchThemeType.normal;
}

export const ScratchThemeConfig = {
    [ScratchThemeType.christmas]: {
        banner: christmasBannerImg,
        purchaseTicketStringKey: "actions.purchase_christmas_ticket",
        introList: [
            {
                img: IntroImg1,
                titleKey: "landing.christmas_theme_2021_title_1",
                descriptionKey: "landing.christmas_theme_2021_description_1",
            },
            {
                img: IntroImg2,
                titleKey: "landing.christmas_theme_2021_title_2",
                descriptionKey: "landing.christmas_theme_2021_description_2",
            },
            {
                img: IntroImg3,
                titleKey: "landing.christmas_theme_2021_title_3",
                descriptionKey: "landing.christmas_theme_2021_description_3",
            },
        ],
    },
    [ScratchThemeType.easter]: {
        banner: easterBannerImg,
        purchaseTicketStringKey: "actions.purchase_easter_ticket",
        introList: [
            {
                img: easterIntroImg1,
                titleKey: "landing.easter_theme_2022_title_1",
                descriptionKey: "landing.easter_theme_2022_description_1",
            },
            {
                img: easterIntroImg2,
                titleKey: "landing.easter_theme_2022_title_2",
                descriptionKey: "landing.easter_theme_2022_description_2",
            },
            {
                img: easterIntroImg3,
                titleKey: "landing.easter_theme_2022_title_3",
                descriptionKey: "landing.easter_theme_2022_description_3",
            },
        ],
    },
    [ScratchThemeType.may2022]: {
        banner: may2022BannerImg,
        purchaseTicketStringKey: "actions.purchase_may_2022_ticket",
        introList: [
            {
                img: may2022Img1,
                titleKey: "landing.may_theme_2022_title_1",
                descriptionKey: "landing.may_theme_2022_description_1",
            },
            {
                img: may2022Img2,
                titleKey: "landing.may_theme_2022_title_2",
                descriptionKey: "landing.may_theme_2022_description_2",
            },
            {
                img: may2022Img3,
                titleKey: "landing.may_theme_2022_title_3",
                descriptionKey: "landing.may_theme_2022_description_3",
            },
        ],
    },
    [ScratchThemeType.summer2022]: {
        banner: summer2022BannerImg,
        purchaseTicketStringKey: "actions.purchase_summer_2022_ticket",
        introList: [
            {
                img: summer2022Img1,
                titleKey: "landing.summer_theme_2022_title_1",
                descriptionKey: "landing.summer_theme_2022_description_1",
            },
            {
                img: summer2022Img2,
                titleKey: "landing.summer_theme_2022_title_2",
                descriptionKey: "landing.summer_theme_2022_description_2",
            },
            {
                img: summer2022Img3,
                titleKey: "landing.summer_theme_2022_title_3",
                descriptionKey: "landing.summer_theme_2022_description_3",
            },
        ],
    },
    [ScratchThemeType.halloween]: {
        banner: halloweenBannerImg,
        purchaseTicketStringKey: "actions.purchase_halloween_ticket",
        introList: [
            {
                img: halloweenImg1,
                titleKey: "landing.halloween_theme_2022_title_1",
                descriptionKey: "landing.halloween_theme_2022_description_1",
            },
            {
                img: halloweenImg2,
                titleKey: "landing.halloween_theme_2022_title_2",
                descriptionKey: "landing.halloween_theme_2022_description_2",
            },
            {
                img: halloweenImg3,
                titleKey: "landing.halloween_theme_2022_title_3",
                descriptionKey: "landing.halloween_theme_2022_description_3",
            },
        ],
    },
};
