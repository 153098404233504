import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ScrollToTop from "../../../components/scroll-to-top/ScrollToTop";
import { toast } from "react-toastify";
import Avatar from "../../../components/avatar/Avatar";
import {
    ButtonHolder,
    FormHolder,
    FormLabel,
    Input,
    PaymentCardIcon,
    SuperDrawCheckoutBed,
} from "./SuperDrawCheckout.page.styled";
import CurrencyFormat from "../../../components/currency-format/CurrencyFormat";
import { initiateStandardPayment } from "../../../utils/payment";
import { RaffleProductButton } from "../../../components/button/Buttons";
import qs from "query-string";
import {
    CheckboxBed,
    CheckboxLabel,
    LabelText,
} from "../../../containers/spot-the-ball/form/SpotTheBallForm.container.styled";

class SuperDrawCheckoutPage extends React.Component {
    state = {
        buyerName: { value: "", error: false },
        buyerEmail: { value: "", error: false },
        buyerPhoneNumber: { value: "", error: false },
        terms: { value: false },
    };

    componentDidMount() {
        this.validateForm();
        const { price, quantity } = qs.parse(this.props.location.search);
        this.setState({ price, quantity });
    }

    onFieldChange = (field, value) => {
        const state = { ...this.state };
        state[field] = { value, error: false };
        this.setState(state, () => {
            this.validateForm();
        });
    };

    handlePaymentError = (error) => {
        this.setState({ isSubmitting: false });
        toast(
            error.response?.data?.message ||
                this.props.t("toast.form_submit_error"),
            { type: "error" }
        );
    };

    pay = async () => {
        const {
            seller,
            location,
            match: {
                params: { salesLinkCodeOrShortId },
            },
        } = this.props;
        const { source, profileId } = qs.parse(location.search);
        const { buyerName, buyerEmail, buyerPhoneNumber, quantity } =
            this.state;

        this.setState({ isSubmitting: true });

        initiateStandardPayment({
            cart: [
                {
                    quantity,
                },
            ],
            seller,
            type: "CARD",
            source,
            sellerProfileId: profileId,
            formData: {
                name: buyerName.value,
                email: buyerEmail.value,
                phoneNumber: buyerPhoneNumber.value,
            },
            errorCallback: this.handlePaymentError,
            salesLinkCodeOrShortId,
        });
    };

    validateForm = () => {
        const { buyerName, buyerEmail, buyerPhoneNumber } = this.state;
        let hasError = false;

        if (!buyerName.value) {
            this.setState({
                buyerName: { value: buyerName.value, error: true },
            });
            hasError = true;
        } else {
            this.setState({
                buyerName: { value: buyerName.value, error: false },
            });
        }

        if (!buyerEmail.value || buyerEmail.value.indexOf("@") === -1) {
            this.setState({
                buyerEmail: { value: buyerEmail.value, error: true },
            });
            hasError = true;
        } else {
            this.setState({
                buyerEmail: { value: buyerEmail.value, error: false },
            });
        }

        if (!buyerPhoneNumber.value) {
            this.setState({
                buyerPhoneNumber: {
                    value: buyerPhoneNumber.value,
                    error: true,
                },
            });
            hasError = true;
        } else {
            //const phoneNumber = parsePhoneNumber(buyerPhoneNumber.value, 'GB')
            if (
                !!!buyerPhoneNumber.value ||
                buyerPhoneNumber.value.length === 0
            ) {
                this.setState({
                    buyerPhoneNumber: {
                        value: buyerPhoneNumber.value,
                        error: true,
                    },
                });
                hasError = true;
            } else {
                this.setState({
                    buyerPhoneNumber: {
                        value: buyerPhoneNumber.value,
                        error: false,
                    },
                });
            }
        }

        return hasError;
    };

    render() {
        const { seller, t, location } = this.props;
        const { imageUrl, clubImageUrl, shortId, isUsingOrgImage, theme } =
            seller;
        const {
            buyerName,
            buyerEmail,
            buyerPhoneNumber,
            hasSubmitted,
            price,
            quantity,
            terms,
        } = this.state;

        const cartTotal = parseInt(price);

        const FormLabelExt = ({ error, children }) => (
            <>
                {!hasSubmitted && <FormLabel>{children}</FormLabel>}
                {hasSubmitted && (
                    <FormLabel style={{ color: error ? "#cc0000" : "inherit" }}>
                        {children}
                        {error && <span>&nbsp;(required)</span>}
                    </FormLabel>
                )}
            </>
        );

        const formHasErrors =
            buyerName.error ||
            buyerEmail.error ||
            buyerPhoneNumber.error ||
            !!!terms.value;

        return (
            <SuperDrawCheckoutBed>
                <ScrollToTop />
                <div>
                    <div
                        style={{
                            margin: "25px auto 30px auto",
                            textAlign: "center",
                        }}
                    >
                        <Avatar
                            width="100px"
                            imageUrl={imageUrl}
                            clubImageUrl={clubImageUrl}
                            shortId={`${shortId.substring(0, 6)}B`}
                            style={{ border: "1px solid white" }}
                            isUsingOrgImage={isUsingOrgImage}
                        />
                    </div>

                    <div style={{ margin: "30px auto" }}>
                        <h2>{t("landing.please_enter_contact_details")}</h2>
                        <p
                            style={{
                                textAlign: "center",
                                marginTop: "10px",
                                marginBottom: "0",
                            }}
                        >
                            {t(
                                "landing.enter_your_contact_details_description"
                            )}
                        </p>
                        <FormHolder>
                            <FormLabelExt error={buyerName.error}>
                                {t("general.name")}
                            </FormLabelExt>
                            <Input
                                type="text"
                                id="your_name"
                                placeholder={t("general.your_name")}
                                required
                                onChange={(e) =>
                                    this.onFieldChange(
                                        "buyerName",
                                        e.target.value
                                    )
                                }
                            />

                            <FormLabelExt error={buyerEmail.error}>
                                {t("general.email")}
                            </FormLabelExt>
                            <Input
                                type="text"
                                placeholder={t("general.your_email")}
                                id="your_email"
                                required
                                onChange={(e) =>
                                    this.onFieldChange(
                                        "buyerEmail",
                                        e.target.value
                                    )
                                }
                            />

                            <FormLabelExt error={buyerPhoneNumber.error}>
                                {t("general.phoneNumber")}
                            </FormLabelExt>
                            <Input
                                type="text"
                                placeholder={t("general.phoneNumber")}
                                id="phoneNumber"
                                required
                                onChange={(e) =>
                                    this.onFieldChange(
                                        "buyerPhoneNumber",
                                        e.target.value
                                    )
                                }
                            />

                            <div style={{ margin: "20px auto 0 auto" }}>
                                <h2
                                    style={{
                                        marginBottom: "10px",
                                        textAlign: "center",
                                    }}
                                >
                                    {t("checkout.order_summary")}
                                </h2>
                                <div
                                    style={{
                                        display: "table",
                                        tableLayout: "fixed",
                                        width: "100%",
                                    }}
                                >
                                    {/*{presentableCart.map((pc, idx) => (*/}
                                    {/*  <div style={{ display: 'table-row' }} key={idx}>*/}
                                    {/*    <div style={{ display: 'table-cell', textAlign: 'left', padding: '10px 0', borderBottom: '1px solid #eee'}}>*/}
                                    {/*      {pc.product.title}*/}
                                    {/*    </div>*/}
                                    {/*    <div style={{ display: 'table-cell', textAlign: 'right', padding: '10px 0', borderBottom: '1px solid #eee'}}>*/}
                                    {/*      <CurrencyFormat value={pc.product.price} fixedDecimalScale={2} t={t} />*/}
                                    {/*    </div>*/}
                                    {/*  </div>*/}
                                    {/*))}*/}
                                    <div style={{ display: "table-row" }}>
                                        <div
                                            style={{
                                                display: "table-cell",
                                                textAlign: "left",
                                                padding: "10px 0",
                                                borderBottom: "1px solid #eee",
                                                fontWeight: "500",
                                            }}
                                        >
                                            {t("superdraw.x_entries", {
                                                count: quantity,
                                            })}
                                        </div>
                                        <div
                                            style={{
                                                display: "table-cell",
                                                textAlign: "right",
                                                padding: "10px 0",
                                                borderBottom: "1px solid #eee",
                                                fontWeight: "500",
                                            }}
                                        >
                                            <CurrencyFormat
                                                value={cartTotal}
                                                fixedDecimalScale={2}
                                                t={t}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <CheckboxLabel>
                                <CheckboxBed>
                                    <input
                                        type="checkbox"
                                        id="terms"
                                        onChange={(e) =>
                                            this.onFieldChange(
                                                "terms",
                                                e.target.checked
                                            )
                                        }
                                        checked={terms.value}
                                    />
                                </CheckboxBed>
                                <LabelText
                                    onClick={(e) =>
                                        this.onFieldChange(
                                            "terms",
                                            !terms.value
                                        )
                                    }
                                    dangerouslySetInnerHTML={{
                                        __html: t("superdraw.terms"),
                                    }}
                                />
                            </CheckboxLabel>

                            <ButtonHolder>
                                <RaffleProductButton
                                    theme={theme}
                                    disabled={formHasErrors}
                                    id="pay_action"
                                    onClick={() => this.pay()}
                                >
                                    <PaymentCardIcon />
                                    {t("superdraw.proceed_to_payment")}
                                </RaffleProductButton>
                            </ButtonHolder>
                        </FormHolder>
                    </div>
                </div>
            </SuperDrawCheckoutBed>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: state.sellerReducer.seller,
    cart: state.sellerReducer.cart,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(SuperDrawCheckoutPage))
);
