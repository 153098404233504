import React, { Component } from "react";

const MAX_CANVAS_SIZE = 540;

const scaleCoordinates = (x, y, inResX, inResY, outResX, outResY) => {
    const scaleFactorX = outResX / inResX;
    const scaleFactorY = outResY / inResY;

    return {
        x: x * scaleFactorX,
        y: y * scaleFactorY,
    };
};

class SpotTheBallResultCanvas extends Component {
    state = {
        judgedPosition: {},
        attempts: [],
        otherAttempts: [],
        canvasWidth: MAX_CANVAS_SIZE,
        canvasHeight: 0,
    };

    renderCanvas = () => {
        const {
            canvasWidth,
            canvasHeight,
            attempts,
            judgedPosition,
            otherAttempts,
        } = this.state;
        const { highlightClosest } = this.props;
        const { backgroundImage } = this;

        // Protect against undef refs while unmounting
        if (!this.backgroundCanvasRef) {
            return;
        }

        // Calculate scaled guess positions

        const scaledattempts = attempts.map(({ xCoord, yCoord, resX, resY }) =>
            scaleCoordinates(
                xCoord,
                yCoord,
                resX,
                resY,
                canvasWidth,
                canvasHeight
            )
        );
        const scaledJudgedPosition = judgedPosition
            ? scaleCoordinates(
                  judgedPosition.xCoord,
                  judgedPosition.yCoord,
                  judgedPosition.resX,
                  judgedPosition.resY,
                  canvasWidth,
                  canvasHeight
              )
            : {};
        const scaledOtherAttempts = otherAttempts
            ? otherAttempts.map(({ xCoord, yCoord, resX, resY }) =>
                  scaleCoordinates(
                      xCoord,
                      yCoord,
                      resX,
                      resY,
                      canvasWidth,
                      canvasHeight
                  )
              )
            : [];

        const ctxBackground = this.backgroundCanvasRef.getContext("2d");
        const backgroundCanvasRect =
            this.backgroundCanvasRef.getBoundingClientRect();
        this.backgroundCanvasRef.width =
            backgroundCanvasRect.width * devicePixelRatio;
        this.backgroundCanvasRef.height =
            backgroundCanvasRect.height * devicePixelRatio;
        ctxBackground.scale(devicePixelRatio, devicePixelRatio);

        ctxBackground.clearRect(
            0,
            0,
            this.backgroundCanvasRef.width,
            this.backgroundCanvasRef.height
        );
        ctxBackground.drawImage(
            backgroundImage,
            0,
            0,
            canvasWidth,
            canvasHeight
        );

        const ctx = this.canvasRef.getContext("2d");
        const canvasRect = this.canvasRef.getBoundingClientRect();
        this.canvasRef.width = canvasRect.width * devicePixelRatio;
        this.canvasRef.height = canvasRect.height * devicePixelRatio;
        ctx.scale(devicePixelRatio, devicePixelRatio);

        // Do not fade guesses if we're not in highlighting closes, i.e. contest has ended
        let ALPHA_LIGHT = 0.7;
        if (highlightClosest) {
            ALPHA_LIGHT = 0.2;
        }

        const ALPHA_DENSE = 1.0;

        ctx.clearRect(0, 0, this.canvasRef.width, this.canvasRef.height);
        ctx.globalCompositeOperation = "multiply";

        // Draw contestant's guesses
        for (let i = 0; i < scaledattempts.length; i++) {
            const { x, y } = scaledattempts[i];

            ctx.beginPath();
            ctx.arc(x, y, 10, 0, 2 * Math.PI, false);
            if (i === 0 && highlightClosest) {
                ctx.globalAlpha = ALPHA_DENSE;
                ctx.fillStyle = "rgba(148, 195, 40, 1.0)";
            } else {
                ctx.globalAlpha = ALPHA_LIGHT;
                ctx.fillStyle = "rgba(255, 255, 255, 1.0)";
            }
            ctx.fill();
        }

        // Draw other contestant guesses
        for (let i = 0; i < scaledOtherAttempts.length; i++) {
            const { x, y } = scaledOtherAttempts[i];
            ctx.globalAlpha = ALPHA_LIGHT;
            ctx.beginPath();
            ctx.arc(x, y, 10, 0, 2 * Math.PI, false);
            ctx.fillStyle = "rgba(255, 255, 255, 1.0)";
            ctx.fill();
        }

        // Draw judges' position
        ctx.globalCompositeOperation = "source-over";
        ctx.globalAlpha = ALPHA_DENSE;
        ctx.beginPath();
        ctx.arc(
            scaledJudgedPosition.x,
            scaledJudgedPosition.y,
            6,
            0,
            2 * Math.PI,
            false
        );
        ctx.fillStyle = "rgba(203, 23, 229, 1.0)";
        ctx.fill();
    };

    componentDidMount() {
        const { imageUrl, attempts, judgedPosition, otherAttempts } =
            this.props;

        this.setState(
            {
                attempts,
                judgedPosition,
                otherAttempts,
            },
            () => {
                this.backgroundImage = new Image();
                this.backgroundImage.onload = () => {
                    this.applyCanvasWidth();
                };
                this.backgroundImage.src = imageUrl;

                // TODO: Should probably add debounce
                window.addEventListener("resize", () =>
                    this.applyCanvasWidth()
                );
                window.addEventListener("deviceorientation", () =>
                    this.applyCanvasWidth()
                );
            }
        );
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.applyCanvasWidth);
        window.removeEventListener("deviceorientation", this.applyCanvasWidth);
    }

    applyCanvasWidth = () => {
        const { contentHolderRef } = this;
        const { width, height } = this.props;
        // Scale the canvas to fit
        const MARGIN = 20;

        // Recalculate canvas size
        if (contentHolderRef) {
            const contentHolderWidth = contentHolderRef.clientWidth;
            const canvasWidth = Math.min(contentHolderWidth, MAX_CANVAS_SIZE);
            const canvasHeight = (canvasWidth * height) / width;

            this.setState({ canvasWidth, canvasHeight }, () => {
                this.renderCanvas();
            });
        } else {
            this.renderCanvas();
        }
    };

    render() {
        const { canvasWidth, canvasHeight } = this.state;

        return (
            <div
                ref={(ref) => {
                    this.contentHolderRef = ref;
                }}
            >
                <div
                    style={{
                        position: "relative",
                        width: `${canvasWidth}px`,
                        height: `${canvasHeight}px`,
                        margin: "0 auto",
                        minHeight: "120px",
                    }}
                >
                    <canvas
                        width={canvasWidth}
                        height={canvasHeight}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: `${canvasWidth}px`,
                            height: `${canvasHeight}px`,
                        }}
                        ref={(ref) => {
                            this.backgroundCanvasRef = ref;
                        }}
                    ></canvas>
                    <canvas
                        width={canvasWidth}
                        height={canvasHeight}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: `${canvasWidth}px`,
                            height: `${canvasHeight}px`,
                            touchAction: "none",
                        }}
                        ref={(ref) => {
                            this.canvasRef = ref;
                        }}
                    ></canvas>
                </div>
            </div>
        );
    }
}

export default SpotTheBallResultCanvas;
