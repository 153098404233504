export const getPricesOrderedByPrice = (prices) => {
    let allPrices = prices;
    if (typeof prices === "string") {
        allPrices = [
            { price: prices, quantity: 1 },
            { price: prices * 2, quantity: 2 },
            { price: prices * 4, quantity: 4 },
        ];
    }

    return allPrices.sort((a, b) => {
        if (a.price > b.price) {
            return 1;
        } else if (a.price < b.price) {
            return -1;
        } else {
            return 0;
        }
    });
};

export const getMostExpensivePrice = (prices) => {
    const orderedPrices = getPricesOrderedByPrice(prices);
    return orderedPrices[orderedPrices.length - 1];
};
