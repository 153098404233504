import React, { Component } from "react";
import { TicketCanvas } from "../ticket-canvas/TicketCanvas";
import { withTranslation } from "react-i18next";

import {
    NumberOfTickets,
    RevealAllButton,
    TicketBackgroundHolder,
    TicketHolder,
    TicketInfoButton,
    TicketInnerHolder,
    TicketPriceInfoHolder,
    TicketSubtitle,
    TicketTitle,
    TicketTitleHolder,
} from "./Scratchcard.styled";
import { apiClient } from "../../api/ApiClient";

const INITIAL_TICKET_STATE = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
];

class Scratchcard extends Component {
    constructor(props) {
        super(props);
        const { width, height } = this.calculateScratchcardSize();

        this.state = {
            openAll: false,
            scratchComplete: false,
            introAnimationComplete: false,
            ticketScratchState: [].concat(INITIAL_TICKET_STATE),
            width,
            height,
        };
    }

    calculateScratchcardSize() {
        const windowHeight = Math.min(
            800,
            window.innerWidth < 650
                ? window.innerHeight
                : window.innerHeight - 50
        );

        const width = Math.min(480, window.innerWidth);
        const height = windowHeight;
        return { width, height };
    }

    componentDidUpdate(prevProps) {
        const { ticket } = this.props;
        const { ticket: prevTicket } = prevProps;
        if (ticket.id !== prevTicket.id) {
            this.setState({
                openAll: false,
                scratchComplete: false,
                introAnimationComplete: false,
                ticketScratchState: [].concat(INITIAL_TICKET_STATE),
            });
        }
    }

    initializeState = () => {
        const { width, height } = this.calculateScratchcardSize();
        this.setState({
            introAnimationComplete: false,
            scratchComplete: false,
            openAll: false,
            ticketScratchState: [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
            ],
            width,
            height,
        });
    };

    onInternalFinished = (hasPrize) => {
        const { onFinishedScratching } = this.props;
        onFinishedScratching(hasPrize);
    };

    onIntroAnimationComplete = () => {
        this.setState({ introAnimationComplete: true });
    };

    getWinnerNumber = (numbers) => {
        const dict = {};

        numbers.forEach((number) => {
            if (!dict[number]) {
                dict[number] = 1;
            } else {
                dict[number] = dict[number] + 1;
            }
        });

        const winnerNumbers = Object.entries(dict).filter(
            ([_, entry]) => entry === 3
        );

        return winnerNumbers.length > 0
            ? parseInt(winnerNumbers[0][0], 10)
            : null;
    };

    onInfoClicked = () => {
        const { onInfoClicked: parentOnInfoClicked } = this.props;
        parentOnInfoClicked();
    };

    checkScratchedCards = () => {
        const { ticketScratchState } = this.state;
        const { ticketNumbers } = this.props;

        const winnerNumber = this.getWinnerNumber(ticketNumbers);

        const allTiles = ticketNumbers.map((number, index) => ({
            number,
            isScratched: ticketScratchState[index],
        }));
        const scratchedTickets = allTiles.filter((tile) => tile.isScratched);
        const isThreeIdenticalScratched =
            scratchedTickets.filter((tile) => tile.number === winnerNumber)
                .length === 3;
        const isAllScratched = scratchedTickets.length === 9;

        if (isThreeIdenticalScratched || isAllScratched) {
            if (isThreeIdenticalScratched) {
                setTimeout(() => {
                    this.ticketCanvas.highlightWinner(winnerNumber);
                }, 1000);
            } else {
                setTimeout(() => {
                    this.ticketCanvas.revealAll();
                }, 500);
            }

            this.revealTicket();

            setTimeout(() => {
                this.setState({ scratchComplete: true });
                const timeoutDelay = isThreeIdenticalScratched ? 3000 : 900;

                setTimeout(
                    () => this.onInternalFinished(isThreeIdenticalScratched),
                    timeoutDelay
                );
            }, 1500);
        }
    };

    revealTicket = () => {
        const {
            ticket,
            orderId,
            seller: { shortId },
        } = this.props;
        apiClient
            .revealLotteryTicket(shortId, orderId, ticket.id)
            .catch((err) => {
                console.error("Error while revealing scratchcard", err);
            });
    };

    onClickOpenAll = () => {
        this.setState(
            {
                openAll: true,
                ticketScratchState: this.state.ticketScratchState.map(
                    () => true
                ),
            },
            () => {
                this.checkScratchedCards();
                this.ticketCanvas.revealAll();
            }
        );
    };

    onSingleScratchComplete = (index) => {
        const { ticketScratchState } = this.state;

        if (ticketScratchState[index]) {
            return;
        }

        const updatedScratchState = [...ticketScratchState];
        updatedScratchState[index] = true;

        this.setState(
            (state) => ({ ...state, ticketScratchState: updatedScratchState }),
            () => {
                this.checkScratchedCards();
            }
        );
    };

    render() {
        const {
            ticketScratchState,
            showInfo,
            introAnimationComplete,
            width,
            height,
        } = this.state;
        const {
            ticketNumbers,
            displayName,
            currentTicketNum,
            totalTicketCount,
            seller,
            ticket,
            t,
        } = this.props;

        const winnerNumber = this.getWinnerNumber(ticketNumbers);

        if (!ticketScratchState) {
            return null;
        }

        return (
            <TicketHolder height={height}>
                <TicketBackgroundHolder
                    height={height}
                    bgImage={seller.theme.background}
                >
                    {!showInfo && (
                        <TicketInnerHolder height={height}>
                            <TicketTitleHolder>
                                <TicketTitle
                                    color={seller.theme.primaryColour}
                                    strokeColor={
                                        seller.theme.primaryStrokeColour
                                    }
                                >
                                    {displayName}
                                </TicketTitle>
                                <TicketSubtitle
                                    color={seller.theme.primaryColour}
                                    strokeColor={
                                        seller.theme.primaryStrokeColour
                                    }
                                >
                                    {t("scratch_page.subtitle")}
                                </TicketSubtitle>
                            </TicketTitleHolder>

                            <TicketCanvas
                                ticket={ticket}
                                seller={seller}
                                ticketNumbers={ticketNumbers}
                                winnerNumber={winnerNumber}
                                ref={(holderRef) =>
                                    (this.ticketCanvas = holderRef)
                                }
                                width={width}
                                height={height}
                                singleScratchCompleteCallback={
                                    this.onSingleScratchComplete
                                }
                                introCompleteCallback={
                                    this.onIntroAnimationComplete
                                }
                            />

                            <TicketPriceInfoHolder>
                                <RevealAllButton
                                    buttonColor={
                                        seller.theme.primaryButtonColour
                                    }
                                    buttonStrokeColor={
                                        seller.theme.primaryButtonStrokeColour
                                    }
                                    buttonTextColor={
                                        seller.theme.primaryButtonTextColour
                                    }
                                    disabled={!introAnimationComplete}
                                    introAnimationComplete={
                                        introAnimationComplete
                                    }
                                    onClick={() => this.onClickOpenAll()}
                                >
                                    {t("actions.scratch_all")}
                                </RevealAllButton>
                            </TicketPriceInfoHolder>

                            <NumberOfTickets color={seller.theme.primaryColour}>
                                {totalTicketCount > 1 &&
                                    ` (${currentTicketNum} av ${totalTicketCount})`}
                            </NumberOfTickets>

                            <TicketInfoButton
                                src={seller.theme.prizeInfoImage}
                                disabled={!introAnimationComplete}
                                onClick={() => this.onInfoClicked()}
                            />
                        </TicketInnerHolder>
                    )}
                </TicketBackgroundHolder>
            </TicketHolder>
        );
    }
}

export default withTranslation()(Scratchcard);
